import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ApiError, LoadingStates} from "helpers/types";
import { Currencies } from "models/currencies";
import {UserSettingAnalyzeOptions} from "models/user_setting_analyze_options";
import {UserSettingCostAndFeeOptions} from "models/user_setting_cost_and_fee_options";
import {UserSettingExchangeOptions} from "models/user_setting_exchange_options";

type SettingsLoadingStates = LoadingStates<"list" | "create" | "update" | "duplicate" | "delete" >;

export type SettingsState = {
  loading: SettingsLoadingStates;
  costAndFeesList: UserSettingCostAndFeeOptions[];
  analyzeList: UserSettingAnalyzeOptions[];
  exchangeRates: UserSettingExchangeOptions[];
  defaultCurrencies: Currencies[];
  currentCostAndFeeOption: UserSettingCostAndFeeOptions;
  currentAnalyzeOption: UserSettingAnalyzeOptions;
  error?: ApiError;
};

const initialState: SettingsState = {
  loading: {
    list: false,
    create: false,
    update: false,
    duplicate: false,
    delete: false,
  },
  costAndFeesList: [] as UserSettingCostAndFeeOptions[],
  analyzeList: [] as UserSettingAnalyzeOptions[],
  exchangeRates: [] as UserSettingExchangeOptions[],
  defaultCurrencies: [] as Currencies[],
  currentCostAndFeeOption: {} as UserSettingCostAndFeeOptions,
  currentAnalyzeOption: {} as UserSettingAnalyzeOptions,
  error: {} as ApiError,
};

const SettingsSlice = createSlice({
  name: "Settings",
  initialState,
  reducers: {
    setCostAndFeesList(state, action: PayloadAction<UserSettingCostAndFeeOptions[]>) {
      state.costAndFeesList = action.payload;
    },
    appendToCostAndFeesList(state, action: PayloadAction<UserSettingCostAndFeeOptions>) {
      state.costAndFeesList.push(action.payload);
    },
    removeFromCostAndFeesList(state, action: PayloadAction<UserSettingCostAndFeeOptions>) {
      state.costAndFeesList = state.costAndFeesList.filter((item) => item.userSettingCostAndFeeOptionId !== action.payload.userSettingCostAndFeeOptionId);
    },
    setAsDefaultCostAndFeeItem(state, action: PayloadAction<UserSettingCostAndFeeOptions>) {
      state.costAndFeesList.forEach((item) => {
        if (item.marketplace === action.payload.marketplace) item.isDefault = item.userSettingCostAndFeeOptionId === action.payload.userSettingCostAndFeeOptionId;
      });
    },


    setAnalyzeList(state, action: PayloadAction<UserSettingAnalyzeOptions[]>) {
      state.analyzeList = action.payload;
    },
    appendToAnalyzeList(state, action: PayloadAction<UserSettingAnalyzeOptions>) {
      state.analyzeList.push(action.payload);
    },
    removeFromAnalyzeList(state, action: PayloadAction<UserSettingAnalyzeOptions>) {
      state.analyzeList = state.analyzeList.filter((item) => item.userSettingAnalyzeOptionId !== action.payload.userSettingAnalyzeOptionId);
    },
    setAsDefaultAnalyzeItem(state, action: PayloadAction<UserSettingAnalyzeOptions>) {
      state.analyzeList.forEach((item) => {
        item.isDefault = item.userSettingAnalyzeOptionId === action.payload.userSettingAnalyzeOptionId;
      });
    },



    setExchangeRates(state, action: PayloadAction<UserSettingExchangeOptions[]>) {
      state.exchangeRates = action.payload;
    },
    setDefaultCurrencies(state, action: PayloadAction<Currencies[]>) {
      state.defaultCurrencies = action.payload;
    },
    loading(state, action: PayloadAction<[keyof SettingsLoadingStates, boolean]>) {
      const [operation, loadingState] = action.payload;
      state.loading[operation] = loadingState;
    },
    setCurrentCostAndFeeOption(state, action: PayloadAction<UserSettingCostAndFeeOptions>) {
      state.currentCostAndFeeOption = action.payload;
    },
    setCurrentAnalyzeOption(state, action: PayloadAction<UserSettingAnalyzeOptions>) {
      state.currentAnalyzeOption = action.payload;
    },


    apiError(state, action: PayloadAction<ApiError>) {
      state.error = action.payload;
    },
    reset(state){
      state.costAndFeesList = []
      state.analyzeList = initialState.analyzeList
      state.exchangeRates = initialState.exchangeRates
      state.defaultCurrencies = initialState.defaultCurrencies
      state.currentCostAndFeeOption = initialState.currentCostAndFeeOption
      state.currentAnalyzeOption = initialState.currentAnalyzeOption
      state.error = initialState.error
    }
  },
});

export const {
  setCostAndFeesList,
  appendToCostAndFeesList,
  removeFromCostAndFeesList,
  setAsDefaultCostAndFeeItem,
  setAnalyzeList,
  appendToAnalyzeList,
  removeFromAnalyzeList,
  setAsDefaultAnalyzeItem,
  setExchangeRates,
  setDefaultCurrencies,
  setCurrentCostAndFeeOption,
  setCurrentAnalyzeOption,
  loading,
  apiError,
  reset
} = SettingsSlice.actions;

export default SettingsSlice.reducer;
