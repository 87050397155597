import AmazonMarketplaceInfos, {AmazonMarketplaceInfosType} from "Components/Common/AmazonMarketplaceInfos";
import {useProfile} from "Components/Hooks/UserHooks";
import {UserSearchProduct} from "models/user_search_product";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Card, CardBody} from "reactstrap";
import DisplayDate from "Components/Common/DisplayDate";

interface ProductAnalyzeProps {
  row: UserSearchProduct;
}
const ProductAnalyze = ({row}: ProductAnalyzeProps) => {
  const {t} = useTranslation();
  const {userProfile} = useProfile();
  const [amazonMarketplaceInfos] = useState(AmazonMarketplaceInfos());
  const [sourceMarketplace, setSourceMarketplace] = useState<AmazonMarketplaceInfosType>();
  const [destinationMarketplace, setDestinationMarketplace] = useState<AmazonMarketplaceInfosType>();

  useEffect(() => {
    if (row) {
      let source = amazonMarketplaceInfos.find((amazonMarketplaceInfo) => amazonMarketplaceInfo.domain === row.domainSource);
      let destination = amazonMarketplaceInfos.find((amazonMarketplaceInfo) => amazonMarketplaceInfo.domain === row.domainTarget);
      setSourceMarketplace(source);
      setDestinationMarketplace(destination);
    }
  }, [row]); // eslint-disable-line

  return (
    <Card className="border card-animate">
      <CardBody>
        <h5>{row.asin}</h5>
        <ul className="list-group">
          <li className="d-flex justify-content-between">
            <span>{t("Admin.SearchLogs.Box.Results.ProductAnalyze.Label.CreateDate")}</span>
            <span>
              <DisplayDate id={`DisplayDateCreateDate-${row.userSearchProductId}`} value={row.createDate} format="D MMM YYYY HH:mm" tz={userProfile?.timezone} />
            </span>
          </li>
          <li className="d-flex justify-content-between">
            <span>{t("Admin.SearchLogs.Box.Results.ProductAnalyze.Label.StatusDate")}</span>
            <span>
              <DisplayDate id={`DisplayDateStatusDate-${row.userSearchProductId}`} value={row.statusDate} format="D MMM YYYY HH:mm" tz={userProfile?.timezone} />
            </span>
          </li>
          <li className="d-flex justify-content-between">
            <span>{t("Admin.SearchLogs.Box.Results.ProductAnalyze.Label.UpdateDate")}</span>
            <span>
              <DisplayDate id={`DisplayDateUpdateDate-${row.userSearchProductId}`} value={row.updateDate} format="D MMM YYYY HH:mm" tz={userProfile?.timezone} />
            </span>
          </li>
          <li className="d-flex justify-content-between">
            <span>{t("Admin.SearchLogs.Box.Results.ProductAnalyze.Label.Duration")}</span>
            <span>TODO ADD</span>
          </li>
          <li className="d-flex justify-content-between">
            <span>{t("Admin.SearchLogs.Box.Results.ProductAnalyze.Label.Completed")}</span>
            <span>{row.completedServiceCount}</span>
            <span>{t("Admin.SearchLogs.Box.Results.ProductAnalyze.Label.Success")}</span>
            <span>{row.successCount}</span>
          </li>
          <li className="d-flex justify-content-between"></li>
        </ul>

        <div className="hstack gap-4 flex-wrap">
          <div className="hstack gap-2">
            <span className="text-nowrap">
              {t(sourceMarketplace?.countryName ?? "")} <img src={sourceMarketplace?.flag} alt="Country Flag" className="rounded-circle" height="20" />
            </span>
            <span>
              <i className="ri-arrow-right-line fs-20 align-bottom"></i>
            </span>
            <span className="text-nowrap">
              <img src={destinationMarketplace?.flag} alt="Country Flag" className="rounded-circle" height="20" /> {t(destinationMarketplace?.countryName ?? "")}
            </span>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default ProductAnalyze;
