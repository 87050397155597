import useSelectOptions, {MultiSelectTranslations, SelectOptionsType} from "../Hooks/SelectOptions";
import {FormikValidation} from "helpers/types";
import {FormFeedback} from "reactstrap";
import {MultiSelect} from "react-multi-select-component";
import {defaultItemRenderer} from "./Select/MultiSelectStyle";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {useSelector} from "react-redux";

interface ValidatedMultiSelectProps<T> {
  value: any;
  field: keyof T;
  options: SelectOptionsType[];
  isClearable?: boolean;
  validation: FormikValidation<T>;
  className?: string;
  placeholder?: string;
  translations?: Partial<MultiSelectTranslations>;
}
const ValidatedMultiSelect = <T,>(componentProps: ValidatedMultiSelectProps<T>) => {
  const {multiSelectTranslations} = useSelectOptions();
  const fieldName = componentProps.field.toString();
  const {error, touched} = componentProps.validation.getFieldMeta(fieldName);

  const handleChange = (selectedOption: any) => {
    const newValue = selectedOption ? selectedOption.map((option: any) => option.value) : undefined;
    componentProps.validation.setFieldValue(fieldName, newValue);
  };
  const layoutData = createSelector(
    (state: RootState) => state,
    (state) => ({
      layoutModeType: state.Layout.layoutModeType,
    }),
  );
  const {layoutModeType} = useSelector(layoutData);

  const mergedTranslations: MultiSelectTranslations = {
    ...multiSelectTranslations,            // Varsayılan çeviriler
    ...componentProps.translations,        // Yalnızca override edilen key'ler geçerli olacak
  };

  return (
    <>
      <MultiSelect
        className={`${componentProps.className} ${layoutModeType}`}
        ItemRenderer={defaultItemRenderer}
        options={componentProps.options}
        value={
          componentProps.options?.filter((option: any) => {
            return componentProps.value && componentProps.value.includes(option.value);
          }) || []
        }
        onChange={handleChange}
        labelledBy={"Select"}
        isCreatable={false}
        overrideStrings={mergedTranslations}
      />
      {touched && error ? (
        <FormFeedback type="invalid" className="searches validation-width">
          {error}
        </FormFeedback>
      ) : null}
    </>
  );
};

export default ValidatedMultiSelect;
