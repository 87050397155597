import {Option} from "react-multi-select-component";
import {Input, Label} from "reactstrap";

export interface DefaultItemRendererProps {
  checked: boolean;
  option: Option;
  disabled?: boolean;
  onClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
export const defaultItemRenderer = ({checked, option, onClick, disabled}: DefaultItemRendererProps) => (
  <div className={`d-flex align-items-center item-renderer ${disabled ? "disabled" : ""}`}>
    <Input id={`Option-${option.value}`} className="option-checkbox-style" type="checkbox" onChange={(event: React.ChangeEvent<HTMLInputElement>) => onClick(event)} checked={checked} tabIndex={-1} disabled={disabled} />
    <Label htmlFor={`Option-${option.value}`} className="option-label-style" check>
      <span className="ellipsis-for-option" title={option.label}>
        {option.label}
      </span>
    </Label>
  </div>
);