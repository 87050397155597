import DataTable, {DataTableRef} from "Components/Common/DataTable";
import {useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import {Card, CardBody, Container, Spinner} from "reactstrap";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {useTranslation} from "react-i18next";
import {ColumnDef} from "@tanstack/react-table";
import {RootState} from "slices";
import {useImmer} from "use-immer";
import {useDebounce} from "Components/Hooks/HelperHooks";
import {ScanAndSave, ScanAndSaveStatus} from "models/scan_and_save";
import {deleteScan, exportAsins, getScanAndSaveList, resetScanAndSaveState} from "slices/scan-and-save/thunk";
import {formatDate} from "helpers/utilities";
import {useProfile} from "Components/Hooks/UserHooks";
import {ConstantPage} from "helpers/permission_helper";
import {ScanAndSaveQuery} from "api/query";
import BreadCrumb from "Components/Common/BreadCrumb";
import Filters from "./Filters";
import Loader from "Components/Common/Loader";
import SendAnalysis from "./Modals/SendAnalysis";
import Restricted from "Components/Common/Restricted";
import Unauthorized from "pages/Errors/_Unauthorized";
import NewScan from "./Modals/NewScan";
import NoResult from "Components/Common/NoResult";
import RenderNumber from "Components/Common/RenderNumber";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import Dialog, {DialogRef} from "Components/Common/Dialog";
import DisplayDate from "Components/Common/DisplayDate";
import _ from "lodash";

export type ScanAndSaveListRef = {
  reload: VoidFunction;
};
interface ScanAndSavePageProps {
  new?: boolean;
}
const PAGE_IDENTIFIER: ConstantPage = "scanAndSave";
const ScanAndSavePage = (props: ScanAndSavePageProps) => {
  const {t} = useTranslation();
  const {userProfile, hasPermission} = useProfile();
  const [selectedScan, setSelectedScan] = useState<ScanAndSave>();
  const [isSendingAnalysis, setIsSendingAnalysis] = useState(false);
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const tableRef = useRef<DataTableRef>(null);
  const listRef = useRef<ScanAndSaveListRef>(null);
  const location = useLocation();
  const deleteDialogRef = useRef<DialogRef>(null);

  const [query, updateQuery] = useImmer<ScanAndSaveQuery>({
    filtering: true,
    startDate: undefined,
    endDate: undefined,
    page: 1,
    pageSize: 10,
  });

  const debouncedLoadList = useDebounce(() => {
    if (hasPermission(PAGE_IDENTIFIER)) {
      getScanAndSaveList(query)(dispatch).then(() => {
        tableRef.current?.resetSelection();
      });
    }
  }, 200);

  useEffect(() => {
    if (query.startDate !== undefined && query.endDate !== undefined) {
      if (location.pathname !== "/scan-and-save/new") {
        navigate(`/scan-and-save?start=${formatDate(query.startDate)}&end=${formatDate(query.endDate, true)}`, {replace: true});
      }
      debouncedLoadList();
    }
  }, [debouncedLoadList, query]); // eslint-disable-line

  useImperativeHandle(
    listRef,
    () => {
      return {
        reload: () => {
          debouncedLoadList();
        },
      };
    },
    [debouncedLoadList],
  );

  const scanAndSaveData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.ScanAndSave.loading,
      list: state.ScanAndSave.list,
    }),
  );

  const {loading, list} = useSelector(scanAndSaveData);

  const columns = useMemo<ColumnDef<ScanAndSave, any>[]>(
    () => [
      {
        header: t("Actions"),
        size: 90,
        enableResizing: false,
        cell: (cellProps) => {
          const row = cellProps.row.original as ScanAndSave;
          const [isExporting, setIsExporting] = useState(false); // eslint-disable-line
          return (
            <div className="d-flex align-items-center">
              <Link
                to=""
                id={`Analyze-${row.id}`}
                className={`btn btn-ghost-secondary px-1 py-0 fs-18 ${row.total === 0 || row.total === row.sent ? "disabled" : ""}`}
                onClick={() => {
                  setSelectedScan(row);
                  setIsSendingAnalysis(true);
                }}
              >
                <i className="mdi mdi-chart-timeline-variant-shimmer"></i>
              </Link>
              <DefaultUncontrolledTooltip target={`Analyze-${row.id}`}>{t("ScanAndSave.Button.Analyze")}</DefaultUncontrolledTooltip>

              <Link
                to=""
                id={`DownloadScan-${row.id}`}
                className={`btn btn-ghost-secondary px-1 py-0 fs-18 ${row.total === 0 || isExporting ? "disabled" : ""}`}
                onClick={() => {
                  setIsExporting(true);
                  const exportPromise = exportAsins({asinFetcherTaskId: row.id}, row.searchName)(dispatch);
                  exportPromise.then(() => {
                    setIsExporting(false);
                  });
                }}
              >
                {isExporting ? <Spinner size="sm"></Spinner> : <i className="mdi mdi-cloud-download"></i>}
              </Link>
              <DefaultUncontrolledTooltip target={`DownloadScan-${row.id}`}>{t("ScanAndSave.Button.Download")}</DefaultUncontrolledTooltip>

              <Link
                to=""
                id={`DeleteScan-${row.id}`}
                className={`btn btn-ghost-danger px-1 py-0 fs-18 ${row.total === 0 || isExporting ? "disabled" : ""}`}
                onClick={() => {
                  setSelectedScan(row);
                  deleteDialogRef.current?.show();
                }}
              >
                {isExporting ? <Spinner size="sm"></Spinner> : <i className="ri ri-delete-bin-fill"></i>}
              </Link>
              <DefaultUncontrolledTooltip target={`DeleteScan-${row.id}`}>{t("ScanAndSave.Button.Delete")}</DefaultUncontrolledTooltip>
            </div>
          );
        },
      },
      {
        header: t("ScanAndSave.TableColumn.ScanName"),
        accessorKey: "searchName",
        sortingFn: "alphanumeric",
        size: 200,
        cell: (cellProps) => {
          const row = cellProps.row.original as ScanAndSave;
          return <div className="ellipsis-two-lines">{row.searchName}</div>;
        },
      },
      {
        header: t("ScanAndSave.TableColumn.Url"),
        size: 400,
        accessorKey: "url",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as ScanAndSave;
          const truncated = _.truncate(row.url, {length: 60, omission: "..."});

          return (
            <>
              <div className="ellipsis-single-line">
                <Link to={row.url} className="link-secondary link-offset-2 text-decoration-underline" target="_blank">
                  {truncated}
                  <i className="ri-arrow-right-up-line"></i>
                </Link>
              </div>
            </>
          );
        },
      },
      {
        header: t("ScanAndSave.TableColumn.CreateDate"),

        size: 150,
        accessorKey: "createDate",
        sortingFn: "datetime",
        cell: (cellProps) => {
          const row = cellProps.row.original as ScanAndSave;
          return (
            <>
              <DisplayDate id={`DisplayDateScanAndSaveCreateDate-${row.id}`} value={row.createDate} format="D MMM YYYY HH:mm" tz={userProfile?.timezone} />
            </>
          );
        },
      },
      {
        header: t("ScanAndSave.TableColumn.Status"),
        accessorKey: "status",
        sortingFn: "alphanumericCaseSensitive",
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as ScanAndSave;
          let cssClass = "";
          let statuText = "";

          switch (row.status) {
            case ScanAndSaveStatus.DONE:
              cssClass = "bg-success-subtle text-success";
              statuText = "Done";
              break;
            case ScanAndSaveStatus.PLANNED:
              cssClass = "bg-info-subtle text-info";
              statuText = "Planned";
              break;
            case ScanAndSaveStatus.WORKING:
              cssClass = "bg-info-subtle text-info";
              statuText = "Working";
              break;
            case ScanAndSaveStatus.WAITING:
              cssClass = "bg-warning-subtle text-warning";
              statuText = "Waiting";
              break;
            case ScanAndSaveStatus.UNDEFINED:
              cssClass = "bg-secondary-subtle text-secondary";
              statuText = "Undefined";
              break;
            case ScanAndSaveStatus.CANCELED:
              cssClass = "bg-danger-subtle text-danger";
              statuText = "Canceled";
              break;
            default:
              cssClass = ""; // Varsayılan bir sınıf eklemek isterseniz buraya yazabilirsiniz.
              statuText = "";
              break;
          }

          return (
            <>
              <span className={`badge ${cssClass} fs-12`}>
                {statuText === "Working" && <i className="mdi mdi-spin mdi-loading me-1"></i>}
                {t(statuText)}
              </span>
            </>
          );
        },
      },
      {
        header: t("ScanAndSave.TableColumn.Total"),
        accessorKey: "total",
        sortingFn: "alphanumeric",
        size: 90,
        cell: (cellProps) => {
          const row = cellProps.row.original as ScanAndSave;
          return <RenderNumber value={row.total} />;
        },
      },
      {
        header: t("ScanAndSave.TableColumn.Sent"),
        accessorKey: "sent",
        sortingFn: "alphanumeric",
        size: 90,
        cell: (cellProps) => {
          const row = cellProps.row.original as ScanAndSave;
          return <RenderNumber value={row.sent} />;
        },
      },
    ],
    [t], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    return () => {
      dispatch(resetScanAndSaveState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  document.title = t("PageTitles.ScanAndSave");

  //#endregion
  return (
    <Restricted require={PAGE_IDENTIFIER} read fallback={() => <Unauthorized />}>
      <div className="page-content">
        <Container fluid>
          <NewScan isOpen={props.new || false} busy={loading.filter} listRef={listRef} toggle={() => navigate("/scan-and-save")} />
          <BreadCrumb title={t("ScanAndSave.Title")} menus={[{label: t("ScanAndSave.Title")}]} />
          <Filters handleFilter={(f) => updateQuery(f)} busy={loading.filter} page={query.page} pageSize={query.pageSize} listRef={listRef} />
          <Card>
            <CardBody>
              {loading.filter ? (
                <>
                  <Loader />
                </>
              ) : list.items && list.items.length > 0 ? (
                <>
                  <DataTable
                    ref={tableRef}
                    busy={loading.list}
                    columns={columns}
                    data={list?.items || []}
                    totalDataLength={list?.totalCount}
                    pagination={{
                      pageIndex: query.page - 1,
                      pageSize: query.pageSize,
                    }}
                    onPaginationChanged={(pagination) =>
                      updateQuery((q) => {
                        q.page = pagination.pageIndex + 1;
                        q.pageSize = pagination.pageSize;
                        q.filtering = false;
                      })
                    }
                    hovered
                  />
                </>
              ) : (
                <NoResult title={t("ScanAndSave.NoResult.Title")} description={t("ScanAndSave.NoResult.Description")} />
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
      <SendAnalysis isOpen={isSendingAnalysis} selectedScan={selectedScan!} toggle={() => setIsSendingAnalysis(!isSendingAnalysis)} listRef={listRef} />
      <Dialog
        ref={deleteDialogRef}
        color="danger"
        buttons={["yes", "no"]}
        busy={loading.delete}
        iconClass="ri-delete-bin-line"
        message={t("ScanAndSave.Dialog.Delete.Description")}
        title={t("ScanAndSave.Dialog.Delete.Title", {value: selectedScan?.searchName})}
        onButtonClick={async (button, hide) => {
          if (button === "yes") {
            if (selectedScan) {
              const isSuccess = await deleteScan(selectedScan)(dispatch);
              if (isSuccess) {
                deleteDialogRef.current?.hide();
                listRef.current?.reload();
              }
            }
          } else {
            deleteDialogRef.current?.hide();
          }
        }}
      />
    </Restricted>
  );
};

export default ScanAndSavePage;
