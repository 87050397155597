import DataTable, {DataTableRef} from "Components/Common/DataTable";
import Dialog, {DialogRef} from "Components/Common/Dialog";
import {useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import {getSearchStatusDescription, SearchStatus, UserSearch} from "models/user_search";
import {Badge, Button, Card, CardBody, Col, Container, Progress} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {formatDate} from "helpers/utilities";
import {createSelector} from "reselect";
import {useTranslation} from "react-i18next";
import {useProfile} from "Components/Hooks/UserHooks";
import {ColumnDef} from "@tanstack/react-table";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {RootState} from "slices";
import {useImmer} from "use-immer";
import {useDebounce} from "Components/Hooks/HelperHooks";
import {AdminUserSearchesQuery} from "api/query";
import {cancelSearch, getAdminUserSearchesList, resetAdminSearchesState, restartSearch, undoDeleteSearch} from "slices/admin/searches/thunk";
import {ConstantPage} from "helpers/permission_helper";
import Moment from "react-moment";
import Loader from "Components/Common/Loader";
import Restricted from "Components/Common/Restricted";
import Unauthorized from "pages/Errors/_Unauthorized";
import AmazonMarketplaceInfos from "Components/Common/AmazonMarketplaceInfos";
import BreadCrumb from "Components/Common/BreadCrumb";
import Filters from "./Filters";
import i18n from "i18n";
import NoResult from "Components/Common/NoResult";
import SelectOptions from "Components/Hooks/SelectOptions";
import defaultAvatar from "assets/images/user-dummy-img.jpg";
import RenderNumber from "Components/Common/RenderNumber";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import DisplayDate from "Components/Common/DisplayDate";

export type AdminSearchesListRef = {
  reload: VoidFunction;
};
interface AdminSearchesListProps {
  edit?: boolean;
}
const PAGE_IDENTIFIER: ConstantPage = "adminSearch";
const AdminSearchesPage = (props: AdminSearchesListProps) => {
  const {t} = useTranslation();
  const dispatch: any = useDispatch();
  const tableRef = useRef<DataTableRef>(null);
  const restartDialogRef = useRef<DialogRef>(null);
  const cancelDialogRef = useRef<DialogRef>(null);
  const undoDeleteDialogRef = useRef<DialogRef>(null);
  const listRef = useRef<AdminSearchesListRef>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const [currentSearch, setCurrentSearch] = useState<UserSearch>();
  const {marketplaceSelectOptions} = SelectOptions();
  const {userProfile, hasPermission} = useProfile();
  const [amazonMarketplaceInfos] = useState(AmazonMarketplaceInfos());
  const [query, updateQuery] = useImmer<AdminUserSearchesQuery>({
    filtering: true,
    startDate: undefined,
    endDate: undefined,
    page: 1,
    pageSize: 10,
  });

  const debouncedLoadList = useDebounce(() => {
    if (hasPermission(PAGE_IDENTIFIER)) {
      getAdminUserSearchesList(query)(dispatch).then(() => {
        tableRef.current?.resetSelection();
      });
    }
  }, 200);

  useEffect(() => {
    if (query.endDate !== undefined && query.startDate !== undefined) {
      if (location.pathname !== "/searches/new") {
        navigate(`/admin/searches?start=${formatDate(query.startDate)}&end=${formatDate(query.endDate, true)}`, {replace: true});
      }
      debouncedLoadList();
    }
  }, [debouncedLoadList, query]); // eslint-disable-line

  useImperativeHandle(
    listRef,
    () => {
      return {
        reload: () => {
          debouncedLoadList();
        },
      };
    },
    [debouncedLoadList],
  );

  const searchesData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.AdminSearches.loading,
      searchesList: state.AdminSearches.searchesList,
    }),
  );

  const {loading, searchesList} = useSelector(searchesData);

  const columns = useMemo<ColumnDef<UserSearch, any>[]>(
    () => [
      {
        header: t("Actions"),
        size: 50,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearch;
          const statusText: SearchStatus = row.status;
          return (
            <>
              <div className="d-flex flex-row align-items-center">
                {statusText !== SearchStatus.IN_PROGRESS && ( // Restart button is only visible when the search is not in progress
                  <Col xs="auto">
                    <Link
                      to=""
                      id={`RestartSearch-${row.userSearchId}`}
                      className="btn btn-ghost-success px-1 py-0 fs-18"
                      onClick={() => {
                        setCurrentSearch(row);
                        restartDialogRef.current?.show();
                      }}
                    >
                      <i className="mdi mdi-sync-circle"></i>
                    </Link>
                    <DefaultUncontrolledTooltip target={`RestartSearch-${row.userSearchId}`}>{t("Admin.Searches.Tooltip.Restart")}</DefaultUncontrolledTooltip>
                  </Col>
                )}

                <Col xs="auto">
                  <Link to={`/admin/search-logs/${row.userSearchId}`} id={`LogSearch-${row.userSearchId}`} className="btn btn-ghost-secondary px-1 py-0 fs-18" target="_blank">
                    <i className="mdi mdi-text-box"></i>
                  </Link>
                  <DefaultUncontrolledTooltip target={`LogSearch-${row.userSearchId}`}>{t("Admin.Searches.Tooltip.Logs")}</DefaultUncontrolledTooltip>
                </Col>
                {statusText === SearchStatus.IN_PROGRESS && ( // Cancel button is only visible when the search is in progress
                  <Col xs="auto">
                    <Link
                      to=""
                      id={`CancelSearch-${row.userSearchId}`}
                      className="btn btn-ghost-danger px-1 py-0 fs-18"
                      onClick={() => {
                        setCurrentSearch(row);
                        cancelDialogRef.current?.show();
                      }}
                    >
                      <i className="mdi mdi-close-circle"></i>
                    </Link>
                    <DefaultUncontrolledTooltip target={`CancelSearch-${row.userSearchId}`}>{t("Admin.Searches.Tooltip.Cancel")}</DefaultUncontrolledTooltip>
                  </Col>
                )}
              </div>
            </>
          );
        },
      },
      {
        header: t("Admin.Searches.TableColumn.User"),
        accessorFn: (row) => row.user.fullName,
        size: 100,
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearch;
          return (
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 me-2">
                <img src={row.user.avatar || defaultAvatar} className="rounded-circle avatar-sm img-thumbnail user-profile-image" alt="user-profile" />
              </div>
              <div className="flex-grow-1 ellipsis-two-lines">
                <div className="fw-semibold">
                  {row.user.fullName}
                  {row.user.userId === userProfile?.userId && <Badge className="ms-1">{t("You")}</Badge>}
                </div>
                <div className="text-muted" title={row.user.email}>
                  {row.user.email}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        header: t("Admin.Searches.TableColumn.SearchName"),
        accessorKey: "name",
        sortingFn: "alphanumeric",
        size: 250,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearch;
          return (
            <Link className="link-secondary link-offset-2 text-decoration-underline me-1" role="button" to={`/searches/search-results/${row.userSearchId}`}>
              {row.name}
            </Link>
          );
        },
      },
      {
        header: t("Admin.Searches.TableColumn.Marketplace"),
        accessorKey: "marketplace",
        sortingFn: "alphanumeric",
        size: 250,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearch;
          return (
            <>
              <span className="hstack gap-2">
                <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                  <img
                    src={amazonMarketplaceInfos.find((amazonMarketplaceInfo: any) => amazonMarketplaceInfo.marketplace === row.marketplaceTarget)?.flag}
                    alt="Country Flag"
                    className=" rounded-circle"
                  />
                </div>
                <div className="flex-grow-1 ellipsis-two-lines">
                  {amazonMarketplaceInfos.find((amazonMarketplaceInfo: any) => amazonMarketplaceInfo.marketplace === row.marketplaceTarget)?.countryName} {`(${row.marketplaceTarget}) `}{" "}
                  {row.userStore ? `- ${row.userStore.name}` : ""}
                </div>
              </span>
            </>
          );
        },
      },
      {
        header: t("Admin.Searches.TableColumn.CreateDate"),
        accessorKey: "createDate",
        sortingFn: "datetime",
        size: 150,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearch;
          return (
            <div className="ellipsis-two-lines">
              <DisplayDate id={`DisplayDateSearchesCreateDate-${row.userSearchId}`} value={row.createDate} format="D MMM YYYY HH:mm" tz={userProfile?.timezone} />
            </div>
          );
        },
      },
      {
        header: t("Admin.Searches.TableColumn.ProcessingTime"),
        accessorFn: (row) => row.statusDate,
        sortingFn: "alphanumeric",
        size: 150,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearch;
          const diffDate = row.status === SearchStatus.IN_PROGRESS ? new Date() : row.statusDate;
          return (
            <>
              <Moment className="text-primary" locale={i18n.language} tz={userProfile?.timezone} diff={row.createDate} decimal={false} unit="minutes">
                {diffDate}
              </Moment>{" "}
              {t("min")}
            </>
          );
        },
      },
      {
        header: t("Admin.Searches.TableColumn.Status"),
        accessorFn: (row) => row.status,
        sortingFn: "alphanumericCaseSensitive",
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearch;

          let cssClass = "";
          switch (row.status) {
            case SearchStatus.COMPLETED:
              cssClass = "bg-success-subtle text-success";
              break;
            case SearchStatus.IN_PROGRESS:
              cssClass = "bg-warning-subtle text-warning";
              break;
            case SearchStatus.INITIAL:
              cssClass = "bg-secondary-subtle text-secondary";
              break;
            default:
              cssClass = "bg-danger-subtle text-danger";
              break;
          }

          return (
            <div className="d-flex align-items-center gap-2">
              {row.deleted ? (
                <>
                  <span className={`float-start badge bg-danger-subtle text-danger fs-12`}>{t("Deleted")}</span>
                  <Button
                    type="button"
                    id={`UndoDelete-${row.userSearchId}`}
                    className="btn btn-ghost-secondary waves-effect py-0 px-1 fs-16"
                    onClick={() => {
                      setCurrentSearch(row);
                      undoDeleteDialogRef.current?.show();
                    }}
                  >
                    <i className="mdi mdi-undo-variant"></i>
                  </Button>
                  <DefaultUncontrolledTooltip target={`UndoDelete-${row.userSearchId}`}>{t("Admin.Searches.Tooltip.UndoDelete")}</DefaultUncontrolledTooltip>
                </>
              ) : (
                <span className={`float-start badge ${cssClass} fs-12`}>{t(getSearchStatusDescription(row.status))}</span>
              )}
            </div>
          );
        },
      },
      {
        header: t("Admin.Searches.TableColumn.Total"),
        accessorKey: "totalProductCount",
        sortingFn: "alphanumeric",
        size: 80,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearch;
          return (
            <Link className="link-secondary link-offset-2 text-decoration-underline me-1" role="button" to={`/searches/search-results/${row.userSearchId}`}>
              <RenderNumber value={row.totalProductCount} />
            </Link>
          );
        },
      },
      {
        header: t("Admin.Searches.TableColumn.Completed"),
        accessorKey: "completedProductCount",
        sortingFn: "alphanumeric",
        size: 80,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearch;
          const statusText: SearchStatus = row.status;
          return statusText === SearchStatus.IN_PROGRESS ? (
            <>
              <div className="align-items-center d-flex">
                <RenderNumber value={row.completedProductCount} />
                <i className="mdi mdi-spin mdi-loading fs-20 ms-2"></i>
              </div>
              <div style={{width: "90%"}}>
                <Progress color="secondary" value={(row.completedProductCount / row.totalProductCount) * 100} className="progress-sm" />
              </div>
            </>
          ) : (
            <RenderNumber value={row.completedProductCount} />
          );
        },
      },
      {
        header: t("Admin.Searches.TableColumn.Sellable"),
        accessorKey: "sellableProductCount",
        sortingFn: "alphanumeric",
        size: 80,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearch;
          return <RenderNumber value={row.sellableProductCount} />;
        },
      },
      {
        header: t("Admin.Searches.TableColumn.Recheck"),
        accessorKey: "recheckProductCount",
        sortingFn: "alphanumeric",
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearch;
          return <RenderNumber value={row.recheckProductCount} />;
        },
      },
      {
        header: t("Admin.Searches.TableColumn.RestrictionCheck"),
        accessorKey: "restrictionCheckCompletedProductCount",
        sortingFn: "alphanumeric",
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearch;
          return <RenderNumber value={row.restrictionCheckCompletedProductCount} />;
        },
      },
    ],
    [t], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    return () => {
      dispatch(resetAdminSearchesState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  document.title = t("PageTitles.Searches");

  //#endregion
  return (
    <Restricted require={PAGE_IDENTIFIER} read fallback={() => <Unauthorized />}>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Searches.Title")} menus={[{label: t("Searches.Title")}]} />
          <Filters
            handleFilter={(f) => updateQuery(f)}
            busy={loading.filter}
            page={query.page}
            pageSize={query.pageSize}
            listRef={listRef}
            marketplaceSelectOptionsWithStores={marketplaceSelectOptions}
          />
          <Card>
            <CardBody>
              {loading.filter ? (
                <>
                  <Loader />
                </>
              ) : searchesList.items && searchesList.items.length > 0 ? (
                <>
                  <DataTable
                    ref={tableRef}
                    busy={loading.list}
                    columns={columns}
                    data={searchesList?.items || []}
                    totalDataLength={searchesList?.totalCount}
                    pagination={{
                      pageIndex: query.page - 1,
                      pageSize: query.pageSize,
                    }}
                    onPaginationChanged={(pagination) =>
                      updateQuery((q) => {
                        q.page = pagination.pageIndex + 1;
                        q.pageSize = pagination.pageSize;
                        q.filtering = false;
                      })
                    }
                    hovered
                  />
                </>
              ) : (
                <NoResult title={t("Searches.NoResult.Title")} description={t("Searches.NoResult.Description")} />
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
      <Dialog
        ref={restartDialogRef}
        color="success"
        buttons={["yes", "no"]}
        busy={loading.restart}
        iconClass="ri-restart-line"
        message={t("Admin.Searches.Dialog.Restart.Description")}
        title={t("Admin.Searches.Dialog.Restart.Title")}
        onButtonClick={async (button, hide) => {
          if (button === "yes") {
            if (currentSearch) {
              await restartSearch(currentSearch, query)(dispatch);
            }
          }
          restartDialogRef.current?.hide();
        }}
      />
      <Dialog
        ref={cancelDialogRef}
        color="danger"
        buttons={["yes", "no"]}
        busy={loading.cancel}
        iconClass="ri-close-circle-line"
        message={t("Admin.Searches.Dialog.Cancel.Description")}
        title={t("Admin.Searches.Dialog.Cancel.Title")}
        onButtonClick={async (button, hide) => {
          if (button === "yes") {
            if (currentSearch) {
              await cancelSearch(currentSearch, query)(dispatch);
            }
          }
          cancelDialogRef.current?.hide();
        }}
      />

      <Dialog
        ref={undoDeleteDialogRef}
        color="primary"
        buttons={["yes", "no"]}
        busy={loading.cancel}
        iconClass="mdi mdi-undo-variant"
        message={t("Admin.Searches.Dialog.UndoDelete.Description")}
        title={t("Admin.Searches.Dialog.UndoDelete.Title")}
        onButtonClick={async (button, hide) => {
          if (button === "yes") {
            if (currentSearch) {
              await undoDeleteSearch(currentSearch, query)(dispatch);
            }
          }
          undoDeleteDialogRef.current?.hide();
        }}
      />
    </Restricted>
  );
};

export default AdminSearchesPage;
