import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Card, CardBody, Col} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {UserInventory} from "models/user_inventory";
import CopyAsinWidget from "Components/Common/CopyAsinWidget";
import DomainToFlag from "Components/Common/DomainToFlag";
import errorImage from "assets/images/svg/product.svg";
import SimpleBar from "simplebar-react";
import NoData from "Components/Common/NoData";
import CountUp from "react-countup";
import DisplayPrice from "Components/Common/DisplayPrice";

const RemainingInventories = () => {
  const {t} = useTranslation();
  const [remainingInventoryList, setRemainingInventoryList] = useState<UserInventory[]>([]);
  const dashboardData = createSelector(
    (state: RootState) => state,
    (state) => ({
      statistics: state.Dashboard.statistics,
      filter: state.Dashboard.filter,
    }),
  );

  const {filter, statistics} = useSelector(dashboardData);

  useEffect(() => {
    if (statistics && statistics.remainingInventories) {
      const list = statistics.remainingInventories.filter((inventory) => inventory.afnFulfillableQuantity > 0);
      setRemainingInventoryList(list);
    }
  }, [statistics]);

  return (
    <React.Fragment>
      <Col xl={4} className="same-height">
        <Card className="card-animate">
          <div className="align-items-center d-flex card-header">
            <h4 className="card-title mb-0 flex-grow-1">{t("Dashboard.RemainingInventory.Title")}</h4>
            <div className="flex-shrink-0">
              <span className="fw-semibold fs-13">
                <CountUp start={0} decimals={0} duration={2} end={remainingInventoryList.length} /> {remainingInventoryList.length > 1 ? t("SKUs") : t("SKU")}
              </span>
            </div>
          </div>
          <CardBody>
            {remainingInventoryList && remainingInventoryList.length > 0 ? (
              <div className="table-responsive table-card">
                <SimpleBar style={{height: "433px"}}>
                  <table className="table-hover table table-centered align-middle mb-0">
                    <tbody>
                      {remainingInventoryList &&
                        remainingInventoryList.map((inventory: UserInventory, index) => {
                          const imagePath = `https://m.media-amazon.com/images/I/${inventory.imageSmall}`;
                          return (
                            <tr key={index}>
                              <td>
                                <div className="d-flex">
                                  <div className="flex-shrink-0">
                                    <div className="avatar-md rounded flex-shrink-0 overflow-hidden">
                                      <img
                                        className="member-img img-fluid d-block rounded"
                                        src={imagePath}
                                        onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                                          e.currentTarget.onerror = null;
                                          e.currentTarget.src = errorImage;
                                        }}
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                  <div className="flex-grow-1 ms-3">
                                    <div className="text-muted d-flex align-items-center text-nowrap mb-1">
                                      <DomainToFlag marketplace={inventory.userStore.marketplace} />
                                      <span>
                                        {inventory.userStore.marketplace} - {inventory.userStore.name}
                                      </span>
                                    </div>
                                    <p className="ellipsis-two-lines mb-0" title={inventory.productName}>
                                      {inventory.productName}
                                    </p>
                                    <div className="d-flex flex-wrap gap-2 align-items-center">
                                      <div className="d-flex">
                                        <CopyAsinWidget Asin={inventory.asin} index={inventory.asin} asLink={true} marketplace={inventory.userStore.marketplace} />
                                      </div>
                                      <div className="d-flex">
                                        <CopyAsinWidget Asin={inventory.sku} index={inventory.sku} asLink={false} />
                                      </div>
                                    </div>
                                    <div className="d-flex gap-1 mt-2">
                                      <span className="badge bg-danger-subtle text-danger fs-12">{inventory.fbaSellerCount} FBA</span>
                                      <span className="badge bg-success-subtle text-success fs-12">{inventory.fbmSellerCount} FBM</span>
                                      <span className="badge bg-primary-subtle text-primary fs-12">
                                        {t("Stock")}: {inventory.afnFulfillableQuantity}
                                      </span>
                                    </div>
                                    <div className="d-flex mt-3">
                                      <span className="ellipsis-single-line">
                                        <i className="text-primary mdi mdi-trophy-variant-outline align-middle me-1"></i>
                                        <span title={inventory.categoryName}>
                                          <strong>BSR:</strong> {inventory.bsr} - {inventory.categoryName}
                                        </span>
                                      </span>
                                    </div>
                                    <div className="d-flex">
                                      <span>
                                        <i className="text-success mdi mdi-bank-plus align-middle me-1"></i>
                                        <strong>{t("Dashboard.RemainingInventory.Label.EstimatedRevenue")}: </strong>
                                        <DisplayPrice source={filter?.currency} value={inventory.salesProceeds} decimals={2}  notation="abbreviated"/>
                                      </span>
                                    </div>
                                    <div className="d-flex">
                                      <span>
                                        <i className="text-secondary mdi mdi-bank-check align-middle me-1"></i>
                                        <strong>{t("Dashboard.RemainingInventory.Label.EstimatedProfit")}: </strong>
                                        <DisplayPrice source={filter?.currency} value={inventory.profit} decimals={2} notation="abbreviated" />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </SimpleBar>
              </div>
            ) : (
              <NoData icon="mdi mdi-note-search-outline" />
            )}
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default RemainingInventories;
