import React, {useEffect, useRef, useState} from "react";
import {Button, Card, CardBody, CardHeader, CardTitle, Col, Container, Form, Input, InputGroup, InputGroupText, Row, Spinner} from "reactstrap";
import {Trans, useTranslation} from "react-i18next";
import {Link, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getAnalyzeOption, resetDefaultAnalyze, updateAnalyzeSettings} from "slices/settings/thunk";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {SelectOptionsType} from "Components/Hooks/SelectOptions";
import {useFormik} from "formik";
import {IUpdateAnalyzeItemDto} from "models/user_setting_analyze_options";
import Dialog, {DialogRef} from "Components/Common/Dialog";
import BreadCrumb, {BreadcrumbMenuItem} from "Components/Common/BreadCrumb";
import SettingsLayout from "Components/Common/Layout/SettingsLayout";
import Select from "react-select";
import ErrorPopover from "Components/Common/ErrorPopover";
import CreateAnalyzeOption from "./Modals/CreateAnalyzeOption";
import * as Yup from "yup";
import {ConstantPage} from "helpers/permission_helper";
import {useProfile} from "Components/Hooks/UserHooks";

const PAGE_IDENTIFIER: ConstantPage = "settings.analyzeOptions";
const AnalyzeOptionsEdit = () => {
  const {t} = useTranslation();
  const {hasPermission} = useProfile();
  const dispatch: any = useDispatch();
  const {userSettingAnalyzeOptionId} = useParams();
  const [settingsOptions, setSettingsOptions] = useState<SelectOptionsType[]>();
  const [selectedSettingOption, setSelectedSettingOption] = useState<SelectOptionsType>();
  const [validationHasErrors, setValidationHasErrors] = useState<boolean>(false);
  const resetDialogRef = useRef<DialogRef>(null);
  const [redirectUrl, setRedirectUrl] = useState<string>("");
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const settingsPageData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Settings.loading,
      currentAnalyzeOption: state.Settings.currentAnalyzeOption,
      analyzeList: state.Settings.analyzeList,
    }),
  );
  const {loading, currentAnalyzeOption, analyzeList} = useSelector(settingsPageData);

  useEffect(() => {
    if (userSettingAnalyzeOptionId && hasPermission(PAGE_IDENTIFIER)) {
      dispatch(getAnalyzeOption(userSettingAnalyzeOptionId));
    }
  }, [dispatch, userSettingAnalyzeOptionId]); //eslint-disable-line

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: currentAnalyzeOption?.name,
      priceChangeRecheckMin: currentAnalyzeOption?.priceChangeRecheckMin,
      priceChangeRecheckMax: currentAnalyzeOption?.priceChangeRecheckMax,
      competitorAnalysisMediumMin: currentAnalyzeOption?.competitorAnalysisMediumMin,
      competitorAnalysisMediumMax: currentAnalyzeOption?.competitorAnalysisMediumMax,
      variationAnalysisRecheck: currentAnalyzeOption?.variationAnalysisRecheck,
      newProductWarningDay: currentAnalyzeOption?.newProductWarningDay,
      maximumDimensionalWeight: currentAnalyzeOption?.maximumDimensionalWeight,
      showCheckList: currentAnalyzeOption?.showCheckList,
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t("Settings.AnalyzeOptions.Validation.Name")),
      priceChangeRecheckMin: Yup.number().required(t("Settings.AnalyzeOptions.Validation.PriceChangeRecheckMin")),
      priceChangeRecheckMax: Yup.number().required(t("Settings.AnalyzeOptions.Validation.PriceChangeRecheckMax")),
      competitorAnalysisMediumMin: Yup.number().required(t("Settings.AnalyzeOptions.Validation.CompetitorAnalysisMediumMin")),
      competitorAnalysisMediumMax: Yup.number().required(t("Settings.AnalyzeOptions.Validation.CompetitorAnalysisMediumMax")),
      variationAnalysisRecheck: Yup.number().required(t("Settings.AnalyzeOptions.Validation.VariationAnalysisRecheck")),
      newProductWarningDay: Yup.number().required(t("Settings.AnalyzeOptions.Validation.NewProductWarningDay")),
      maximumDimensionalWeight: Yup.number().required(t("Settings.AnalyzeOptions.Validation.MaximumDimensionalWeight")),
      showCheckList: Yup.boolean(),
    }),
    onSubmit: (values: any) => {
      const payload: IUpdateAnalyzeItemDto = {
        userSettingAnalyzeOptionId: userSettingAnalyzeOptionId as string,
        name: values.name,
        priceChangeRecheckMin: values.priceChangeRecheckMin,
        priceChangeRecheckMax: values.priceChangeRecheckMax,
        competitorAnalysisMediumMin: values.competitorAnalysisMediumMin,
        competitorAnalysisMediumMax: values.competitorAnalysisMediumMax,
        variationAnalysisRecheck: values.variationAnalysisRecheck,
        newProductWarningDay: values.newProductWarningDay,
        maximumDimensionalWeight: values.maximumDimensionalWeight,
        showCheckList: values.showCheckList,
      };
      dispatch(updateAnalyzeSettings(payload));
    },
  });

  useEffect(() => {
    if (validation && validation.errors) {
      if (Object.keys(validation.errors).length > 0) {
        setValidationHasErrors(true);
      } else {
        setValidationHasErrors(false);
      }
    }
  }, [validation]);

  useEffect(() => {
    if (analyzeList.length > 0) {
      const optionArray: SelectOptionsType[] = [];
      analyzeList.forEach((item) => {
        optionArray.push({value: item.userSettingAnalyzeOptionId, label: item.name});
      });
      setSettingsOptions(optionArray);

      const selectedOption = optionArray.find((option) => option.value === userSettingAnalyzeOptionId);
      if (selectedOption) setSelectedSettingOption(selectedOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyzeList]);

  const breadcrumbMenus: BreadcrumbMenuItem[] = [
    {
      label: t("Settings.Title"),
      url: "/account/settings/analyze-options",
    },
    {
      label: t("Settings.AnalyzeOptions.Title"),
      url: "/account/settings/analyze-options",
    },
    {
      label: currentAnalyzeOption.name,
      url: "",
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Settings.Title")} menus={breadcrumbMenus} />
          <SettingsLayout hasAnyChanges={validation.dirty} navigateTo={redirectUrl}>
            <>
              <Card>
                <CardHeader className="fw-medium">
                  <CardTitle className="fw-medium">
                    <i className="mdi mdi-chart-timeline-variant-shimmer fs-18 align-middle me-1"></i>
                    {t("Settings.AnalyzeOptions.Title")} {">"} {currentAnalyzeOption.name}
                  </CardTitle>
                  <Row className="mt-3">
                    <Col md={4}>
                      <Select
                        id="selectSettings"
                        name="selectSettings"
                        options={settingsOptions}
                        onChange={(e: any) => {
                          const url = `/account/settings/analyze-options/edit/${e.value}`;
                          setRedirectUrl(url);
                        }}
                        value={selectedSettingOption}
                      />
                    </Col>
                    <Col></Col>
                    <Col xs="auto" className="mt-3 mt-md-0">
                      <Button type="button" color="primary" onClick={() => setIsCreateModalOpen(true)}>
                        {t("Settings.AnalyzeOptions.Button.Create")}
                      </Button>
                      <CreateAnalyzeOption isOpen={isCreateModalOpen} toggle={() => setIsCreateModalOpen(!setIsCreateModalOpen)} />
                    </Col>
                  </Row>
                </CardHeader>
              </Card>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col md={6} className="same-height">
                    <Card>
                      <CardHeader className="h4 card-title"> {t("Settings.AnalyzeOptions.Box.PriceChange.Title")} </CardHeader>
                      <CardBody>
                        <Row className="mb-3 align-items-center">
                          <Col>
                            <span>
                              <Trans i18nKey="Settings.AnalyzeOptions.Box.PriceChange.Stable" />
                            </span>
                          </Col>
                          <Col xs="auto">
                            <InputGroup size="sm">
                              <Input
                                id="priceChangeRecheckMin"
                                type="number"
                                className="form-control width-50"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.priceChangeRecheckMin}
                                invalid={validation.touched.priceChangeRecheckMin && validation.errors.priceChangeRecheckMin ? true : false}
                              />
                              <InputGroupText>%</InputGroupText>
                            </InputGroup>
                          </Col>
                        </Row>
                        <Row className="mb-3 align-items-center">
                          <Col>
                            <span>
                              <Trans i18nKey="Settings.AnalyzeOptions.Box.PriceChange.Recheck" />
                            </span>
                          </Col>
                          <Col xs="auto">
                            <InputGroup size="sm">
                              <Input
                                id="priceChangeRecheckMin"
                                type="number"
                                className="form-control width-50"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.priceChangeRecheckMin}
                                invalid={validation.touched.priceChangeRecheckMin && validation.errors.priceChangeRecheckMin ? true : false}
                              />
                              <InputGroupText>%</InputGroupText>
                              <span className="text-muted mx-2">and</span>
                              <Input
                                id="priceChangeRecheckMax"
                                type="number"
                                className="form-control width-50"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.priceChangeRecheckMax}
                                invalid={validation.touched.priceChangeRecheckMax && validation.errors.priceChangeRecheckMax ? true : false}
                              />
                              <InputGroupText>%</InputGroupText>
                            </InputGroup>
                          </Col>
                        </Row>
                        <Row className="mb-3 align-items-center">
                          <Col>
                            <span>
                              <Trans i18nKey="Settings.AnalyzeOptions.Box.PriceChange.NotStable" />
                            </span>
                          </Col>
                          <Col xs="auto">
                            <InputGroup size="sm">
                              <Input
                                id="priceChangeRecheckMax"
                                type="number"
                                className="form-control width-50"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.priceChangeRecheckMax}
                                invalid={validation.touched.priceChangeRecheckMax && validation.errors.priceChangeRecheckMax ? true : false}
                              />
                              <InputGroupText>%</InputGroupText>
                            </InputGroup>
                          </Col>
                        </Row>
                        <Row>
                          <div className="d-flex">
                            <div className="flex-shrink-0 text-info me-2">
                              <i className="mdi mdi-progress-question fs-22 align-middle"></i>
                            </div>
                            <div className="flex-grow-1 text-muted">{t("Settings.AnalyzeOptions.Box.PriceChange.Description")}</div>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md={6} className="same-height">
                    <Card>
                      <CardHeader className="h4 card-title">{t("Settings.AnalyzeOptions.Box.CompetitorAnalysis.Title")} </CardHeader>
                      <CardBody>
                        <Row className="mb-3 align-items-center">
                          <Col>
                            <span>
                              <Trans i18nKey="Settings.AnalyzeOptions.Box.CompetitorAnalysis.Weak" />
                            </span>
                          </Col>
                          <Col xs="auto">
                            <InputGroup size="sm">
                              <Input
                                id="competitorAnalysisMediumMin"
                                type="number"
                                className="form-control width-50"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.competitorAnalysisMediumMin}
                                invalid={validation.touched.competitorAnalysisMediumMin && validation.errors.competitorAnalysisMediumMin ? true : false}
                              />
                              <InputGroupText>#</InputGroupText>
                            </InputGroup>
                          </Col>
                        </Row>
                        <Row className="mb-3 align-items-center">
                          <Col>
                            <span>
                              <Trans i18nKey="Settings.AnalyzeOptions.Box.CompetitorAnalysis.Medium" />
                            </span>
                          </Col>
                          <Col xs="auto">
                            <InputGroup size="sm">
                              <Input
                                id="competitorAnalysisMediumMin"
                                type="number"
                                className="form-control width-50"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.competitorAnalysisMediumMin}
                                invalid={validation.touched.competitorAnalysisMediumMin && validation.errors.competitorAnalysisMediumMin ? true : false}
                              />
                              <InputGroupText>#</InputGroupText>
                              <span className="text-muted mx-2">and</span>
                              <Input
                                id="competitorAnalysisMediumMax"
                                type="number"
                                className="form-control width-50"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.competitorAnalysisMediumMax}
                                invalid={validation.touched.competitorAnalysisMediumMax && validation.errors.competitorAnalysisMediumMax ? true : false}
                              />
                              <InputGroupText>#</InputGroupText>
                            </InputGroup>
                          </Col>
                        </Row>
                        <Row className="mb-3 align-items-center">
                          <Col>
                            <span>
                              <Trans i18nKey="Settings.AnalyzeOptions.Box.CompetitorAnalysis.Strong" />
                            </span>
                          </Col>
                          <Col xs="auto">
                            <InputGroup size="sm">
                              <Input
                                id="competitorAnalysisMediumMax"
                                type="number"
                                className="form-control width-50"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.competitorAnalysisMediumMax}
                                invalid={validation.touched.competitorAnalysisMediumMax && validation.errors.competitorAnalysisMediumMax ? true : false}
                              />
                              <InputGroupText>#</InputGroupText>
                            </InputGroup>
                          </Col>
                        </Row>
                        <Row>
                          <div className="d-flex">
                            <div className="flex-shrink-0 text-info me-2">
                              <i className="mdi mdi-progress-question fs-22 align-middle"></i>
                            </div>
                            <div className="flex-grow-1 text-muted">{t("Settings.AnalyzeOptions.Box.CompetitorAnalysis.Description")}</div>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md={6} className="same-height">
                    <Card>
                      <CardHeader className="h4 card-title">{t("Settings.AnalyzeOptions.Box.VariationAnalysis.Title")}</CardHeader>
                      <CardBody>
                        <Row className="mb-3 align-items-center">
                          <Col>
                            <span>
                              <Trans i18nKey="Settings.AnalyzeOptions.Box.VariationAnalysis.Recheck" />
                            </span>
                          </Col>
                          <Col xs="auto">
                            <InputGroup size="sm">
                              <Input
                                id="variationAnalysisRecheck"
                                type="number"
                                className="form-control width-50"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.variationAnalysisRecheck}
                                invalid={validation.touched.variationAnalysisRecheck && validation.errors.variationAnalysisRecheck ? true : false}
                              />
                              <InputGroupText> {t("Settings.AnalyzeOptions.Label.Piece")}</InputGroupText>
                            </InputGroup>
                          </Col>
                        </Row>
                        <Row>
                          <div className="d-flex">
                            <div className="flex-shrink-0 text-info me-2">
                              <i className="mdi mdi-progress-question fs-22 align-middle"></i>
                            </div>
                            <div className="flex-grow-1 text-muted">{t("Settings.AnalyzeOptions.Box.VariationAnalysis.Description")}</div>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md={6} className="same-height">
                    <Card>
                      <CardHeader className="h4 card-title">{t("Settings.AnalyzeOptions.Box.NewProductWarning.Title")} </CardHeader>
                      <CardBody>
                        <Row className="mb-3 align-items-center">
                          <Col>
                            <span>{t("Settings.AnalyzeOptions.Box.NewProductWarning.ListedProducts")}</span>
                          </Col>
                          <Col xs="auto">
                            <InputGroup size="sm">
                              <Input
                                id="newProductWarningDay"
                                type="number"
                                className="form-control width-50"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.newProductWarningDay}
                                invalid={validation.touched.newProductWarningDay && validation.errors.newProductWarningDay ? true : false}
                              />
                              <InputGroupText>{t("Settings.AnalyzeOptions.Label.Days")}</InputGroupText>
                            </InputGroup>
                          </Col>
                        </Row>
                        <Row>
                          <div className="d-flex">
                            <div className="flex-shrink-0 text-info me-2">
                              <i className="mdi mdi-progress-question fs-22 align-middle"></i>
                            </div>
                            <div className="flex-grow-1 text-muted">{t("Settings.AnalyzeOptions.Box.NewProductWarning.Description")}</div>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md={12} className="same-height">
                    <Card>
                      <CardHeader className="h4 card-title">{t("Settings.AnalyzeOptions.Box.Others.Title")}</CardHeader>
                      <CardBody>
                        <Row>
                          <Col md={6}>
                            <Row>
                              <Col>
                                <span>{t("Settings.AnalyzeOptions.Box.Others.ShowCheckList")}</span>
                              </Col>
                              <Col xs="auto">
                                <div className="form-check form-switch">
                                  {validation.values.showCheckList}
                                  <Input
                                    id="showCheckList"
                                    className="form-check-input form-switch-md "
                                    type="checkbox"
                                    role="switch"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.showCheckList}
                                    checked={validation.values.showCheckList}
                                  />
                                </div>
                              </Col>
                              <Col md={12} className="mt-3">
                                <div className="d-flex">
                                  <div className="flex-shrink-0 text-info me-2">
                                    <i className="mdi mdi-progress-question fs-22 align-middle"></i>
                                  </div>
                                  <div className="flex-grow-1 text-muted">{t("Settings.AnalyzeOptions.Box.Others.ShowCheckListDescription")}</div>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col md={6} className="mt-3 mt-md-0">
                            <Row>
                              <Col>
                                <span>{t("Settings.AnalyzeOptions.Box.Others.MaximumDimensionalWeight")}</span>
                              </Col>
                              <Col xs="auto">
                                <InputGroup size="sm">
                                  <Input
                                    id="maximumDimensionalWeight"
                                    type="number"
                                    className="form-control width-50"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.maximumDimensionalWeight}
                                    invalid={validation.touched.maximumDimensionalWeight && validation.errors.maximumDimensionalWeight ? true : false}
                                  />
                                  <InputGroupText>lbs</InputGroupText>
                                </InputGroup>
                              </Col>
                              <Col md={12} className="mt-3">
                                <div className="d-flex">
                                  <div className="flex-shrink-0 text-info me-2">
                                    <i className="mdi mdi-progress-question fs-22 align-middle"></i>
                                  </div>
                                  <div className="flex-grow-1 text-muted">{t("Settings.AnalyzeOptions.Box.Others.MaximumDimensionalWeightDescription")}</div>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                <Row className="justify-content-end align-items-center ">
                  <Col>
                    <i className="ri-arrow-go-back-line align-middle me-1"></i>
                    <Link
                      color="link"
                      className="link-primary link-offset-2 text-decoration-underline link-underline-opacity-25 link-underline-opacity-100-hover"
                      onClick={() => resetDialogRef.current?.show()}
                      to={""}
                    >
                      {t("Settings.CostAndFees.Button.RestoreDefaultSettings")}
                    </Link>
                  </Col>

                  {validationHasErrors && (
                    <Col xs="auto" className="pe-0">
                      <ErrorPopover validationErrors={validation.errors} />
                    </Col>
                  )}

                  <Col xs="auto">
                    <Button type="submit" color="success" disabled={loading.update || Object.keys(validation.errors).length > 0}>
                      {loading.update && <Spinner size="sm" className="me-2"></Spinner>}
                      {t("Settings.AnalyzeOptions.Button.SaveChanges")}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </>
          </SettingsLayout>
        </Container>
      </div>
      <Dialog
        ref={resetDialogRef}
        color="info"
        buttons={["yes", "no"]}
        busy={loading.update}
        iconClass="ri-arrow-go-back-line"
        message={t("Settings.AnalyzeOptions.Dialog.RestoreDefaultSettings.Description")}
        title={t("Settings.AnalyzeOptions.Dialog.RestoreDefaultSettings.Title")}
        onButtonClick={async (button, hide) => {
          if (button === "yes") {
            await resetDefaultAnalyze(currentAnalyzeOption?.userSettingAnalyzeOptionId)(dispatch);
          }
          resetDialogRef.current?.hide();
        }}
      />
    </React.Fragment>
  );
};

export default AnalyzeOptionsEdit;
