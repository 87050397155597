import React, {useEffect, useState} from "react";
import {UserStore} from "models/user_stores";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Card, CardBody, CardHeader, CardTitle, Col, Row} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";
import DomainToFlag from "Components/Common/DomainToFlag";
import Loader from "Components/Common/Loader";
import i18n from "i18n";
import Moment from "react-moment";
import _ from "lodash";
import DisplayDate from "Components/Common/DisplayDate";
import {useProfile} from "Components/Hooks/UserHooks";

const Summary = () => {
  const {t} = useTranslation();
  const {userProfile} = useProfile();
  const [store, setStore] = useState<UserStore>();
  const ordersData = createSelector(
    (state: RootState) => state,
    (state) => ({
      order: state.OrderDetails.order,
      loading: state.OrderDetails.loading,
    }),
  );
  const {loading, order} = useSelector(ordersData);

  useEffect(() => {
    if (order.userStore) {
      setStore(order.userStore);
    }
  }, [order]);

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <CardTitle className="fw-medium mb-0">{t("OrderDetails.Card.OrderSummary.Title")}</CardTitle>
        </CardHeader>

        <CardBody>
          {loading.list ? (
            <Loader />
          ) : (
            <Row className="g-3">
              <Col lg={3} className="fw-medium">
                {t("OrderDetails.Card.OrderSummary.AmazonsShipBy")}
              </Col>
              <Col lg={3}>
                <Moment locale={i18n.language} format="D MMM YYYY HH:mm">
                  {order.latestShipDate}
                </Moment>
              </Col>
              <Col lg={3} className="fw-medium">
                {t("OrderDetails.Card.OrderSummary.ShippingService")}
              </Col>
              <Col lg={3}>{order.shipmentServiceLevelCategory}</Col>
              <Col lg={3} className="fw-medium">
                {t("OrderDetails.Card.OrderSummary.PurchaseDate")}
              </Col>
              <Col>
                <DisplayDate id={`DisplayDatePurchaseDate-${order.userOrderId}`} value={order.purchaseDate} format="D MMM YYYY HH:mm" tz={userProfile?.timezone} />
              </Col>
              <Col lg={3} className="fw-medium">
                {t("OrderDetails.Card.OrderSummary.Fulfillment")}
              </Col>
              <Col lg={3}>Amazon</Col>
              <Col lg={3}></Col>
              <Col lg={3}></Col>
              <Col lg={3} className="fw-medium">
                {t("OrderDetails.Card.OrderSummary.SalesChannel")}
              </Col>
              <Col lg={3}>
                {store && (
                  <>
                    Amazon.{_.toLower(store.marketplace)} <DomainToFlag marketplace={store.marketplace} />
                  </>
                )}
              </Col>
            </Row>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default Summary;
