import {AmazonMarketplaceInfosType} from "Components/Common/AmazonMarketplaceInfos";
import {Card, CardBody, Col, Input, Row} from "reactstrap";
import {useTranslation} from "react-i18next";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {useSelector} from "react-redux";
import {useCallback, useEffect, useState} from "react";
import {SellerTypeBadge} from "pages/Searches/SearchResults/Item/Components/SellerTypeBadge";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import DisplayPrice from "Components/Common/DisplayPrice";
import RenderNumber from "Components/Common/RenderNumber";
import _ from "lodash";

interface ProfitCalculationProps {
  sourceMarketplace?: AmazonMarketplaceInfosType;
  destinationMarketplace?: AmazonMarketplaceInfosType;
}
const ProfitCalculation = ({sourceMarketplace, destinationMarketplace}: ProfitCalculationProps) => {
  const {t} = useTranslation();

  const productDetailsData = createSelector(
    (state: RootState) => state,
    (state) => ({
      details: state.ProductDetails.details,
    }),
  );
  const {details} = useSelector(productDetailsData);

  const [purchasePriceSource, setPurchasePriceSource] = useState<number | undefined>(details.profitCalculation.purchasePriceSource);
  const [taxSource, setTaxSource] = useState<number | undefined>(details.profitCalculation.taxSource);
  const [prepAndShipSource, setPrepAndShipSource] = useState<number | undefined>(details.profitCalculation.shippingPriceSource);

  const [purchasePrice, setPurchasePrice] = useState<number | undefined>(details.profitCalculation.purchasePrice);
  const [tax, setTax] = useState<number | undefined>(details.profitCalculation.tax);
  const [prepAndShip, setPrepAndShip] = useState<number | undefined>(details.profitCalculation.shippingPrice);

  const [totalCost, setTotalCost] = useState<number | undefined>(details.profitCalculation.totalCost);
  const [totalFees, setTotalFees] = useState<number | undefined>(details.profitCalculation.totalFees);
  const [fbaFee, setFbaFee] = useState<number | undefined>(details.profitCalculation.fbaFee);
  const [referralFee, setReferralFee] = useState<number | undefined>(details.profitCalculation.referralFee);
  const [otherFees, setOtherFees] = useState<number | undefined>(details.profitCalculation.otherFees);
  const [exchangeRate, setExchangeRate] = useState<number | undefined>(details.profitCalculation.exchangeRateTarget);
  const [overallCost, setOverallCost] = useState<number | undefined>(details.profitCalculation.overallCost);
  const [sellPrice, setSellPrice] = useState<number | undefined>(details.profitCalculation.sellPrice);
  const [profit, setProfit] = useState<number | undefined>(details.profitCalculation.profit);
  const [profitSource, setProfitSource] = useState<number | undefined>(details.profitCalculation.profitSource);
  const [margin, setMargin] = useState<number | undefined>(details.profitCalculation.margin);
  const [roi, setRoi] = useState<number | undefined>(details.profitCalculation.roi);

  const handleValueChange = useCallback(() => {
    if (exchangeRate && sellPrice) {
      const _overallCost = (purchasePrice ?? 0) + (tax ?? 0) + (prepAndShip ?? 0) + (fbaFee ?? 0) + (referralFee ?? 0) + (otherFees ?? 0);
      const _profit = sellPrice - _overallCost;
      const _profitSource = _profit / exchangeRate;
      const _margin = (_profit / sellPrice) * 100;
      const _roi = (_profit / ((purchasePrice ?? 0) + (tax ?? 0) + (prepAndShip ?? 0))) * 100;

      setTotalCost((purchasePrice ?? 0) + (tax ?? 0) + (prepAndShip ?? 0));
      setTotalFees((fbaFee ?? 0) + (referralFee ?? 0) + (otherFees ?? 0));
      setOverallCost(_overallCost);

      setProfit(_profit);
      setProfitSource(_profitSource);
      setMargin(_margin);
      setRoi(_roi);
    }
  }, [purchasePrice, tax, prepAndShip, fbaFee, referralFee, otherFees, sellPrice, exchangeRate]);

  const handleSellPriceChange = useCallback(() => {
    if (referralFee && sellPrice) {
      const _oldSellPrice = details?.profitCalculation.sellPrice ?? 1;
      const _referralFee = details.profitCalculation.referralFee * (sellPrice / _oldSellPrice);
      setReferralFee(Number(_referralFee.toFixed(2)));
    }
  }, [sellPrice]); //eslint-disable-line

  const convertCurrencies = useCallback(() => {
    if (!exchangeRate) return;
    if (purchasePriceSource) setPurchasePrice(purchasePriceSource * exchangeRate);
    else setPurchasePrice(undefined);
    if (taxSource) setTax(taxSource * exchangeRate);
    else setTax(undefined);
    if (prepAndShipSource) setPrepAndShip(prepAndShipSource * exchangeRate);
    else setPrepAndShip(undefined);
  }, [purchasePriceSource, taxSource, prepAndShipSource]); //eslint-disable-line

  const initValues = useCallback(() => {
    if (details) {
      setPurchasePriceSource(details.profitCalculation.purchasePriceSource);
      setTaxSource(details.profitCalculation.taxSource);
      setPrepAndShipSource(details.profitCalculation.shippingPriceSource);

      setPurchasePrice(details.profitCalculation.purchasePrice);
      setTax(details.profitCalculation.tax);
      setPrepAndShip(details.profitCalculation.shippingPrice);

      setTotalCost(details.profitCalculation.totalCost);
      setTotalFees(details.profitCalculation.totalFees);
      setFbaFee(details.profitCalculation.fbaFee);
      setReferralFee(details.profitCalculation.referralFee);
      setOtherFees(details.profitCalculation.otherFees);
      setExchangeRate(details.profitCalculation.exchangeRateTarget);
      setOverallCost(details.profitCalculation.overallCost);
      setSellPrice(details.profitCalculation.sellPrice);
      setProfit(details.profitCalculation.profit);
      setProfitSource(details.profitCalculation.profitSource);
      setMargin(details.profitCalculation.margin);
      setRoi(details.profitCalculation.roi);
    }
  }, [details]);

  useEffect(() => {
    convertCurrencies();
  }, [convertCurrencies]);

  useEffect(() => {
    handleValueChange();
  }, [handleValueChange]);

  useEffect(() => {
    handleSellPriceChange();
  }, [handleSellPriceChange]);
  useEffect(() => {
    initValues();
  }, [initValues]);

  return (
      <Card className="card-animate">
        <CardBody>
          <h5>{t("SearchResults.Analyzes.ProfitAnalysis.Title")}</h5>

          <ul className="list-group d-flex flex-group gap-2">
            <li className="d-flex justify-content-between fw-semibold">
              <span>{t("SearchResults.Analyzes.ProfitAnalysis.Label.Costs")}</span>
              <Row>
                <Col>
                  <DisplayPrice
                    source={sourceMarketplace?.currency}
                    value={(purchasePriceSource ?? 0) + (taxSource ?? 0) + (prepAndShipSource ?? 0)}
                    decimals={2}
                    prefix="("
                    suffix=")"
                    notation="abbreviated"
                  />
                </Col>
                <Col>
                  <DisplayPrice source={destinationMarketplace?.currency} value={totalCost} decimals={2} notation="abbreviated" />{" "}
                </Col>
              </Row>
            </li>
            <li className="d-flex align-items-center justify-content-between">
              <span className="ms-3">{t("SearchResults.Analyzes.ProfitAnalysis.Label.PurchasePrice")}</span>
              <span className="d-flex align-items-center">
                <span>
                  <DisplayPrice value={purchasePrice} source={destinationMarketplace?.currency} decimals={2} notation="abbreviated" />
                </span>
                <div className="input-group input-group-sm shipment-details input-group-size ms-2">
                  <Input
                    type="number"
                    className="form-control form-control-sm border-input-group fw-medium"
                    value={purchasePriceSource || " "}
                    onChange={(e) => setPurchasePriceSource(e.target.value === "" ? undefined : Number(e.target.value))}
                  />
                  <span className="input-group-text">{sourceMarketplace?.currency}</span>
                </div>
              </span>
            </li>
            <li className="d-flex align-items-center justify-content-between">
              <span className="ms-3">{t("SearchResults.Analyzes.ProfitAnalysis.Label.Tax")}</span>
              <span className="d-flex align-items-center">
                <span>
                  <DisplayPrice value={tax} source={destinationMarketplace?.currency} decimals={2} notation="abbreviated" />
                </span>
                <div className="input-group input-group-sm shipment-details input-group-size ms-2">
                  <Input
                    type="number"
                    className="form-control form-control-sm border-input-group fw-medium"
                    value={taxSource || " "}
                    onChange={(e) => setTaxSource(e.target.value === "" ? undefined : Number(e.target.value))}
                  />
                  <span className="input-group-text">{sourceMarketplace?.currency}</span>
                </div>
              </span>
            </li>
            <li className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <span className="ms-3">{t("SearchResults.Analyzes.ProfitAnalysis.Label.Prep&Ship")}</span>
                <i id="PopoverShippingPrice" className="ms-1 bx bxs-info-circle text-secondary"></i>
                <DefaultUncontrolledTooltip target="PopoverShippingPrice">{t("Definitions.ShippingPrice")}</DefaultUncontrolledTooltip>
              </div>
              <span className="d-flex align-items-center">
                <span>
                  <DisplayPrice value={prepAndShip} source={destinationMarketplace?.currency} decimals={2} notation="abbreviated" />
                </span>
                <div className="input-group input-group-sm shipment-details input-group-size ms-2">
                  <Input
                    type="number"
                    className="form-control form-control-sm border-input-group fw-medium"
                    value={prepAndShipSource || " "}
                    onChange={(e) => setPrepAndShipSource(e.target.value === "" ? undefined : Number(e.target.value))}
                  />
                  <span className="input-group-text">{sourceMarketplace?.currency}</span>
                </div>
              </span>
            </li>
          </ul>

          <li className="d-flex justify-content-between fw-bold my-2">
            <span>{t("SearchResults.Analyzes.ProfitAnalysis.Label.Fees")}</span>
            <span>
              <DisplayPrice source={destinationMarketplace?.currency} decimals={2} value={totalFees} renderOnEmpty={<span className="text-muted">{t("NA")}</span>} notation="abbreviated" />
            </span>
          </li>

          <ul className="list-group d-flex flex-group gap-2">
            <li className="d-flex align-items-center justify-content-between">
              <span className="ms-3">{t("SearchResults.Analyzes.ProfitAnalysis.Label.FbaFee")}</span>
              <div className="input-group input-group-sm shipment-details input-group-size ms-2">
                <Input
                  type="number"
                  className="form-control form-control-sm border-input-group fw-medium"
                  value={fbaFee || " "}
                  onChange={(e) => setFbaFee(e.target.value === "" ? undefined : Number(e.target.value))}
                />
                <span className="input-group-text">{destinationMarketplace?.currency}</span>
              </div>
            </li>
            <li className="d-flex align-items-center justify-content-between">
              <span className="ms-3">{t("SearchResults.Analyzes.ProfitAnalysis.Label.ReferralFee")}</span>
              <div className="input-group input-group-sm shipment-details input-group-size ms-2">
                <Input
                  type="number"
                  className="form-control form-control-sm border-input-group fw-medium"
                  value={referralFee || " "}
                  onChange={(e) => setReferralFee(e.target.value === "" ? undefined : Number(e.target.value))}
                />
                <span className="input-group-text">{destinationMarketplace?.currency}</span>
              </div>
            </li>
            <li className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <span className="ms-3">{t("ProductDetails.ProfitCalculation.Label.OtherFees")}</span>
                <i id="PopoverOtherFees" className="ms-1 bx bxs-info-circle text-secondary"></i>
                <DefaultUncontrolledTooltip target="PopoverOtherFees">{t("Definitions.OtherFees")}</DefaultUncontrolledTooltip>
              </div>
              <div className="input-group input-group-sm shipment-details input-group-size ms-2">
                <Input
                  type="number"
                  className="form-control form-control-sm border-input-group fw-medium"
                  value={otherFees || " "}
                  onChange={(e) => setOtherFees(e.target.value === "" ? undefined : Number(e.target.value))}
                />
                <span className="input-group-text">{destinationMarketplace?.currency}</span>
              </div>
            </li>
          </ul>
          <li className="d-flex justify-content-between my-2 fw-bold">
            <span>{t("SearchResults.Analyzes.ProfitAnalysis.Label.OverallCost")}</span>
            <span>
              <DisplayPrice value={overallCost} source={destinationMarketplace?.currency} decimals={2} renderOnEmpty={<span className="text-muted">{t("NA")}</span>} notation="abbreviated" />
            </span>
          </li>
          <li className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <span className="ms-3">
                {t("SearchResults.Analyzes.ProfitAnalysis.Label.SellPrice")}
                {details.productInfo?.offerCount === 0 && (
                  <>
                    <i id={`PopoverNoSellerInTheTargetMarketplace-${details.productInfo.asin}`} className="ms-1 mdi mdi-alert align-middle text-warning"></i>
                    <DefaultUncontrolledTooltip target={`PopoverNoSellerInTheTargetMarketplace-${details.productInfo.asin}`}> {t("NoSellerInTheTargetMarketplace")}</DefaultUncontrolledTooltip>
                  </>
                )}
              </span>
            </div>
            <span>
              <Row>
                <Col className="d-flex justify-content-end align-items-center pe-0">
                  <SellerTypeBadge type={details.profitCalculation?.buyBoxType ?? null} hideIfNA={true} />
                </Col>
                <Col xs="auto">
                  <div className="input-group input-group-sm shipment-details input-group-size ms-2">
                    <Input
                      type="number"
                      className="form-control form-control-sm border-input-group fw-medium"
                      value={sellPrice || " "}
                      onChange={(e) => setSellPrice(e.target.value === "" ? undefined : Number(e.target.value))}
                    />
                    <span className="input-group-text">{destinationMarketplace?.currency}</span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  {details?.profitCalculation?.currentShippingPrice !== 0 && details?.profitCalculation?.currentPrice && (
                    <small className="d-flex mt-2 ms-1 text-muted">
                      <DisplayPrice
                        source={destinationMarketplace?.currency}
                        value={details.profitCalculation.currentPrice}
                        decimals={2}
                        prefix="("
                        suffix=" + "
                        className="me-1"
                        notation="abbreviated"
                      />{" "}
                      <DisplayPrice
                        source={destinationMarketplace?.currency}
                        value={details.profitCalculation.currentShippingPrice}
                        decimals={2}
                        prefix=" "
                        suffix={` ${t("Shipping")})`}
                        notation="abbreviated"
                      />
                    </small>
                  )}
                </Col>
              </Row>
            </span>
          </li>
          <hr />
          <ul className="list-group d-flex flex-group gap-1">
            <br></br>

            {details?.profitCalculation?.marketplaceTaxExceptionRate !== 0 && (
              <li className="d-flex justify-content-between">
                <span>
                  <div>{t("SearchResults.Analyzes.ProfitAnalysis.MarketplaceTaxException")}</div>
                </span>
                <span>
                  <DisplayPrice
                    source={destinationMarketplace?.currency}
                    value={details.profitCalculation.marketplaceTaxException}
                    decimals={2}
                    renderOnEmpty={<span className="text-muted">{t("NA")}</span>}
                    notation="abbreviated"
                  />
                </span>
              </li>
            )}
            <li className="d-flex justify-content-between fw-bold">
              <span>{t("SearchResults.Analyzes.ProfitAnalysis.Profit")}</span>
              <DisplayPrice
                source={destinationMarketplace?.currency}
                value={profit}
                decimals={2}
                renderOnEmpty={<span className="text-muted">{t("NA")}</span>}
                className={profit && profit < 0 ? "text-danger" : ""}
                notation="abbreviated"
              />
            </li>
            <li className="d-flex justify-content-between fw-bold">
              <span>
                {t("SearchResults.Analyzes.ProfitAnalysis.Profit")} ({_.toUpper(sourceMarketplace?.currency)})
              </span>
              <DisplayPrice
                source={sourceMarketplace?.currency}
                value={profitSource}
                decimals={2}
                renderOnEmpty={<span className="text-muted">{t("NA")}</span>}
                className={profitSource && profitSource < 0 ? "text-danger" : ""}
                notation="abbreviated"
              />
            </li>

            <li className="d-flex justify-content-between fw-bold">
              <span>{t("SearchResults.Analyzes.ProfitAnalysis.Margin")}</span>
              <RenderNumber value={margin} decimals={2} renderOnEmpty={<span className="text-muted">{t("NA")}</span>} className={margin && margin < 0 ? "text-danger" : ""} suffix="%" />
            </li>
            <li className="d-flex justify-content-between fw-bold">
              <div>
                <span>{t("SearchResults.Analyzes.ProfitAnalysis.ROI")}</span>
              </div>
              <RenderNumber value={roi} decimals={2} renderOnEmpty={<span className="text-muted">{t("NA")}</span>} className={roi && roi < 0 ? "text-danger" : ""} suffix="%" />
            </li>
          </ul>

          {details?.profitCalculation?.exchangeRateTarget ? (
            <div className="d-flex justify-content-between mt-2 fs-11">
              <span></span>
              <span className="text-muted">
                <em>{t("SearchResults.Analyzes.ProfitAnalysis.ExchangeRate") + ": " + details.profitCalculation.exchangeRateTarget}</em>
              </span>
            </div>
          ) : (
            ""
          )}
        </CardBody>
      </Card>
  );
};

export default ProfitCalculation;
