import {useCallback, useEffect, useState} from "react";
import {useProfile} from "Components/Hooks/UserHooks";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {createSelector} from "reselect";
import updateUrlParams, {getToday} from "helpers/utilities";
import {Badge, Button, Card, CardBody, Col} from "reactstrap";
import {AmazonMarketplaceInfosType} from "Components/Common/AmazonMarketplaceInfos";
import {RootState} from "slices";
import {useLocation, useNavigate} from "react-router-dom";
import {SummaryCard} from "pages/Searches/SearchResults/Item/Components/SummaryCard";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import Moment from "react-moment";
import moment from "moment";
import classNames from "classnames";
import DisplayPrice from "Components/Common/DisplayPrice";
import GeneralCheckAndAlerts from "./_GeneralCheckAndAlerts";
import i18n from "i18n";

interface AnalysisSummaryProps {
  sourceMarketplace?: AmazonMarketplaceInfosType;
  destinationMarketplace?: AmazonMarketplaceInfosType;
}
const AnalysisSummary = ({sourceMarketplace, destinationMarketplace}: AnalysisSummaryProps) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const {userProfile, isUserLoggedIn} = useProfile();
  const [_48HourPassed, set48HourPassed] = useState<boolean>(false);

  const productDetailsData = createSelector(
    (state: RootState) => state,
    (state) => ({
      details: state.ProductDetails.details,
    }),
  );
  const {details} = useSelector(productDetailsData);

  useEffect(() => {
    if (details) {
      if (getToday().diff(moment.tz(details.analysisSummary?.analysisDate, userProfile?.timezone!), "hours") > 48) {
        set48HourPassed(true);
      } else {
        set48HourPassed(false);
      }
    }
  }, [details]); //eslint-disable-line

  const getDecisionReasonCountLabel = useCallback(() => {
    const length = details.analysisSummary?.decisionReasons?.split(",").length || 0;
    return length === 1 ? t("ProductDetails.AnalysisSummary.Label.Reason", {value: length}) : t("ProductDetails.AnalysisSummary.Label.ReasonPlural", {value: length});
  }, [details.analysisSummary]); //eslint-disable-line

  return (
    <Card className="card-animate">
      <CardBody>
        <div className="mt-2">
          <div className="d-flex justify-content-center align-items-center fw-medium fs-16">
            <i className="bx bxs-location-plus text-success fs-20 me-1"></i>
            <span>
              <span className="text-nowrap">{sourceMarketplace?.countryName} </span>
              <img src={sourceMarketplace?.flag} alt={`${sourceMarketplace?.countryName} Flag`} className="rounded-circle" height="20" />
            </span>
            <span className="mx-2">
              <i className="ri-arrow-right-line fs-20 align-bottom"></i>
            </span>
            <span className="text-nowrap">
              <img src={destinationMarketplace?.flag} alt={`${destinationMarketplace?.countryName} Flag`} className="rounded-circle" height="20" /> {t(destinationMarketplace?.countryName ?? "")}
              {details.productInfo?.storeName && <span className="text-nowrap">{` - ${t(details.productInfo?.storeName)}`} </span>}
            </span>
          </div>
          <div className="d-flex justify-content-center align-items-center fs-14 mt-1">
            <span className="me-1 text-muted">{t("ProductDetails.AnalysisSummary.Label.AnalysisDate")}:</span>
            <span className="d-flex align-items-center">
              {
                <Moment className={`fw-medium ${_48HourPassed ? "text-warning" : "text-success"}`} locale={i18n.language} fromNow>
                  {details.analysisSummary?.analysisDate}
                </Moment>
              }

              <i className={`text-success fs-18 ms-1 ${_48HourPassed ? "text-warning bx bx-calendar-x " : "text-success bx bx-calendar-check "}`}></i>
            </span>
            <div className="d-flex align-items-center ms-3">
              {isUserLoggedIn() && (
                <Button
                  className="btn btn-soft-secondary px-2 py-0 d-flex align-items-center fw-semibold"
                  onClick={() => {
                    updateUrlParams(navigate, location, {
                      "re-analyze-product": "true",
                      "asin": details.productInfo.asin!,
                      "marketplace": destinationMarketplace?.domain!,});
                  }}
                >
                  <i className="mdi mdi-chart-timeline-variant-shimmer fs-18 me-1"></i>
                  {t("ProductDetails.AnalysisSummary.Button.ReAnalyze")}
                </Button>
              )}
            </div>
          </div>
          <div className="my-4 d-flex justify-content-center">
            <div className="hstack d-flex flex-wrap justify-content-start gap-3">
              <SummaryCard
                icon="mdi mdi-chart-timeline-variant-shimmer"
                label={t("ProductDetails.AnalysisSummary.Label.Decision")}
                content={
                  <div className="d-flex align-items-center">
                    {details.analysisSummary?.decision ? (
                      <>
                        <Badge color={details.analysisSummary.decision === "Sellable" ? "success" : details.analysisSummary.decision === "Recheck" ? "warning" : "danger"} className="fs-11">
                          {t(details.analysisSummary.decision)}
                          {details.analysisSummary.decision === "Sellable" && <i className="bx bx-check ms-1"></i>}
                        </Badge>
                      </>
                    ) : (
                      t("NA")
                    )}
                  </div>
                }
              />

              <SummaryCard
                icon="mdi mdi-finance"
                label={t("ProductDetails.AnalysisSummary.Label.SaleCount")}
                content={
                  <div className="d-flex align-items-center">
                    <>
                      <span>{details?.analysisSummary?.saleCount}</span>
                      <small className="fs-13 text-muted">/{t("month")}</small>
                      <i
                        id="PopoverPrecisionSummary"
                        className={`ms-1 mdi ${
                          details?.salesAnalysis?.precision === "VeryClose"
                            ? "mdi-check-circle text-secondary"
                            : details?.salesAnalysis?.precision === "Medium"
                            ? "mdi-progress-check text-secondary fs-18"
                            : details?.salesAnalysis?.precision === "Prediction"
                            ? "mdi-progress-clock text-danger fs-18"
                            : ""
                        }`}
                      ></i>
                      <DefaultUncontrolledTooltip target={"PopoverPrecisionSummary"}>
                        [{t("SearchResults.Summary.SaleCount.Precision")}: {"VeryClose"}] {t(`SearchResults.Summary.SaleCount.Precisions.${details?.analysisSummary?.precision}`)}{" "}
                      </DefaultUncontrolledTooltip>
                    </>
                  </div>
                }
              />
              <SummaryCard
                icon="mdi mdi-currency-usd"
                label={t("ProductDetails.AnalysisSummary.Label.ProfitRoi")}
                content={
                  <>
                    {details?.analysisSummary?.profit ? (
                      <span className={classNames({"text-danger": details?.analysisSummary?.profit < 0}, "float-start")}>
                        {<DisplayPrice source={details?.productInfo.currencyCodeTarget} value={details?.analysisSummary?.profit} decimals={2} notation="abbreviated" />}
                      </span>
                    ) : (
                      <span>{t("NA")}</span>
                    )}
                    {details?.analysisSummary?.roi ? (
                      <span className={`badge fs-15 ms-2 ${details?.analysisSummary?.roi < 0 ? "bg-danger-subtle text-danger" : "bg-success-subtle text-success"}`}>
                        {" "}
                        %{details?.analysisSummary?.roi}
                      </span>
                    ) : (
                      ""
                    )}
                  </>
                }
              />
              <SummaryCard
                icon="mdi mdi-layers-triple-outline"
                label={t("ProductDetails.AnalysisSummary.Label.Variation")}
                content={
                  <>
                    {details?.variationAnalysis?.variationCount ? (
                      <span
                        className={
                          details?.variationAnalysis.variationCount < 2
                            ? "badge bg-success fs-14 common-css"
                            : details?.variationAnalysis.variationCount < 5
                            ? "badge bg-warning fs-14 common-css"
                            : "badge bg-danger fs-14 common-css"
                        }
                      >
                        {details?.variationAnalysis.variationCount}
                        {details?.variationAnalysis.variationCount < 2 && <i className="bx bx-check ms-1"></i>}
                      </span>
                    ) : (
                      <span>{t("NA")}</span>
                    )}
                  </>
                }
              />
              <SummaryCard
                icon={
                  details?.competitorAnalysis?.competitionLevel === "Weak"
                    ? "mdi mdi-speedometer-slow text-success"
                    : details?.competitorAnalysis?.competitionLevel === "Medium"
                    ? "mdi mdi-speedometer-medium text-warning"
                    : "mdi mdi-speedometer text-danger"
                }
                label={t("ProductDetails.AnalysisSummary.Label.Competition")}
                content={
                  <>
                    {details?.competitorAnalysis?.competitionLevel ? (
                      <Badge
                        color={details?.competitorAnalysis.competitionLevel === "Weak" ? "success" : details?.competitorAnalysis.competitionLevel === "Medium" ? "warning" : "danger"}
                        className="fs-14"
                      >
                        {t(details?.competitorAnalysis.competitionLevel)}
                        {details?.competitorAnalysis.competitionLevel === "Weak" && <i className="bx bx-check ms-1"></i>}
                      </Badge>
                    ) : (
                      t("NA")
                    )}
                  </>
                }
              />

              <SummaryCard
                icon="mdi mdi-trophy-outline text-secondary"
                label={`BSR ${details?.analysisSummary.targetMarketplace ? " - " + details?.analysisSummary.targetMarketplace.replace("com.", "").toUpperCase() : ""}`}
                content={<>{details?.productInfo?.bsr ? details?.productInfo?.bsr?.toLocaleString() : t("NA")}</>}
              />
            </div>
          </div>

          {details.analysisSummary?.decisionReasons && (
            <div className="d-flex justify-content-center">
              <Col xxl={8} xl={10} sm={12}>
                <Card className="mt-3 shadow-lg">
                  <CardBody className="pb-0">
                    <h5 className="fs-15">
                      {t("ProductDetails.AnalysisSummary.Label.DecisionReasons")} <span className="ms-1 fs-12 text-secondary">{getDecisionReasonCountLabel()}</span>
                    </h5>
                    <div className="mt-3">
                      {details.analysisSummary?.decisionReasons?.split(",").map((reason, index) => (
                        <div key={index} className="d-flex">
                          <div className="flex-shrink-0 me-2">
                            <i className={`bx ${reason.endsWith("Recheck") || reason.endsWith("Warning") ? "bxs-error text-warning" : "bxs-x-circle text-danger"} fs-20`}></i>
                          </div>
                          <div className="flex-grow-1">
                            <h5>{t(`DecisionReasons.${reason}.Name`)}</h5>
                            <p className="text-muted">{t(`DecisionReasons.${reason}.Description`)}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </div>
          )}

          <div className="d-flex justify-content-center">
            <Col xxl={8} xl={10} sm={12}>
              <GeneralCheckAndAlerts />
            </Col>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default AnalysisSummary;
