import BreadCrumb, {BreadcrumbMenuItem} from "Components/Common/BreadCrumb";
import DataTable, {DataTableRef} from "Components/Common/DataTable";
import {useEffect, useImperativeHandle, useMemo, useRef} from "react";
import {useTranslation} from "react-i18next";
import {Badge, Card, CardBody, CardHeader, CardTitle, Col, Container, Row} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {useImmer} from "use-immer";
import {useDebounce} from "Components/Hooks/HelperHooks";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {UserLimitType, UserLimitUsageHistory} from "models/user_limit_usage_history";
import {getUserLimitUsageHistory} from "slices/account/thunk";
import {ColumnDef} from "@tanstack/react-table";
import {Link} from "react-router-dom";
import {useProfile} from "Components/Hooks/UserHooks";
import {ConstantPage} from "helpers/permission_helper";
import {UserLimitUsageHistoryQuery} from "api/query";
import Filters from "./Filter";
import ProfileLayout from "Components/Common/Layout/ProfileLayout";
import Restricted from "Components/Common/Restricted";
import Unauthorized from "pages/Errors/_Unauthorized";
import defaultAvatar from "../../../assets/images/user-dummy-img.jpg";
import UsageSummary from "./_UsageSummary";
import RenderNumber from "Components/Common/RenderNumber";
import DisplayDate from "Components/Common/DisplayDate";

export type LimitUsageHistoryListRef = {
  reload: VoidFunction;
};
const PAGE_IDENTIFIER: ConstantPage = "account.limitUsageHistory";
const LimitUsageHistory = () => {
  const {t} = useTranslation();
  const tableRef = useRef<DataTableRef>(null);
  const {userProfile, hasPermission} = useProfile();

  const dispatch: any = useDispatch();
  const listRef = useRef<LimitUsageHistoryListRef>(null);

  const [query, updateQuery] = useImmer<UserLimitUsageHistoryQuery>({
    page: 1,
    pageSize: 10,
    dateRange: {start: undefined, end: undefined},
  });

  const debouncedLoadList = useDebounce(() => {
    if (query.dateRange.start !== undefined && query.dateRange.end !== undefined) {
      getUserLimitUsageHistory(query)(dispatch).then(() => {
        tableRef.current?.resetSelection();
      });
    }
  }, 200);

  useEffect(() => {
    if (hasPermission(PAGE_IDENTIFIER)) {
      debouncedLoadList();
    }
  }, [debouncedLoadList, query]); // eslint-disable-line

  useImperativeHandle(
    listRef,
    () => {
      return {
        reload: () => {
          debouncedLoadList();
        },
      };
    },
    [debouncedLoadList],
  );

  const storeData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Account.loading,
      limitUsageHistoryList: state.Account.limitUsageHistoryList,
    }),
  );
  const {loading, limitUsageHistoryList} = useSelector(storeData);

  const columns = useMemo<ColumnDef<UserLimitUsageHistory, any>[]>(
    () => [
      {
        header: t("Account.LimitUsageHistory.TableColumn.User"),
        size: 100,
        accessorFn: (item) => item.userId,
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as UserLimitUsageHistory;
          return (
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 me-2">
                <img src={row.user.avatar || defaultAvatar} className="rounded-circle avatar-sm img-thumbnail user-profile-image" alt="user-profile" />
              </div>
              <div className="flex-grow-1 ellipsis-two-lines">
                <div className="fw-semibold">
                  {row.user.fullName}
                  {row.user.userId === userProfile?.userId && <Badge className="ms-1">{t("You")}</Badge>}
                </div>
                <div className="text-muted" title={row.user.email}>
                  {row.user.email}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        header: t("Account.LimitUsageHistory.TableColumn.Date"),
        size: 100,
        accessorFn: (item) => item.date,
        sortingFn: "datetime",
        cell: (cellProps) => {
          const row = cellProps.row.original as UserLimitUsageHistory;
          return (
            <>
              <DisplayDate id={`DisplayDateLimitUsageDate-${row.userLimitUsageHistoryEntityId}`} value={row.date} format="D MMM YYYY HH:mm" tz={userProfile?.timezone} />
            </>
          );
        },
      },
      {
        header: t("Account.LimitUsageHistory.TableColumn.OperationType"),
        size: 50,
        accessorFn: (item) => item.type,
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as UserLimitUsageHistory;
          let type = "";
          if(row.type === UserLimitType.SEARCH && row.usage === 1){
            type = t("Single Search");
          }
          else if (row.type === UserLimitType.SEARCH && row.usage > 1) {
            type = t("Bulk Search");
          }
          else if (row.type === UserLimitType.CRAWLER_SCAN) {
            type = t("Scan");
          }
          
          return <>{type}</>;
        },
      },
      {
        header: t("Account.LimitUsageHistory.TableColumn.Usage"),
        size: 50,
        accessorKey: "usage",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as UserLimitUsageHistory;
          return <RenderNumber value={row.usage} />;
        },
      },

      {
        header: t("Account.LimitUsageHistory.TableColumn.ReferenceLink"),
        size: 100,
        accessorKey: "referenceId",
        cell: (cellProps) => {
          const row = cellProps.row.original as UserLimitUsageHistory;
          const searchPage = "/searches/search-results/";
          const scanAndSavePage = "/scan-and-save/";
          let target="_blank";
          let link = row.type === UserLimitType.SEARCH ? searchPage + row.referenceId : scanAndSavePage;
          if(row.type === UserLimitType.SEARCH && row.usage === 1){
            link = `/account/limit-usage-history?singleSearch=true&asin=${row.referenceId}`;
            target = "_self"
          }
          return (
            <>
              {
                <Link to={link} target={target} className={`link-secondary link-offset-2 text-decoration-underline me-1 `}>
                  Link
                  <i className="ri-arrow-right-up-line"></i>
                </Link>
              }
            </>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, limitUsageHistoryList.items],
  );

  const breadcrumbMenus: BreadcrumbMenuItem[] = [
    {
      label: t("Settings.Title"),
      url: "#",
    },
    {
      label: t("Account.LimitUsageHistory.Title"),
      url: "",
    },
  ];

  document.title = t("PageTitles.LimitUsageHistory");

  return (
    <Restricted require="account.limitUsageHistory" read fallback={() => <Unauthorized />}>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Account.Title")} menus={breadcrumbMenus} />
          <ProfileLayout hasAnyChanges={false} navigateTo="">
            <>
              <Card>
                <CardHeader>
                  <Row>
                    <Col>
                      <CardTitle className="fw-medium mb-0">
                        <i className="mdi mdi-speedometer fs-18 align-middle me-1"></i>
                        {t("Account.LimitUsageHistory.Title")}
                      </CardTitle>
                    </Col>
                  </Row>
                </CardHeader>
              </Card>
              <UsageSummary />
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <Filters busy={loading.list} page={query.page} pageSize={query.pageSize} handleFilter={(f) => updateQuery(f)} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <DataTable
                        ref={tableRef}
                        busy={loading.list || loading.update}
                        columns={columns}
                        data={limitUsageHistoryList.items || []}
                        totalDataLength={limitUsageHistoryList.totalCount}
                        showColumnSelect
                        pagination={{
                          pageIndex: query.page - 1,
                          pageSize: query.pageSize,
                        }}
                        onPaginationChanged={(pagination) => {
                          updateQuery((q) => {
                            q.page = pagination.pageIndex + 1;
                            q.pageSize = pagination.pageSize;
                          });
                        }}
                        hovered
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </>
          </ProfileLayout>
        </Container>
      </div>
    </Restricted>
  );
};

export default LimitUsageHistory;
