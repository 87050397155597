import DataTable, {DataTableRef} from "Components/Common/DataTable";
import Dialog, {DialogRef} from "Components/Common/Dialog";
import {ColumnDef} from "@tanstack/react-table";
import {useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link, useNavigate, useParams} from "react-router-dom";
import {Modal, ModalHeader, ModalBody, Col, Row, Button, ModalFooter, Card, CardHeader, CardBody} from "reactstrap";
import {KafkaQueue, MarketplaceStats} from "models/system_monitoring_details";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {useDispatch, useSelector} from "react-redux";
import {clearTopics, getSystemMonitoringDetails} from "slices/admin/systemMonitoring/thunk";
import AmazonMarketplaceInfos from "Components/Common/AmazonMarketplaceInfos";
import CountUp from "react-countup";
import NoData from "Components/Common/NoData";
import PlaceholderGlow from "Components/Common/PlaceholderGlow";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import { preventScrollUp } from "helpers/utilities";

interface ServiceDetailsProps {
  isOpen: boolean;
}

const ServiceDetails = (props: ServiceDetailsProps) => {
  function FormatMilliseconds(milliseconds: number) {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const totalMinutes = Math.floor(totalSeconds / 60);
    const totalHours = Math.floor(totalMinutes / 60);

    const hours = totalHours;
    const minutes = totalMinutes % 60;
    const seconds = totalSeconds % 60;

    if (hours > 0) {
      return `${hours}h ${minutes}m`;
    } else if (minutes > 0) {
      return `${minutes}m ${seconds}s`;
    } else {
      return `${seconds}s`;
    }
  }

  function CalculateCoverage(interval: number, avgCycleTime: number) {
    const avgCycleTimeInHours = avgCycleTime / (60 * 60 * 1000);

    return (interval / avgCycleTimeInHours) * 100;
  }

  const {t} = useTranslation();
  const [amazonMarketplaceInfos] = useState(AmazonMarketplaceInfos());
  const [selectedKafkaTopic, setSelectedKafkaTopic] = useState<KafkaQueue>({} as KafkaQueue);
  const clearTopicDialog = useRef<DialogRef>(null);
  const clearAllTopicDialog = useRef<DialogRef>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {serviceId} = useParams();
  const monitoringData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.AdminSystemMonitoring.loading,
      details: state.AdminSystemMonitoring.details,
    }),
  );
  const {loading, details} = useSelector(monitoringData);

  useEffect(() => {
    if (serviceId && props.isOpen) {
      getSystemMonitoringDetails(serviceId)(dispatch);
    }
  }, [serviceId, props.isOpen, dispatch]);

  const tableRef = useRef<DataTableRef>(null);
  const columns = useMemo<ColumnDef<MarketplaceStats, any>[]>(
    () => [
      {
        accessorKey: "selector",
        enableSorting: false,
        size: 5,
        header: ({table}) => (
          <>
            <Link to="" className="btn btn-ghost-primary px-1 py-0 fs-18" onClick={() => table.toggleAllRowsExpanded()}>
              {table.getIsAllRowsExpanded() ? <i className="mdi mdi-chevron-up"></i> : <i className="mdi mdi-chevron-down"></i>}
            </Link>
          </>
        ),
        cell: ({row, getValue}) => (
          <>
            {row.getCanExpand() ? (
              <>
                <button
                  className="btn btn-ghost-primary px-1 py-0 fs-18"
                  {...{
                    onClick: row.getToggleExpandedHandler(),
                    style: {cursor: "pointer"},
                  }}
                  style={{cursor: "pointer"}}
                >
                  {row.getIsExpanded() ? <i className="mdi mdi-chevron-up"></i> : <i className="mdi mdi-chevron-down"></i>}
                </button>
              </>
            ) : (
              ""
            )}
          </>
        ),
      },
      {
        header: t("Admin.SystemMonitoring.Dialog.ServiceDetails.MarketplaceStats.TableColumn.Marketplace"),
        accessorKey: "marketplace",
        size: 50,
        cell: (cellProps) => {
          const row = cellProps.row.original as MarketplaceStats;
          const depth = cellProps.row.depth;
          return (
            <>
              {depth === 0 && (
                <span className="hstack gap-2">
                  <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                    <img
                      src={amazonMarketplaceInfos.find((amazonMarketplaceInfo: any) => amazonMarketplaceInfo.marketplace === row.marketplace)?.flag}
                      alt="Country Flag"
                      className=" rounded-circle"
                    />
                  </div>
                  {row.marketplace}
                </span>
              )}
            </>
          );
        },
      },
      {
        header: t("Admin.SystemMonitoring.Dialog.ServiceDetails.MarketplaceStats.TableColumn.Interval"),
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as MarketplaceStats;
          return <>{row.interval > 0 ? row.interval + "h" : "-"}</>;
        },
      },
      {
        header: t("Admin.SystemMonitoring.Dialog.ServiceDetails.MarketplaceStats.TableColumn.TotalCount"),
        size: 150,
        cell: (cellProps) => {
          const row = cellProps.row.original as MarketplaceStats;
          return <>{row.totalCount.toLocaleString()}</>;
        },
      },
      {
        header: t("Admin.SystemMonitoring.Dialog.ServiceDetails.MarketplaceStats.TableColumn.AvgCycleTime"),
        size: 150,
        cell: (cellProps) => {
          const row = cellProps.row.original as MarketplaceStats;
          return <>{FormatMilliseconds(row.avgCycleTime)}</>;
        },
      },
      {
        header: t("Admin.SystemMonitoring.Dialog.ServiceDetails.MarketplaceStats.TableColumn.AvgProcessingTime"),
        size: 150,
        cell: (cellProps) => {
          const row = cellProps.row.original as MarketplaceStats;
          return <>{FormatMilliseconds(row.avgProcessingTime)}</>;
        },
      },
      {
        header: t("Admin.SystemMonitoring.Dialog.ServiceDetails.MarketplaceStats.TableColumn.Coverage"),
        size: 150,
        cell: (cellProps) => {
          const row = cellProps.row.original as MarketplaceStats;
          const coverage = row.avgProcessingTime > 0 && row.interval > 0 ? CalculateCoverage(row.interval, row.avgCycleTime) : 0;
          return (
            <>
              {coverage !== 0 && coverage >= 90 ? (
                <span className="fw-medium text-success">{coverage.toFixed(2)}%</span>
              ) : coverage !== 0 && coverage < 90 ? (
                <span className="fw-medium text-danger">{coverage.toFixed(2)}%</span>
              ) : (
                "-"
              )}
            </>
          );
        },
      },
    ],
    [t], // eslint-disable-line
  );

  const toggle = () => {
    navigate("/admin/system-monitoring");
    preventScrollUp();
  };
  return (
    <>
      <Modal backdrop="static" id="showServiceDetailsModal" isOpen={props.isOpen} toggle={toggle} fade={true} centered={true} size="xl">
        <PlaceholderGlow busy={loading.details}>
          <div className="placeholder-glow">
            <ModalHeader className="bg-light p-3" toggle={toggle}>
              {details.serviceName}
            </ModalHeader>
            <div className="shadow-sm">
              <Row>
                <Col className="same-height">
                  <div className="mt-3 mt-md-0 pb-3 pt-4 px-3">
                    <h5
                      className="fs-15 nowrap text-center"
                      dangerouslySetInnerHTML={{
                        __html: t("Admin.SystemMonitoring.Services.Label.Completed", {value: 1}),
                      }}
                    ></h5>
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="fs-2">
                        <i className={"text-secondary mdi mdi-chart-timeline-variant-shimmer"}></i>
                      </div>
                      <div className="ms-2">
                        <h4 className="mb-0">
                          <span className="counter-value">
                            <CountUp end={details?.completed} duration={2} />
                          </span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col className="same-height">
                  <div className="mt-3 mt-md-0 pb-3 pt-4 px-3">
                    <h5 className="fs-15 nowrap text-center">{t("Admin.SystemMonitoring.Services.Label.InProgress")}</h5>
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="fs-2">
                        <i className={"text-warning mdi mdi-spin mdi-cog"}></i>
                      </div>
                      <div className="ms-2">
                        <h4 className="mb-0">
                          <span className="counter-value">
                            <CountUp end={details?.inProgress} duration={2} />
                          </span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col className="same-height">
                  <div className="mt-3 mt-md-0 pb-3 pt-4 px-3">
                    <h5 className="fs-15 nowrap text-center">{t("Admin.SystemMonitoring.Services.Label.InQueue")}</h5>
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="fs-2">
                        <i className={"text-success mdi mdi-clipboard-text-search-outline"}></i>
                      </div>
                      <div className="ms-2">
                        <h4 className="mb-0">
                          <span className="counter-value">
                            <CountUp end={details?.inQueue} duration={2} />
                          </span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col className="same-height">
                  <div className="mt-3 mt-md-0 pb-3 pt-4 px-3">
                    <h5 className="fs-15 nowrap text-center">{t("Admin.SystemMonitoring.Services.Label.OverDue")}</h5>
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="fs-2">
                        <i className={"text-danger mdi mdi-clock-alert"}></i>
                      </div>
                      <div className="ms-2">
                        <h4 className="mb-0">
                          <span className="counter-value text-danger">
                            <CountUp end={details?.overdue} duration={2} />
                          </span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="same-height">
                  <div className="mt-3 mt-md-0 pb-3 pt-4 px-3">
                    <h5 className="fs-15 nowrap text-center">{t("Admin.SystemMonitoring.Services.Label.AvgProcessingTime")}</h5>
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="fs-2">
                        <i className={"text-warning mdi mdi-database-clock-outline"}></i>
                      </div>
                      <div className="ms-2">
                        <h4 className="mb-0">
                          <span className="counter-value">
                            <CountUp end={details?.avgProcessingTime} duration={2} />
                          </span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <ModalBody style={{background: "var(--vz-body-bg)"}}>
              {details?.marketplaceStats?.length > 0 && (
                <Card>
                  <CardHeader>
                    <h5 className="fs-15 nowrap mb-0">{t("Admin.SystemMonitoring.Dialog.ServiceDetails.MarketplaceStats.Title")}</h5>
                  </CardHeader>
                  <CardBody>
                    <DataTable
                       
                      trClass="text-start"
                      tdClass="text-start"
                      ref={tableRef}
                      busy={loading.details}
                      columns={columns}
                      data={details?.marketplaceStats || []}
                      totalDataLength={details?.marketplaceStats?.length}
                      hovered
                    />
                  </CardBody>
                </Card>
              )}
              <Card>
                <CardHeader>
                  <Row>
                    <Col xs={12} sm="auto">
                      <h5 className="fs-15 nowrap mb-0 ">{t("Admin.SystemMonitoring.Dialog.ServiceDetails.KafkaQueues.Title")}</h5>
                    </Col>
                    <Col>
                      <span className="text-muted ms-2 fs-12">{`(${t("Total")}: ${details.kafkaQueues?.reduce((sum, item) => sum + item.itemCount, 0)})`}</span>
                    </Col>
                    <Col xs={12} sm="auto">
                      <Button
                        className="btn btn-danger btn-sm"
                        onClick={() => {
                          clearAllTopicDialog.current?.show();
                        }}
                        disabled={loading.details || details.kafkaQueues?.length === 0}
                      >
                        {t("Admin.SystemMonitoring.Dialog.ServiceDetails.KafkaQueues.Button.ClearAllTopics")}
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    {loading.details ? (
                      <PlaceholderGlow busy></PlaceholderGlow>
                    ) : details.kafkaQueues && details.kafkaQueues.length > 0 ? (
                      details.kafkaQueues?.map((kafkaQueue: KafkaQueue, index: number) => {
                        return (
                          <Col lg={6} md={6} key={index}>
                            <Card>
                              <CardBody>
                                <div className="d-flex align-items-center">
                                  <div className="avatar-sm flex-shrink-0">
                                    <span className="avatar-title bg-light text-primary rounded-circle fs-3">
                                      <i id={`TopicInfo-${index}`} className="ri-information-fill text-info align-middle fs-20"></i>
                                      <DefaultUncontrolledTooltip target={`TopicInfo-${index}`}>{t(`KafkaQueueInformation.${kafkaQueue.topicName}`)}</DefaultUncontrolledTooltip>
                                    </span>
                                  </div>
                                  <div className="flex-grow-1 ms-3">
                                    <Row>
                                      <Col xs="auto" className="d-flex justify-content-center align-items-center">
                                        <p className="text-uppercase fw-semibold text-muted mb-0 fs-14">{kafkaQueue.topicName}</p>
                                      </Col>
                                    </Row>
                                    <Row className="mt-2">
                                      <Col>
                                        <h4 className=" mb-0">
                                          <CountUp start={0} end={kafkaQueue.itemCount} decimals={0} duration={3} />
                                        </h4>
                                      </Col>
                                      <Col xs="2">
                                        <div className="flex-shrink-0 align-self-end">
                                          <Button
                                            className="btn btn-soft-danger btn-sm"
                                            onClick={() => {
                                              setSelectedKafkaTopic(kafkaQueue);
                                              clearTopicDialog.current?.show();
                                            }}
                                          >
                                            {t("Admin.SystemMonitoring.Dialog.ServiceDetails.KafkaQueues.Button.ClearTopic")}
                                          </Button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        );
                      })
                    ) : (
                      <NoData icon="ri-server-fill" style={{height: 350}} />
                    )}
                  </Row>
                </CardBody>
              </Card>
            </ModalBody>
            <ModalFooter>
              <div className="hstack gap-2 justify-content-end">
                <Button type="button" className="btn btn-light mt-3" onClick={toggle}>
                  {t("Close")}
                </Button>
              </div>
            </ModalFooter>
          </div>
          <Dialog
            ref={clearTopicDialog}
            color="danger"
            buttons={["yes", "no"]}
            busy={loading.clearTopic}
            iconClass="ri-alert-fill"
            message={t("Admin.SystemMonitoring.Dialog.ServiceDetails.KafkaQueues.Dialog.ClearTopics.Description")}
            title={t("Admin.SystemMonitoring.Dialog.ServiceDetails.KafkaQueues.Dialog.ClearTopics.Title", {value: selectedKafkaTopic?.topicName})}
            onButtonClick={async (button, hide) => {
              if (button === "yes" && selectedKafkaTopic) {
                await clearTopics([selectedKafkaTopic.topicName])(dispatch);
              }
              clearTopicDialog.current?.hide();
            }}
          />
          <Dialog
            ref={clearAllTopicDialog}
            color="danger"
            buttons={["yes", "no"]}
            busy={loading.clearTopic}
            iconClass="ri-alert-fill"
            message={t("Admin.SystemMonitoring.Dialog.ServiceDetails.KafkaQueues.Dialog.ClearAllTopics.Description")}
            title={t("Admin.SystemMonitoring.Dialog.ServiceDetails.KafkaQueues.Dialog.ClearAllTopics.Title")}
            onButtonClick={async (button, hide) => {
              const allTopics = details?.kafkaQueues?.map((kafkaQueue: KafkaQueue) => kafkaQueue.topicName);
              if (button === "yes" && allTopics) {
                await clearTopics(allTopics)(dispatch);
              }
              clearAllTopicDialog.current?.hide();
            }}
          />
        </PlaceholderGlow>
      </Modal>
    </>
  );
};

export default ServiceDetails;
