import { TeamUser } from "./user";

export interface UserLimitUsageHistory {
  userLimitUsageHistoryEntityId: string;
  referenceId: string;
  userId: string;
  type: UserLimitType;
  usage: number;
  date: Date;
  user: TeamUser;
}

export enum UserLimitType {
  SEARCH = 0,
  CRAWLER_SCAN = 1,
}