import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {ButtonDropdown, Card, CardBody, CardHeader, CardTitle, Col, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, Row} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {updateOrderCost} from "slices/order-details/thunk";
import AmazonMarketplaceInfos from "Components/Common/AmazonMarketplaceInfos";
import Loader from "Components/Common/Loader";
import DisplayPrice from "Components/Common/DisplayPrice";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";

const CostAndFees = () => {
  const {t} = useTranslation();
  const [currency, setCurrency] = useState<string>();
  const [amazonMarketplaceInfos] = useState(AmazonMarketplaceInfos());
  const activeCurrencyList = amazonMarketplaceInfos.filter((info) => info.active || info.isDefaultUs);
  const dispatch: any = useDispatch();

  const ordersData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.OrderDetails.loading,
      order: state.OrderDetails.order,
      defaultShipments: state.OrderDetails.defaultShipments,
    }),
  );
  const {loading, defaultShipments, order} = useSelector(ordersData);

  const [costValue, setCostValue] = useState<number>(order.totalCost || 0);
  const [previousValue, setPreviousValue] = useState<number>(order.totalCost || 0);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    if (order) {
      setCurrency(order.currencyCodeSource);
      setCostValue(Number(order.totalCost?.toFixed(2)));
      setPreviousValue(Number(costValue.toFixed(2)));
    }
    // eslint-disable-next-line
  }, [order]);

  const getBadgeWithValue = (value: number, prefix: string = "", postfix: string = ""): JSX.Element => {
    if (typeof value !== "number" || isNaN(value)) {
      return <span className={`badge bg-light text-dark rounded-pill fs-12 `}>{t("NA")}</span>;
    }
    const badgeClass = value < 0 ? "bg-danger-subtle text-danger" : "bg-success-subtle text-success";
    const formattedValue = `${prefix} ${value.toFixed(2)} ${postfix}`;
    return <span className={`badge rounded-pill fs-12 ${badgeClass}`}>{formattedValue}</span>;
  };

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(e.target.value);
    setCostValue(newValue);
  };

  const handleBlur = (from: "dropdown" | "input", currencyValue: string) => {
    if ((from === "input" && costValue !== previousValue) || from === "dropdown") {
      const updatePromise = updateOrderCost(order.userOrderId, costValue, currencyValue)(dispatch);
      updatePromise.then((isSuccess) => {
        if (isSuccess) {
          setCostValue(Number(costValue.toFixed(2)));
          setPreviousValue(Number(costValue.toFixed(2)));
        } else {
          setCostValue(Number(previousValue.toFixed(2)));
        }
      });
    }
  };
  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <CardTitle className="fw-medium mb-0">{t("OrderDetails.Card.CostAndFees.Title")}</CardTitle>
        </CardHeader>
        <div className="placeholder-glow">
          <div className={loading.updateCost ? "placeholder w-100" : ""}>
            <CardBody>
              {loading.list ? (
                <Loader />
              ) : (
                <div className="d-flex flex-column gap-3">
                  <Row>
                    <Col>{t("OrderDetails.Card.CostAndFees.Total")}</Col>
                    <Col>
                      <DisplayPrice source={currency} value={order.totalSellPrice} decimals={2} notation="abbreviated"/>
                    </Col>
                  </Row>
                  <Row>
                    <Col>{t("OrderDetails.Card.CostAndFees.Tax")}</Col>
                    <Col>
                      <DisplayPrice source={currency} value={order.totalTax} decimals={2} notation="abbreviated"/>{" "}
                    </Col>
                  </Row>
                  <Row>
                    <Col>{t("OrderDetails.Card.CostAndFees.SalesProceeds")}</Col>
                    <Col>
                      <DisplayPrice source={currency} value={order.orderTotal} decimals={2} notation="abbreviated"/>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col>{t("OrderDetails.Card.CostAndFees.Cost")}</Col>
                    <Col>
                      <InputGroup size="sm" style={{width: "130px"}} className="d-flex align-items-center">
                        {order.costIsPredicted && (
                          <>
                            <i className="ri-question-fill text-warning fs-14 me-2" id={`CostIsPredictedTooltip-${order.amazonOrderId}`} />
                            <DefaultUncontrolledTooltip target={`CostIsPredictedTooltip-${order.amazonOrderId}`}>{t("CostIsPredicted")}</DefaultUncontrolledTooltip>
                          </>
                        )}
                        <Input
                          id={"cost-" + order.userOrderId}
                          type="number"
                          className="form-control form-control-sm border-input-group border-end-0"
                          value={isNaN(costValue) ? "" : costValue}
                          onChange={handleValueChange}
                          onBlur={() => handleBlur("input", currency!)}
                          min={0}
                          disabled={defaultShipments && defaultShipments.length > 0}
                        />
                        <ButtonDropdown isOpen={isDropdownOpen} toggle={() => setIsDropdownOpen(!isDropdownOpen)} direction={"down"}>
                          <DropdownToggle caret>{currency}</DropdownToggle>
                          <DropdownMenu>
                            {activeCurrencyList.map((marketplaceInfo) => (
                              <DropdownItem
                                key={marketplaceInfo.marketplace}
                                onClick={() => {
                                  setCurrency(marketplaceInfo.currency);
                                  handleBlur("dropdown", marketplaceInfo.currency);
                                }}
                                className={order.currencyCodeSource === marketplaceInfo.currency ? "active" : ""}
                              >
                                {marketplaceInfo.currency}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </ButtonDropdown>
                      </InputGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>{t("OrderDetails.Card.CostAndFees.FbaFee")}</Col>
                    <Col>
                      <DisplayPrice source={currency} value={order.totalFBAFee} decimals={2} notation="abbreviated"/>
                    </Col>
                  </Row>
                  <Row>
                    <Col>{t("OrderDetails.Card.CostAndFees.ReferralFee")}</Col>
                    <Col>
                      <DisplayPrice source={currency} value={order.totalReferralFee} decimals={2} notation="abbreviated"/>
                    </Col>
                  </Row>
                  <Row>
                    <Col>{t("OrderDetails.Card.CostAndFees.MarketplaceFacilitatorTax")}</Col>
                    <Col>
                      <DisplayPrice source={currency} value={order.marketplaceFacilitatorTax} decimals={2} notation="abbreviated" />
                    </Col>
                  </Row>
                  <Row>
                    <Col>{t("OrderDetails.Card.CostAndFees.Promotions")}</Col>
                    <Col>
                      <DisplayPrice source={currency} value={order.promotions} decimals={2} notation="abbreviated"/>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col>{t("OrderDetails.Card.CostAndFees.Profit")}</Col>
                    <Col>{getBadgeWithValue(order.profit!, "", currency)}</Col>
                  </Row>
                  <Row>
                    <Col>{t("OrderDetails.Card.CostAndFees.Margin")}</Col>
                    <Col>{getBadgeWithValue(order.margin!, "%")}</Col>
                  </Row>
                  <Row>
                    <Col>{t("OrderDetails.Card.CostAndFees.ROI")}</Col>
                    <Col>{getBadgeWithValue(order.roi!, "%")}</Col>
                  </Row>
                </div>
              )}
            </CardBody>
          </div>
        </div>
      </Card>
    </React.Fragment>
  );
};

export default CostAndFees;
