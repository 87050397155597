import {UserSearchProduct} from "models/user_search_product";
import {Button, Card, CardBody, Col, Input, Row, Table, UncontrolledPopover} from "reactstrap";
import {SellerTypeBadge} from "./Components/SellerTypeBadge";
import {useTranslation} from "react-i18next";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import DisplayPrice from "Components/Common/DisplayPrice";
import {useCallback, useEffect, useState} from "react";
import AmazonMarketplaceInfos, {AmazonMarketplaceInfosType} from "Components/Common/AmazonMarketplaceInfos";
import RenderNumber from "Components/Common/RenderNumber";
import _ from "lodash";

interface ProfitAnalysisProps {
  item: UserSearchProduct;
}
const ProfitAnalysis = ({item}: ProfitAnalysisProps) => {
  const {t} = useTranslation();
  const [amazonMarketplaceInfos] = useState(AmazonMarketplaceInfos());
  const [sourceMarketplace, setSourceMarketplace] = useState<AmazonMarketplaceInfosType>();
  const [destinationMarketplace, setDestinationMarketplace] = useState<AmazonMarketplaceInfosType>();

  const [purchasePriceSource, setPurchasePriceSource] = useState<number | undefined>(item.profitAnalysis.purchasePriceSource);
  const [taxSource, setTaxSource] = useState<number | undefined>(item.profitAnalysis.taxSource);
  const [prepAndShipSource, setPrepAndShipSource] = useState<number | undefined>(item.profitAnalysis.shippingPriceSource);

  const [purchasePrice, setPurchasePrice] = useState<number | undefined>(item.profitAnalysis.purchasePrice);
  const [tax, setTax] = useState<number | undefined>(item.profitAnalysis.tax);
  const [prepAndShip, setPrepAndShip] = useState<number | undefined>(item.profitAnalysis.shippingPrice);

  const [totalCost, setTotalCost] = useState<number | undefined>(item.profitAnalysis.totalCost);
  const [totalFees, setTotalFees] = useState<number | undefined>(item.profitAnalysis.totalFees);
  const [fbaFee, setFbaFee] = useState<number | undefined>(item.profitAnalysis.fbaFee);
  const [referralFee, setReferralFee] = useState<number | undefined>(item.profitAnalysis.referralFee);
  const [otherFees, setOtherFees] = useState<number | undefined>(item.profitAnalysis.otherFees);
  const [exchangeRate] = useState<number | undefined>(item.profitAnalysis.exchangeRateTarget);
  const [overallCost, setOverallCost] = useState<number | undefined>(item.profitAnalysis.overallCost);
  const [sellPrice, setSellPrice] = useState<number | undefined>(item.profitAnalysis.sellPrice);
  const [profit, setProfit] = useState<number | undefined>(item.profitAnalysis.profit);
  const [profitSource, setProfitSource] = useState<number | undefined>(item.profitAnalysis.profitSource);
  const [margin, setMargin] = useState<number | undefined>(item.profitAnalysis.margin);
  const [roi, setRoi] = useState<number | undefined>(item.profitAnalysis.roi);

  useEffect(() => {
    let source = amazonMarketplaceInfos.find((amazonMarketplaceInfo) => amazonMarketplaceInfo.marketplace === item.marketplaceSource);
    let destination = amazonMarketplaceInfos.find((amazonMarketplaceInfo: any) => amazonMarketplaceInfo.marketplace === item.marketplaceTarget);
    setSourceMarketplace(source);
    setDestinationMarketplace(destination);
  }, [amazonMarketplaceInfos]); //eslint-disable-line

  const handleValueChange = useCallback(() => {
    if (exchangeRate && sellPrice) {
      const _overallCost = (purchasePrice ?? 0) + (tax ?? 0) + (prepAndShip ?? 0) + (fbaFee ?? 0) + (referralFee ?? 0) + (otherFees ?? 0);
      const _profit = sellPrice - _overallCost;
      const _profitSource = _profit / exchangeRate;
      const _margin = (_profit / sellPrice) * 100;
      const _roi = (_profit / ((purchasePrice ?? 0) + (tax ?? 0) + (prepAndShip ?? 0))) * 100;

      setTotalCost((purchasePrice ?? 0) + (tax ?? 0) + (prepAndShip ?? 0));
      setTotalFees((fbaFee ?? 0) + (referralFee ?? 0) + (otherFees ?? 0));
      setOverallCost(_overallCost);

      setProfit(_profit);
      setProfitSource(_profitSource);
      setMargin(_margin);
      setRoi(_roi);
    }
  }, [purchasePrice, tax, prepAndShip, fbaFee, referralFee, otherFees, sellPrice, exchangeRate]);

  const handleSellPriceChange = useCallback(() => {
    if (referralFee && sellPrice) {
      const _oldSellPrice = item.profitAnalysis.sellPrice ?? 1;
      const _referralFee = (item.profitAnalysis.referralFee ?? 1) * (sellPrice / _oldSellPrice);
      setReferralFee(Number(_referralFee.toFixed(2)));
    }
  }, [sellPrice]); //eslint-disable-line

  const convertCurrencies = useCallback(() => {
    if (!exchangeRate) return;
    if (purchasePriceSource) setPurchasePrice(purchasePriceSource * exchangeRate);
    else setPurchasePrice(undefined);
    if (taxSource) setTax(taxSource * exchangeRate);
    else setTax(undefined);
    if (prepAndShipSource) setPrepAndShip(prepAndShipSource * exchangeRate);
    else setPrepAndShip(undefined);
  }, [purchasePriceSource, taxSource, prepAndShipSource]); //eslint-disable-line

  useEffect(() => {
    convertCurrencies();
  }, [convertCurrencies]);

  useEffect(() => {
    handleValueChange();
  }, [handleValueChange]);

  useEffect(() => {
    handleSellPriceChange();
  }, [handleSellPriceChange]);

  return (
    <Card className="border card-animate mb-3">
      <CardBody>
        <h5>{t("SearchResults.Analyzes.ProfitAnalysis.Title")}</h5>

        <ul className="list-group d-flex flex-group gap-2">
          <li className="d-flex justify-content-between fw-semibold">
            <span>{t("SearchResults.Analyzes.ProfitAnalysis.Label.Costs")}</span>
            <Row>
              <Col>
                <DisplayPrice source={sourceMarketplace?.currency} value={item.profitAnalysis.totalCostSource} decimals={2} prefix="(" suffix=")" notation="abbreviated" />
              </Col>
              <Col>
                <DisplayPrice source={destinationMarketplace?.currency} value={totalCost} decimals={2} notation="abbreviated" />{" "}
              </Col>
            </Row>
          </li>
          <li className="d-flex align-items-center justify-content-between">
            <span className="ms-3">{t("SearchResults.Analyzes.ProfitAnalysis.Label.PurchasePrice")}</span>
            <span className="d-flex align-items-center">
              <span>
                <DisplayPrice value={purchasePrice} source={destinationMarketplace?.currency} decimals={2} notation="abbreviated" />
              </span>
              <div className="input-group input-group-sm shipment-details input-group-size ms-2">
                <Input
                  type="number"
                  className="form-control form-control-sm border-input-group fw-medium"
                  value={purchasePriceSource}
                  onChange={(e) => setPurchasePriceSource(e.target.value === "" ? undefined : Number(e.target.value))}
                />
                <span className="input-group-text">{sourceMarketplace?.currency}</span>
              </div>
            </span>
          </li>
          <li className="d-flex align-items-center justify-content-between">
            <span className="ms-3">{t("SearchResults.Analyzes.ProfitAnalysis.Label.Tax")}</span>
            <span className="d-flex align-items-center">
              <span>
                <DisplayPrice value={tax} source={destinationMarketplace?.currency} decimals={2} notation="abbreviated" />
              </span>
              <div className="input-group input-group-sm shipment-details input-group-size ms-2">
                <Input
                  type="number"
                  className="form-control form-control-sm border-input-group fw-medium"
                  value={taxSource}
                  onChange={(e) => setTaxSource(e.target.value === "" ? undefined : Number(e.target.value))}
                />
                <span className="input-group-text">{sourceMarketplace?.currency}</span>
              </div>
            </span>
          </li>
          <li className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <span className="ms-3">{t("SearchResults.Analyzes.ProfitAnalysis.Label.Prep&Ship")}</span>
              <i id="PopoverShippingPrice" className="ms-1 bx bxs-info-circle text-secondary"></i>
              <DefaultUncontrolledTooltip target="PopoverShippingPrice">{t("Definitions.ShippingPrice")}</DefaultUncontrolledTooltip>
            </div>
            <span className="d-flex align-items-center">
              <span>
                <DisplayPrice value={prepAndShip} source={destinationMarketplace?.currency} decimals={2} notation="abbreviated" />
              </span>
              <div className="input-group input-group-sm shipment-details input-group-size ms-2">
                <Input
                  type="number"
                  className="form-control form-control-sm border-input-group fw-medium"
                  value={prepAndShipSource}
                  onChange={(e) => setPrepAndShipSource(e.target.value === "" ? undefined : Number(e.target.value))}
                />
                <span className="input-group-text">{sourceMarketplace?.currency}</span>
              </div>
            </span>
          </li>
        </ul>

        <li className="d-flex justify-content-between fw-bold my-2">
          <span>{t("SearchResults.Analyzes.ProfitAnalysis.Label.Fees")}</span>
          <span>
            <DisplayPrice source={destinationMarketplace?.currency} decimals={2} value={totalFees} renderOnEmpty={<span className="text-muted">{t("NA")}</span>} notation="abbreviated" />
          </span>
        </li>

        <ul className="list-group d-flex flex-group gap-2">
          <li className="d-flex align-items-center justify-content-between">
            <span className="ms-3">{t("SearchResults.Analyzes.ProfitAnalysis.Label.FbaFee")}</span>
            <div className="input-group input-group-sm shipment-details input-group-size ms-2">
              <Input
                type="number"
                className="form-control form-control-sm border-input-group fw-medium"
                value={fbaFee}
                onChange={(e) => setFbaFee(e.target.value === "" ? undefined : Number(e.target.value))}
              />
              <span className="input-group-text">{destinationMarketplace?.currency}</span>
            </div>
          </li>
          <li className="d-flex align-items-center justify-content-between">
            <span className="ms-3">{t("SearchResults.Analyzes.ProfitAnalysis.Label.ReferralFee")}</span>
            <div className="input-group input-group-sm shipment-details input-group-size ms-2">
              <Input
                type="number"
                className="form-control form-control-sm border-input-group fw-medium"
                value={referralFee}
                onChange={(e) => setReferralFee(e.target.value === "" ? undefined : Number(e.target.value))}
              />
              <span className="input-group-text">{destinationMarketplace?.currency}</span>
            </div>
          </li>
          <li className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <Button id={`OtherFees-${item.profitAnalysis.userSearchProductProfitAnalysisId}`} style={{height: 19.5}} className="d-flex align-items-center btn btn-ghost-dark" role="button">
                <span>{t("SearchResults.Analyzes.ProfitAnalysis.Label.OtherFees")}</span>
                <i className="ri-arrow-down-s-line fs-16 ms-1 text-info"></i>
              </Button>
              <UncontrolledPopover trigger="legacy" placement="right" popperClassName="search-results-other-fees" target={`OtherFees-${item.profitAnalysis.userSearchProductProfitAnalysisId}`}>
                <Table className="align-middle table table-hover mb-0">
                  <thead className="table-light">
                    <tr>
                      <th>{t("SearchResults.Analyzes.ProfitAnalysis.TableColumn.Type")}</th>
                      <th>{t("SearchResults.Analyzes.ProfitAnalysis.TableColumn.Fee")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{t("SearchResults.Analyzes.ProfitAnalysis.BankTransferCommission")}</td>
                      <td>
                        {item.profitAnalysis?.bankTransferCommission ? (
                          <div className="d-flex gap-1">
                            <DisplayPrice source={destinationMarketplace?.currency} decimals={2} value={item.profitAnalysis.bankTransferCommission} notation="abbreviated" />
                            <RenderNumber value={item.profitAnalysis.bankTransferCommissionRate} prefix="(%" suffix=")" />
                          </div>
                        ) : (
                          <span className="text-muted">{t("NA")}</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("SearchResults.Analyzes.ProfitAnalysis.EstimatedRefund")}</td>
                      <td>
                        {item.profitAnalysis?.estimatedRefund ? (
                          <div className="d-flex gap-1">
                            <DisplayPrice source={destinationMarketplace?.currency} decimals={2} value={item.profitAnalysis.estimatedRefund} notation="abbreviated" />
                            <RenderNumber value={item.profitAnalysis.estimatedRefundRate} prefix="(%" suffix=")" />
                          </div>
                        ) : (
                          <span className="text-muted">{t("NA")}</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("SearchResults.Analyzes.ProfitAnalysis.CurrencyExchangeLoss")}</td>
                      <td>
                        {item.profitAnalysis?.currencyExchangeLoss ? (
                          <>
                            <RenderNumber className="text-nowrap" value={item.profitAnalysis.currencyExchangeLossRate} prefix="%" />
                          </>
                        ) : (
                          <span className="text-muted">{t("NA")}</span>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </UncontrolledPopover>
            </div>
            <span>
              <div className="input-group input-group-sm shipment-details input-group-size ms-2">
                <Input
                  type="number"
                  className="form-control form-control-sm border-input-group fw-medium"
                  value={otherFees}
                  onChange={(e) => setOtherFees(e.target.value === "" ? undefined : Number(e.target.value))}
                />
                <span className="input-group-text">{destinationMarketplace?.currency}</span>
              </div>
            </span>
          </li>
        </ul>
        <li className="d-flex justify-content-between my-2 fw-bold">
          <span>{t("SearchResults.Analyzes.ProfitAnalysis.Label.OverallCost")}</span>
          <span>
            <DisplayPrice value={overallCost} source={destinationMarketplace?.currency} decimals={2} renderOnEmpty={<span className="text-muted">{t("NA")}</span>} notation="abbreviated" />
          </span>
        </li>
        <li className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <span className="ms-3">
              {t("SearchResults.Analyzes.ProfitAnalysis.Label.SellPrice")}
              {item.competitorAnalysis?.offerCount === 0 && (
                <>
                  <i id={`PopoverNoSellerInTheTargetMarketplace-${item.userSearchProductId}`} className="ms-1 mdi mdi-alert align-middle text-warning"></i>
                  <DefaultUncontrolledTooltip target={`PopoverNoSellerInTheTargetMarketplace-${item.userSearchProductId}`}> {t("NoSellerInTheTargetMarketplace")}</DefaultUncontrolledTooltip>
                </>
              )}
            </span>
          </div>
          <span>
            <Row>
              <Col className="d-flex justify-content-end align-items-center pe-0">
                <SellerTypeBadge type={item.competitorAnalysis?.buyBoxType ?? null} hideIfNA={true} />
              </Col>
              <Col xs="auto">
                <div className="input-group input-group-sm shipment-details input-group-size ms-2">
                  <Input
                    type="number"
                    className="form-control form-control-sm border-input-group fw-medium"
                    value={sellPrice}
                    onChange={(e) => setSellPrice(e.target.value === "" ? undefined : Number(e.target.value))}
                  />
                  <span className="input-group-text">{destinationMarketplace?.currency}</span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                {item.profitAnalysis?.currentShippingPrice !== 0 && item.profitAnalysis?.currentPrice && (
                  <small className="d-flex mt-2 ms-1 text-muted">
                    <DisplayPrice source={destinationMarketplace?.currency} value={item.profitAnalysis.currentPrice} decimals={2} prefix="(" suffix=" + " className="me-1" notation="abbreviated" />{" "}
                    <DisplayPrice
                      source={destinationMarketplace?.currency}
                      value={item.profitAnalysis.currentShippingPrice}
                      decimals={2}
                      prefix=" "
                      suffix={` ${t("Shipping")})`}
                      notation="abbreviated"
                    />
                  </small>
                )}
              </Col>
            </Row>
          </span>
        </li>
        <hr />
        <ul className="list-group d-flex flex-group gap-1">
          <br></br>

          {item.profitAnalysis?.marketplaceTaxExceptionRate !== 0 && (
            <li className="d-flex justify-content-between">
              <span>
                <div>{t("SearchResults.Analyzes.ProfitAnalysis.MarketplaceTaxException")}</div>
              </span>
              <span>
                <DisplayPrice
                  source={destinationMarketplace?.currency}
                  value={item.profitAnalysis.marketplaceTaxException}
                  decimals={2}
                  renderOnEmpty={<span className="text-muted">{t("NA")}</span>}
                  notation="abbreviated"
                />
              </span>
            </li>
          )}
          <li className="d-flex justify-content-between fw-bold">
            <span>{t("SearchResults.Analyzes.ProfitAnalysis.Profit")}</span>
            <DisplayPrice
              source={destinationMarketplace?.currency}
              value={profit}
              decimals={2}
              renderOnEmpty={<span className="text-muted">{t("NA")}</span>}
              className={profit && profit < 0 ? "text-danger" : ""}
              notation="abbreviated"
            />
          </li>
          <li className="d-flex justify-content-between fw-bold">
            <span>
              {t("SearchResults.Analyzes.ProfitAnalysis.Profit")} ({_.toUpper(sourceMarketplace?.currency)})
            </span>
            <DisplayPrice
              source={sourceMarketplace?.currency}
              value={profitSource}
              decimals={2}
              renderOnEmpty={<span className="text-muted">{t("NA")}</span>}
              className={profitSource && profitSource < 0 ? "text-danger" : ""}
              notation="abbreviated"
            />
          </li>

          <li className="d-flex justify-content-between fw-bold">
            <span>{t("SearchResults.Analyzes.ProfitAnalysis.Margin")}</span>
            <RenderNumber value={margin} decimals={2} renderOnEmpty={<span className="text-muted">{t("NA")}</span>} className={margin && margin < 0 ? "text-danger" : ""} suffix="%" />
          </li>
          <li className="d-flex justify-content-between fw-bold">
            <div>
              <span>{t("SearchResults.Analyzes.ProfitAnalysis.ROI")}</span>
            </div>
            <RenderNumber value={roi} decimals={2} renderOnEmpty={<span className="text-muted">{t("NA")}</span>} className={roi && roi < 0 ? "text-danger" : ""} suffix="%" />
          </li>
        </ul>

        {item.profitAnalysis?.exchangeRateTarget ? (
          <div className="d-flex justify-content-between mt-2 fs-11">
            <span></span>
            <span className="text-muted">
              <em>{t("SearchResults.Analyzes.ProfitAnalysis.ExchangeRate") + ": " + item.profitAnalysis.exchangeRateTarget}</em>
            </span>
          </div>
        ) : (
          ""
        )}
      </CardBody>
    </Card>
  );
};

export default ProfitAnalysis;
