import {generateError} from "helpers/utilities";
import {apiError, loading, reset, setStatistics} from "./reducer";
import {ResponseModel} from "models/response_model";
import {Dashboard} from "models/dashboard";
import {GetStatisticsQuery} from "api/query";
import {postGetDashboardStatistics} from "services/store_service";
import {setCurrencies} from "slices/common/reducer";

export const getStatistics = (payload: GetStatisticsQuery) => async (dispatch: any) => {
  try {
    dispatch(loading(["list", true]));
    const result: ResponseModel = await postGetDashboardStatistics(payload);
    const statistics: Dashboard = result.data;
    dispatch(setStatistics(statistics));
    dispatch(setCurrencies(statistics.currencies));
    return true;
  } catch (error:any) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["list", false]));
  }
};


export const resetDashboardState = () => async (dispatch: any) => {
  dispatch(reset());
}