import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import {useTranslation} from "react-i18next";
import {ApplicationUser} from "models/application_user";
import {useProfile} from "Components/Hooks/UserHooks";
import defaultAvatar from "../../assets/images/user-dummy-img.jpg";
import Restricted from "./Restricted";
import _ from "lodash";

const ProfileDropdown = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {userProfile} = useProfile();
  const [user, setUser] = useState<ApplicationUser | null>();
  useEffect(() => {
    if (_.isNil(userProfile)) {
      navigate("/account/login");
    } else {
      setUser(userProfile);
    }
  }, [userProfile, navigate]);

  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };
  return (
    <React.Fragment>
      <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="ms-sm-3 header-item topbar-user">
        <DropdownToggle tag="button" type="button" className="btn" onClick={()=>{
          document.body.classList.remove("menu")
        }}>
          <span className="d-flex align-items-center">
            <img className="rounded-circle header-profile-user object-fit-cover" src={userProfile?.avatar || defaultAvatar} alt="Header Avatar" />
            <span className="text-start ms-xl-2">
              <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{userProfile?.firstName}</span>
              <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">{userProfile?.lastName}</span>
            </span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <h6 className="dropdown-header">
            {t("Welcome")} {user?.firstName}!
          </h6>
          <DropdownItem className="p-0">
            <Link to={process.env.PUBLIC_URL + "/account/profile"} className="dropdown-item">
              <i className="mdi mdi-account-outline text-muted fs-16 align-middle me-1"></i>
              <span className="align-middle">{t("Account.Title")}</span>
            </Link>
          </DropdownItem>

          <Restricted require="favorites" read>
            <DropdownItem className="p-0">
              <Link to={process.env.PUBLIC_URL + "/favorites"} className="dropdown-item">
                <i className="mdi mdi-star-outline text-muted fs-16 align-middle me-1"></i>
                <span className="align-middle">{t("Favorites.Title")}</span>
              </Link>
            </DropdownItem>
          </Restricted>

          <DropdownItem className="p-0">
            <Link to={process.env.PUBLIC_URL + "/account/settings/cost-and-fees"} className="dropdown-item">
              <i className="mdi mdi-cog-outline text-muted fs-16 align-middle"></i> <span className="align-middle">{t("Settings.Title")}</span>
            </Link>
          </DropdownItem>

          <Restricted require="account.subscription" read>
            <DropdownItem className="p-0">
              <Link to={process.env.PUBLIC_URL + "/account/subscription"} className="dropdown-item">
                <i className="mdi mdi-credit-card-outline text-muted fs-16 align-middle me-1"></i>
                <span className="align-middle">{t("Account.Subscription.Title")}</span>
              </Link>
            </DropdownItem>
          </Restricted>

          <DropdownItem className="p-0">
            <Link to={process.env.PUBLIC_URL + "/account/support"} className="dropdown-item">
              <i className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i>
              <span className="align-middle">{t("Account.Support.Title")}</span>
            </Link>
          </DropdownItem>

          <div className="dropdown-divider"></div>
          <DropdownItem className="p-0">
            <Link to={process.env.PUBLIC_URL + "/account/logout"} className="dropdown-item">
              <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
              <span className="align-middle" data-key="t-logout">
                {t("Logout")}
              </span>
            </Link>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileDropdown;
