import React from "react";
import {Modal, ModalHeader, ModalBody, ModalFooter, Button, Col, Row, Spinner, Form, Card, CardBody} from "reactstrap";
import {useFormik} from "formik";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import AmazonMarketplaceInfos from "Components/Common/AmazonMarketplaceInfos";
import GetConnectRequestUrl from "../GetConnectRequestUrl";
import ValidatedInput from "Components/Common/ValidatedInput";
import * as Yup from "yup";

interface ConnectUserStoreProps {
  isOpen: boolean;
}
const ConnectUserStore = (props: ConnectUserStoreProps) => {
  const {t} = useTranslation();
  const [selectedMarketplace, setSelectedMarketplace] = useState("");
  const [amazonMarketplaceInfos] = useState(AmazonMarketplaceInfos());
  const navigate = useNavigate();
  const storesData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Stores.loading,
    }),
  );
  const {loading} = useSelector(storesData);

  const handleSelectedMarketplace = (marketplace: string) => {
    setSelectedMarketplace(marketplace === selectedMarketplace ? "" : marketplace);
  };

  const validation = useFormik({
    initialValues: {storeName: ""},
    validationSchema: Yup.object().shape({
      storeName: Yup.string()
        .required(t("Stores.NameRequired"))
        .max(100, t("Stores.NameMaxLength")),
    }),
    onSubmit: (values: any) => {
      window.location.href = GetConnectRequestUrl(amazonMarketplaceInfos, values.storeName, selectedMarketplace, "false");
    },
  });

  const toggle = () => {
    navigate("/stores");
  };
  return (
    <>
      <Modal id="showConnectUserStoreModal" className="modal-lg" isOpen={props.isOpen} toggle={toggle} fade={true} centered={true}>
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          {t("Stores.Connect")}
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={validation.handleSubmit}
          >
            <Row>
              <Col>
                <h5>{t("Stores.NameTitle")}</h5>
                <p>{t("Stores.NameInfo")}</p>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col xs={12} lg={4}>
                <div className="mb-3">
                  <ValidatedInput validation={validation} field={"storeName"} placeholder={t("Enter your store name")} maxLength={100} disableValidationUI />
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <h5>{t("Stores.SelectTitle")}</h5>
                <p>{t("Stores.SelectInfo")}</p>
              </Col>
            </Row>
            <Row className="d-flex align-items-center">
              {amazonMarketplaceInfos.map(
                (amazonInfo: any, key: any) =>
                  amazonInfo.active && (
                    <React.Fragment key={key}>
                      <Col xl={4} lg={4} sm={6} onClick={() => handleSelectedMarketplace(amazonInfo.marketplace)}>
                        <Card className={`cursor-pointer border${selectedMarketplace === amazonInfo.marketplace ? " card-item-checked" : ""}`}>
                          <CardBody className="p-2 d-flex">
                            <div className="flex-shrink-0 me-3">
                              <div className="avatar-sm img-thumbnail rounded-circle flex-shrink-0">
                                <img src={amazonInfo.flag} alt="" className="img-fluid d-block rounded-circle" />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <h5 className="ellipsis-single-line">{t(amazonInfo.countryName)}</h5>
                              <span className="text-muted">{amazonInfo.marketplace}</span>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </React.Fragment>
                  ),
              )}
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <div className="hstack gap-2 justify-content-end">
            <Button type="button" className="btn btn-light" disabled={loading.save} onClick={toggle}>
              {t("Close")}
            </Button>

            <Button
              type="submit"
              className="btn btn-success"
              disabled={loading.save}
              onClick={() => {
                if (validation.isValid && selectedMarketplace && amazonMarketplaceInfos.some((amazonMarketplaceInfo: any) => amazonMarketplaceInfo.marketplace === selectedMarketplace)) {
                  validation.handleSubmit();
                } else if (!validation.isValid) {
                  toast(validation.errors.storeName?.toString(), {
                    position: "top-center",
                    hideProgressBar: false,
                    progressStyle: {background: "LightSalmon"},
                    closeOnClick: true,
                    className: "bg-danger text-white",
                  });
                } else if (!amazonMarketplaceInfos.some((amazonInfo: any) => amazonInfo.marketplace === selectedMarketplace)) {
                  toast(t("Stores.SelectRequired"), {
                    position: "top-center",
                    hideProgressBar: false,
                    progressStyle: {background: "LightSalmon"},
                    closeOnClick: true,
                    className: "bg-danger text-white",
                  });
                }
              }}
            >
              {loading.save && <Spinner size="sm" className="me-2"></Spinner>}
              {t("Connect")}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ConnectUserStore;
