import {useEffect, useState} from "react";
import {useFormik} from "formik";
import {Button, Card, CardBody, Col, Form, Label, Row, Spinner} from "reactstrap";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {RootState} from "slices";
import {createSelector} from "reselect";
import {FilterOrderQuery} from "api/query";
import {getRelativeDateByTimezone, getToday} from "helpers/utilities";
import {useProfile} from "Components/Hooks/UserHooks";
import ValidatedInput from "Components/Common/ValidatedInput";
import PlaceholderGlow from "Components/Common/PlaceholderGlow";
import moment from "moment-timezone";
import ValidatedDate from "Components/Common/ValidatedDate";
import ValidatedMultiSelect from "Components/Common/ValidatedMultiSelect";
import useSelectOptions from "Components/Hooks/SelectOptions";
import * as Yup from "yup";

interface FiltersProps {
  busy: boolean;
  page: number;
  pageSize: number;
  handleFilter: (filter: FilterOrderQuery) => void;
}
const Filters = (props: FiltersProps) => {
  const {t} = useTranslation();
  const {multiSelectTranslations} = useSelectOptions();
  const {userProfile} = useProfile();
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const start = queryParams.get("start");
    const end = queryParams.get("end");

    var payload: FilterOrderQuery = {
      page: 1,
      pageSize: props.pageSize,
      filtering: true,
    };
    if (start && end) {
      setStartDate(new Date(start));
      setEndDate(new Date(end));
      validation.setFieldValue("startDate", new Date(start));
      validation.setFieldValue("endDate", new Date(end));
      payload = {
        ...payload,
        startDate: moment
          .tz(start, userProfile?.timezone!)
          .startOf("day")
          .tz("Etc/GMT")
          .toDate(),
        endDate: moment
          .tz(end, userProfile?.timezone!)
          .endOf("day")
          .tz("Etc/GMT")
          .toDate(),
      };
    } else {
      setStartDate(getRelativeDateByTimezone(30, userProfile?.timezone));
      setEndDate(getToday().toDate());
      validation.setFieldValue("startDate", getRelativeDateByTimezone(30, userProfile?.timezone));
      validation.setFieldValue("endDate", getToday().toDate());
      payload = {
        ...payload,
        startDate: getRelativeDateByTimezone(30, userProfile?.timezone),
        endDate: getToday()
          .tz(userProfile?.timezone!)
          .endOf("day")
          .toDate(),
      };
    }

    props.handleFilter(payload);
  }, []); // eslint-disable-line

  const orderData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Orders.loading,
      activeUserStoreOptions: state.Common.activeUserStoreOptions,
    }),
  );
  const {loading, activeUserStoreOptions} = useSelector(orderData);

  const validation = useFormik({
    initialValues: {
      startDate: startDate,
      endDate: endDate,
    } as FilterOrderQuery,
    validationSchema: Yup.object<FilterOrderQuery>({
      search: Yup.string(),
      store: Yup.string(),
      marginMin: Yup.number(),
      marginMax: Yup.number(),
      roiMin: Yup.number(),
      roiMax: Yup.number(),
      profitMin: Yup.number(),
      profitMax: Yup.number(),
      startDate: Yup.string(),
      endDate: Yup.string(),
    }),
    onSubmit: (values: FilterOrderQuery) => {
      const payload: FilterOrderQuery = {
        page: 1,
        pageSize: props.pageSize,
        stores: values.stores || undefined,
        search: values.search || undefined,
        marginMin: values.marginMin || undefined,
        marginMax: values.marginMax || undefined,
        roiMin: values.roiMin || undefined,
        roiMax: values.roiMax || undefined,
        profitMin: values.profitMin || undefined,
        profitMax: values.profitMax || undefined,
        startDate: moment
          .tz(values.startDate, userProfile?.timezone!)
          .startOf("day")
          .tz("Etc/GMT")
          .toDate(),
        endDate: moment
          .tz(values.endDate, userProfile?.timezone!)
          .tz(userProfile?.timezone!)
          .endOf("day")
          .toDate(),
        filtering: true,
      };

      props.handleFilter(payload);
    },
  });

  return (
    <>
      <PlaceholderGlow busy={props.busy}>
        <Card>
          <CardBody>
            <div className="live-preview">
              <Form onSubmit={validation.handleSubmit}>
                <Row className="align-items-center">
                  <Col>
                    <div className="flex-shrink-0">
                      <Label className="form-label text-muted">{t("Orders.Filters.Title")}</Label>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Row className=" mb-3">
                    <Col sm={2} className="inventory filters-col">
                      <Label htmlFor="search">{t("Orders.Filters.Search")}</Label>
                      <ValidatedInput validation={validation} field="search" maxLength={100} placeholder={t("Orders.Filters.SearchPlaceholder")} disableValidationUI />
                    </Col>

                    <Col sm={2} className="inventory filters-col">
                      <Label htmlFor="stores">{t("Orders.Filters.StoreFilter")}</Label>
                      <ValidatedMultiSelect
                        options={activeUserStoreOptions}
                        validation={validation}
                        field="stores"
                        value={validation.values.stores}
                        translations={{
                          ...multiSelectTranslations,
                          allItemsAreSelected: t("General.Select.AllStoresSelected"),
                          selectSomeItems: t("General.Select.SelectStore"),
                        }}
                      />
                    </Col>

                    <Col sm={2} className="inventory filters-col">
                      <Label htmlFor="startDate">{t("Orders.Filters.StartDate")}</Label>
                      <ValidatedDate field="startDate" validation={validation} value={validation.values.startDate}></ValidatedDate>
                    </Col>
                    <Col sm={2} className="inventory filters-col">
                      <Label htmlFor="endDate">{t("Orders.Filters.EndDate")}</Label>
                      <ValidatedDate field="endDate" validation={validation} value={validation.values.endDate}></ValidatedDate>
                    </Col>
                  </Row>
                  <Row className=" mb-3">
                    <Col sm={2} className="inventory filters-col">
                      <Label htmlFor="marginMin">{t("Orders.Filters.Margin")}</Label>
                      <Row>
                        <Col>
                          <ValidatedInput validation={validation} type="number" field="marginMin" placeholder="Min" disableValidationUI={true} />
                        </Col>
                        <Col xs="auto" className="text-muted d-flex align-items-center p-0">
                          <Label>{t("and")}</Label>
                        </Col>
                        <Col>
                          <ValidatedInput validation={validation} type="number" field="marginMax" placeholder="Max" disableValidationUI={true} />
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={2} className="inventory filters-col">
                      <Label htmlFor="roi">{t("Orders.Filters.ROI")}</Label>
                      <Row>
                        <Col>
                          <ValidatedInput validation={validation} type="number" field="roiMin" placeholder="Min" disableValidationUI={true} />
                        </Col>
                        <Col xs="auto" className="text-muted d-flex align-items-center p-0">
                          <Label>{t("and")}</Label>
                        </Col>
                        <Col>
                          <ValidatedInput validation={validation} type="number" field="roiMax" placeholder="Max" disableValidationUI={true} />
                        </Col>
                      </Row>
                    </Col>

                    <Col sm={2} className="inventory filters-col">
                      <Label htmlFor="profit">{t("Orders.Filters.Profit")}</Label>
                      <Row>
                        <Col>
                          <ValidatedInput validation={validation} type="number" field="profitMin" placeholder="Min" disableValidationUI={true} />
                        </Col>
                        <Col xs="auto" className="text-muted d-flex align-items-center p-0">
                          <Label>{t("and")}</Label>
                        </Col>
                        <Col>
                          <ValidatedInput validation={validation} type="number" field="profitMax" placeholder="Max" disableValidationUI={true} />
                        </Col>
                      </Row>
                    </Col>
                    <Col className="mt-3 mt-xl-0">
                      <Label htmlFor="profit">&nbsp;</Label>
                      <Row>
                        <Col>
                          <Button type="submit" className="btn bg-primary me-0" disabled={loading.filter}>
                            {loading.filter ? <Spinner size="sm" className="me-2"></Spinner> : <i className="ri-equalizer-fill me-1 align-bottom"></i>}
                            {t("Orders.Filters.Button.Apply")}
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Row>
              </Form>
            </div>
          </CardBody>
        </Card>
      </PlaceholderGlow>
    </>
  );
};

export default Filters;
