import React, {useState} from "react";
import PropTypes from "prop-types";
import {Col, Row, UncontrolledAlert, Button} from "reactstrap";
import {useTranslation} from "react-i18next";
import {useProfile} from "Components/Hooks/UserHooks";
import {AlertComponentProps, LocalizedAlert} from "models/alert_types";
import {loadCollapseState, saveCollapseState} from "helpers/alert_helpers";

const AlertComponent: React.FC<AlertComponentProps> = ({alertType, replaceVariables = {}}) => {
  const {t} = useTranslation();
  const {userProfile} = useProfile();
  const [isCollapsed, setIsCollapsed] = useState(() => loadCollapseState(`alertType_${alertType.translationKey}_collapsed`));

  // Save collapse state to local storage
  const toggleCollapse = () => {
    const newCollapseState = !isCollapsed;
    setIsCollapsed(newCollapseState);
    saveCollapseState(`alertType_${alertType.translationKey}_collapsed`, newCollapseState);
  };

  const localizedAlert: LocalizedAlert = t(`Alerts.${alertType.translationKey}`, {returnObjects: true}) as LocalizedAlert;
  const replacedTitle = localizedAlert.Title.replace(/{{(\w+)}}/g, (match, p1) => String(replaceVariables[p1] || (userProfile && userProfile[p1 as keyof typeof userProfile]) || ""));

  return (
    <React.Fragment>
      <Row>
        <Col className="mb-4">
          <UncontrolledAlert color={alertType.color} className="alert-additional mb-xl-0" fade={false} toggle={null}>
            <div className="alert-body">
              <div className="d-flex">
                <div className="flex-shrink-0 me-3">
                  <i className={`${alertType.icon} fs-20 align-middle`}></i>
                </div>
                <div className="flex-grow-1">
                  <div className="d-flex align-items-center justify-content-between">
                    <h5
                      className="alert-heading pb-0 mb-0"
                      dangerouslySetInnerHTML={{
                        __html: replacedTitle,
                      }}
                    ></h5>
                    <Button className={`btn-ghost-${alertType.color} px-1 py-0 fs-18`} onClick={toggleCollapse}>
                      {isCollapsed ? <i className="mdi mdi-arrow-expand"></i> : <i className="mdi mdi-arrow-collapse"></i>}
                    </Button>
                  </div>

                  {!isCollapsed && localizedAlert.Content && <h6 className="alert-heading mt-3">{localizedAlert.Content}</h6>}
                  {!isCollapsed &&
                    localizedAlert.BulletPoints &&
                    localizedAlert.BulletPoints.length > 0 &&
                    localizedAlert.BulletPoints.map((bullet, index) => (
                      <React.Fragment key={index}>
                        <h6 className="alert-heading mt-3">{bullet.Title}</h6>
                        {bullet.Items && (
                          <ul
                            className="mb-2"
                            dangerouslySetInnerHTML={{
                              __html: bullet.Items.map((item) => `<li>${item}</li>`).join(""),
                            }}
                          ></ul>
                        )}
                      </React.Fragment>
                    ))}
                </div>
              </div>
            </div>
            {!isCollapsed && localizedAlert.FooterMessage && (
              <div className="alert-content">
                <p className="mb-0">{localizedAlert.FooterMessage}</p>
              </div>
            )}
          </UncontrolledAlert>
        </Col>
      </Row>
    </React.Fragment>
  );
};

AlertComponent.propTypes = {
  alertType: PropTypes.shape({
    color: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    translationKey: PropTypes.string.isRequired,
  }).isRequired,
  replaceVariables: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null]), PropTypes.any])).isRequired,
};

export default AlertComponent;
