import {useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Button, Card, CardBody, Col, Container, Row} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {Link, useParams} from "react-router-dom";
import {useImmer} from "use-immer";
import {ColumnDef} from "@tanstack/react-table";
import {useDebounce} from "Components/Hooks/HelperHooks";
import {DataTableRef} from "Components/Common/DataTable";
import {useProfile} from "Components/Hooks/UserHooks";
import {ConstantPage} from "helpers/permission_helper";
import {SearchLogQuery} from "api/query";
import {getSearchLogs, resetSearchProductItem} from "slices/admin/searchLogs/thunk";
import BreadCrumb from "Components/Common/BreadCrumb";
import Filters from "./Filters";
import Stats from "./Stats";
import Loader from "Components/Common/Loader";
import DataTable from "Components/Common/DataTable";
import Restricted from "Components/Common/Restricted";
import Unauthorized from "pages/Errors/_Unauthorized";
import NoResult from "Components/Common/NoResult";
import ProductAnalyze from "./Results/_ProductAnalyze";
import SpiApi from "./Results/_SpApi";
import ProductOffers from "./Results/_ProductOffers";
import TargetServices from "./Results/_TargetServices";
import Analyses from "./Results/_Analyses";
import Others from "./Results/_Others";
import StatusReasons from "./Results/_StatusReasons";
import {UserSearchProduct} from "models/user_search_product";
import Dialog, {DialogRef} from "Components/Common/Dialog";

export type AdminSearchLogsRef = {
  reload: VoidFunction;
};

const PAGE_IDENTIFIER: ConstantPage = "adminSearchLogs";
const AdminSearchLogs = () => {
  const {t} = useTranslation();
  const {hasPermission} = useProfile();
  const {searchId} = useParams();
  const dispatch: any = useDispatch();
  const [selectedProductItem, setSelectedProductItem] = useState<UserSearchProduct>();
  const tableRef = useRef<DataTableRef>(null);
  const listRef = useRef<AdminSearchLogsRef>(null);
  const resetSearchProductDialogRef = useRef<DialogRef>(null);

  const [query, updateQuery] = useImmer<SearchLogQuery>({
    searchId: searchId as string,
    filtering: true,
    page: 1,
    pageSize: 10,
  });

  const debouncedLoadList = useDebounce(() => {
    if (hasPermission(PAGE_IDENTIFIER) && query.searchId) {
      getSearchLogs(query)(dispatch).then(() => {
        tableRef.current?.resetSelection();
      });
    }
  }, 200);

  useEffect(() => {
    debouncedLoadList();
  }, [debouncedLoadList, query]); // eslint-disable-line

  useImperativeHandle(
    listRef,
    () => {
      return {
        reload: () => {
          debouncedLoadList();
        },
      };
    },
    [debouncedLoadList],
  );

  const searchLogsData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.AdminSearchLogs.loading,
      list: state.AdminSearchLogs.list,
    }),
  );
  const {loading, list} = useSelector(searchLogsData);

  const columns = useMemo<ColumnDef<UserSearchProduct, any>[]>(
    () => [
      {
        header: " ",
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearchProduct;
          const index = cellProps.row.index as number;
          return (
            <>
              <Row key={index}>
                <Col>
                  <Button
                    type="button"
                    color="primary"
                    className="mb-3"
                    onClick={() => {
                      setSelectedProductItem(row);
                      resetSearchProductDialogRef.current?.show();
                    }}
                  >
                    {t("Admin.SearchLogs.Button.Reset")}
                  </Button>
                </Col>

                <Col sm={{size: 7}}>
                  <Link to={`/searches/search-results/${row.userSearchId}?asin=${row.asin}`} className="btn btn-soft-secondary btn-sm rounded-pill mb-3 p-2" target="_blank">
                    {t("Admin.SearchLogs.Button.ShowAnalysisResults")} <i className="mdi mdi-magnify ms-1"></i>
                  </Link>
                </Col>
              </Row>
              <Row>
                <Col sm={2} className="same-height">
                  <ProductAnalyze key={index} row={row} />
                </Col>
                <Col sm={2} className="same-height">
                  <Row>
                    <Col xs={12}>
                      <SpiApi row={row} />
                    </Col>
                    <Col xs={12}>
                      <ProductOffers row={row} />
                    </Col>
                  </Row>
                </Col>
                <Col sm={2} className="same-height">
                  <TargetServices row={row} />
                </Col>
                <Col sm={2} className="same-height">
                  <Analyses row={row} />
                </Col>
                <Col sm={2} className="same-height">
                  <Others row={row} />
                </Col>
                <Col sm={2} className="same-height">
                  <StatusReasons row={row} />
                </Col>
              </Row>
            </>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, list],
  );

  document.title = t("PageTitles.SearchLogs");

  return (
    <Restricted require={PAGE_IDENTIFIER} read fallback={() => <Unauthorized />}>
      <>
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title={t("Admin.SearchLogs.Title")} menus={[{label: t("Admin.SearchLogs.Title"), url: "/admin/search-logs"}]} />
            <>
              <Filters busy={loading.filter} page={query.page} pageSize={query.pageSize} handleFilter={(f) => updateQuery(f)} />
            </>
            {loading.filter ? <Loader /> : list.items && list.items.length > 0 ? <Stats busy={loading.list} /> : ""}
            <Card>
              <CardBody>
                {loading.filter ? (
                  <Loader />
                ) : list.items && list.items.length > 0 ? (
                  <>
                    <DataTable
                       
                      ref={tableRef}
                      busy={loading.list || loading.list}
                      columns={columns}
                      data={list.items || []}
                      totalDataLength={list.totalCount}
                      pagination={{
                        pageIndex: query.page - 1,
                        pageSize: query.pageSize,
                      }}
                      onPaginationChanged={(pagination) =>
                        updateQuery((q) => {
                          q.page = pagination.pageIndex + 1;
                          q.pageSize = pagination.pageSize;
                          q.filtering = false;
                        })
                      }
                      hovered
                    />
                  </>
                ) : (
                  <NoResult title={t("Admin.SearchLogs.NoResult.Title")} description={t("Admin.SearchLogs.NoResult.Description")} />
                )}
              </CardBody>
            </Card>
          </Container>
        </div>
      </>
      <Dialog
        ref={resetSearchProductDialogRef}
        color="primary"
        buttons={["yes", "no"]}
        busy={loading.reset}
        iconClass="ri-restart-line"
        message={t("Admin.SearchLogs.Dialog.Reset.Description")}
        title={t("Admin.SearchLogs.Dialog.Reset.Title")}
        onButtonClick={async (button, hide) => {
          if (button === "yes" && selectedProductItem) {
            await dispatch(
              resetSearchProductItem({
                userId: selectedProductItem?.userId!,
                searchId: selectedProductItem?.userSearchId,
                searchProductId: selectedProductItem?.userSearchProductId,
              }),
            );
          }
          resetSearchProductDialogRef.current?.hide();
        }}
      />
    </Restricted>
  );
};

export default AdminSearchLogs;
