import {Button, Col, Form, Modal, ModalBody, Row, Spinner} from "reactstrap";
import {AnalyzeShipmentCommand} from "api/command";
import {useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {analyzeUserShipment} from "slices/thunks";
import {preventScrollUp} from "helpers/utilities";
import ValidatedInput from "Components/Common/ValidatedInput";
import ValidatedSelect from "Components/Common/ValidatedSelect";
import * as Yup from "yup";
interface AnalyzeShipmentProps {
  isOpen: boolean;
}
const AnalyzeShipment = (props: AnalyzeShipmentProps) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {userShipmentId} = useParams();
  const dispatch = useDispatch();
  const shipmentsData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Shipment.loading,
      activeUserStoreOptions: state.Common.activeUserStoreOptions,
      activeMarketplaceOptions: state.Common.activeMarketplaceOptions,
    }),
  );
  const {loading, activeUserStoreOptions, activeMarketplaceOptions} = useSelector(shipmentsData);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      shipmentId: userShipmentId,
      searchName: "",
      marketplace: "",
    } as AnalyzeShipmentCommand,
    validationSchema: Yup.object().shape({
      searchName: Yup.string().required(t("Searches.Dialog.ReAnalyze.Validation.SearchName")),
      marketplace: Yup.string().required(t("Searches.Dialog.ReAnalyze.Validation.Marketplace")),
    }),

    onSubmit: (values: AnalyzeShipmentCommand) => {
      const payload: AnalyzeShipmentCommand = {
        marketplace: values.marketplace,
        shipmentId: userShipmentId ?? "",
        searchName: values.searchName,
      };
      const reAnalyzePromise = analyzeUserShipment(payload, navigate)(dispatch);
      reAnalyzePromise.then((isSuccess) => {
        if (isSuccess) {
          toggle();
        }
      });
    },
  });
  const toggle = () => {
    navigate(`/shipments/shipment-details/${userShipmentId}`);
    // Preventing scroll to top
    preventScrollUp();
    validation.resetForm();
  };
  return (
    <Modal backdrop="static" isOpen={props.isOpen} toggle={toggle} centered={true}>
      <ModalBody className="py-3 px-5">
        <Form onSubmit={validation.handleSubmit}>
          <div className="mt-2 text-center">
            <i className={"mdi mdi-chart-timeline-variant-shimmer display-5 text-info"}></i>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-4">
              <h4>{t("Shipments.ShipmentDetails.Dialog.AnalyzeShipment.Title")}</h4>
              <p className="text-muted mx-4 mb-0 mt-3">{t("Shipments.ShipmentDetails.Dialog.AnalyzeShipment.Description")}</p>
            </div>
          </div>
          <div className="mt-4">
            <Row className="g-3">
              <Col xs={12} md={6}>
                <ValidatedInput validation={validation} type="text" field="searchName" maxLength={100} placeholder={t("Searches.Name")} disableValidationUI />
              </Col>
              <Col xs={12} md={6}>
                <ValidatedSelect
                  className={`new-search filter-input ${validation.errors.marketplace && validation.touched.marketplace && "form-control is-invalid form-select-invalid"}`}
                  options={[...activeUserStoreOptions, ...activeMarketplaceOptions]}
                  validation={validation}
                  field="marketplace"
                  value={validation.values.marketplace}
                  errorStyle="container"
                />
              </Col>
            </Row>
          </div>

          <div className="mt-4 mb-2">
            <div className="d-flex gap-2 justify-content-center">
              <Button type="submit" color={"success"} disabled={loading.analyzeShipment}>
                {loading.analyzeShipment && <Spinner size="sm" color="light" className="me-2" />}
                {t("Favorites.Dialog.Reanalyze.SubmitButton")}
              </Button>
              <Button type="button" color={"light"} onClick={toggle} disabled={loading.analyzeShipment}>
                {t("Favorites.Dialog.Reanalyze.CancelButton")}
              </Button>
            </div>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default AnalyzeShipment;
