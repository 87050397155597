import BreadCrumb, {BreadcrumbMenuItem} from "Components/Common/BreadCrumb";
import Dialog, {DialogRef} from "Components/Common/Dialog";
import DataTable, {DataTableRef} from "Components/Common/DataTable";
import {useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import {UserFavorite} from "models/user_favorites";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Card, CardBody, Container} from "reactstrap";
import {createSelector} from "reselect";
import {changeRenameModalVisibility, changeSendToAnalyzeModalVisibility, deleteUserFavoriteList, getUserFavorites} from "slices/thunks";
import {Link, useNavigate} from "react-router-dom";
import {RootState} from "slices";
import {setSelectedUserFavoriteList} from "slices/favorites/reducer";
import {ColumnDef} from "@tanstack/react-table";
import {useImmer} from "use-immer";
import {useDebounce} from "Components/Hooks/HelperHooks";
import {useProfile} from "Components/Hooks/UserHooks";
import {ConstantPage} from "helpers/permission_helper";
import {GetUserFavoriteListQuery} from "api/query";
import AmazonMarketplaceInfos from "Components/Common/AmazonMarketplaceInfos";
import ReAnalyze from "./Modals/ReAnalyze";
import Rename from "./Modals/Rename";
import PlaceholderGlow from "Components/Common/PlaceholderGlow";
import Restricted from "Components/Common/Restricted";
import Unauthorized from "pages/Errors/_Unauthorized";
import NoResult from "Components/Common/NoResult";
import DisplayDate from "Components/Common/DisplayDate";

export type FavoriteListRef = {
  reload: VoidFunction;
};

const PAGE_IDENTIFIER: ConstantPage = "favorites";
const Favorites = () => {
  const {t} = useTranslation();
  const {userProfile, hasPermission} = useProfile();
  const navigate = useNavigate();
  const deleteDialogRef = useRef<DialogRef>(null);
  const tableRef = useRef<DataTableRef>(null);
  const listRef = useRef<FavoriteListRef>(null);

  const [amazonMarketplaceInfos] = useState(AmazonMarketplaceInfos());

  const [query, updateQuery] = useImmer<GetUserFavoriteListQuery>({
    page: 1,
    pageSize: 10,
    marketplace: "",
  });

  const debouncedLoadList = useDebounce(() => {
    if (hasPermission(PAGE_IDENTIFIER)) {
      getUserFavorites(query)(dispatch).then(() => {
        tableRef.current?.resetSelection();
      });
    }
  }, 200);

  useEffect(() => {
    debouncedLoadList();
  }, [debouncedLoadList, query]);

  useImperativeHandle(
    listRef,
    () => {
      return {
        reload: () => {
          debouncedLoadList();
        },
      };
    },
    [debouncedLoadList],
  );

  const dispatch: any = useDispatch();
  const favoritesData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Favorites.loading,
      selectedFavoriteList: state.Favorites.selectedFavoriteList,
      analyzeModalVisibility: state.Favorites.analyzeModalVisibility,
      list: state.Favorites.list,
    }),
  );
  const {loading, selectedFavoriteList, list} = useSelector(favoritesData);

  const columns = useMemo<ColumnDef<UserFavorite, any>[]>(
    () => [
      {
        header: t("Actions"),
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserFavorite;
          return (
            <div className="d-flex align-items-center">
              <Link
                to=""
                className="btn btn-ghost-secondary px-1 py-0 fs-18"
                onClick={() => {
                  dispatch(setSelectedUserFavoriteList(row));
                  dispatch(changeSendToAnalyzeModalVisibility(true));
                }}
              >
                <i className="mdi mdi-chart-timeline-variant-shimmer"></i>
              </Link>

              <Link
                to=""
                className="btn btn-ghost-secondary px-1 py-0 fs-18"
                onClick={() => {
                  dispatch(setSelectedUserFavoriteList(row));
                  dispatch(changeRenameModalVisibility(true));
                }}
              >
                <i className="ri-pencil-fill"></i>
              </Link>

              <Link
                to=""
                className="btn btn-ghost-danger px-1 py-0 fs-18"
                onClick={() => {
                  dispatch(setSelectedUserFavoriteList(row));
                  deleteDialogRef.current?.show();
                }}
              >
                <i className="ri-delete-bin-fill"></i>
              </Link>
            </div>
          );
        },
      },
      {
        header: t("Favorites.TableColumn.Name"),
        size: 100,
        accessorKey: "name",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as UserFavorite;
          return (
            <span className="link-secondary link-offset-2 text-decoration-underline" onClick={() => navigate(`/favorites/${row.userFavoriteId}`)} role="button">
              {row.name}
            </span>
          );
        },
      },
      {
        header: t("Favorites.TableColumn.Marketplace"),
        size: 200,
        accessorKey: "marketplace",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as UserFavorite;
          const marketplaceInfo = amazonMarketplaceInfos.find((marketplaceInfo) => marketplaceInfo.marketplace === row.marketplace);
          return (
            <>
              {marketplaceInfo && (
                <>
                  <span>
                    <span className="hstack gap-2">
                      <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                        <img src={marketplaceInfo.flag} alt="Country Flag" className="rounded-circle" />
                      </div>
                      {`${marketplaceInfo.countryName} (${marketplaceInfo.marketplace})`}
                    </span>
                  </span>
                </>
              )}
            </>
          );
        },
      },
      {
        header: t("Favorites.TableColumn.Total"),
        size: 100,
        accessorKey: "count",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as UserFavorite;
          return <>{row.count}</>;
        },
      },
      {
        header: t("Favorites.TableColumn.CreateDate"),
        size: 100,
        accessorKey: "createDate",
        sortingFn: "datetime",
        cell: (cellProps) => {
          const row = cellProps.row.original as UserFavorite;
          return (
            <>
              <DisplayDate id={`DisplayDateCreateDate-${row.userFavoriteId}`} value={row.createDate} format="D MMM YYYY HH:mm" tz={userProfile?.timezone} />
            </>
          );
        },
      },
    ],
    [t, amazonMarketplaceInfos, dispatch, navigate, userProfile],
  );

  const breadcrumbMenus: BreadcrumbMenuItem[] = [
    {
      label: t("Favorites.Title"),
    },
  ];
  return (
    <Restricted require={PAGE_IDENTIFIER} read fallback={() => <Unauthorized />}>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Favorites.Title")} menus={breadcrumbMenus} />
          <PlaceholderGlow busy={loading.list}>
            <Card>
              <CardBody>
                {list?.totalCount ? (
                  <DataTable
                    ref={tableRef}
                    busy={loading.list}
                    columns={columns}
                    data={list?.items || []}
                    totalDataLength={list?.totalCount}
                    pagination={{
                      pageIndex: query.page - 1,
                      pageSize: query.pageSize,
                    }}
                    onPaginationChanged={(pagination) =>
                      updateQuery((q) => {
                        q.page = pagination.pageIndex + 1;
                        q.pageSize = pagination.pageSize;
                      })
                    }
                    hovered
                  />
                ) : (
                  <NoResult title={t("Favorites.EmptyList.Title")} description={t("Favorites.EmptyList.Description")} />
                )}
              </CardBody>
            </Card>
          </PlaceholderGlow>
        </Container>
      </div>
      <Dialog
        ref={deleteDialogRef}
        color="danger"
        buttons={["yes", "no"]}
        busy={loading.delete}
        iconClass="ri-delete-bin-line"
        message={t("Favorites.Dialog.Delete.Description")}
        title={t("Favorites.Dialog.Delete.Title")}
        onButtonClick={async (button, hide) => {
          if (button === "yes") {
            if (selectedFavoriteList) {
              await dispatch(deleteUserFavoriteList(selectedFavoriteList?.userFavoriteId, query));
            }
          }
          deleteDialogRef.current?.hide();
        }}
      />
      <ReAnalyze />
      <Rename />
    </Restricted>
  );
};

export default Favorites;
