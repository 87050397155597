import SelectOptions from "Components/Hooks/SelectOptions";
import {SearchResultFilter} from "models/search_result";

const asin = new URLSearchParams(window.location.search).get("asin");
const pathname = window.location.pathname;
const InitialFilter = (): SearchResultFilter => {
  const keyword = pathname.endsWith("sell-this") ? "" : asin;
  const {commonProductSelectOptions, dateRangeSelectOptions} = SelectOptions();

  return {
    decisionFilter: undefined,
    amazonCatalogStatusFilter: undefined,
    keyword: keyword,
    profitMarginMin: undefined,
    profitMarginMax: undefined,
    fbaSellerCountMin: undefined,
    fbaSellerCountMax: undefined,
    saleCountMin: undefined,
    saleCountMax: undefined,
    priceChangeMin: undefined,
    priceChangeMax: undefined,
    roiMin: undefined,
    roiMax: undefined,
    fbmSellerCountMin: undefined,
    fbmSellerCountMax: undefined,
    bsrMin: undefined,
    bsrMax: undefined,
    weightMin: undefined,
    weightMax: undefined,
    profitMin: undefined,
    profitMax: undefined,
    remoteFbaSellerCountMin: undefined,
    remoteFbaSellerCountMax: undefined,
    variationCountMin: undefined,
    variationCountMax: undefined,
    sellPriceMin: undefined,
    sellPriceMax: undefined,
    purchasePriceMin: undefined,
    purchasePriceMax: undefined,
    amazonNotListedSince: commonProductSelectOptions[0].value,
    competition: undefined,
    priceAnalysisResult: undefined,
    productAge: dateRangeSelectOptions[0].value,
    potentialBrandOwner: commonProductSelectOptions[0].value,
    isAmazonExist: commonProductSelectOptions[0].value,
    sameProduct: commonProductSelectOptions[0].value,
    customsRestriction: commonProductSelectOptions[0].value,
    restrictionCheck: commonProductSelectOptions[0].value,
    sizeIsEligible: commonProductSelectOptions[0].value,
    isOldProduct: commonProductSelectOptions[0].value,
    eligibleToSell: commonProductSelectOptions[0].value,
    categories: undefined,
    sortBy: undefined,
  } as SearchResultFilter;
};

export default InitialFilter;
