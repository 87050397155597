import {DateRange, PagerQuery} from "helpers/types";
import {UserStore} from "./user_stores";
import {UserShipmentFinance} from "./user_shipment_finance";
import {UserShipmentSetting} from "./user_shipment_setting";
import {UserShipmentItem} from "./user_shipment_item";
import {OrderStatus} from "./user_order";

export interface UserShipment {
  userShipmentId: string;
  userId: string;
  userStoreId: string;
  nameId: BigInt; // Defined as long type in database. That's why it's BigInt
  name: string;
  totalSKU: number;
  totalItems: number;
  soldItems: number;
  status: UserShipmentStatus;
  shipDate?: Date;
  amazonShipmentId?: string;
  completedDate?: Date;
  createDate: Date;
  updateDate: Date;
  userStore: UserStore;
  userShipmentFinance: UserShipmentFinance;
  userShipmentItems: UserShipmentItem[];
  userShipmentSetting: UserShipmentSetting;
}

export type ShipmentOrder = {
  assignmentDate: Date;
  orderId: string;
  marketplace: string;
  storeName: string;
  asin: string;
  sku: string;
  image: string;
  amazonOrderId: string;
  status: OrderStatus;
  quantity: number;
  expectedSellPrice: number;
  expectedProfit: number;
  expectedMargin: number;
  expectedROI: number;
  actualSellPrice: number;
  actualProfit: number;
  actualMargin: number;
  actualROI: number;
  cost: number;
  costIsPredicted: boolean;
  fees: number;
  tax: number;
};

export enum UserShipmentStatus {
  DRAFT = 0,
  SHIPPED = 1,
  COMPLETED = 2,
}

export type FilterShipmentsQuery = PagerQuery & {
  createDateRange?: DateRange;
  shipDateRange?: DateRange;
  userStoreIds?: string[];
  statuses?: string[];
  filtering: boolean; // For loading indicator
};

export interface GetUserDraftShipmentsQuery {
  asin: string;
  marketplace: string;
}

export interface ISaveNewUserShipmentDto {
  shipmentName: string;
  marketplace: string;
  userStoreId: string;
}

export interface IEditUserShipmentDto {
  userShipmentId: string;
  shipmentName: string;
  amazonShipmentId: string;
  shipDate: string;
  asin: string;
  from: string;
}

export interface IDeleteUserShipmentDto {
  userShipmentId: string;
  asin: string;
  from: "SearchResults" | "Shipments";
}

export interface IGetUserShipmentDetailsDto {
  userShipmentId: string;
}

export interface ISetUserShipmentDetailsDto {
  userShipmentId: string;
  userInventoryId: string;
  userShipmentItemId: string;
  field: string;
  newValue: number;
  amazonShipmentId: string;
  shipDate: string;
  from: "SearchResults" | "Shipments";
}

export interface IAddUserShipmentItemDto {
  userStoreId: string;
  userShipmentId: string;
  shipmentName: string;
  asin: string;
  purchasePrice: number;
  tax: number;
  shippingPrice: number;
  fBAFee: number;
  referralFee: number;
  otherFees: number;
  expectedSellPrice: number;
  totalItems: number;
  weight: number;
  from: "SearchResults" | "ShipmentDetails";
}

export interface IDeleteUserShipmentItemDto {
  userShipmentId: string;
  userShipmentItemId: string;
  asin: string;
  from: "SearchResults" | "ShipmentDetails";
}

export interface IRefreshUserShipmentItemDto {
  userShipmentId: string;
  userShipmentItemId: string;
}

export interface IAddToAmazonInventoryDto {
  userShipmentId: string;
  userShipmentItemId?: string;
}
