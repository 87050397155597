import {useCallback} from "react";
import {useProfile} from "Components/Hooks/UserHooks";
import {DateStyle} from "models/enums/date_style";
import {useDispatch} from "react-redux";
import {updateUIPreferences} from "slices/account/thunk";
import Moment from "react-moment";
import DefaultUncontrolledTooltip from "./DefaultUncontrolledTooltip";
import i18n from "i18n";
import moment from "moment";

interface DisplayDateProps {
  id: string;
  value?: Date;
  format?: string;
  tz?: string;
  className?: string;
  showDateAndTimeSeparate?: boolean;
}

const DisplayDate = (props: DisplayDateProps) => {
  const {userProfile} = useProfile();
  const dispatch = useDispatch();

  const getDateString = () => {
    if (userProfile?.dateStyle === DateStyle.SHORT) {
      return new Date(props.value || new Date()).toLocaleDateString(i18n.language, {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      });
    } else {
      return moment(props.value)
        .locale(i18n.language)
        .fromNow();
    }
  };

  const handleUpdateUIPreferences = useCallback(() => {
    if (userProfile) {
      if (userProfile.dateStyle === DateStyle.SHORT) {
        updateUIPreferences({dateStyle: DateStyle.LONG})(dispatch);
      } else {
        updateUIPreferences({dateStyle: DateStyle.SHORT})(dispatch);
      }
    }
  }, [userProfile]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <span className="user-select-none" onClick={handleUpdateUIPreferences} role="button">
      {userProfile?.dateStyle === DateStyle.SHORT ? (
        <>
          <div id={`SHORT-${props.id}`} style={{width: "fit-content"}}>
            <Moment className={props.className} locale={i18n.language} fromNow>
              {props.value}
            </Moment>
          </div>
          {props.id && <DefaultUncontrolledTooltip target={`SHORT-${props.id}`}>{getDateString()}</DefaultUncontrolledTooltip>}
        </>
      ) : props.showDateAndTimeSeparate ? (
        <div>
          <span id={`LONG-Separate-${props.id}`}>
            <h5 className="fs-14 my-1 text-nowrap">
              <Moment format="D MMM YYYY" locale={i18n.language} tz={userProfile?.timezone}>
                {props.value}
              </Moment>
            </h5>
            <span className="text-muted text-nowrap">
              <Moment format="HH:mm" locale={i18n.language} tz={userProfile?.timezone}>
                {props.value}
              </Moment>
            </span>
          </span>
          <DefaultUncontrolledTooltip target={`LONG-Separate-${props.id}`}>{getDateString()}</DefaultUncontrolledTooltip>
        </div>
      ) : (
        <div>
          <span id={`LONG-${props.id}`}>
            <Moment className={props.className} locale={i18n.language} format={props.format}>
              {props.value}
            </Moment>
          </span>
          <DefaultUncontrolledTooltip target={`LONG-${props.id}`}>{getDateString()}</DefaultUncontrolledTooltip>
        </div>
      )}
    </span>
  );
};

export default DisplayDate;
