import AmazonMarketplaceInfos, {AmazonMarketplaceInfosType} from "Components/Common/AmazonMarketplaceInfos";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import DataTable, {DataTableRef} from "Components/Common/DataTable";
import Select, {OptionProps, StylesConfig, components} from "react-select";
import {useProfile} from "Components/Hooks/UserHooks";
import {ColumnDef} from "@tanstack/react-table";
import {PagerQuery} from "helpers/types";
import {ShipmentOrder} from "models/user_shipment";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Button, ButtonGroup, Card, CardBody, CardHeader, Col, Input, Row} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {useImmer} from "use-immer";
import {getRelativeDate} from "helpers/utilities";
import {SelectOptionsType} from "Components/Hooks/SelectOptions";
import {Link} from "react-router-dom";
import {multiValueContainer, Option} from "Components/Common/Select/SelectStyle";
import errorImage from "assets/images/svg/product.svg";
import DomainToFlag from "Components/Common/DomainToFlag";
import CopyAsinWidget from "Components/Common/CopyAsinWidget";
import DisplayPrice from "Components/Common/DisplayPrice";
import RenderNumber from "Components/Common/RenderNumber";
import NoData from "Components/Common/NoData";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import DisplayDate from "Components/Common/DisplayDate";

type OrderTimeFilter = "allTime" | "last7Days" | "last1Month";
const Orders = () => {
  const {t} = useTranslation();
  const {userProfile} = useProfile();
  const tableRef = useRef<DataTableRef>(null);
  const [amazonMarketplaceInfos] = useState(AmazonMarketplaceInfos());
  const [tableFilteredData, setTableFilteredData] = useState<ShipmentOrder[]>([]);
  const [tableOriginalData, setTableOriginalData] = useState<ShipmentOrder[]>([]);
  const [dataLength, setDataLength] = useState(0);
  const [asinFilterOptions, setAsinFilterOptions] = useState<SelectOptionsType[]>();
  const [selectedTimeFilter, setSelectedTimeFilter] = useState<OrderTimeFilter>("allTime");
  const [selectedAsinFilterOptions, setSelectedAsinFilterOptions] = useState<SelectOptionsType[]>([]);
  const [destinationMarketplace, setDestinationMarketplace] = useState<AmazonMarketplaceInfosType>();
  const shipmentsData = createSelector(
    (state: RootState) => state,
    (state) => ({
      currentUserShipment: state.Shipment.currentUserShipment,
      currentUserShipmentOrders: state.Shipment.currentUserShipmentOrders,
    }),
  );
  const {currentUserShipment, currentUserShipmentOrders} = useSelector(shipmentsData);

  const [query, updateQuery] = useImmer<PagerQuery>({
    page: 1,
    pageSize: 10,
  });

  useEffect(() => {
    if (currentUserShipmentOrders) {
      let destination = amazonMarketplaceInfos.find((amazonMarketplaceInfo: any) => amazonMarketplaceInfo.marketplace === currentUserShipment.userStore.marketplace);
      setDestinationMarketplace(destination);
      setTableOriginalData(currentUserShipmentOrders);

      const start = (query.page - 1) * query.pageSize;
      const end = start + query.pageSize;

      setTableFilteredData(currentUserShipmentOrders.slice(start, end));

      const asins = currentUserShipmentOrders.map((order) => order.asin);
      const uniqueAsins = asins.filter((asin, index) => asins.indexOf(asin) === index);

      setAsinFilterOptions(uniqueAsins.map((asin) => ({value: asin, label: asin})));
      // handle data
    }
  }, [currentUserShipmentOrders]); // eslint-disable-line

  const columns = useMemo<ColumnDef<ShipmentOrder, any>[]>(
    () => [
      {
        header: t("Shipments.ShippedOrCompleted.Orders.TableColumn.OrderId"),
        size: 80,
        accessorKey: "orderId",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row: ShipmentOrder = cellProps.row.original;
          return (
            <>
              <div className="mb-1 d-flex">
                <Link className="link-secondary link-offset-2 text-decoration-underline me-1 text-nowrap" target="_blank" to={`/orders/${row.amazonOrderId}`}>
                  {row.amazonOrderId}
                  <i className="ri-arrow-right-up-line"></i>
                </Link>
              </div>
              <div className="d-flex align-items-center text-nowrap text-muted">
                <DomainToFlag marketplace={row.marketplace} />
                <span>{`${row.marketplace} - ${row.storeName}`}</span>
              </div>
            </>
          );
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.Orders.TableColumn.Product"),
        size: 80,
        accessorKey: "image",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row: ShipmentOrder = cellProps.row.original;
          return (
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center avatar-sm rounded flex-shrink-0 overflow-hidden">
                <img
                  className="rounded w-100"
                  src={`https://m.media-amazon.com/images/I/${row.image}`}
                  onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                    e.currentTarget.onerror = null;
                    e.currentTarget.src = errorImage;
                  }}
                  alt=""
                />
              </div>
              <div className="flex-grow-1 ms-2">
                <div className="d-flex align-items-center">
                  <CopyAsinWidget Asin={row.asin} index={row.asin} asLink={true} marketplace={row.marketplace} hideCopyIcon={true} />
                </div>
                <div className="d-flex align-items-center">{row.sku}</div>
              </div>
            </div>
          );
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.Orders.TableColumn.Quantity"),
        size: 50,
        accessorKey: "quantity",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row: ShipmentOrder = cellProps.row.original;
          return (
            <>
              <h5 className="fs-14 my-1 align-items-center d-flex">
                {row.quantity} <i className="ri-bar-chart-fill text-success fs-16 ms-1"></i>
              </h5>
              <span className="text-muted text-nowrap"></span>
            </>
          );
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.Orders.TableColumn.Date"),
        size: 100,
        accessorFn: (item) => item.assignmentDate,
        sortingFn: "datetime",
        cell: (cellProps) => {
          const row: ShipmentOrder = cellProps.row.original;
          return <DisplayDate id={`DisplayDateShipmentOrderAssignmentDate-${row.orderId}`} value={row.assignmentDate} format="D MMM YYYY HH:mm" tz={userProfile?.timezone}   className="text-nowrap" />;
        },
      },

      {
        header: t("Shipments.ShippedOrCompleted.Orders.TableColumn.ExpectedSellPriceAndActualSellPrice"),
        size: 170,
        accessorKey: "expectedSellPrice",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row: ShipmentOrder = cellProps.row.original;
          return (
            <>
              <span className="text-muted">
                <DisplayPrice source={destinationMarketplace?.currency} value={row.expectedSellPrice} decimals={2} notation="decimal"/>
              </span>
              <h5 className="fs-14 my-1">
                <DisplayPrice source={destinationMarketplace?.currency} value={row.actualSellPrice} decimals={2} notation="decimal"/>
              </h5>
            </>
          );
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.Orders.TableColumn.ActualSellPrice"),
        size: 100,
        accessorKey: "actualSellPrice",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row: ShipmentOrder = cellProps.row.original;
          const successRate = (row.actualProfit / row.expectedProfit) * 100;
          let style = "";
          if (successRate >= 50 && successRate <= 70) {
            style = "warning";
          } else if (successRate > 70) {
            style = "success";
          } else {
            style = "danger";
          }
          return (
            <div className={`fw-medium text-${style}`}>
              <DisplayPrice source={destinationMarketplace?.currency} value={row.actualSellPrice} decimals={2} notation="decimal"/>
            </div>
          );
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.Orders.TableColumn.ExpectedProfitAndActualProfit"),
        size: 150,
        accessorKey: "expectedProfit",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row: ShipmentOrder = cellProps.row.original;
          return (
            <>
              <span className={`text-muted`}>
                <DisplayPrice source={destinationMarketplace?.currency} value={row.expectedProfit} decimals={2} notation="decimal"/>
              </span>
              <h5 className="fs-14 my-1">
                <div className="d-flex align-items-center">
                  <DisplayPrice source={destinationMarketplace?.currency} value={row.actualProfit} decimals={2} notation="decimal"/>
                  {row.costIsPredicted && (
                    <>
                      <i className="ri-question-fill text-warning fs-14 ms-1" id={`CostIsPredictedTooltip-${row.amazonOrderId}`} />
                      <DefaultUncontrolledTooltip target={`CostIsPredictedTooltip-${row.amazonOrderId}`}>{t("CostIsPredicted")}</DefaultUncontrolledTooltip>
                    </>
                  )}
                </div>
              </h5>
            </>
          );
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.Orders.TableColumn.ExpectedRoiMarginAndActualRoiMargin"),
        size: 170,
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row: ShipmentOrder = cellProps.row.original;
          return (
            <div className="text-nowrap">
              <span className={`text-muted d-flex flex-nowrap`}>
                <RenderNumber value={row.expectedROI} decimals={0} prefix={`%`} suffix="" />
                <i className="mdi mdi-slash-forward"></i>
                <RenderNumber value={row.expectedMargin} decimals={0} prefix={`%`} suffix="" />
              </span>
              <h5 className="fs-14 my-1 d-flex flex-nowrap">
                <RenderNumber value={row.actualROI} decimals={0} prefix={`%`} suffix="" />
                <i className="mdi mdi-slash-forward"></i>
                <RenderNumber value={row.actualMargin} decimals={0} prefix={`%`} suffix="" />
              </h5>
            </div>
          );
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.Orders.TableColumn.Cost"),
        size: 100,
        accessorKey: "cost",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row: ShipmentOrder = cellProps.row.original;
          return (
            <span className="fw-medium">
              <DisplayPrice source={destinationMarketplace?.currency} value={row.cost} decimals={2} notation="decimal"/>
            </span>
          );
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.Orders.TableColumn.FeesAndTax"),
        size: 100,
        accessorKey: "fees",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row: ShipmentOrder = cellProps.row.original;
          return (
            <>
              <span className={`text-muted`}>
                <DisplayPrice source={destinationMarketplace?.currency} value={row.fees} decimals={2} notation="decimal"/>
              </span>
              <h5 className="fs-14 my-1">
                <DisplayPrice source={destinationMarketplace?.currency} value={row.tax} decimals={2} notation="decimal"/>
              </h5>
            </>
          );
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.Orders.TableColumn.SuccessRate"),
        size: 150,

        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row: ShipmentOrder = cellProps.row.original;
          const successRate = (row.actualProfit / row.expectedProfit) * 100;
          let style = "";
          if (successRate >= 50 && successRate <= 70) {
            style = "warning";
          } else if (successRate > 70) {
            style = "success";
          } else {
            style = "danger";
          }
          return (
            <div className={style}>
              <h5 className="my-1 text-nowrap align-items-center d-flex">
                <i className={`text-${style} mdi mdi-trophy me-1 fs-16`}></i>
                <RenderNumber value={successRate} decimals={2} prefix="%" suffix=" " className={`text-${style}`} />
              </h5>
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, tableFilteredData, destinationMarketplace],
  );

  const handlePaginationChange = useCallback(() => {
    const start = (query.page - 1) * query.pageSize;
    const end = start + query.pageSize;
    setTableFilteredData(tableOriginalData.slice(start, end));
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFilterChange = useCallback(() => {
    const filterByAsin = tableOriginalData.filter((item) => {
      if (selectedAsinFilterOptions.length > 0) {
        return selectedAsinFilterOptions.find((f) => f.value === item.asin);
      } else return true;
    });

    const last7Days = getRelativeDate(7);
    const last1Month = getRelativeDate(30);

    const filterByDate = filterByAsin.filter((item) => {
      switch (selectedTimeFilter) {
        case "last7Days":
          return new Date(item.assignmentDate) >= last7Days;
        case "last1Month":
          return new Date(item.assignmentDate) >= last1Month;
        case "allTime":
          return true;
        default:
          return true;
      }
    });

    setDataLength(filterByDate.length);
    const start = 0;
    const end = 0 + query.pageSize;
    setTableFilteredData(filterByDate.slice(start, end));
  }, [tableOriginalData, selectedTimeFilter, selectedAsinFilterOptions, query]);

  useEffect(() => {
    handleFilterChange();
  }, [handleFilterChange]);

  useEffect(() => {
    handlePaginationChange();
  }, [handlePaginationChange]);

  const [selectStyle, setSelectStyle] = useState<any>();
  useMemo(() => {
    const customStyles: StylesConfig<Option, boolean> = {
      valueContainer: (provided: any) => ({
        ...provided,
        textOverflow: "ellipsis",
        maxWidth: "90%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        display: selectedAsinFilterOptions && selectedAsinFilterOptions.length > 0 ? "unset" : "inline-grid",
      }),
    };
    setSelectStyle(customStyles);
  }, [selectedAsinFilterOptions]);

  const renderOptionContainer = useCallback(
    (props: OptionProps<SelectOptionsType, boolean>) => {
      const itemAsin = props.data.value;
      const item = currentUserShipment.userShipmentItems.find((item) => item.userInventory.asin === itemAsin);
      const imagePath = `https://m.media-amazon.com/images/I/${item?.userInventory.imageSmall}`;

      return (
        <div>
          <components.Option {...props}>
            <Row className="d-flex align-items-center">
              <Col xs="auto">
                <Input className="me-2" type={"checkbox"} checked={props.isSelected} onChange={() => null} />
              </Col>
              <Col xs="auto" className="px-0">
                <div className="avatar-sm rounded flex-shrink-0 overflow-hidden">
                  <img
                    className="rounded w-100"
                    src={imagePath}
                    onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                      e.currentTarget.onerror = null;
                      e.currentTarget.src = errorImage;
                    }}
                    alt=""
                  />
                </div>
              </Col>
              <Col>
                <Row className="d-flex flex-column">
                  <Col>
                    <p className="ellipsis-two-lines mb-0" title={item?.userInventory.productName}>
                      {item?.userInventory.productName}
                    </p>
                  </Col>
                  <Col>
                    <span className="fw-bold">{props.label}</span>
                  </Col>
                  <Col>
                    <span className="text-decoration-underline">{item?.userInventory.sku}</span>
                  </Col>
                </Row>
              </Col>
            </Row>
          </components.Option>
        </div>
      );
    },
    [currentUserShipment],
  );

  return (
    <Card>
      <CardHeader>
        <Row className="align-items-center">
          <Col xs={12} md="auto" className="d-flex justify-content-lg-center align-items-lg-center mb-2 mb-md-0">
            <h5 className="mb-0 border-0 align-items-center d-flex">
              <i className="bx bx-basket me-1"></i>
              {t("Shipments.ShippedOrCompleted.Orders.Title")}
            </h5>
          </Col>
          <Col md></Col>
          <Col xs={12} md={3} className="d-flex justify-content-end mb-3 mb-md-0">
            <Select
              className="w-100"
              styles={selectStyle}
              components={{MultiValueContainer: multiValueContainer, Option: renderOptionContainer}}
              options={asinFilterOptions}
              onChange={(e: any) => setSelectedAsinFilterOptions(e)}
              isClearable
              isMulti
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
            />
          </Col>
          <Col xs={12} md="auto">
            <div className="d-flex justify-content-end gap-1">
              <ButtonGroup className="gap-2">
                <Button type="button" color="soft-primary" onClick={() => setSelectedTimeFilter("allTime")} active={selectedTimeFilter === "allTime"}>
                  {t("Shipments.ShippedOrCompleted.Orders.Button.AllTime")}
                </Button>
                <Button type="button" color="soft-primary" onClick={() => setSelectedTimeFilter("last7Days")} active={selectedTimeFilter === "last7Days"}>
                  {t("Shipments.ShippedOrCompleted.Orders.Button.Last7Days")}
                </Button>
                <Button type="button" color="soft-primary" onClick={() => setSelectedTimeFilter("last1Month")} active={selectedTimeFilter === "last1Month"}>
                  {t("Shipments.ShippedOrCompleted.Orders.Button.Last1Month")}
                </Button>
              </ButtonGroup>
            </div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        {currentUserShipmentOrders && currentUserShipmentOrders.length > 0 ? (
          <DataTable
            trClass="text-start"
            tdClass="text-start"
            ref={tableRef}
            busy={false}
            columns={columns}
            data={tableFilteredData}
            totalDataLength={dataLength}
            pagination={{
              pageIndex: query.page - 1,
              pageSize: query.pageSize,
            }}
            onPaginationChanged={(pagination) =>
              updateQuery((q) => {
                q.page = pagination.pageIndex + 1;
                q.pageSize = pagination.pageSize;
              })
            }
            hovered
          />
        ) : (
          <NoData icon="mdi mdi-note-search-outline" />
        )}
      </CardBody>
    </Card>
  );
};

export default Orders;
