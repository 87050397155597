import {AmazonMarketplaceInfosType} from "Components/Common/AmazonMarketplaceInfos";
import {Card, CardBody, CardHeader, Col, Row, Table} from "reactstrap";
import {useTranslation} from "react-i18next";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {useSelector} from "react-redux";
import {useCallback, useEffect, useState} from "react";
import {PriceHistoryChart} from "./Charts/PriceHistoryChart";
import {SummaryCard} from "pages/Searches/SearchResults/Item/Components/SummaryCard";
import {getToday} from "helpers/utilities";
import Currency from "Components/Common/Currency";
import RenderNumber from "Components/Common/RenderNumber";
import moment from "moment";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import NoData from "Components/Common/NoData";

interface ProfitAnalysisProps {
  sourceMarketplace?: AmazonMarketplaceInfosType;
  destinationMarketplace?: AmazonMarketplaceInfosType;
}
const ProfitAnalysis = ({sourceMarketplace, destinationMarketplace}: ProfitAnalysisProps) => {
  const {t} = useTranslation();
  const [priceChangeBadgeCss, setPriceChangeBadgeCss] = useState<string>("");
  const [priceChangeIcon, setPriceChangeIcon] = useState<string>("");
  const [changeRateBadgeCss, setChangeRateBadgeCss] = useState<string>("");
  const [filterDay, setFilterDay] = useState<"ALL" | "7DAY" | "30DAY" | "90DAY">("90DAY");
  const [priceHistorySeries, setPriceHistorySeries] = useState<any[]>([]);
  const [priceHistoryCategories, setPriceHistoryCategories] = useState<string[]>([]);
  const productDetailsData = createSelector(
    (state: RootState) => state,
    (state) => ({
      details: state.ProductDetails.details,
    }),
  );
  const {details} = useSelector(productDetailsData);

  const handleBadgeStylesAndIcons = useCallback(() => {
    if (details.profitAnalysis) {
      switch (details.profitAnalysis.priceAnalysisResult) {
        case "Stable":
          setPriceChangeBadgeCss("bg-success-subtle text-success");
          setPriceChangeIcon("bx bx-check");
          break;
        case "Recheck":
          setPriceChangeBadgeCss("bg-warning-subtle text-warning");
          setPriceChangeIcon("bx bx-error");
          break;
        case "Not Stable":
          setPriceChangeBadgeCss("bg-danger-subtle text-danger");
          setPriceChangeIcon("bx bx-x");
          break;
        default:
          setPriceChangeBadgeCss("bg-dark-subtle text-muted");
          setPriceChangeIcon("");
          break;
      }

      if (details.profitAnalysis.changeRate) {
        if (details.profitAnalysis.changeRate > 0 && details.profitAnalysis.changeRate <= 30) {
          setChangeRateBadgeCss("bg-success-subtle text-success");
        } else if (details.profitAnalysis.changeRate > 30 && details.profitAnalysis.changeRate <= 60) {
          setChangeRateBadgeCss("bg-warning-subtle text-warning");
        } else {
          setChangeRateBadgeCss("bg-danger-subtle text-danger");
        }
      } else {
        setChangeRateBadgeCss("bg-dark-subtle text-dark");
      }
    }
  }, [details]);

  const handleFilterChange = useCallback(() => {
    if (details.profitAnalysis) {
      if (details.profitAnalysis?.priceHistory) {
        var sortedList = details.profitAnalysis.priceHistory && details.profitAnalysis.priceHistory.length > 0 ? [...details.profitAnalysis.priceHistory] : [];
        sortedList.sort((a, b) => {
          if (a.date && b.date) {
            if (a.date < b.date) return -1;
            if (a.date > b.date) return 1;
          }
          return 0;
        });

        if (filterDay === "ALL") {
        } else if (filterDay === "7DAY") {
          sortedList = sortedList.filter((item) => moment(item.date).isAfter(getToday().subtract(7, "days")));
        } else if (filterDay === "30DAY") {
          sortedList = sortedList.filter((item) => moment(item.date).isAfter(getToday().subtract(30, "days")));
        } else if (filterDay === "90DAY") {
          sortedList = sortedList.filter((item) => moment(item.date).isAfter(getToday().subtract(90, "days")));
        }

        let series: any[] = sortedList.map((item) => item.price);
        let categories: any[] = sortedList.map((item) => item.date);
        setPriceHistorySeries(series);
        setPriceHistoryCategories(categories);
      }
    }
  }, [details, filterDay]);

  useEffect(() => {
    handleBadgeStylesAndIcons();
  }, [handleBadgeStylesAndIcons]);

  useEffect(() => {
    handleFilterChange();
  }, [handleFilterChange]);

  return (
      <Card className="card-animate">
        <CardBody>
          <h5 className="fs-15">{t("ProductDetails.ProfitAnalysis.Title")}</h5>
          <Row className="mt-3">
            <Col xl={3} lg={5}>
              <div className="d-flex flex-column">
                <SummaryCard
                  icon="mdi mdi-cash-fast"
                  label={t("ProductDetails.ProfitAnalysis.Label.PriceChange")}
                  cardClassName="mb-3"
                  content={
                    <span className={`fs-14 badge ${priceChangeBadgeCss}`}>
                      {t(details?.profitAnalysis?.priceAnalysisResult ?? "")}
                      <i className={`ms-1 ${priceChangeIcon}`}></i>
                    </span>
                  }
                />

                <SummaryCard
                  icon="ri-exchange-dollar-line"
                  label={t("ProductDetails.ProfitAnalysis.Label.ChangeRate")}
                  cardClassName="mb-3"
                  content={
                    <span className={`fs-14 badge ${changeRateBadgeCss}`}>
                      <RenderNumber value={details?.profitAnalysis?.changeRate} decimals={2} suffix=" %" renderOnEmpty={<span className="text-muted">{t("NA")}</span>} />
                    </span>
                  }
                />
              </div>
            </Col>
            <Col xl={9} lg={7}>
              <div className="w-100 h-100">
                <div className="table-responsive mb-2 shadow">
                  <Table className="align-middle table-hover table-nowrap table-borderless mb-0 h-100">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col">
                          {t("ProductDetails.ProfitAnalysis.TableColumn.Scenarios")}
                          <i className="mdi mdi-information ms-1 align-middle text-secondary" id="Scenarios"></i>
                          <DefaultUncontrolledTooltip target={`Scenarios`}>{t("ProductDetails.ProfitAnalysis.Label.TableDescription")}</DefaultUncontrolledTooltip>
                        </th>
                        <th scope="col">{t("ProductDetails.ProfitAnalysis.TableColumn.Price")}</th>
                        <th scope="col">{t("ProductDetails.ProfitAnalysis.TableColumn.Profit")}</th>
                        <th scope="col">{t("ProductDetails.ProfitAnalysis.TableColumn.ROI")}</th>
                        <th scope="col">{t("ProductDetails.ProfitAnalysis.TableColumn.Margin")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <span className="d-flex align-items-center text-danger">
                            <i className="mdi mdi-trending-down align-middle me-1"></i>
                            {t("ProductDetails.ProfitAnalysis.Label.MinPrice")}
                          </span>
                        </td>
                        <td className="fw-medium">
                          {details?.profitAnalysis?.minPrice ? (
                            <Currency source={sourceMarketplace?.currency || "USD"} target={destinationMarketplace?.currency} value={details?.profitAnalysis?.minPrice} decimals={2} />
                          ) : (
                            <span className="text-muted">{t("NA")}</span>
                          )}
                        </td>
                        <td>
                          {details?.profitAnalysis?.minPriceProfit ? (
                            <Currency source={sourceMarketplace?.currency || "USD"} target={destinationMarketplace?.currency} value={details?.profitAnalysis?.minPriceProfit} decimals={2} />
                          ) : (
                            <span className="text-muted">{t("NA")}</span>
                          )}
                        </td>
                        <td>
                          <RenderNumber value={details?.profitAnalysis?.minPriceROI} decimals={2} suffix="%" renderOnEmpty={<span className="text-muted">{t("NA")}</span>} />
                        </td>
                        <td>
                          <RenderNumber value={details?.profitAnalysis?.minPriceMargin} decimals={2} suffix="%" renderOnEmpty={<span className="text-muted">{t("NA")}</span>} />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="d-flex align-items-center text-success">
                            <i className="mdi mdi-trending-up align-middle me-1"></i> {t("ProductDetails.ProfitAnalysis.Label.MaxPrice")}
                          </span>
                        </td>
                        <td className="fw-medium">
                          {details?.profitAnalysis?.maxPrice ? (
                            <Currency source={sourceMarketplace?.currency || "USD"} target={destinationMarketplace?.currency} value={details?.profitAnalysis?.maxPrice} decimals={2} />
                          ) : (
                            <span className="text-muted">{t("NA")}</span>
                          )}
                        </td>
                        <td>
                          {details?.profitAnalysis?.maxPriceProfit ? (
                            <Currency source={sourceMarketplace?.currency || "USD"} target={destinationMarketplace?.currency} value={details?.profitAnalysis?.maxPriceProfit} decimals={2} />
                          ) : (
                            <span className="text-muted">{t("NA")}</span>
                          )}
                        </td>
                        <td>
                          <RenderNumber value={details?.profitAnalysis?.maxPriceROI} decimals={2} suffix="%" renderOnEmpty={<span className="text-muted">{t("NA")}</span>} />
                        </td>
                        <td>
                          <RenderNumber value={details?.profitAnalysis?.maxPriceMargin} decimals={2} suffix="%" renderOnEmpty={<span className="text-muted">{t("NA")}</span>} />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="d-flex align-items-center text-primary">
                            <i className="mdi mdi-swap-vertical align-middle me-1"></i>
                            {t("ProductDetails.ProfitAnalysis.Label.AveragePrice")}
                          </span>
                        </td>
                        <td className="fw-medium">
                          {details?.profitAnalysis?.averagePrice ? (
                            <Currency source={sourceMarketplace?.currency || "USD"} target={destinationMarketplace?.currency} value={details?.profitAnalysis?.averagePrice} decimals={2} />
                          ) : (
                            <span className="text-muted">{t("NA")}</span>
                          )}
                        </td>
                        <td>
                          {details?.profitAnalysis.averagePriceProfit ? (
                            <Currency source={sourceMarketplace?.currency || "USD"} target={destinationMarketplace?.currency} value={details?.profitAnalysis.averagePriceProfit} decimals={2} />
                          ) : (
                            <span className="text-muted">{t("NA")}</span>
                          )}{" "}
                        </td>
                        <td>
                          <RenderNumber value={details?.profitAnalysis?.averagePriceROI} decimals={2} suffix="%" renderOnEmpty={<span className="text-muted">{t("NA")}</span>} />
                        </td>
                        <td>
                          <RenderNumber value={details?.profitAnalysis?.averagePriceMargin} decimals={2} suffix="%" renderOnEmpty={<span className="text-muted">{t("NA")}</span>} />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="d-flex align-items-center text-secondary">
                            <i className="mdi mdi-currency-usd align-middle me-1"></i>
                            {t("ProductDetails.ProfitAnalysis.Label.CurrentPrice")}
                          </span>
                        </td>
                        <td className="fw-medium">
                          {details?.profitAnalysis?.currentPrice ? (
                            <Currency source={sourceMarketplace?.currency || "USD"} target={destinationMarketplace?.currency} value={details?.profitAnalysis?.currentPrice} decimals={2} />
                          ) : (
                            <span className="text-muted">{t("NA")}</span>
                          )}
                        </td>
                        <td>
                          {details?.profitAnalysis?.currentPriceProfit ? (
                            <Currency source={sourceMarketplace?.currency || "USD"} target={destinationMarketplace?.currency} value={details?.profitAnalysis?.currentPriceProfit} decimals={2} />
                          ) : (
                            <span className="text-muted">{t("NA")}</span>
                          )}
                        </td>
                        <td>
                          <RenderNumber value={details?.profitAnalysis?.currentPriceROI} decimals={2} suffix="%" renderOnEmpty={<span className="text-muted">{t("NA")}</span>} />
                        </td>
                        <td>
                          <RenderNumber value={details?.profitAnalysis?.currentPriceMargin} decimals={2} suffix="%" renderOnEmpty={<span className="text-muted">{t("NA")}</span>} />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="mb-0">
                <CardHeader className="align-items-center d-flex">
                  <h5 className="fs-15 card-title mb-0 flex-grow-1">Price History Chart</h5>
                  <div className="flex-shrink-0">
                    <div className="d-flex justify-content-end gap-1">
                      <div role="group" className="gap-2 btn-group">
                        <button
                          type="button"
                          className={`link-body-emphasis link-underline-opacity-75-hover align-items-center d-flex nowrap p-0 btn btn-link ${filterDay === "ALL" && "text-decoration-underline "}`}
                          onClick={() => setFilterDay("ALL")}
                        >
                          {t("ProductDetails.ProfitAnalysis.Button.AllTime")}
                        </button>
                        <button
                          type="button"
                          className={`link-body-emphasis link-underline-opacity-75-hover align-items-center d-flex nowrap p-0 btn btn-link ${filterDay === "7DAY" && "text-decoration-underline "}`}
                          onClick={() => setFilterDay("7DAY")}
                        >
                          {t("ProductDetails.ProfitAnalysis.Button.7Days")}
                        </button>
                        <button
                          type="button"
                          className={`link-body-emphasis link-underline-opacity-75-hover align-items-center d-flex nowrap p-0 btn btn-link ${filterDay === "30DAY" && "text-decoration-underline "}`}
                          onClick={() => setFilterDay("30DAY")}
                        >
                          {t("ProductDetails.ProfitAnalysis.Button.30Days")}
                        </button>
                        <button
                          type="button"
                          className={`link-body-emphasis link-underline-opacity-75-hover align-items-center d-flex nowrap p-0 btn btn-link ${filterDay === "90DAY" && "text-decoration-underline "}`}
                          onClick={() => setFilterDay("90DAY")}
                        >
                          {t("ProductDetails.ProfitAnalysis.Button.90Days")}
                        </button>
                      </div>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  {priceHistorySeries.length > 0 ? (
                    <PriceHistoryChart dataColors='["--vz-warning"]' categories={priceHistoryCategories} series={priceHistorySeries} currency={details.productInfo.currencyCodeTarget} />
                  ) : (
                    <NoData icon="mdi mdi-note-search-outline" />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CardBody>
      </Card>
  );
};

export default ProfitAnalysis;
