import React, {useEffect, useMemo, useRef, useState} from "react";
import Dialog, {DialogRef} from "Components/Common/Dialog";
import BreadCrumb, {BreadcrumbMenuItem} from "Components/Common/BreadCrumb";
import DataTable, {DataTableRef} from "Components/Common/DataTable";
import SelectOptions, {SelectOptionsType} from "Components/Hooks/SelectOptions";
import {setCurrentCostAndFeeOption} from "slices/settings/reducer";
import {Link, useNavigate} from "react-router-dom";
import {Card, CardBody, CardHeader, CardTitle, Col, Container, Row} from "reactstrap";
import {useTranslation} from "react-i18next";
import {RootState} from "slices";
import {createSelector} from "reselect";
import {useDispatch, useSelector} from "react-redux";
import {deleteCostAndFeeSettings, getCostAndFeesList, setAsDefaultCostAndFees} from "slices/settings/thunk";
import {UserSettingCostAndFeeOptions} from "models/user_setting_cost_and_fee_options";
import {ColumnDef} from "@tanstack/react-table";
import {useProfile} from "Components/Hooks/UserHooks";
import {ConstantPage} from "helpers/permission_helper";
import SettingsLayout from "Components/Common/Layout/SettingsLayout";
import AmazonMarketplaceInfos from "Components/Common/AmazonMarketplaceInfos";
import Select from "react-select";
import CreateCostAndFeeOption from "./Modals/CreateCostAndFeeOption";
import DuplicateCostAndFeeOption from "./Modals/DuplicateCostAndFeeOption";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import DisplayDate from "Components/Common/DisplayDate";

const PAGE_IDENTIFIER: ConstantPage = "settings.costAndFees";
const CostAndFeesList = () => {
  const {t} = useTranslation();
  const {userProfile, hasPermission} = useProfile();
  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);
  const setAsDefaultDialogRef = useRef<DialogRef>(null);
  const deleteDialogRef = useRef<DialogRef>(null);
  const [amazonMarketplaceInfos] = useState(AmazonMarketplaceInfos());
  const {marketplaceSelectOptions} = SelectOptions();
  const [selectedMarketplace, setSelectedMarketplace] = useState<SelectOptionsType>();
  const dispatch: any = useDispatch();
  const navigate = useNavigate();

  const tableRef = useRef<DataTableRef>(null);

  useEffect(() => {
    if (hasPermission(PAGE_IDENTIFIER)) {
      const marketplace = selectedMarketplace?.value;
      dispatch(getCostAndFeesList(marketplace));
    }
  }, [dispatch, navigate, selectedMarketplace]); // eslint-disable-line react-hooks/exhaustive-deps

  const settingsPageData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Settings.loading,
      costAndFeesList: state.Settings.costAndFeesList,
      currentCostAndFeeOption: state.Settings.currentCostAndFeeOption,
    }),
  );
  const {loading, costAndFeesList, currentCostAndFeeOption} = useSelector(settingsPageData);

  const columns = useMemo<ColumnDef<UserSettingCostAndFeeOptions, any>[]>(
    () => [
      {
        header: t("Actions"),
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSettingCostAndFeeOptions;
          return (
            <div className="d-flex align-items-center">
              <Link
                id={`EditCostAndFee-${row.userSettingCostAndFeeOptionId}`}
                to={`/account/settings/cost-and-fees/edit/${row.userSettingCostAndFeeOptionId}`}
                className="btn btn-ghost-secondary px-1 py-0 fs-16"
              >
                <i className="ri-pencil-fill"></i>
              </Link>
              <DefaultUncontrolledTooltip target={`EditCostAndFee-${row.userSettingCostAndFeeOptionId}`}>{t("Settings.CostAndFees.Tooltip.Edit")}</DefaultUncontrolledTooltip>

              <Link
                id={`CopyCostAndFees-${row.userSettingCostAndFeeOptionId}`}
                to=""
                className="btn btn-ghost-secondary px-1 py-0 fs-16"
                onClick={() => {
                  dispatch(setCurrentCostAndFeeOption(row));
                  setIsDuplicateModalOpen(true);
                }}
              >
                <i className="ri-file-copy-fill"></i>
              </Link>
              <DefaultUncontrolledTooltip target={`CopyCostAndFees-${row.userSettingCostAndFeeOptionId}`}>{t("Settings.CostAndFees.Tooltip.Duplicate")}</DefaultUncontrolledTooltip>

              <Link
                id={`DeleteCostAndFees-${row.userSettingCostAndFeeOptionId}`}
                to=""
                className="btn btn-ghost-danger px-1 py-0 fs-16"
                onClick={() => {
                  dispatch(setCurrentCostAndFeeOption(row));
                  deleteDialogRef.current?.show();
                }}
              >
                <i className="ri-delete-bin-fill"></i>
              </Link>

              <DefaultUncontrolledTooltip target={`DeleteCostAndFees-${row.userSettingCostAndFeeOptionId}`}>{t("Settings.CostAndFees.Tooltip.Delete")}</DefaultUncontrolledTooltip>
              {!row.isDefault && (
                <>
                  <Link
                    id={`SetAsDefaultCostAndFees-${row.userSettingCostAndFeeOptionId}`}
                    to=""
                    className="btn btn-ghost-secondary px-1 py-0 fs-16"
                    onClick={() => {
                      dispatch(setCurrentCostAndFeeOption(row));
                      setAsDefaultDialogRef.current?.show();
                    }}
                  >
                    <i className="ri-checkbox-circle-fill"></i>
                  </Link>
                  <DefaultUncontrolledTooltip target={`SetAsDefaultCostAndFees-${row.userSettingCostAndFeeOptionId}`}>{t("Settings.CostAndFees.Tooltip.SetAsDefault")}</DefaultUncontrolledTooltip>
                </>
              )}
            </div>
          );
        },
      },
      {
        header: t("Settings.CostAndFees.TableColumn.Name"),
        size: 200,
        accessorKey: "name",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSettingCostAndFeeOptions;
          return (
            <span
              className="link-secondary link-offset-2 text-decoration-underline"
              onClick={() => navigate(`/account/settings/cost-and-fees/edit/${row.userSettingCostAndFeeOptionId}`)}
              role="button"
            >
              {row.name}
            </span>
          );
        },
      },
      {
        header: t("Settings.CostAndFees.TableColumn.Marketplace"),
        size: 200,
        accessorKey: "marketplace",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSettingCostAndFeeOptions;
          const marketplaceInfo = amazonMarketplaceInfos.find((marketplaceInfo) => marketplaceInfo.marketplace === row.marketplace && marketplaceInfo.active);
          return (
            <>
              {marketplaceInfo && (
                <span className="hstack gap-2">
                  <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                    <img src={marketplaceInfo.flag} alt="Country Flag" className="rounded-circle" />
                  </div>
                  {`${marketplaceInfo.countryName} (${marketplaceInfo.marketplace})`}
                </span>
              )}
            </>
          );
        },
      },
      {
        header: t("Settings.CostAndFees.TableColumn.IsDefault"),
        size: 200,
        accessorKey: "isDefault",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSettingCostAndFeeOptions;
          return <>{row.isDefault && <span className="fs-12 badge bg-success-subtle text-success">{t("Default")}</span>}</>;
        },
      },
      {
        header: t("Settings.CostAndFees.TableColumn.CreateDate"),
        size: 150,
        accessorKey: "createDate",
        sortingFn: "datetime",
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSettingCostAndFeeOptions;
          return (
            <>
              <DisplayDate id={`DisplayDateCreateDate-${row.userSettingCostAndFeeOptionId}`} value={row.createDate} format="D MMM YYYY HH:mm" tz={userProfile?.timezone} />
            </>
          );
        },
      },
    ],
    [t, amazonMarketplaceInfos, dispatch, navigate, userProfile],
  );

  const breadcrumbMenus: BreadcrumbMenuItem[] = [
    {
      label: t("Settings.Title"),
      url: "#",
    },
    {
      label: t("Settings.CostAndFees.Title"),
      url: "",
    },
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Settings.Title")} menus={breadcrumbMenus} />
          <SettingsLayout hasAnyChanges={false} navigateTo="">
            <>
              <Card>
                <CardHeader>
                  <CardTitle className="fw-medium align-items-center d-flex">
                    <i className="mdi mdi-currency-jpy fs-18 me-1"></i>
                    {t("Settings.CostAndFees.Title")}
                  </CardTitle>
                  <Row className="mt-3">
                    <Col xs={9} md={4}>
                      <Select
                        placeholder={t("Settings.CostAndFees.SelectMarketplacePlaceholder")}
                        isClearable={true}
                        options={marketplaceSelectOptions}
                        name="marketplaceFilter"
                        id="marketplaceFilter"
                        onChange={(e: any) => setSelectedMarketplace(e)}
                        value={selectedMarketplace}
                      />
                    </Col>
                    <Col></Col>
                    <Col xs={2} md="auto" className="d-flex justify-content-end">
                      <CreateCostAndFeeOption marketplaceOptions={marketplaceSelectOptions} />
                      <DuplicateCostAndFeeOption show={isDuplicateModalOpen} toggle={() => setIsDuplicateModalOpen(!isDuplicateModalOpen)} />
                    </Col>
                  </Row>
                </CardHeader>
              </Card>
              <Card>
                <CardBody>
                  <DataTable ref={tableRef} columns={columns} data={costAndFeesList || []} totalDataLength={costAndFeesList.length} busy={loading.list} hovered />
                </CardBody>
              </Card>
            </>
          </SettingsLayout>
        </Container>
      </div>
      <Dialog
        ref={setAsDefaultDialogRef}
        color="success"
        buttons={["yes", "no"]}
        busy={loading.update}
        iconClass="ri-checkbox-circle-fill"
        message={t("Settings.CostAndFees.Dialog.SetAsDefault.Description")}
        title={t("Settings.CostAndFees.Dialog.SetAsDefault.Title", {marketplace: currentCostAndFeeOption.marketplace})}
        onButtonClick={async (button) => {
          if (button === "yes") {
            const current = currentCostAndFeeOption;
            await dispatch(setAsDefaultCostAndFees(current.userSettingCostAndFeeOptionId, current.marketplace));
          }
          setAsDefaultDialogRef.current?.hide();
        }}
      />
      <Dialog
        ref={deleteDialogRef}
        color="danger"
        buttons={["yes", "no"]}
        busy={loading.delete}
        iconClass="ri-delete-bin-line"
        message={t("Settings.CostAndFees.Dialog.Delete.Description")}
        title={t("Settings.CostAndFees.Dialog.Delete.Title")}
        onButtonClick={async (button) => {
          if (button === "yes") {
            await dispatch(deleteCostAndFeeSettings(currentCostAndFeeOption.userSettingCostAndFeeOptionId));
          }
          deleteDialogRef.current?.hide();
        }}
      />
    </React.Fragment>
  );
};

export default CostAndFeesList;
