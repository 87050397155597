import { SelectOptionsType } from "Components/Hooks/SelectOptions";
import {components, CSSObjectWithLabel, StylesConfig} from "react-select";
import {Label} from "reactstrap";

export interface Option {
  value: string | number | object;
  label: string;
  color?: string;
  isFixed?: boolean;
  disabled?: boolean;
}

export const singleSelectStyles: StylesConfig<SelectOptionsType, false> = {
  control: (base: CSSObjectWithLabel, state) => ({
    ...base,
    border: "var(--vz-border-width) var(--vz-border-style) var(--vz-border-color) !important",
    background: "var(--vz-input-bg-custom)",
  }),
  option: (base: CSSObjectWithLabel, state) => ({
    ...base,
    background: state.isSelected ? "var(--vz-tertiary-bg)" : state.isFocused ? "var(--vz-btn-hover-bg)" : "var(--vz-input-bg-custom)",
    color: "var(--vz-body-color)",
    '&:hover': {
      background: 'var(--vz-dark-bg-subtle)', 
      transition: 'all 0.5s ease',
    },
  }),
  menuList: (base: CSSObjectWithLabel) => ({
    ...base,
    background: "var(--vz-input-bg-custom)",
  }),
  menuPortal: base => ({...base, zIndex: 9999}),
};
export const optionContainer = (props: any) => {
  return (
    <div>
      <components.Option {...props}>
        <div className="d-flex">
          <Label htmlFor={"name"} check>
            <span className="ellipsis-single-line" title={props.label}>
              {props.label}
            </span>
          </Label>
        </div>
      </components.Option>
    </div>
  );
};
export const inputContainer = (props: any) => {
  return (
    <div>
      <components.Input {...props} className="d-none">
      </components.Input>
    </div>
  );
};

export const multiValueContainer = (props: any) => {
  const {selectProps, data} = props;
  const label = data.label;
  const allSelected = selectProps.value as Option[];
  const index = allSelected.findIndex((selected) => selected.label === label);
  const isLastSelected = index === allSelected.length - 1;
  const labelSuffix = isLastSelected ? ` (${allSelected.length})` : ", ";
  return `${label}${labelSuffix}`;
};
