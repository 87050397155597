import {useEffect, useState} from "react";
import {useFormik} from "formik";
import {Button, Card, CardBody, Col, Form, Label, Row, Spinner} from "reactstrap";
import useSelectOptions, {SelectOptionsType} from "Components/Hooks/SelectOptions";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {setSelectAllUserInventories} from "slices/inventory/reducer";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {UserStore} from "models/user_stores";
import {FilterInventoryQuery} from "api/query";
import {Link} from "react-router-dom";
import AmazonMarketplaceInfos from "Components/Common/AmazonMarketplaceInfos";
import ValidatedInput from "Components/Common/ValidatedInput";
import ValidationWrapper from "Components/Common/ValidationWrapper";
import Checkbox from "Components/Common/Checkbox";
import PlaceholderGlow from "Components/Common/PlaceholderGlow";
import ValidatedMultiSelect from "Components/Common/ValidatedMultiSelect";
import * as Yup from "yup";

interface FiltersProps {
  busy: boolean;
  page: number;
  pageSize: number;
  handleFilter: (filter: FilterInventoryQuery) => void;
}
const Filters = (props: FiltersProps) => {
  const dispatch: any = useDispatch();
  const {multiSelectTranslations} = useSelectOptions();
  const {t} = useTranslation();
  const [storeSelectOptions, setStoreSelectOptions] = useState<SelectOptionsType[]>([]);
  const amazonMarketplaceInfos = AmazonMarketplaceInfos();

  const reduxData = createSelector(
    (state: RootState) => state,
    (state) => ({
      commonUserStore: state.Common.userStores,
    }),
  );

  const {commonUserStore} = useSelector(reduxData);

  useEffect(() => {
    prepareOptions();
  }, [commonUserStore]); //eslint-disable-line

  const prepareOptions = async () => {
    const stores = commonUserStore.filter((store) => store.marketplace !== "US" && store.status);
    const storeOptions = stores.map((store: UserStore) => {
      return {
        value: store.userStoreId,
        label: amazonMarketplaceInfos.find((amazonMarketplaceInfo: any) => amazonMarketplaceInfo.marketplace === store.marketplace)?.countryName + " - " + store.name,
      };
    });
    setStoreSelectOptions(storeOptions);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const asin = queryParams.get("asin");
    if (asin) {
      const payload: FilterInventoryQuery = {
        page: 1,
        pageSize: props.pageSize,
        search: asin,
        filtering: true,
      };
      props.handleFilter(payload);
      validation.setFieldValue("search", asin);
    }
  }, [dispatch]); //eslint-disable-line

  const validation = useFormik({
    initialValues: {
      search: "",
      stores: [] as string[],
    } as FilterInventoryQuery,
    validationSchema: Yup.object<FilterInventoryQuery>({
      search: Yup.string(),
      store: Yup.string().notRequired(),
      marginMin: Yup.number(),
      marginMax: Yup.number(),
      roiMin: Yup.number(),
      roiMax: Yup.number(),
      profitMin: Yup.number(),
      profitMax: Yup.number(),
      inStock: Yup.boolean(),
      showArchived: Yup.boolean(),
      productsWithoutCostInformation: Yup.boolean(),
      availableInMyStore: Yup.boolean(),
    }),
    onSubmit: (values: FilterInventoryQuery) => {
      dispatch(setSelectAllUserInventories(false));
      const payload: FilterInventoryQuery = {
        page: 1,
        pageSize: props.pageSize,
        stores: values.stores || undefined,
        search: values.search || undefined,
        marginMin: values.marginMin || undefined,
        marginMax: values.marginMax || undefined,
        roiMin: values.roiMin || undefined,
        roiMax: values.roiMax || undefined,
        profitMin: values.profitMin || undefined,
        profitMax: values.profitMax || undefined,
        inStock: values.inStock || undefined,
        showArchived: values.showArchived || undefined,
        productsWithoutCostInformation: values.productsWithoutCostInformation || undefined,
        availableInMyStore: values.availableInMyStore || undefined,
        filtering: true,
      };
      props.handleFilter(payload);
    },
  });

  return (
    <>
      <PlaceholderGlow busy={props.busy}>
        <Card>
          <CardBody>
            <div className="live-preview">
              <Form onSubmit={validation.handleSubmit}>
                <Row className="align-items-center">
                  <Col>
                    <div className="flex-shrink-0">
                      <Label className="form-label text-muted">{t("Inventory.Filters.Title")}</Label>
                    </div>
                  </Col>
                  <Col xs="auto" align="right">
                    <Link to="/inventory/updateInventory" className="btn btn-info">
                      <i className="mdi mdi-swap-vertical-bold align-bottom me-1"></i>
                      {t("Inventory.Dialog.UpdateInventory.Button")}
                    </Link>
                  </Col>
                </Row>
                <Row className="align-items-center mt-3">
                  <Row className="d-flex align-items-center  mb-3">
                    <Col sm={2} className="inventory filters-col">
                      <Label htmlFor="search">{t("Inventory.Filters.Search")}</Label>
                      <ValidatedInput validation={validation} field="search" maxLength={100} placeholder={t("Inventory.Filters.SearchPlaceholder")} disableValidationUI />
                    </Col>

                    <Col sm={2} className="inventory filters-col">
                      <Label htmlFor="stores">{t("Inventory.Filters.StoreFilter")}</Label>
                      <ValidatedMultiSelect
                        options={storeSelectOptions}
                        validation={validation}
                        field="stores"
                        value={validation.values.stores}
                        translations={{
                          ...multiSelectTranslations,
                          allItemsAreSelected: t("General.Select.AllStoresSelected"),
                          selectSomeItems: t("General.Select.SelectStore"),
                        }}
                      />
                    </Col>
                    <Col sm={2} className="inventory filters-col">
                      <Label htmlFor="marginMin">{t("Inventory.Filters.Margin")}</Label>
                      <Row className="d-flex align-items-center">
                        <Col>
                          <ValidatedInput validation={validation} type="number" field="marginMin" placeholder="Min" disableValidationUI={true} />
                        </Col>
                        <Col xs="auto" className="text-muted p-0">
                          {t("and")}
                        </Col>
                        <Col>
                          <ValidatedInput validation={validation} type="number" field="marginMax" placeholder="Max" disableValidationUI={true} />
                        </Col>
                      </Row>
                    </Col>

                    <Col sm={2} className="inventory filters-col">
                      <Label htmlFor="roi">{t("Inventory.Filters.ROI")}</Label>
                      <Row className="d-flex align-items-center">
                        <Col>
                          <ValidatedInput validation={validation} type="number" field="roiMin" placeholder="Min" disableValidationUI={true} />
                        </Col>
                        <Col xs="auto" className="text-muted p-0">
                          {t("and")}
                        </Col>
                        <Col>
                          <ValidatedInput validation={validation} type="number" field="roiMax" placeholder="Max" disableValidationUI={true} />
                        </Col>
                      </Row>
                    </Col>

                    <Col sm={2} className="inventory filters-col">
                      <Label htmlFor="profit">{t("Inventory.Filters.Profit")}</Label>
                      <Row className="d-flex align-items-center">
                        <Col>
                          <ValidatedInput validation={validation} type="number" field="profitMin" placeholder="Min" disableValidationUI={true} />
                        </Col>
                        <Col xs="auto" className="text-muted p-0">
                          {t("and")}
                        </Col>
                        <Col>
                          <ValidatedInput validation={validation} type="number" field="profitMax" placeholder="Max" disableValidationUI={true} />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col xs="auto">
                      <ValidationWrapper validation={validation} field="inStock" submitOnChange>
                        <Checkbox disableValidationUI>
                          <span>{t("Inventory.Filters.InStock")}</span>
                        </Checkbox>
                      </ValidationWrapper>
                    </Col>

                    <Col xs="auto">
                      <ValidationWrapper validation={validation} field="showArchived" submitOnChange>
                        <Checkbox disableValidationUI>
                          <span>{t("Inventory.Filters.ShowArchived")}</span>
                        </Checkbox>
                      </ValidationWrapper>
                    </Col>

                    <Col xs="auto">
                      <ValidationWrapper validation={validation} field="productsWithoutCostInformation" submitOnChange>
                        <Checkbox disableValidationUI>
                          <span>{t("Inventory.Filters.ProductsWithoutCostInformation")}</span>
                        </Checkbox>
                      </ValidationWrapper>
                    </Col>

                    <Col>
                      <ValidationWrapper validation={validation} field="availableInMyStore" submitOnChange>
                        <Checkbox disableValidationUI>
                          <span>{t("Inventory.Filters.AvailableInMyStore")}</span>
                        </Checkbox>
                      </ValidationWrapper>
                    </Col>

                    <Col xs="auto">
                      <Button type="submit" className="btn bg-primary me-0" disabled={props.busy}>
                        {props.busy ? <Spinner size="sm" className="me-2"></Spinner> : <i className="ri-equalizer-fill me-1 align-bottom"></i>}
                        {t("Inventory.Filters.Button.Apply")}
                      </Button>
                    </Col>
                  </Row>
                </Row>
              </Form>
            </div>
          </CardBody>
        </Card>
      </PlaceholderGlow>
    </>
  );
};

export default Filters;
