import AmazonMarketplaceInfos, {AmazonMarketplaceInfosType} from "Components/Common/AmazonMarketplaceInfos";
import BreadCrumb, {BreadcrumbMenuItem} from "Components/Common/BreadCrumb";
import {useCallback, useEffect, useState} from "react";
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {getProductDetails, resetSearchProductState, setProductDetailsPageUrl} from "slices/product-details/thunk";
import {useProfile} from "Components/Hooks/UserHooks";
import PlaceholderGlow from "Components/Common/PlaceholderGlow";
import ProductInfo from "./_ProductInfo";
import AnalysisSummary from "./_AnalysisSummary";
import ProfitCalculation from "./_ProfitCalculation";
import ProfitAnalysis from "./_ProfitAnalysis";
import CompetitorAnalysis from "./_CompetitorAnalysis";
import VariationAnalysis from "./_VariationAnalysis";
import SellersAndListingAnalysis from "./_SellersAndListingAnalysis";
import SalesAnalysis from "./_SalesAnalysis";
import SellThis from "Components/Common/SellThis";
import CheckList from "./Modals/CheckList";
import ReAnalyzeProduct from "Components/Common/Modals/ReAnalyzeProduct";
import IneligibilityReasonList from "./Modals/IneligibilityReasonList";
import NoResult from "Components/Common/NoResult";
import Cookies from "js-cookie";
import {COOKIE_STORAGE} from "helpers/cookie_helper";
import LimitExceeded from "./_LimitExceeded";

const MAX_SEARCH_LIMIT_FOR_ANONYM_USERS: number = 1000;

const ProductDetails = () => {
  const {t} = useTranslation();
  const {isUserLoggedIn} = useProfile();
  const [amazonMarketplaceInfos] = useState(AmazonMarketplaceInfos());
  const [sourceMarketplace, setSourceMarketplace] = useState<AmazonMarketplaceInfosType>();
  const [destinationMarketplace, setDestinationMarketplace] = useState<AmazonMarketplaceInfosType>();
  const [isLimitExceededForAnonymUsers, setIsLimitExceededForAnonymUsers] = useState<boolean>(false);
  const {asin, searchId} = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const productDetailsData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.ProductDetails.loading,
      details: state.ProductDetails.details,
      pageUrl: state.ProductDetails.pageUrl,
      error: state.ProductDetails.error,
    }),
  );
  const {loading, details, pageUrl, error} = useSelector(productDetailsData);

  const findPageUrl = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchId && asin) {
      setProductDetailsPageUrl(`/dp/${asin}/${searchId}`)(dispatch);
    } else if (asin && searchParams.has("marketplace")) {
      setProductDetailsPageUrl(`/dp/${asin}?marketplace=${searchParams.get("marketplace")}&asin=${asin}`)(dispatch);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    findPageUrl();
  }, [findPageUrl]);

  useEffect(() => {
    let source = amazonMarketplaceInfos.find((amazonMarketplaceInfo) => amazonMarketplaceInfo.isDefaultUs);
    let destination = amazonMarketplaceInfos.find((amazonMarketplaceInfo) => amazonMarketplaceInfo.marketplace === details.productInfo?.targetMarketplace);
    setSourceMarketplace(source);
    setDestinationMarketplace(destination);
  }, [details]); // eslint-disable-line

  const onUrlChange = useCallback(() => {
    if (!isUserLoggedIn()) {
      const currentLimit: number = Number(Cookies.get(COOKIE_STORAGE.DAILY_SINGLE_SEARCH)) || 0;
      if (currentLimit < MAX_SEARCH_LIMIT_FOR_ANONYM_USERS) {
        const newLimit = currentLimit + 1;
        Cookies.set(COOKIE_STORAGE.DAILY_SINGLE_SEARCH, newLimit.toString(), {expires: 1});
        setIsLimitExceededForAnonymUsers(false);
      } else {
        setIsLimitExceededForAnonymUsers(true);
        return;
      }
    }
    const searchParams = new URLSearchParams(location.search);
    const isUrlSame = location.pathname === pageUrl || location.pathname === pageUrl + "/";
    const isSellThisModalTriggered = location.pathname.includes("sell-this");
    const isReAnalyzeModalTriggered = location.pathname.includes("re-analyze-product");
    if (searchParams.has("singleSearch") || isUrlSame || isSellThisModalTriggered || isReAnalyzeModalTriggered) {
      return;
    }
    if (asin && searchId) {
      // For SearchResult
      getProductDetails({asin, userSearchId: searchId})(dispatch);
    } else if (asin && searchParams.has("marketplace")) {
      // For Single Search with marketplace
      const mpValue = searchParams.get("marketplace");
      if(mpValue!.length > 2) {
        getProductDetails({asin, userStoreId: mpValue!})(dispatch);
      }else {
        getProductDetails({asin, marketplaceTarget: searchParams.get("marketplace")!})(dispatch);
      }

    } else {
      navigate("/");
    }
  }, [asin, searchId]); // eslint-disable-line

  const onErrorChange = useCallback(
    () => {
      if(error?.message === "LIMIT_ERROR") {
        setIsLimitExceededForAnonymUsers(true);
      }
    },
    [error],
  )
  

  useEffect(() => {
    onUrlChange();
  }, [onUrlChange]);

  useEffect(() => {
    onErrorChange();
  }, [onErrorChange]);

  useEffect(() => {
    return () => {
      resetSearchProductState()(dispatch);
    };
  }, []); // eslint-disable-line

  document.title = `${t("PageTitles.ProductDetails")} - ${asin}`;

  const breadcrumbMenus: BreadcrumbMenuItem[] = [
    {
      label: `${t("PageTitles.ProductDetails")} - ${asin}`,
    },
  ];

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <PlaceholderGlow busy={loading.list}>
            {
            !isUserLoggedIn() && isLimitExceededForAnonymUsers ? (
              <>
                <LimitExceeded />
              </>
            ) : loading.list === false && error ? (
              <Card>
                <CardBody>
                  <NoResult title={t("ProductDetails.NoResult.Title")} description={t(error.message)} />
                </CardBody>
              </Card>
            ) : (
              <>
                <BreadCrumb title={`${t("ProductDetails.Title")} - ${asin}`} menus={breadcrumbMenus} />

                <Row>
                  <Col>
                    <div className="h-100">
                      <Row>
                        <Col>
                          <ProductInfo destinationMarketplace={destinationMarketplace} sourceMarketplace={sourceMarketplace} />
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <AnalysisSummary destinationMarketplace={destinationMarketplace} sourceMarketplace={sourceMarketplace} />
                        </Col>
                      </Row>

                      <Row>
                        <Col xl={4} className="same-height">
                          <ProfitCalculation destinationMarketplace={destinationMarketplace} sourceMarketplace={sourceMarketplace} />
                        </Col>
                        <Col xl={8} className="same-height">
                          <ProfitAnalysis destinationMarketplace={destinationMarketplace} sourceMarketplace={sourceMarketplace} />
                        </Col>
                      </Row>

                      <Row>
                        <Col xl={12} className="same-height">
                          <SalesAnalysis />
                        </Col>
                      </Row>

                      <Row>
                        <Col xl={12} className="same-height">
                          <CompetitorAnalysis destinationMarketplace={destinationMarketplace} />
                        </Col>
                      </Row>

                      <Row>
                        <Col xl={12} className="same-height">
                          <SellersAndListingAnalysis destinationMarketplace={destinationMarketplace} sourceMarketplace={sourceMarketplace} />
                        </Col>
                      </Row>

                      <Row>
                        <Col xl={12} className="same-height">
                          <VariationAnalysis destinationMarketplace={destinationMarketplace} />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </PlaceholderGlow>
        </Container>
      </div>
      <SellThis />
      <CheckList />
      <ReAnalyzeProduct />
      <IneligibilityReasonList />
    </>
  );
};

export default ProductDetails;
