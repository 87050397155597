import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import DisplayPrice from "Components/Common/DisplayPrice";
import {UserSearchProduct} from "models/user_search_product";
import {useTranslation} from "react-i18next";
import {Badge, Card, CardBody} from "reactstrap";

interface PriceAnalysisProps {
  item: UserSearchProduct;
}
const PriceAnalysis = ({item}: PriceAnalysisProps) => {
  const {t} = useTranslation();

  return (
    <Card className="border card-animate mb-3">
      <CardBody>
        <h5>{t("SearchResults.Analyzes.PriceAnalysis.Title")}</h5>
        <ul className="list-group d-flex flex-group gap-1">
          <li className="d-flex justify-content-between">
            <span>{t("SearchResults.Analyzes.PriceAnalysis.Status")}</span>
            {item.priceAnalysis?.result ? (
              <Badge color={item.priceAnalysis.result === "Stable" ? "success" : item.priceAnalysis.result === "Recheck" ? "warning" : "danger"} className="h-25 fs-10">
                {t(item.priceAnalysis.result)}
                {item.priceAnalysis.result === "Stable" && <i className="mdi mdi-check ms-1"></i>}
              </Badge>
            ) : (
              <span>{t("NA")}</span>
            )}
          </li>
          <li className="d-flex justify-content-between">
            <span>
              {t("SearchResults.Analyzes.PriceAnalysis.MinPrice", {day: item.priceAnalysis?.result && item.priceAnalysis?.priceAnalysisDateRange ? item.priceAnalysis.priceAnalysisDateRange : 90})}
            </span>
            <span>{item.priceAnalysis?.result && item.priceAnalysis?.minPrice ? <DisplayPrice source={item.currencyCodeTarget} value={item.priceAnalysis?.minPrice} decimals={2} notation="abbreviated"/> : t("NA")}</span>
          </li>
          <li className="d-flex justify-content-between">
            <span>
              {t("SearchResults.Analyzes.PriceAnalysis.MaxPrice", {day: item.priceAnalysis?.result && item.priceAnalysis?.priceAnalysisDateRange ? item.priceAnalysis.priceAnalysisDateRange : 90})}
            </span>
            <span>{item.priceAnalysis?.result && item.priceAnalysis?.maxPrice ? <DisplayPrice source={item.currencyCodeTarget} value={item.priceAnalysis?.maxPrice} decimals={2} notation="abbreviated"/> : t("NA")}</span>
          </li>
          <li className="d-flex justify-content-between">
            <div>
              <span>{t("SearchResults.Analyzes.PriceAnalysis.CurrentPrice")}</span>
            </div>
            <span>{item.priceAnalysis?.currentPrice ? <DisplayPrice source={item.marketplaceTarget} value={item.priceAnalysis?.currentPrice} decimals={2} notation="abbreviated"/> : t("NA")}</span>
          </li>
          <li className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <span>{t("SearchResults.Analyzes.PriceAnalysis.Change")}</span>
              <i id={`PopoverPriceAnalysisChange-${item.userSearchProductId}`} className="ms-1 bx bxs-info-circle text-secondary"></i>
              <DefaultUncontrolledTooltip target={`PopoverPriceAnalysisChange-${item.userSearchProductId}`}>{t("Definitions.PriceChange")}</DefaultUncontrolledTooltip>
            </div>
            <span>{item.priceAnalysis?.result && item.priceAnalysis?.priceDiffRate !== null ? item.priceAnalysis.priceDiffRate + "%" : t("NA")}</span>
          </li>
        </ul>
      </CardBody>
    </Card>
  );
};

export default PriceAnalysis;
