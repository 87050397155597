import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ApiError, LoadingStates, PagedList} from "helpers/types";
import {ScanAndSave, ScanAndSaveStatus} from "models/scan_and_save";

type AdminScanAndSaveLoadingStates = LoadingStates<"list" | "restart" | "cancel" | "delete" | "filter">;

export type AdminScanAndSaveState = {
  scanAndSaveList: Partial<PagedList<ScanAndSave>>;
  loading: AdminScanAndSaveLoadingStates;
  error?: ApiError;
};

const initialState: AdminScanAndSaveState = {
  scanAndSaveList: {items: []},
  loading: {
    list: false,
    filter: true,
    restart: false,
    cancel: false,
    delete: false,
  },
  error: {} as ApiError,
};

const AdminScanAndSaveSlice = createSlice({
  name: "AdminScanAndSave",
  initialState,
  reducers: {
    setScanAndSaveList(state, action: PayloadAction<PagedList<ScanAndSave>>) {
      state.scanAndSaveList = action.payload;
    },
    updateScanAndSaveItemAsCanceled(state, action: PayloadAction<ScanAndSave>) {
      const scanAndSave = action.payload;
      state.scanAndSaveList.items = state.scanAndSaveList.items?.map(x => {
        if (x.id === scanAndSave.id) {
          x.status = ScanAndSaveStatus.CANCELED;
        }
        return x;
      })
    },
    updateScanAndSaveItemAsRestarted(state, action: PayloadAction<ScanAndSave>) {
      const scanAndSave = action.payload;
      state.scanAndSaveList.items = state.scanAndSaveList.items?.map(x => {
        if (x.id === scanAndSave.id) {
          x.status = ScanAndSaveStatus.WORKING;
        }
        return x;
      })
    },
    loading(state, action: PayloadAction<[keyof AdminScanAndSaveLoadingStates, boolean]>) {
      const [operation, loadingState] = action.payload;
      state.loading[operation] = loadingState;
    },
    apiError(state, action: PayloadAction<ApiError>) {
      state.error = action.payload;
    },
    reset(state) {
      state.loading = initialState.loading;
      state.scanAndSaveList = initialState.scanAndSaveList;
      state.error = initialState.error;
    },
  },
});

export const {setScanAndSaveList, updateScanAndSaveItemAsCanceled, updateScanAndSaveItemAsRestarted, loading, apiError, reset} = AdminScanAndSaveSlice.actions;

export default AdminScanAndSaveSlice.reducer;
