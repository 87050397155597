import {ApplicationUser} from "models/application_user";
import {LOCAL_STORAGE} from "helpers/local_storage";
import CryptoJS from "crypto-js";

const GetEncryptedState = (state: string) => {
  var key = CryptoJS.enc.Utf8.parse("connect-SellThis");
  var iv = CryptoJS.enc.Utf8.parse("amazonToSellThis");

  var encryptedState = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(state), key, {
    keySize: 128 / 8,
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  const encryptedString = CryptoJS.enc.Base64.stringify(encryptedState.ciphertext);

  return encryptedString;
};

const GetConnectRequestUrl = (amazonMarketplaceInfos: any, storeName: string, marketplace: string, status: string) => {
  function fetchUserProfile(): ApplicationUser | null {
    const jsonUserProfile = localStorage.getItem(LOCAL_STORAGE.LOGGED_USER);
    if (jsonUserProfile) {
      return JSON.parse(jsonUserProfile);
    }
    return null;
  }

  const userProfile: ApplicationUser | null = fetchUserProfile();

  let sellerCentral = "https://sellercentral.amazon." + amazonMarketplaceInfos.find((amazonMarketplaceInfo: any) => amazonMarketplaceInfo.marketplace === marketplace).domain;

  let state = GetEncryptedState(userProfile?.userId + "|" + storeName + "|" + marketplace + "|" + status);

  return sellerCentral + "/apps/authorize/consent?application_id=amzn1.sp.solution.34223bef-0b90-4ba2-b760-1e402782fc33&state=" + encodeURIComponent(state);
};

export default GetConnectRequestUrl;
