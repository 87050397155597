import {AddProductCommand} from "api/command";
import {useFormik} from "formik";
import {formatBytes, preventScrollUp} from "helpers/utilities";
import {CSSProperties, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {Modal, ModalHeader, ModalBody, ModalFooter, Button, Col, Row, Card, Form, Spinner} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {useAmazonMarketplace} from "Components/Hooks/AmazonMarketplaceHooks";
import {addProduct} from "slices/admin/systemMonitoring/thunk";
import Dropzone from "react-dropzone";
import ValidatedSelect from "Components/Common/ValidatedSelect";
import * as Yup from "yup";

interface AddProductProps {
  isOpen: boolean;
}
const AddProduct = (props: AddProductProps) => {
  const {t} = useTranslation();
  const [selectedFile, setSelectedFile] = useState<File>();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const {activeMarketplaceOptions} = useAmazonMarketplace();

  const systemMonitoringData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.AdminSystemMonitoring.loading,
    }),
  );
  const {loading} = useSelector(systemMonitoringData);

  const validation = useFormik({
    initialValues: {
      marketplace: "",
      file: selectedFile,
    } as AddProductCommand,
    validationSchema: Yup.object().shape({
      marketplace: Yup.string().required(t("Admin.SystemMonitoring.Dialog.AddProduct.Validation.SelectStore")),
      file: Yup.mixed().required(t("Admin.SystemMonitoring.Dialog.AddProduct.Validation.UploadFile")),
    }),
    onSubmit: async (values: AddProductCommand) => {
      if (selectedFile) {
        const payload: AddProductCommand = {
          marketplace: values.marketplace,
          file: selectedFile,
        };
        addProduct(payload)(dispatch);
        toggle();
        // send request
      }
    },
  });
  const disabledStyle: CSSProperties = {
    pointerEvents: "none",
    opacity: 0.5,
  };
  const toggle = () => {
    validation.resetForm();
    setSelectedFile(undefined);

    const baseUrl = location.pathname.split("add-product")[0];
    preventScrollUp();
    navigate(baseUrl);
  };

  function handleAcceptedFile(file: File) {
    if (file instanceof Blob) {
      // Check if the file is indeed a Blob (or File)
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target) {
          validation.setFieldValue("file", file);
          setSelectedFile(file);
        }
      };

      // Trigger the file read process
      reader.readAsBinaryString(file);
    } else {
      validation.setFieldError("inventories", t("Inventory.Dialog.UpdateInventory.Validation.InvalidFile"));
      setSelectedFile(undefined);
    }
    return true;
  }

  return (
    <>
      <Modal id="showNewSearchModal" className="modal-md" isOpen={props.isOpen} toggle={toggle} centered={true}>
        <Form onSubmit={validation.handleSubmit}>
          <ModalHeader className="bg-light p-3" toggle={toggle}>
            {t("Admin.SystemMonitoring.Dialog.AddProduct.Title")}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <p className="text-muted">{t("Admin.SystemMonitoring.Dialog.AddProduct.Description")}</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="acitivity-timeline acitivity-main mt-2">
                  <div className="acitivity-item d-flex pb-5">
                    <div className="flex-shrink-0 avatar-xs acitivity-avatar">
                      <div className="avatar-title bg-warning-subtle text-warning rounded-circle">
                        <i className="bx bx-store"></i>
                      </div>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <h6 className="mb-2 lh-base">{t("Admin.SystemMonitoring.Dialog.AddProduct.SelectStore")}</h6>
                      <div className="lh-base">
                        <ValidatedSelect
                          className="w-100"
                          options={activeMarketplaceOptions}
                          validation={validation}
                          field="marketplace"
                          value={validation.values.marketplace}
                          errorStyle="container"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="acitivity-item d-flex pb-5" style={validation.values.marketplace ? {} : disabledStyle}>
                    <div className="flex-shrink-0 avatar-xs acitivity-avatar">
                      <div className="avatar-title bg-secondary-subtle text-secondary rounded-circle">
                        <i className="ri-download-2-line"></i>
                      </div>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <h6 className="mb-2 lh-base">{t("Admin.SystemMonitoring.Dialog.AddProduct.DownloadSample")}</h6>
                      <div className="lh-base">
                        <Link to={`/SearchTemplate.xlsx`} target="_blank" className="btn btn-soft-info waves-effect waves-light" download>
                          {loading.add ? <Spinner size="sm" className="me-2"></Spinner> : <i className="ri-download-2-line align-bottom me-1"></i>}
                          {t("Admin.SystemMonitoring.Dialog.AddProduct.Download")}
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="acitivity-item d-flex" style={validation.values.marketplace ? {} : disabledStyle}>
                    <div className="flex-shrink-0 avatar-sm acitivity-avatar">
                      <div className="avatar-title bg-success-subtle text-success rounded-circle">
                        <i className="ri-upload-cloud-2-fill"></i>
                      </div>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <h6 className="mb-2 lh-base">{t("Admin.SystemMonitoring.Dialog.AddProduct.Upload.Title")}</h6>
                      <Dropzone
                        maxFiles={1}
                        onDrop={(acceptedFiles: any) => {
                          handleAcceptedFile(acceptedFiles[0]); // Handle the first file only
                        }}
                        accept={{
                          "text/csv": [".csv"],
                          "application/vnd.ms-excel": [".xls"],
                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
                        }}
                      >
                        {({getRootProps, getInputProps}) => (
                          <div className={"dz-clickable"}>
                            <div className="dz-message needsclick" {...getRootProps()}>
                              <div className="alert alert-light d-flex align-items-center mb-0">
                                <div className="flex-shrink-0">
                                  <i className="ri-upload-cloud-2-fill text-success align-bottom display-6"></i>
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <p className="text-muted mb-0">{t("Admin.SystemMonitoring.Dialog.AddProduct.Upload.Description")}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      <div className="list-unstyled mb-0" id="file-previews">
                        {selectedFile && (
                          <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col>
                                  <Link to="#" className="text-muted font-weight-bold">
                                    {selectedFile?.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{formatBytes(selectedFile?.size)}</strong>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        )}
                      </div>
                      {validation.values.marketplace && validation.touched.file && validation.errors.file && <p className="mt-2 text-danger">{validation.errors.file.toString()}</p>}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end mt-3">
              <Button type="button" className="btn btn-light" onClick={toggle} disabled={loading.add}>
                {t("Close")}
              </Button>
              <Button type="submit" className="btn btn-success" disabled={loading.add}>
                {loading.add ? <Spinner size="sm" className="me-2"></Spinner> : <i className="ri ri-arrow-up-line label-icon align-middle fs-16 me-2"></i>}
                {t("Admin.SystemMonitoring.Dialog.AddProduct.SubmitButton")}
              </Button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default AddProduct;
