import {useProfile} from "Components/Hooks/UserHooks";
import {Outlet} from "react-router-dom";
import NonAuth2Layout from "layouts/NonAuth2Layout";
import Unauthorized from "pages/Errors/_Unauthorized";
import VerticalLayout from "layouts/VerticalLayout";
import Restricted from "Components/Common/Restricted";

const SingleSearchRoutes = () => {
  const {isUserLoggedIn} = useProfile();
  // If token is invalid
  if (isUserLoggedIn()) {
    return (
      <VerticalLayout>
        <Restricted require="productDetails" read fallback={() => <Unauthorized />}>
          <Outlet />
        </Restricted>
      </VerticalLayout>
    );
  } else {
    return (
      <NonAuth2Layout>
        <Outlet />
      </NonAuth2Layout>
    );
  }
};

export default SingleSearchRoutes;
