import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {Card, CardBody, Col} from "reactstrap";
import {useTranslation} from "react-i18next";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {useSelector} from "react-redux";
import {UserOrder, UserOrderItem} from "models/user_order";
import {useProfile} from "Components/Hooks/UserHooks";
import CopyAsinWidget from "Components/Common/CopyAsinWidget";
import DomainToFlag from "Components/Common/DomainToFlag";
import RenderNumber from "Components/Common/RenderNumber";
import SimpleBar from "simplebar-react";
import errorImage from "assets/images/svg/product.svg";
import NoData from "Components/Common/NoData";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import DisplayPrice from "Components/Common/DisplayPrice";
import DisplayDate from "Components/Common/DisplayDate";

const LatestOrders = () => {
  const {t} = useTranslation();
  const {userProfile} = useProfile();
  const [latestOrders, setLatestOrders] = useState<UserOrder[]>([]);
  const dashboardData = createSelector(
    (state: RootState) => state,
    (state) => ({
      filter: state.Dashboard.filter,
      statistics: state.Dashboard.statistics,
    }),
  );
  const {filter, statistics} = useSelector(dashboardData);

  useEffect(() => {
    if (statistics && statistics.latestOrders) {
      setLatestOrders(statistics.latestOrders);
    }
  }, [statistics]);

  return (
    <React.Fragment>
      <Col xl={8} className="same-height">
        <Card className="card-height-100 card-animate">
          <div className="align-items-center d-flex card-header">
            <h4 className="card-title mb-0 flex-grow-1">{t("Dashboard.LatestOrders.Title")}</h4>
            <div className="flex-shrink-0">
              <Link className="link-body-emphasis text-decoration-underline link-underline-opacity-25 link-underline-opacity-75-hover mb-0" to={`/orders`}>
                {t("Dashboard.LatestOrders.Link.ViewOrders")}
              </Link>
            </div>
          </div>
          <CardBody>
            {latestOrders.length !== 0 ? (
              <div className="table-responsive table-card">
                <SimpleBar style={{height: "500px"}}>
                  <table className="table-hover table table-centered align-middle mb-0 border-top-0">
                    <thead className="text-muted table-light sticky-top">
                      <tr>
                        <th scope="col">{t("Dashboard.LatestOrders.TableColumn.OrderID")}</th>
                        <th scope="col">{t("Dashboard.LatestOrders.TableColumn.Products")}</th>
                        <th scope="col">{t("Dashboard.LatestOrders.TableColumn.Quantity")}</th>
                        <th scope="col">{t("Dashboard.LatestOrders.TableColumn.TotalProfit")}</th>
                        <th scope="col">{t("Dashboard.LatestOrders.TableColumn.RoiMargin")}</th>
                        <th scope="col">{t("Dashboard.LatestOrders.TableColumn.FeesTaxes")}</th>
                        <th scope="col">{t("Dashboard.LatestOrders.TableColumn.OrderDate")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {latestOrders.map((order: UserOrder, orderIndex: number) => {
                        return (
                          <tr key={orderIndex}>
                            <td>
                              <div className="mb-1 d-flex">
                                <Link className="link-secondary link-offset-2 text-decoration-underline me-1" target="_blank" to={`/orders/${order.amazonOrderId}`}>
                                  {order.amazonOrderId}
                                  <i className="ri-arrow-right-up-line"></i>
                                </Link>
                              </div>
                              <div className="d-flex align-items-center text-nowrap text-muted">
                                <DomainToFlag marketplace={order.userStore.marketplace} />
                                <span>{`${order.userStore.marketplace} - ${order.userStore.name}`}</span>
                              </div>
                            </td>
                            <td>
                              {order.userOrderItems && order.userOrderItems.length <= 1 ? (
                                <div className="d-flex align-items-center">
                                  <div className="d-flex align-items-center avatar-sm rounded flex-shrink-0 overflow-hidden">
                                    <img
                                      className="rounded w-100"
                                      src={`https://m.media-amazon.com/images/I/${order.userOrderItems[0].userInventory?.imageSmall}`}
                                      onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                                        e.currentTarget.onerror = null;
                                        e.currentTarget.src = errorImage;
                                      }}
                                      alt=""
                                    />
                                  </div>
                                  <div className="flex-grow-1 ms-2">
                                    <div className="d-flex align-items-center">
                                      <CopyAsinWidget
                                        Asin={order.userOrderItems[0].asin}
                                        index={order.userOrderItems[0].asin}
                                        asLink={true}
                                        marketplace={order.userStore.marketplace}
                                        hideCopyIcon={true}
                                      />
                                    </div>
                                    <div className="d-flex align-items-center">{order.userOrderItems[0].sellerSKU}</div>
                                  </div>
                                </div>
                              ) : (
                                <div className="d-flex align-items-center">
                                  <div className="flex-shrink-0">
                                    <div className="avatar-group">
                                      {order.userOrderItems &&
                                        order.userOrderItems.map((item: UserOrderItem, itemIndex: number) => {
                                          const itemImage = `https://m.media-amazon.com/images/I/${item.userInventory.imageSmall}`;
                                          return (
                                            <div key={itemIndex}>
                                              <Link to={`/inventory?asin=${item.asin}`} target="_blank" className="avatar-group-item">
                                                <img
                                                  src={itemImage}
                                                  id={`${order.userOrderId}-${item.asin}`}
                                                  alt=""
                                                  className="rounded-circle avatar-xxs"
                                                  onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                                                    e.currentTarget.onerror = null;
                                                    e.currentTarget.src = errorImage;
                                                  }}
                                                />
                                              </Link>
                                              <DefaultUncontrolledTooltip target={`${order.userOrderId}-${item.asin}`}>
                                                {item.asin} x {item.quantityOrdered}
                                              </DefaultUncontrolledTooltip>
                                            </div>
                                          );
                                        })}
                                    </div>
                                  </div>
                                  <div className="flex-grow-1 ms-2">
                                    <Link className="link-body-emphasis link-offset-1 text-decoration-underline link-underline-opacity-75-hover " target="_blank" to={`/orders/${order.amazonOrderId}`}>
                                      {t("Dashboard.LatestOrders.Link.ClickForDetails")}
                                      <i className="ri-arrow-right-up-line"></i>
                                    </Link>
                                  </div>
                                </div>
                              )}
                            </td>
                            <td>
                              <h5 className="fs-14 my-1 align-items-center d-flex">
                                {order.quantityOrdered} <i className="ri-bar-chart-fill text-success fs-16 ms-1"></i>
                              </h5>
                              <span className="text-muted text-nowrap"></span>
                            </td>
                            <td>
                              <h5 className="fs-14 my-1">
                                <DisplayPrice source={filter?.currency} value={order.totalSellPrice} decimals={2} notation="abbreviated" />
                              </h5>
                              <span className="text-muted d-flex align-items-center">
                                <DisplayPrice source={filter?.currency} value={order.profit} decimals={2} notation="abbreviated" />
                                {order.costIsPredicted && (
                                  <>
                                    <i className="ri-question-fill text-warning fs-14 ms-1" id={`CostIsPredictedTooltip-${order.amazonOrderId}`} />
                                    <DefaultUncontrolledTooltip target={`CostIsPredictedTooltip-${order.amazonOrderId}`}>{t("CostIsPredicted")}</DefaultUncontrolledTooltip>
                                  </>
                                )}
                              </span>
                            </td>
                            <td>
                              <h5 className="fs-14 my-1">
                                <RenderNumber value={order.roi} decimals={0} prefix={"%"} suffix={""} />
                              </h5>
                              <span className="text-muted">
                                <RenderNumber value={order.margin} decimals={0} prefix={"%"} suffix={""} />
                              </span>
                            </td>
                            <td>
                              <span className="text-muted text-nowrap">
                                <DisplayPrice source={filter?.currency} value={order.totalFees} decimals={2} notation="abbreviated" />
                              </span>
                              <h5 className="fs-14 my-1 text-nowrap">
                                <DisplayPrice source={filter?.currency} value={order.totalTax} decimals={2} notation="abbreviated" />
                              </h5>
                            </td>
                            <td>
                              <DisplayDate
                                id={`DisplayDateLatestOrders-${order.userOrderId}`}
                                value={order.purchaseDate}
                                format="D MMM YYYY HH:mm"
                                tz={userProfile?.timezone}
                                showDateAndTimeSeparate={true}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </SimpleBar>
              </div>
            ) : (
              <NoData icon="mdi mdi-note-search-outline" />
            )}
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default LatestOrders;
