import axios, {AxiosRequestConfig} from "axios";
import {ResponseModel} from "models/response_model";
import {ApplicationUser} from "models/application_user";
import {postRefreshToken} from "services/identity_service";
import {LOCAL_STORAGE} from "./local_storage";

axios.defaults.headers.post["Content-Type"] = "application/json";

const authUser: any = localStorage.getItem(LOCAL_STORAGE.LOGGED_USER);
var token: string | null = null;
if (authUser) {
  const auth = JSON.parse(authUser) as ApplicationUser;
  token = auth ? auth?.tokenInfo?.signature : null;
}
if (token) axios.defaults.headers.common["Authorization"] = "Bearer " + token;

const language = localStorage.getItem("I18N_LANGUAGE");
if (language) axios.defaults.headers.common["x-language"] = language;
else axios.defaults.headers.common["x-language"] = navigator.language || "en";

// intercepting to capture errors
axios.interceptors.response.use(
  (response) => (response.data ? response.data : response),
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const newAccessToken = await refreshAccessTokenAsync();
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        return axios(originalRequest);
      } catch (refreshError) {
        throw refreshError;
      }
    }
    return Promise.reject(error);
  },
);

async function refreshAccessTokenAsync() {
  try {
    const user = localStorage.getItem(LOCAL_STORAGE.LOGGED_USER);

    if (!user) {
      throw new Error();//"Token expired"
    }

    const auth = JSON.parse(user) as ApplicationUser;
    const response: ResponseModel = await postRefreshToken({refreshToken: auth.tokenInfo.refreshToken});

    if (!response.status) {
      throw new Error();//"Refresh token failed"
    }

    const data: ApplicationUser = response.data;
    setAuthorization(data.tokenInfo.signature);
    localStorage.setItem(LOCAL_STORAGE.LOGGED_USER, JSON.stringify(auth));

    return data.tokenInfo.signature;
  } catch (error:any) {
    localStorage.clear();
    window.location.href = "/account/login";
    //throw error;
  }
}



class APIClient {
  get = (url: string, params?: any): Promise<ResponseModel> => {
    let response: Promise<ResponseModel>;

    let paramKeys: string[] = [];

    if (params) {
      Object.keys(params).map((key) => {
        paramKeys.push(key + "=" + params[key]);
        return paramKeys;
      });

      const queryString = paramKeys && paramKeys.length ? paramKeys.join("&") : "";
      response = axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}`, params);
    }

    return response;
  };

  create = (url: string, data: any, config?: any): Promise<ResponseModel> => {
    return axios.post(url, data, config);
  };

  update = (url: string, data: any): Promise<ResponseModel> => {
    return axios.patch(url, data);
  };

  put = (url: string, data: any): Promise<ResponseModel> => {
    return axios.put(url, data);
  };

  delete = (url: string, config?: AxiosRequestConfig): Promise<ResponseModel> => {
    return axios.delete(url, {...config});
  };
}

const setAuthorization = (token: string) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

export {APIClient, setAuthorization};
