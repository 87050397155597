import {UserSearchProductCheckList} from "./user_search_product_check_list";

export interface ProductDetails {
  showCheckList: boolean;
  userSearchId: string;
  productInfo: ProductInfo;
  analysisSummary: AnalysisSummary;
  generalCheckAndAlerts: GeneralCheckAndAlerts;
  restrictionCheck: RestrictionCheck;
  profitCalculation: ProfitCalculation;
  profitAnalysis: ProfitAnalysis;
  competitorAnalysis: CompetitorAnalysis;
  variationAnalysis: VariationAnalysis;
  sellerAndListingAnalysis: SellerAndListingAnalysis;
  salesAnalysis: SalesAnalysis;
}

export interface ProductInfo {
  userStoreId?: string;
  productImage?: string;
  productName?: string;
  asin?: string;
  sku?: string;
  storeName?: string;
  sourceMarketplace: string;
  targetMarketplace: string;
  currencyCodeSource?: string;
  currencyCodeTarget?: string;
  stockAvailable?: number;
  stockInbound?: number;
  stockReserved?: number;
  stockUnfulfillable?: number;
  stockResearching?: number;
  stockTotal?: number;
  dimensions?: string;
  dimensionStatus?: boolean;
  variationCount?: number;
  isFBAEligible?: boolean;
  weight?: number;
  weightStatus?: boolean;
  categoryName?: string;
  categoryPath?: string;
  reviewCount?: number;
  reviewRating?: number;
  dimensionalWeight?: number;
  dimensionalWeightStatus?: boolean;
  bsr?: number;
  checkList: UserSearchProductCheckList;
  launchDate: Date;
  offerCount?: number;
}

export interface AnalysisSummary {
  sourceMarketplace: string;
  targetMarketplace: string;
  analysisDate?: Date;
  decision?: string;
  decisionReasons?: string;
  saleCount?: number;
  precision?: string;
  profit?: number;
  roi?: number;
  variationCount?: number;
  competitionLevel?: string;
}

export interface GeneralCheckAndAlerts {
  sameProductCheckReason?: string;
  sameProductCheckResult?: number;
  customsCheckKeywordCheckReason?: string;
  customsCheckKeywordCheckResult?: number;
  customsCheckCategoryCheckReason?: string;
  customsCheckCategoryCheckResult?: number;
  productSizeEligibleToFBA?: boolean;
  isNewProduct?: boolean;
  reviewCount?: number;
  reviewRating?: number;
}

export interface RestrictionCheck {
  hasRestriction?: boolean;
  spapiRestrictionCheckResultDate?: Date;
  restrictionReasonCodes?: string;
}

export interface ProfitCalculation {
  buyBoxType?: number;
  totalFees: number;
  totalCostSource: number;
  totalCost: number;
  purchasePriceSource: number;
  purchasePrice: number;
  taxSource: number;
  tax: number;
  shippingPriceSource: number;
  shippingPrice: number;
  fbaFee: number;
  referralFee: number;
  otherFees: number;
  overallCost: number;
  sellPrice: number;
  profitSource: number;
  profit: number;
  margin: number;
  roi: number;
  exchangeRateTarget?: number;
  currentShippingPrice?: number;
  currentPrice?: number;
  marketplaceTaxException?: number;
  marketplaceTaxExceptionRate?: number;
}

export interface ProfitAnalysis {
  priceAnalysisResult?: string;
  changeRate?: number;
  minPrice?: number;
  minPriceProfit?: number;
  minPriceMargin?: number;
  minPriceROI?: number;
  maxPrice?: number;
  maxPriceProfit?: number;
  maxPriceMargin?: number;
  maxPriceROI?: number;
  averagePrice?: number;
  averagePriceProfit?: number;
  averagePriceMargin?: number;
  averagePriceROI?: number;
  currentPrice?: number;
  currentPriceProfit?: number;
  currentPriceMargin?: number;
  currentPriceROI?: number;
  priceHistory?: PriceHistory[];
}

export interface PriceHistory {
  date?: Date;
  price?: number;
}
export interface CompetitorAnalysis {
  competitionLevel?: string;
  fbaSellerCount?: number;
  remoteFbaSellerCount?: number;
  fbmSellerCount?: number;
  averageFBASellerCount?: number;
  isAmazonExist?: boolean;
  amazonLastSeen?: Date;
  hasBuyBox: boolean;
  sellerList: SellerList[];
  sellerHistory: SellerHistory[];
}
export interface SellerHistory {
  date?: Date;
  isAmazonExist?: boolean;
  fbaSellerCount?: number;
  fbmSellerCount?: number;
}

export interface SellerList {
  sellerId?: string;
  sellerType?: SellerType;
  ratingPercentage?: number;
  ratingCount?: number;
  price?: number;
  isRemoteFBA?: boolean;
  isAmazon?: boolean;
  isCommonFbaSeller?: boolean;
  isBuyBoxWinner?: boolean;
  potentialBrandOwner?: boolean;
}

export interface VariationAnalysis {
  saleCount?: number;
  variationCount?: number;
  priceDiffRate?: number;
  isHasFBASeller?: boolean;
  othersHasFBASeller?: boolean;
  variations: Variation[];
}

export interface Variation {
  asin?: string;
  productName?: string;
  image?: string;
  isSelectedVariation?: boolean;
  saleCount?: number;
  dimensions?: string;
  weight?: number;
  price?: number;
  priceChangeRate?: number;
  fbaCount?: number;
  fbmCount?: number;
}

export interface SellerAndListingAnalysis {
  sourceMarketplace: string;
  targetMarketplace: string;
  buyBoxTypeSource?: number;
  offerCountSource?: number;
  buyBoxType?: number;
  offerCount?: number;
  commonFBASellerCount?: number;
  commonFBARemoteSellerCount?: number;
  potentialBrandOwner?: boolean;
  sourceSellerList: SellerList[];
  targetSellerList: SellerList[];
}

export interface SalesAnalysis {
  saleCount?: number;
  saleTrendRate?: number;
  categoryName?: string;
  averageBSR?: number;
  precision?: string;
  hasAnomalies?: boolean;
  ineligibilityReasonList?: string;
  bsrHistory?: BsrHistory[];
}
export interface BsrHistory {
  date?: Date;
  bsr?: number;
}

export enum SellerType {
  AMAZON = 0,
  OWNER = 1,
  FBA = 2,
  FBM = 3,
}
