import DataTable, {DataTableRef} from "Components/Common/DataTable";
import {useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Card, CardBody, Col, Container, Row} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {useDispatch, useSelector} from "react-redux";
import {useImmer} from "use-immer";
import {useDebounce} from "Components/Hooks/HelperHooks";
import {getUserWebNotifications, markAllAsRead, readUserWebNotification} from "slices/notifications/thunk";
import {ColumnDef} from "@tanstack/react-table";
import BreadCrumb from "Components/Common/BreadCrumb";
import NoNotifications from "./_NoNotifications";
import Moment from "react-moment";
import i18n from "i18n";
import classnames from "classnames";
import {useNavigate} from "react-router-dom";
import { WebNotificationFilter, WebNotificationQuery, WebNotification } from "models/web_notification";

const TAB_ALL: WebNotificationFilter = "all";
const TAB_UNREAD: WebNotificationFilter = "unread";
export type NotificationsListRef = {
  reload: VoidFunction;
};

const Notifications = () => {
  const {t} = useTranslation();
  const [selectedTab, setSelectedTab] = useState<WebNotificationFilter>(TAB_ALL);
  const tabParam = new URLSearchParams(window.location.search).get("tab");
  const navigate = useNavigate();
  const tableRef = useRef<DataTableRef>(null);
  const listRef = useRef<NotificationsListRef>(null);
  const dispatch = useDispatch();
  const notificationsData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Notifications.loading,
      userWebNotifications: state.Notifications.userWebNotifications,
    }),
  );
  const {loading, userWebNotifications} = useSelector(notificationsData);

  useEffect(() => {
    updateQuery((q) => {
      q.filter = selectedTab;
    });
  }, [selectedTab]); // eslint-disable-line

  useEffect(() => {
    if (tabParam) {
      if (tabParam === "unread") {
        setSelectedTab(TAB_UNREAD);
      } else if (tabParam === "all") {
        setSelectedTab(TAB_ALL);
      }
    }
  }, [tabParam]);

  const [query, updateQuery] = useImmer<WebNotificationQuery>({
    page: 1,
    pageSize: 10,
    filter: TAB_UNREAD,
  });

  const debouncedLoadList = useDebounce(() => {
    getUserWebNotifications(query)(dispatch).then(() => {
      tableRef.current?.resetSelection();
    });
  }, 200);

  useEffect(() => {
    debouncedLoadList();
  }, [debouncedLoadList, query, t]);

  useImperativeHandle(
    listRef,
    () => {
      return {
        reload: () => {
          debouncedLoadList();
        },
      };
    },
    [debouncedLoadList],
  );

  const handleReadAndRedirect = (row: WebNotification) => {
    if (!row.isRead) {
      readUserWebNotification(row.notificationHistoryId)(dispatch);
    }
    if (row.link) {
      navigate(row.link);
    }
  };
  const columns = useMemo<ColumnDef<WebNotification, any>[]>(
    () => [
      {
        header: t("Notifications.TableColumn.Notification"),
        accessorKey: "sendDate",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as WebNotification;
          return (
            <div
              className={`${row.link ? "cursor-pointer" : ""}`}
              onClick={() => {
                handleReadAndRedirect(row);
              }}
            >
              <div className={classnames("justify-content-center", row.isRead ? "text-muted" : "")}>
                <Row>
                  <Col>
                    <Row>
                      <Col xs="auto">
                        <span className="fw-bold ">
                          {row.subject}
                        </span>
                      </Col>
                      <Col>
                        <span className="d-flex align-items-center ">
                          <i className="bx bx-calendar-check fs-18 me-1 text-muted"></i>
                          <Moment className="text-muted" locale={i18n.language} fromNow>
                            {row.sendDate}
                          </Moment>
                        </span>
                      </Col>
                      <Col xs={12}>
                        {row.body}
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={"auto"} className="d-flex justify-content-center align-items-center">
                    {!row.isRead && <i className="mdi mdi-checkbox-blank-circle font-size-11 ms-2 text-primary"></i>}
                  </Col>
                </Row>
              </div>
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, userWebNotifications.items],
  );

  document.title = t("PageTitles.Notifications");

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Notifications.Title")} menus={[{label: t("Notifications.Title"), url: "/notifications"}]} />
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <Button className="mb-0 btn-soft-info w-100" id="tabUnread" active={selectedTab === TAB_UNREAD} onClick={() => setSelectedTab(TAB_UNREAD)}>
                    {t("Notifications.Label.Unread")}
                  </Button>
                </Col>

                <Col>
                  <Button className="mb-0 btn-soft-info w-100" id="tabAll" active={selectedTab === TAB_ALL} onClick={() => setSelectedTab(TAB_ALL)}>
                    {t("Notifications.Label.All")}
                  </Button>
                </Col>
              </Row>
              <Row className="my-3">
                <Col className="d-flex justify-content-end ">
                  <Button
                    color="primary"
                    disabled={loading.markAllRead}
                    onClick={() => {
                      markAllAsRead()(dispatch);
                    }}
                  >
                    {t("Notifications.Label.MarkAllAsRead")}
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <DataTable
                         
                        ref={tableRef}
                        busy={loading.list || loading.update}
                        columns={columns}
                        data={userWebNotifications.items || []}
                        totalDataLength={userWebNotifications.totalCount}
                        pagination={{
                          pageIndex: query.page - 1,
                          pageSize: query.pageSize,
                        }}
                        onPaginationChanged={(pagination) => {
                          updateQuery((q) => {
                            q.page = pagination.pageIndex + 1;
                            q.pageSize = pagination.pageSize;
                            q.filter = selectedTab;
                          });
                        }}
                        renderOnEmpty={() => <NoNotifications />}
                        hideTopPaginationStage
                        hovered
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default Notifications;
