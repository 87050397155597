import {useCallback, useEffect} from "react";
import {Modal, ModalHeader, ModalBody, ModalFooter, Button, Col, Row, Spinner, Form} from "reactstrap";
import {useFormik} from "formik";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {createSelector} from "reselect";
import {RootState} from "slices";
import ValidatedSelect from "Components/Common/ValidatedSelect";
import ValidatedInput from "Components/Common/ValidatedInput";
import * as Yup from "yup";
import { getSingleSearchLocalData, updateSingleSearchLocalData } from "helpers/local_storage";

interface SingleSearchProps {
  isOpen: boolean;
}
const SingleSearch = (props: SingleSearchProps) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const searchesData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Searches.loading,
      activeUserStoreOptions: state.Common.activeUserStoreOptions,
      activeMarketplaceOptions: state.Common.activeMarketplaceOptions,
    }),
  );
  const {loading, activeUserStoreOptions, activeMarketplaceOptions} = useSelector(searchesData);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      asin: searchParams.get("asin") || "",
      marketplace: getSingleSearchLocalData()?.marketplace,
    },
    validationSchema: Yup.object({
      asin: Yup.string().required(t("Searches.Dialog.SingleSearch.Validation.Asin")),
      marketplace: Yup.string().required(t("Searches.Dialog.SingleSearch.Validation.Marketplace")),
    }),
    onSubmit: (values) => {
      navigate(`/dp/${values.asin}?marketplace=${values.marketplace}`);
    },
  })

  const initModal = useCallback(() => {
    if (searchParams.has("singleSearch")) {
      const asin = searchParams.get("asin");
      if (asin) {
        validation.setFieldValue("asin", asin);
      }
    }
  }, [props.isOpen]); // eslint-disable-line

  const onMarketplaceChange = useCallback(
    () => {
      if(validation.values.marketplace) { 
        updateSingleSearchLocalData({marketplace:validation.values.marketplace})
      }
    },
    [validation.values.marketplace],
  )
  
  useEffect(() => {
    initModal();
  }, [initModal]);

  useEffect(() => {
    onMarketplaceChange();
  }, [onMarketplaceChange]);

  const toggle = () => {
    const searchParams = new URLSearchParams(location.search);
    if(searchParams.has("singleSearch") && searchParams.has("asin")) {
      searchParams.delete("singleSearch");
      searchParams.delete("asin");
      navigate(`${location.pathname}?${searchParams.toString()}`);
    }
    else if (location.pathname.startsWith("/searches/new")) {
      navigate(`/searches`);
    }
    validation.resetForm();
  };

  return (
    <>
      <Form onSubmit={validation.submitForm}>
        <Modal id="showNewSearchModal" size="md" isOpen={props.isOpen} toggle={toggle} centered={true}>
          <ModalHeader className="bg-light p-3" toggle={toggle}>
            {t("Common.Dialog.SingleSearch.Title")}
          </ModalHeader>
          <ModalBody>
            <Row className="g-3">
              <Col xs={12} lg={6}>
                <ValidatedInput validation={validation} type="text" field="asin" maxLength={10} placeholder={t("Asin")} disableValidationUI />
              </Col>
              <Col xs={12} lg={6}>
                <ValidatedSelect validation={validation} className={`w-100`} errorStyle="container" options={[...activeUserStoreOptions, ...activeMarketplaceOptions]} value={validation.values.marketplace} field="marketplace" />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <Button type="button" className="btn btn-light" disabled={loading.save} onClick={toggle}>
                {loading.save && <Spinner size="sm" className="me-2"></Spinner>}
                {t("Common.Dialog.SingleSearch.CancelButton")}
              </Button>

              <Button
                type="submit"
                className="btn btn-success"
                disabled={loading.save}
                onClick={() => {
                  validation.setFieldTouched("asin");
                  validation.setFieldTouched("marketplace");

                  if (validation.isValid) {
                    validation.handleSubmit();
                  }
                }}
              >
                {loading.save && <Spinner size="sm" className="me-2"></Spinner>}
                {t("Common.Dialog.SingleSearch.SubmitButton")}
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      </Form>
    </>
  );
};

export default SingleSearch;
