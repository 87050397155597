import AmazonMarketplaceInfos, {AmazonMarketplaceInfosType} from "Components/Common/AmazonMarketplaceInfos";
const useAmazonMarketplace = () => {
	
/**
 * Retrieves a list of active marketplaces from the AmazonMarketplaceInfos.
 *
 * @returns {AmazonMarketplaceInfosType[]} An array of active Amazon marketplace information objects.
 */
  const activeMarketplaces = (): AmazonMarketplaceInfosType[] => {
    return AmazonMarketplaceInfos().filter((x) => x.active);
  };

  const activeMarketplaceOptions = AmazonMarketplaceInfos().filter((x) => x.active).map((x) => {
      return {
        value: x.marketplace,
        label: x.countryName,
      };
    });

  return {activeMarketplaces, activeMarketplaceOptions};
};

export {useAmazonMarketplace};
