import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import {ToastContainer} from "react-toastify";
import {configureStore} from "@reduxjs/toolkit";
import {setupConsoleLogging} from "helpers/logging";
import {fetchUserStores} from "services/store_service";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import config from "config";
import rootReducer from "./slices";
import listeners from "slices/listeners";
import "react-toastify/dist/ReactToastify.css";

setupConsoleLogging();

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    const middlewares = getDefaultMiddleware();
    middlewares.push(...listeners);

    return middlewares;
  },
  devTools: config.env.isDevelopment,
});

store.dispatch(fetchUserStores());
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <Provider store={store}>
    <React.Fragment>
      <ToastContainer />
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <App />
      </BrowserRouter>
    </React.Fragment>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
