import DataTable, {DataTableRef} from "Components/Common/DataTable";
import {ColumnDef} from "@tanstack/react-table";
import {GetUserPaymentHistoryQuery} from "api/query";
import {useDebounce} from "Components/Hooks/HelperHooks";
import {useProfile} from "Components/Hooks/UserHooks";
import {ConstantPage} from "helpers/permission_helper";
import {User} from "models/user";
import {UserPayment} from "models/user_payment";
import {useEffect, useImperativeHandle, useMemo, useRef} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Badge, Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {downloadAdminUserPayment, getUserPaymentHistory} from "slices/admin/users/thunk";
import {useImmer} from "use-immer";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import DisplayDate from "Components/Common/DisplayDate";

export type UserPaymentHistoryListRef = {
  reload: VoidFunction;
};

interface PaymentHistoryProps {
  show: boolean;
  toggle: () => void;
  user: User;
}

const PAGE_IDENTIFIER: ConstantPage = "adminUsers";
const PaymentHistoryModal = ({show, toggle, user}: PaymentHistoryProps) => {
  const {t} = useTranslation();
  const {userProfile, hasPermission} = useProfile();
  const dispatch: any = useDispatch();
  const tableRef = useRef<DataTableRef>(null);
  const listRef = useRef<UserPaymentHistoryListRef>(null);
  const [query, updateQuery] = useImmer<GetUserPaymentHistoryQuery>({
    page: 1,
    pageSize: 10,
    userId: user.userId || undefined,
  });

  const debouncedLoadList = useDebounce(() => {
    getUserPaymentHistory(query)(dispatch).then(() => {
      tableRef.current?.resetSelection();
    });
  }, 200);

  useEffect(() => {
    if (hasPermission(PAGE_IDENTIFIER) && show && query.userId !== undefined) {
      debouncedLoadList();
    }
  }, [debouncedLoadList, query]); // eslint-disable-line

  useEffect(() => {
    if (show) {
      updateQuery({
        page: 1,
        pageSize: query.pageSize,
        userId: user.userId,
      });
    }
  }, [show]); // eslint-disable-line

  useImperativeHandle(
    listRef,
    () => {
      return {
        reload: () => {
          debouncedLoadList();
        },
      };
    },
    [debouncedLoadList],
  );

  const adminUsersData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.AdminUsers.loading,
      paymentHistoryList: state.AdminUsers.paymentHistoryList,
    }),
  );

  // Inside your component
  const {loading, paymentHistoryList} = useSelector(adminUsersData);

  const columns = useMemo<ColumnDef<UserPayment, any>[]>(
    () => [
      {
        header: t("Admin.Users.Dialog.PaymentHistory.TableColumn.Subscription"),
        size: 100,
        accessorKey: "subscriptionName",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as UserPayment;
          return <>{`${row.subscriptionName} ${row.billingPeriodMonth === 0 ? `(${t("Trial")})` : `(${row.billingPeriodMonth} ${t("Monthly")})`}`}</>;
        },
      },
      {
        header: t("Admin.Users.Dialog.PaymentHistory.TableColumn.InvoiceNumber"),
        size: 100,
        accessorKey: "stripeInvoiceNumber",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as UserPayment;
          return <>{row.stripeInvoiceNumber}</>;
        },
      },
      {
        header: t("Admin.Users.Dialog.PaymentHistory.TableColumn.Price"),
        size: 50,
        accessorKey: "price",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as UserPayment;
          return <>${row.price}</>;
        },
      },
      {
        header: t("Admin.Users.Dialog.PaymentHistory.TableColumn.Date"),
        size: 100,
        accessorKey: "date",
        sortingFn: "datetime",
        cell: (cellProps) => {
          const row = cellProps.row.original as UserPayment;
          return (
            <>
              <DisplayDate id={`DisplayDateUserPaymentDate-${row.userPaymentId}`} value={row.date} format="D MMM YYYY HH:mm" tz={userProfile?.timezone} />
            </>
          );
        },
      },
      {
        header: t("Admin.Users.Dialog.PaymentHistory.TableColumn.Status"),
        size: 80,
        accessorKey: "isSuccess",
        sortingFn: "alphanumericCaseSensitive",
        cell: (cellProps) => {
          const row = cellProps.row.original as UserPayment;
          return (
            <>
              {row.isSuccess ? (
                <Badge color="success" className="fs-12">
                  {t("Admin.Users.Dialog.PaymentHistory.Badge.Paid")}
                </Badge>
              ) : (
                <Badge color="danger" className="fs-12">
                  {t("Admin.Users.Dialog.PaymentHistory.Badge.Unpaid")}
                </Badge>
              )}
            </>
          );
        },
      },
      {
        header: t("Actions"),
        size: 30,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserPayment;
          return (
            <>
              <Button
                id={`PaymentHistory-${row.userPaymentId}`}
                color="link"
                className="btn btn-ghost-secondary px-1 py-0 fs-18"
                onClick={async () => {
                  await dispatch(downloadAdminUserPayment(row.userPaymentId));
                }}
              >
                <i className="mdi mdi-download"></i>
              </Button>
              <DefaultUncontrolledTooltip target={`PaymentHistory-${row.userPaymentId}`}>{t("Download")}</DefaultUncontrolledTooltip>
            </>
          );
        },
      },
    ],
    [t, dispatch], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Modal backdrop="static" isOpen={show} toggle={toggle} fade={true} centered={true} size="lg">
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        {t("Admin.Users.Dialog.PaymentHistory.Title")}
      </ModalHeader>
      <ModalBody>
        <DataTable
          ref={tableRef}
          busy={loading.listPaymentHistory}
          columns={columns}
          data={paymentHistoryList?.items || []}
          totalDataLength={paymentHistoryList?.totalCount}
          pagination={{
            pageIndex: query.page - 1,
            pageSize: query.pageSize,
          }}
          onPaginationChanged={(pagination) =>
            updateQuery((q) => {
              q.page = pagination.pageIndex + 1;
              q.pageSize = pagination.pageSize;
            })
          }
          hovered
        />
      </ModalBody>
      <ModalFooter>
        <div className="hstack gap-2 justify-content-end">
          <Button type="button" className="btn btn-light" onClick={toggle}>
            {t("Admin.Users.Dialog.PaymentHistory.CancelButton")}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default PaymentHistoryModal;
