import React, {useEffect, useRef} from "react";
import {Col, Container, Row} from "reactstrap";
import {useTranslation} from "react-i18next";
import {useImmer} from "use-immer";
import {useDispatch, useSelector} from "react-redux";
import {GetStatisticsQuery} from "api/query";
import {getStatistics} from "slices/thunks";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {UserStore} from "models/user_stores";
import {useDebounce} from "Components/Hooks/HelperHooks";
import {getToday} from "helpers/utilities";
import Widget from "./Widgets/Totals";
import Section from "./Widgets/Section";
import SuccessAnalysisWidget from "./Widgets/SuccessAnalysis";
import ShipmentStatistic from "./Widgets/ShipmentStatistics";
import SearchAndScan from "./Widgets/SearchAndScan";
import FinanceWidget from "./Widgets/Finance";
import SalesOverview from "./_SalesOverview";
import BestSellingProductsWidget from "./_BestSellingProducts";
import RestockInventory from "./_RestockInventory";
import SalesSummary from "./_SalesSummary";
import LatestOrders from "./_LastestOrders";
import LatestShipments from "./_LatestShipments";
import RemainingInventory from "./_RemainingInventory";
import OrdersSummary from "./_OrdersSummary";
import InviteNewSellers from "./Widgets/InviteNewSellers";
import BestSellingCategories from "./_BestSellingCategories";
import SellThis from "Components/Common/SellThis";
import SalesHistoryGraphic from "./_SalesHistory";
import DisconnectedStoreAlert from "pages/Alerts/DisconnectedStoreAlert";


const Dashboard = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const dashboardData = createSelector(
    (state: RootState) => state,
    (state) => ({
      filter: state.Dashboard.filter,
    }),
  );

  const {filter} = useSelector(dashboardData);

  const [query, updateQuery] = useImmer<GetStatisticsQuery>({
    dateRange: filter?.dateRange || 1,
    userStoreIds: filter?.stores?.map((store: UserStore) => store.userStoreId) || [],
    startDate: filter?.startDate || getToday().toDate(),
    endDate: filter?.endDate || getToday().toDate(),
    currencyCode: filter?.currency || "USD",
  });
  const prevQueryRef = useRef<GetStatisticsQuery>(query);

  const debouncedLoadList = useDebounce(() => {
    if (JSON.stringify(prevQueryRef.current) !== JSON.stringify(query)) {
      prevQueryRef.current = query;
      getStatistics(query)(dispatch);
    }
  }, 200);

  useEffect(() => {
    debouncedLoadList();
  }, [debouncedLoadList, query]);

  document.title = t("PageTitles.Dashboard");

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <Section
                  handleFilter={(f) => {
                    if (f.userStoreIds.length > 0) {
                      updateQuery(f);
                    }
                  }}
                />
                <DisconnectedStoreAlert />
                <Row>
                  <Widget />
                </Row>

                <Row className="d-flex align-items-stretch">
                  <SuccessAnalysisWidget />
                  <ShipmentStatistic />
                </Row>
                <Row>
                  <SearchAndScan />
                  <InviteNewSellers />
                  <FinanceWidget />
                </Row>
                <Row>
                  <SalesHistoryGraphic />
                  <SalesOverview />
                </Row>
                <Row>
                  <RestockInventory />
                  <BestSellingProductsWidget />
                </Row>
                <Row>
                  <LatestOrders />
                  <SalesSummary />
                </Row>
                <Row>
                  <LatestShipments />
                </Row>
                <Row>
                  <RemainingInventory />
                  <BestSellingCategories />
                  <OrdersSummary />
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <SellThis />
    </React.Fragment>
  );
};

export default Dashboard;
