import {Subscription} from "./subscription";
import {UserLimit} from "./user_limit";
import { UserSubscriptionHistory } from "./user_subscription_history";

export enum UserSubscriptionStatus {
  Trial = 0,
  Active = 1,
  CanceledAtPeriodEnd = 2,
  Canceled = 3,
  PaymentFailed = 4,
}

export interface UserSubscription {
  userSubscriptionId: string;
  subscriptionId: string;
  userId: string;
  paymentId: string;
  stripeSubscriptionId: string;
  startDate: Date;
  endDate: Date;
  billingDay: number;
  billingPeriodMonth: number;
  isActive: boolean;
  status: UserSubscriptionStatus;
  subscription: Subscription;
  limits: UserLimit[];
  history: UserSubscriptionHistory[];
}

export interface IChangeUserSubscriptionDto {
  userId: string;
  newSubscriptionId: string;
  newSubscriptionPriceId: string;
}

export interface ICreateUserSubscriptionDto {
  userId: string;
  newSubscriptionId: string;
  newSubscriptionPriceId: string;
}

export interface IDownloadInvoiceDto {
  userPaymentId: string;
}

export interface ICancelUserSubscriptionDto {
  subscriptionId: string;
  cancelAtPeriodEnd: boolean;
}
export interface IContinueUserSubscriptionDto {
  subscriptionId: string;
}

export interface IRetrySubscriptionPaymentDto {
  userSubscriptionId: string;
}

export interface IValidateCouponDto {
  couponCode: string;
}
