import DataTable, {DataTableRef} from "Components/Common/DataTable";
import Dialog, {DialogRef} from "Components/Common/Dialog";
import {CSSProperties, useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import {Badge, Button, Card, CardBody, Col, Container, Spinner} from "reactstrap";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {useTranslation} from "react-i18next";
import {ColumnDef} from "@tanstack/react-table";
import {RootState} from "slices";
import {useImmer} from "use-immer";
import {useDebounce} from "Components/Hooks/HelperHooks";
import {getScanAndSaveStatusDescription, ScanAndSave, ScanAndSaveStatus} from "models/scan_and_save";
import {exportAsins} from "slices/scan-and-save/thunk";
import {cancelScan, getAdminScanAndSaveList, resetAdminScanAndSaveState, restartScan, undoDeleteScan} from "slices/admin/scanAndSave/thunk";
import {AdminScanAndSaveQuery} from "api/query";
import {formatDate} from "helpers/utilities";
import {useProfile} from "Components/Hooks/UserHooks";
import {ConstantPage} from "helpers/permission_helper";
import BreadCrumb from "Components/Common/BreadCrumb";
import Filters from "./Filters";
import Loader from "Components/Common/Loader";
import Restricted from "Components/Common/Restricted";
import Unauthorized from "pages/Errors/_Unauthorized";
import NoResult from "Components/Common/NoResult";
import defaultAvatar from "assets/images/user-dummy-img.jpg";
import SendAnalysis from "./Modals/SendAnalysis";
import RenderNumber from "Components/Common/RenderNumber";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import DisplayDate from "Components/Common/DisplayDate";

export type AdminScanAndSaveListRef = {
  reload: VoidFunction;
};
interface AdminScanAndSavePageProps {
  edit?: boolean;
}
const PAGE_IDENTIFIER: ConstantPage = "adminScanAndSave";
const AdminScanAndSavePage = (props: AdminScanAndSavePageProps) => {
  const {t} = useTranslation();
  const {userProfile, hasPermission} = useProfile();
  const [selectedScan, setSelectedScan] = useState<ScanAndSave>();
  const [isSendingAnalysis, setIsSendingAnalysis] = useState(false);
  const restartDialogRef = useRef<DialogRef>(null);
  const cancelDialogRef = useRef<DialogRef>(null);
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const tableRef = useRef<DataTableRef>(null);
  const listRef = useRef<AdminScanAndSaveListRef>(null);
  const undoDeleteDialogRef = useRef<DialogRef>(null);

  const [query, updateQuery] = useImmer<AdminScanAndSaveQuery>({
    filtering: true,
    startDate: undefined,
    endDate: undefined,
    page: 1,
    pageSize: 10,
  });

  const debouncedLoadList = useDebounce(() => {
    if (hasPermission(PAGE_IDENTIFIER)) {
      getAdminScanAndSaveList(query)(dispatch).then(() => {
        tableRef.current?.resetSelection();
      });
    }
  }, 200);

  useEffect(() => {
    if (query.startDate !== undefined && query.endDate !== undefined) {
      navigate(`/admin/scan-and-save?start=${formatDate(query.startDate)}&end=${formatDate(query.endDate, true)}`, {replace: true});
      debouncedLoadList();
    }
  }, [debouncedLoadList, query]); // eslint-disable-line

  useImperativeHandle(
    listRef,
    () => {
      return {
        reload: () => {
          debouncedLoadList();
        },
      };
    },
    [debouncedLoadList],
  );

  const scanAndSaveData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.AdminScanAndSave.loading,
      list: state.AdminScanAndSave.scanAndSaveList,
    }),
  );

  const {loading, list} = useSelector(scanAndSaveData);

  const columns = useMemo<ColumnDef<ScanAndSave, any>[]>(
    () => [
      {
        header: t("Actions"),
        size: 50,
        cell: (cellProps) => {
          const row = cellProps.row.original as ScanAndSave;
          const disableCancelStyle: CSSProperties = row.status === ScanAndSaveStatus.CANCELED || row.status === ScanAndSaveStatus.DONE ? {pointerEvents: "none", color: "#dedede"} : {};
          const disableRestartStyle: CSSProperties = row.status !== ScanAndSaveStatus.CANCELED && row.status !== ScanAndSaveStatus.DONE ? {pointerEvents: "none", color: "#dedede"} : {};
          return (
            <>
              <div className="d-flex flex-row align-items-center">
                <Col xs="auto">
                  <Link
                    to=""
                    id={`RestartScan-${row.id}`}
                    className="btn btn-ghost-success px-1 py-0 fs-18"
                    onClick={() => {
                      setSelectedScan(row);
                      restartDialogRef.current?.show();
                    }}
                    style={disableRestartStyle}
                  >
                    <i className="mdi mdi-sync-circle"></i>
                  </Link>
                  <DefaultUncontrolledTooltip target={`RestartScan-${row.id}`}>{t("Admin.ScanAndSave.Tooltip.Restart")}</DefaultUncontrolledTooltip>
                </Col>
                <Col xs="auto">
                  <Link
                    to=""
                    id={`CancelScan-${row.id}`}
                    className="btn btn-ghost-danger px-1 py-0 fs-18"
                    onClick={() => {
                      setSelectedScan(row);
                      cancelDialogRef.current?.show();
                    }}
                    style={disableCancelStyle}
                  >
                    <i className="mdi mdi-close-circle"></i>
                  </Link>
                  <DefaultUncontrolledTooltip target={`CancelScan-${row.id}`}>{t("Admin.ScanAndSave.Tooltip.Cancel")}</DefaultUncontrolledTooltip>
                </Col>
              </div>
            </>
          );
        },
      },
      {
        header: t("Admin.ScanAndSave.TableColumn.User"),
        accessorFn: (row) => row.user.firstName,
        sortingFn: "alphanumeric",
        size: 300,
        cell: (cellProps) => {
          const row = cellProps.row.original as ScanAndSave;
          return (
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 me-2">
                <img src={row?.avatarUrl || defaultAvatar} className="rounded-circle avatar-sm img-thumbnail user-profile-image" alt="user-profile" />
              </div>
              <div className="flex-grow-1 ellipsis-two-lines">
                <div className="fw-semibold">
                  {row?.user?.fullName}
                  {row?.user?.userId === userProfile?.userId && <Badge className="ms-1">{t("You")}</Badge>}
                </div>
                <div className="text-muted" title={row?.user?.email}>
                  {row?.user?.email}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        header: t("Admin.ScanAndSave.TableColumn.ScanName"),
        accessorKey: "searchName",
        sortingFn: "alphanumeric",
        size: 200,
        cell: (cellProps) => {
          const row = cellProps.row.original as ScanAndSave;
          return (
            <>
              <div className="ellipsis-two-lines" title={row.searchName}>
                {row.searchName}
              </div>
            </>
          );
        },
      },
      {
        header: t("Admin.ScanAndSave.TableColumn.Url"),
        size: 400,
        cell: (cellProps) => {
          const row = cellProps.row.original as ScanAndSave;
          return (
            <>
              <div className="ellipsis-two-lines" title={row.url} style={{maxWidth: 250}}>
                <Link to={row.url} className="link-secondary link-offset-2 text-decoration-underline" target="_blank">
                  {row.url}
                  <i className="ri-arrow-right-up-line"></i>
                </Link>
              </div>
            </>
          );
        },
      },
      {
        header: t("Admin.ScanAndSave.TableColumn.CreateDate"),
        size: 150,
        accessorKey: "createDate",
        sortingFn: "datetime",
        cell: (cellProps) => {
          const row = cellProps.row.original as ScanAndSave;
          return (
            <>
              <DisplayDate id={`DisplayDateScanAndSaveCreateDate-${row.id}`} value={row.createDate} format="D MMM YYYY HH:mm" tz={userProfile?.timezone} />
            </>
          );
        },
      },
      {
        header: t("Admin.ScanAndSave.TableColumn.Status"),
        accessorFn: (row) => row.status,
        sortingFn: "alphanumericCaseSensitive",
        size: 150,
        cell: (cellProps) => {
          const row = cellProps.row.original as ScanAndSave;
          let cssClass = "";

          switch (row.status) {
            case ScanAndSaveStatus.DONE:
              cssClass = "bg-success-subtle text-success";
              break;
            case ScanAndSaveStatus.PLANNED:
              cssClass = "bg-info-subtle text-info";
              break;
            case ScanAndSaveStatus.WORKING:
              cssClass = "bg-info-subtle text-info";
              break;
            case ScanAndSaveStatus.WAITING:
              cssClass = "bg-warning-subtle text-warning";
              break;
            case ScanAndSaveStatus.UNDEFINED:
              cssClass = "bg-secondary-subtle text-secondary";
              break;
            case ScanAndSaveStatus.CANCELED:
              cssClass = "bg-danger-subtle text-danger";
              break;
            default:
              cssClass = "";
              break;
          }
          return (
            <div className="d-fle align-items-center">
              {row.deleted ? (
                <>
                  <span className={`float-start badge bg-danger-subtle text-danger fs-12`}>{t("Deleted")}</span>
                  <Button
                    type="button"
                    id={`UndoDelete-${row.id}`}
                    className="btn btn-ghost-secondary waves-effect py-0 px-1 fs-16"
                    onClick={() => {
                      setSelectedScan(row);
                      undoDeleteDialogRef.current?.show();
                    }}
                  >
                    <i className="mdi mdi-undo-variant"></i>
                  </Button>
                  <DefaultUncontrolledTooltip target={`UndoDelete-${row.id}`}>{t("Admin.ScanAndSave.Tooltip.UndoDelete")}</DefaultUncontrolledTooltip>
                </>
              ) : (
                <span className={`float-start badge ${cssClass} fs-12`}>{t(getScanAndSaveStatusDescription(row.status))}</span>
              )}
            </div>
          );
        },
      },
      {
        header: t("Admin.ScanAndSave.TableColumn.Total"),
        accessorKey: "total",
        sortingFn: "alphanumeric",
        size: 90,
        cell: (cellProps) => {
          const row = cellProps.row.original as ScanAndSave;
          return <RenderNumber value={row.total} />;
        },
      },
      {
        header: t("Admin.ScanAndSave.TableColumn.Sent"),
        accessorKey: "sent",
        sortingFn: "alphanumeric",
        size: 90,
        cell: (cellProps) => {
          const row = cellProps.row.original as ScanAndSave;
          return <RenderNumber value={row.sent} />;
        },
      },
      {
        header: " ",
        size: 50,
        enableResizing: false,
        cell: (cellProps) => {
          const row = cellProps.row.original as ScanAndSave;
          const [isExporting, setIsExporting] = useState(false); // eslint-disable-line
          return (
            <div className="d-flex align-items-center">
              <Link
                to=""
                id={`Analyze-${row.id}`}
                className={`btn btn-ghost-secondary px-1 py-0 fs-18 ${row.total === 0 || row.total === row.sent ? "disabled" : ""}`}
                onClick={() => {
                  setSelectedScan(row);
                  setIsSendingAnalysis(true);
                }}
              >
                <i className="mdi mdi-chart-timeline-variant-shimmer"></i>
              </Link>
              <DefaultUncontrolledTooltip target={`Analyze-${row.id}`}>{t("Admin.ScanAndSave.Button.Analyze")}</DefaultUncontrolledTooltip>

              <Link
                to=""
                id={`DownloadScan-${row.id}`}
                className={`btn btn-ghost-secondary px-1 py-0 fs-18 ${row.total === 0 || isExporting ? "disabled" : ""}`}
                onClick={() => {
                  setIsExporting(true);
                  const exportPromise = exportAsins({asinFetcherTaskId: row.id}, row.searchName)(dispatch);
                  exportPromise.then(() => {
                    setIsExporting(false);
                  });
                }}
              >
                {isExporting ? <Spinner size="sm"></Spinner> : <i className="mdi mdi-cloud-download"></i>}
              </Link>
              <DefaultUncontrolledTooltip target={`DownloadScan-${row.id}`}>{t("Admin.ScanAndSave.Button.Download")}</DefaultUncontrolledTooltip>
            </div>
          );
        },
      },
    ],
    [t], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    return () => {
      dispatch(resetAdminScanAndSaveState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  document.title = t("PageTitles.ScanAndSave");

  //#endregion
  return (
    <Restricted require={PAGE_IDENTIFIER} read fallback={() => <Unauthorized />}>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Admin.ScanAndSave.Title")} menus={[{label: t("Admin.ScanAndSave.Title")}]} />
          <Filters handleFilter={(f) => updateQuery(f)} busy={loading.filter} page={query.page} pageSize={query.pageSize} listRef={listRef} />
          <Card>
            <CardBody>
              {loading.filter ? (
                <>
                  <Loader />
                </>
              ) : list.items && list.items.length > 0 ? (
                <>
                  <DataTable
                    ref={tableRef}
                    busy={loading.list}
                    columns={columns}
                    data={list?.items || []}
                    totalDataLength={list?.totalCount}
                    pagination={{
                      pageIndex: query.page - 1,
                      pageSize: query.pageSize,
                    }}
                    onPaginationChanged={(pagination) =>
                      updateQuery((q) => {
                        q.page = pagination.pageIndex + 1;
                        q.pageSize = pagination.pageSize;
                        q.filtering = false;
                      })
                    }
                    hovered
                  />
                </>
              ) : (
                <NoResult title={t("Admin.ScanAndSave.NoResult.Title")} description={t("Admin.ScanAndSave.NoResult.Description")} />
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
      <SendAnalysis isOpen={isSendingAnalysis} selectedScan={selectedScan!} toggle={() => setIsSendingAnalysis(!isSendingAnalysis)} listRef={listRef} />
      <Dialog
        ref={restartDialogRef}
        color="success"
        buttons={["yes", "no"]}
        busy={loading.restart}
        iconClass="ri-restart-line"
        message={t("Admin.ScanAndSave.Dialog.Restart.Description")}
        title={t("Admin.ScanAndSave.Dialog.Restart.Title")}
        onButtonClick={async (button, hide) => {
          if (button === "yes") {
            if (selectedScan) {
              await restartScan(selectedScan)(dispatch);
            }
          }
          restartDialogRef.current?.hide();
        }}
      />
      <Dialog
        ref={cancelDialogRef}
        color="danger"
        buttons={["yes", "no"]}
        busy={loading.cancel}
        iconClass="ri-close-circle-line"
        message={t("Admin.ScanAndSave.Dialog.Cancel.Description")}
        title={t("Admin.ScanAndSave.Dialog.Cancel.Title")}
        onButtonClick={async (button, hide) => {
          if (button === "yes") {
            if (selectedScan) {
              await cancelScan(selectedScan)(dispatch);
            }
          }
          cancelDialogRef.current?.hide();
        }}
      />

      <Dialog
        ref={undoDeleteDialogRef}
        color="primary"
        buttons={["yes", "no"]}
        busy={loading.cancel}
        iconClass="mdi mdi-undo-variant"
        message={t("Admin.ScanAndSave.Dialog.UndoDelete.Description")}
        title={t("Admin.ScanAndSave.Dialog.UndoDelete.Title")}
        onButtonClick={async (button, hide) => {
          if (button === "yes") {
            if (selectedScan) {
              await undoDeleteScan(selectedScan)(dispatch);
              listRef.current?.reload();
            }
          }
          undoDeleteDialogRef.current?.hide();
        }}
      />
    </Restricted>
  );
};

export default AdminScanAndSavePage;
