import DataTable, {DataTableRef} from "Components/Common/DataTable";
import Dialog, {DialogRef} from "Components/Common/Dialog";
import {useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {Modal, ModalHeader, ModalBody, Col, Row, Input, Button, ModalFooter, Form} from "reactstrap";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {ISaveUserFavoriteListDto, UserFavorite} from "models/user_favorites";
import {RootState} from "slices";
import {Link, useNavigate} from "react-router-dom";
import {ColumnDef} from "@tanstack/react-table";
import {useDebounce} from "Components/Hooks/HelperHooks";
import {useProfile} from "Components/Hooks/UserHooks";
import {ConstantPage} from "helpers/permission_helper";
import {preventScrollUp} from "helpers/utilities";
import {changeCurrentFavoriteList, getUserFavoriteList, addFavoriteItem, createUserFavoriteList, deleteUserFavoriteList} from "slices/search-result/thunk";
import {UserSearchProduct} from "models/user_search_product";
import {removeFavItem} from "slices/search-result/reducer";
import AmazonMarketplaceInfos from "Components/Common/AmazonMarketplaceInfos";
import RenameFavoriteList from "./RenameFavoriteList";
import ValidatedInput from "Components/Common/ValidatedInput";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import * as Yup from "yup";
import PlaceholderGlow from "Components/Common/PlaceholderGlow";

interface AddToFavoritesProps {
  isOpen: boolean;
}

const PAGE_IDENTIFIER: ConstantPage = "favorites";
const AddToFavorites = ({isOpen}: AddToFavoritesProps) => {
  const {t} = useTranslation();
  const [product, setProduct] = useState<UserSearchProduct>();
  const [showCreateList, setShowCreateList] = useState<boolean>(false);
  const [amazonMarketplaceInfos] = useState(AmazonMarketplaceInfos());
  const [renameModalVisibility, setRenameModalVisibility] = useState<boolean>(false);
  const [selectedFavoriteList, setSelectedFavoriteList] = useState<UserFavorite>();
  const {hasPermission} = useProfile();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const deleteDialogRef = useRef<DialogRef>(null);

  const searchResultsData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.SearchResult.loading,
      currentFavoriteList: state.SearchResult.currentFavoriteList,
      favoriteList: state.SearchResult.favoriteList,
      pageUrl: state.SearchResult.pageUrl,
      result: state.SearchResult.result,
    }),
  );

  const {favoriteList, loading, result, currentFavoriteList, pageUrl} = useSelector(searchResultsData);

  const initModal = useCallback(() => {
    if (isOpen && pageUrl) {
      const queryParams = new URLSearchParams(window.location.search);
      const asin = queryParams.get("asin");

      const product = result.products?.items?.find((x) => x.asin === asin);
      if (product) {
        setProduct(product);
      } else {
        navigate(pageUrl);
      }
    }
  }, [isOpen, pageUrl]); // eslint-disable-line react-hooks/exhaustive-deps

  const tableRef = useRef<DataTableRef>(null);
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {listName: ""},
    validationSchema: Yup.object().shape({
      listName: Yup.string()
        .required(t("Favorites.Validation.ListName"))
        .max(50, t("Favorites.Validation.ListNameMaxLength")),
    }),
    onSubmit: (values) => {
      let listInfo: ISaveUserFavoriteListDto = {
        favoriteListName: values.listName,
        marketplace: product?.marketplaceTarget!,
      };

      const createPromise = createUserFavoriteList(listInfo)(dispatch);
      createPromise.then((success) => {
        if (success) {
          setShowCreateList(false);
          validation.resetForm();
        }
      });
    },
  });

  const debouncedLoadList = useDebounce(() => {
    getUserFavoriteList({
      page: 1,
      pageSize: 999,
    })(dispatch).then(() => {
      tableRef.current?.resetSelection();
    });
  }, 200);

  useEffect(() => {
    if (isOpen && hasPermission(PAGE_IDENTIFIER)) {
      debouncedLoadList();
    }
  }, [isOpen, debouncedLoadList]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleListSelection = async (userFavorite: UserFavorite) => {
    setShowCreateList(false);
    dispatch(
      removeFavItem({
        userFavoriteId: userFavorite.userFavoriteId,
        asin: product?.asin,
        marketplace: product?.marketplaceTarget,
      }),
    );
    changeCurrentFavoriteList(userFavorite)(dispatch);
    await addFavoriteItem(userFavorite.userFavoriteId, product!.asin, product!.marketplaceTarget, true, navigate)(dispatch);
    toggle();
  };

  const toggle = () => {
    preventScrollUp();
    navigate(pageUrl);
    validation.resetForm();
    setShowCreateList(false);
  };

  useEffect(() => {
    initModal();
  }, [initModal]);

  const columns = useMemo<ColumnDef<UserFavorite, any>[]>(
    () => [
      {
        header: t("SearchResults.Dialog.AddToFavorites.TableColumn.Select"),
        size: 50,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserFavorite;
          return (
            <>
              <div className="align-items-center w-100">
                <div className="form-check form-radio-inline form-radio-success">
                  <Input
                    className="form-check-input"
                    type="radio"
                    name={"radioButton" + row.userFavoriteId}
                    id={"radioButton" + row.userFavoriteId}
                    onChange={() => handleListSelection(row)}
                    checked={currentFavoriteList?.userFavoriteId === row.userFavoriteId}
                  />
                </div>
              </div>
            </>
          );
        },
      },
      {
        accessorKey: "name",
        header: t("SearchResults.Dialog.AddToFavorites.TableColumn.ListName"),
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserFavorite;
          return <>{row.name}</>;
        },
      },
      {
        accessorKey: "marketplace",
        header: t("SearchResults.Dialog.AddToFavorites.TableColumn.Marketplace"),
        size: 150,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserFavorite;
          const marketplaceInfo = amazonMarketplaceInfos.find((marketplaceInfo) => marketplaceInfo.marketplace === row.marketplace);
          return (
            <>
              {marketplaceInfo && (
                <span>
                  <span className="hstack gap-2">
                    <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                      <img src={marketplaceInfo.flag} alt="Country Flag" className="rounded-circle" />
                    </div>
                    {`${marketplaceInfo.countryName} (${marketplaceInfo.marketplace})`}
                  </span>
                </span>
              )}
            </>
          );
        },
      },
      {
        header: t("Actions"),
        size: 50,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserFavorite;
          return (
            <div className="d-flex">
              <Button
                id={`Edit-${row.userFavoriteId}`}
                className="btn btn-ghost-info px-1 py-0 fs-16"
                onClick={() => {
                  setSelectedFavoriteList(row);
                  setRenameModalVisibility(true);
                }}
              >
                <i className="ri-pencil-fill"></i>
              </Button>
              <DefaultUncontrolledTooltip target={`Edit-${row.userFavoriteId}`}>{t("SearchResults.Dialog.AddToFavorites.Tooltip.Edit")}</DefaultUncontrolledTooltip>

              <Link
                id={`Delete-${row.userFavoriteId}`}
                to=""
                className="btn btn-ghost-danger px-1 py-0 fs-16"
                onClick={() => {
                  setSelectedFavoriteList(row);
                  deleteDialogRef.current?.show();
                }}
              >
                <i className="ri-delete-bin-fill"></i>
              </Link>
              <DefaultUncontrolledTooltip target={`Delete-${row.userFavoriteId}`}>{t("SearchResults.Dialog.AddToFavorites.Tooltip.Delete")}</DefaultUncontrolledTooltip>
            </div>
          );
        },
      },
    ],
    [t, product], // eslint-disable-line react-hooks/exhaustive-deps
  );
  return (
    <>
      <Modal backdrop="static" isOpen={isOpen} toggle={toggle} fade={true} centered={true} size="lg">
        <PlaceholderGlow busy={loading.listFavorite || loading.update}>
          <>
            <ModalHeader className="bg-light p-3" toggle={toggle}>
              {t("SearchResults.Dialog.AddToFavorites.Title")}
            </ModalHeader>
            <ModalBody>
              <RenameFavoriteList isOpen={renameModalVisibility} toggle={() => setRenameModalVisibility(!renameModalVisibility)} selectedFavoriteList={selectedFavoriteList} />
              <Row>
                <Col>
                  <h5>{t("SearchResults.Dialog.AddToFavorites.Subtitle")}</h5>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <DataTable
                    ref={tableRef}
                    columns={columns}
                    data={favoriteList.items || []}
                    totalDataLength={favoriteList.totalCount}
                    thClass="text-black"
                    busy={loading.listFavorite}
                    renderOnEmpty={() => <>{t("SearchResults.Dialog.AddToFavorites.NoResult", {value: product?.marketplaceTarget})}</>}
                    hovered
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <Button type="button" className="btn btn-secondary add-btn mt-2" onClick={() => setShowCreateList(true)}>
                    <i className="ri-add-line align-bottom me-1"></i>
                    {t("SearchResults.Dialog.AddToFavorites.Button.NewList")}
                  </Button>
                </Col>
              </Row>
              {showCreateList && (
                <Row className="mt-4 ">
                  <Col sm={4} className="mb-2">
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <span>
                        <ValidatedInput validation={validation} field={"listName"} maxLength={100} placeholder={t("SearchResults.Dialog.AddToFavorites.ListName")} disableValidationUI />
                      </span>
                    </Form>
                  </Col>
                  <Col>
                    <Button
                      type="submit"
                      className="btn btn-success"
                      disabled={loading.save}
                      onClick={() => {
                        if (validation.isValid) {
                          validation.handleSubmit();
                        }
                      }}
                    >
                      {t("SearchResults.Dialog.AddToFavorites.Button.Create")}
                    </Button>
                  </Col>
                </Row>
              )}
            </ModalBody>
            <ModalFooter>
              <div className="hstack gap-2 justify-content-end">
                <Button type="button" className="btn btn-light" onClick={toggle}>
                  {t("SearchResults.Dialog.AddToFavorites.Button.Close")}
                </Button>
              </div>
            </ModalFooter>
          </>
        </PlaceholderGlow>
      </Modal>
      <Dialog
        ref={deleteDialogRef}
        color="danger"
        buttons={["yes", "no"]}
        busy={loading.delete}
        iconClass="ri-delete-bin-line"
        message={t("SearchResults.Dialog.AddToFavorites.Dialog.Delete.Description")}
        title={t("SearchResults.Dialog.AddToFavorites.Dialog.Delete.Title")}
        onButtonClick={async (button, hide) => {
          if (button === "yes") {
            if (selectedFavoriteList) {
              await deleteUserFavoriteList(selectedFavoriteList)(dispatch);
            }
          }
          deleteDialogRef.current?.hide();
        }}
      />
    </>
  );
};

export default AddToFavorites;
