import Dialog, {DialogRef} from "Components/Common/Dialog";
import {SelectOptionsType} from "Components/Hooks/SelectOptions";
import {Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, FormFeedback, Col, Row, Spinner, Label, Card, Form} from "reactstrap";
import {useFormik} from "formik";
import {useProfile} from "Components/Hooks/UserHooks";
import {Link, useNavigate} from "react-router-dom";
import {RefObject, useEffect, useRef, useState} from "react";
import {read, utils} from "xlsx";
import {registerPlugin} from "react-filepond";
import {useTranslation} from "react-i18next";
import {ISaveUserSearchRequest} from "models/user_search";
import {saveNewSearch} from "slices/searches/thunk";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {SearchesListRef} from "..";
import {formatBytes, getRelativeDate, getToday, preventScrollUp} from "helpers/utilities";
import Dropzone from "react-dropzone";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import ValidatedLineNumberedTextarea from "Components/Common/ValidatedLineNumberedTextarea";
import ValidatedSelect from "Components/Common/ValidatedSelect";
import ValidatedInput from "Components/Common/ValidatedInput";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import * as Yup from "yup";
import RenderNumber from "Components/Common/RenderNumber";
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const DAILY_SEARCH_LIMIT = 5000;
interface NewSearchProps {
  isOpen: boolean;
  listRef: RefObject<SearchesListRef>;
  busy: boolean;
  userStoresSelectOptions: SelectOptionsType[];
}
const NewSearch = (props: NewSearchProps) => {
  const {t} = useTranslation();
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const exceedLimitConfirmationDialog = useRef<DialogRef>(null);
  const [searchType, setSearchType] = useState("asinList");
  const [isValidFile, setIsValidFile] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const {getDailySearchLimit} = useProfile();
  const [limitExceeding, setLimitExceeding] = useState<boolean>(false);
  const [maxLineLimit, setMaxLineLimit] = useState<number>(DAILY_SEARCH_LIMIT);
  const [liveLimit, setLiveLimit] = useState<number>(getDailySearchLimit());
  const startDate = getRelativeDate(30);

  const searchesData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Searches.loading,
    }),
  );
  const {loading} = useSelector(searchesData);

  const validation = useFormik({
    initialValues: {
      searchName: "",
      marketplace: null,
      asinList: "",
      startDate: startDate,
      endDate: getToday().toDate(),
    },
    validationSchema: Yup.object().shape({
      searchName: Yup.string()
        .required(t("Searches.Dialog.NewSearch.Validation.Name"))
        .max(50, t("Searches.Dialog.NewSearch.Validation.NameMaxLength")),
      marketplace: Yup.string().required(t("Searches.Dialog.NewSearch.Validation.Marketplace")),
      asinList: Yup.string().test("invalid-asin-list", function(value) {
        if (searchType === "asinList") {
          if (!value || !value.trim()) {
            return this.createError({
              path: "asinList",
              message: t("Searches.Dialog.NewSearch.Validation.Asin"),
            });
          }

          let asinList = value!.split("\n");
          let asinCount = 0;

          asinList.forEach((asin: any) => {
            let setAsin = asin.trim().toUpperCase();

            if (setAsin.length > 0) {
              // const isAlphanumeric = /^[A-Z0-9]+$/.test(setAsin);
              // const startsWithLetter = /^[A-Z]/.test(setAsin);

              asinCount = asinCount + 1;
              // isValid =
              //     setAsin.length === 10 &&
              //     isAlphanumeric &&
              //     startsWithLetter;
            }
          });

          if (asinCount > DAILY_SEARCH_LIMIT) {
            return this.createError({
              path: "asinList",
              message: t("Searches.Dialog.NewSearch.Validation.AsinMax"),
            });
          } else if (asinCount === 0) {
            return this.createError({
              path: "asinList",
              message: t("Searches.Dialog.NewSearch.Validation.Asin"),
            });
          }

          return true;
        } else {
          return true;
        }
      }),
      categoryPageList: Yup.string().test("invalid-category-page-list", function(value) {
        if (searchType === "categoryPageList") {
          if (!value || !value.trim()) {
            return this.createError({
              path: "categoryPageList",
              message: t("Searches.Dialog.NewSearch.Validation.CategoryPage"),
            });
          }

          let categoryPageList = value!.split("\n");
          let urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
          let categoryPageCount = 0;
          let isValid = true;

          categoryPageList.forEach((categoryPage: any) => {
            if (categoryPage.trim().length > 0) {
              categoryPageCount = categoryPageCount + 1;
              isValid = urlPattern.test(categoryPage.trim()) && categoryPage.trim().includes("amazon");
            }
          });

          if (!isValid) {
            return this.createError({
              path: "categoryPageList",
              message: t("Searches.Dialog.NewSearch.Validation.CategoryPageInvalid"),
            });
          }

          if (categoryPageCount > 10) {
            return this.createError({
              path: "categoryPageList",
              message: t("Searches.Dialog.NewSearch.Validation.CategoryPageMax"),
            });
          } else if (categoryPageCount === 0) {
            return this.createError({
              path: "categoryPageList",
              message: t("Searches.Dialog.NewSearch.Validation.CategoryPage"),
            });
          }

          return true;
        } else {
          return true;
        }
      }),
      uploadedAsinList: Yup.string().test("invalid-uploaded-asin-list", t("Searches.Dialog.NewSearch.Validation.AsinUploaded"), (value) => {
        if (searchType === "uploadFile") {
          if (!value || !value.trim()) {
            return false;
          }

          return true;
        } else {
          return true;
        }
      }),
    }),
    onSubmit: (values: any) => {
      const searchParameters: ISaveUserSearchRequest = {
        searchName: values.searchName,
        marketplace: values.marketplace,
      };

      searchParameters.searchName = values.searchName;
      searchParameters.marketplace = values.marketplace;

      if (searchType === "asinList") {
        searchParameters.asinList = values.asinList
          .toUpperCase()
          .split("\n")
          .map((item: any) => item.replace(/\r/g, ""));
        searchParameters.categoryPageList = [];
      } else if (searchType === "categoryPageList") {
        searchParameters.categoryPageList = values.categoryPageList.split("\n");
        searchParameters.asinList = [];
      } else {
        if (limitExceeding) {
          searchParameters.asinList = values.uploadedAsinList
            .toUpperCase()
            .split(",")
            .slice(0, getDailySearchLimit());
        } else {
          searchParameters.asinList = values.uploadedAsinList.toUpperCase().split(",");
        }
        searchParameters.categoryPageList = [];
      }
      
      if(searchParameters.asinList?.length === 1) {
        navigate(`/dp/${searchParameters.asinList[0]}?marketplace=${searchParameters.marketplace}`);
        return;
      }
      const savePromise = saveNewSearch(searchParameters)(dispatch);
      savePromise.then((isSuccess) => {
        if (isSuccess) {
          toggle();
          props.listRef.current?.reload();
          validation.resetForm();
        }
      });
    },
  });

  const toggle = () => {
    //setIsOpen(!isOpen);
    navigate("/searches");

    preventScrollUp();
    
    validation.resetForm();
    setSelectedFiles([]);
    setIsValidFile(false);
    setSearchType("asinList");
  };

  useEffect(() => {
    let listLength = validation.values.asinList.split("\n").length;
    listLength = validation.values.asinList === "" ? 0 : listLength;
    var remaining = getDailySearchLimit() - listLength;

    if (listLength === 0) {
      setLiveLimit(getDailySearchLimit());
    }
    if (getDailySearchLimit() > DAILY_SEARCH_LIMIT) {
      setLiveLimit(remaining);
      setMaxLineLimit(DAILY_SEARCH_LIMIT);
    } else {
      setMaxLineLimit(remaining+1);
      setLiveLimit(remaining);
    }
  }, [validation.values.asinList]); // eslint-disable-line react-hooks/exhaustive-deps

  function handleAcceptedFiles(files: any) {
    files.map((file: any) => {
      switch (file.type) {
        case "text/csv":
        case "application/vnd.ms-excel":
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          if (file) {
            let isValid = true;
            const reader = new FileReader();

            reader.onload = (e) => {
              if (e.target) {
                const data = e.target.result;
                const workbook = read(data, {
                  type: "binary",
                });
                const firstSheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[firstSheetName];

                // Now you can work with the worksheet data
                const excelData = utils.sheet_to_json(worksheet, {header: 1});

                const asinList = excelData.map((cell: any) => cell[0].toUpperCase());

                if (asinList.length === 0) {
                  setIsValidFile(false);
                  validation.setFieldError("uploadedAsinList", t("Searches.Dialog.NewSearch.Validation.AsinUploaded"));
                } else {
                  let asinCount = 0;

                  asinList.forEach((asin: any) => {
                    let setAsin = asin.trim();

                    if (setAsin.length > 0) {
                      asinCount = asinCount + 1;
                    }
                  });

                  if (asinCount > DAILY_SEARCH_LIMIT) {
                    isValid = false;
                    setIsValidFile(false);
                    validation.setFieldError("uploadedAsinList", t("Searches.Dialog.NewSearch.Validation.AsinMaxLimitUploaded"));
                  }

                  if (asinCount === 0) {
                    isValid = false;
                    setIsValidFile(false);
                    validation.setFieldError("uploadedAsinList", t("Searches.Dialog.NewSearch.Validation.AsinUploaded"));
                  }

                  if (getDailySearchLimit() === 0) {
                    isValid = false;
                    setIsValidFile(false);
                    validation.setFieldError("uploadedAsinList", t("Searches.Dialog.NewSearch.Validation.InsufficientLimit"));
                  } else if (asinCount > getDailySearchLimit()) {
                    setLimitExceeding(true);
                    exceedLimitConfirmationDialog.current?.show();
                  }
                }

                if (isValid) {
                  setIsValidFile(true);
                  validation.setFieldValue("uploadedAsinList", asinList.toString());
                }
              }
            };

            reader.readAsBinaryString(file);

            if (isValid) {
              Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
              });
              setSelectedFiles(files);
            }
          }

          break;
        default:
          setSelectedFiles([]);
          setIsValidFile(false);
          validation.setFieldError("uploadedAsinList", t("Searches.Dialog.NewSearch.Validation.InvalidFile"));
          break;
      }

      return true;
    });
  }

  function handleSearchType(event: any) {
    validation.validateField("categoryPageList");
    setSearchType(event.target.value);
  }
  return (
    <>
      <Modal id="showNewSearchModal" className="modal-xl" isOpen={props.isOpen} toggle={toggle} centered={true}>
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          {t("Searches.Dialog.NewSearch.Title")}
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row className="g-3">
              <Col xs={12} lg={3}>
                <ValidatedInput validation={validation} type="text" field="searchName" maxLength={100} placeholder={t("Searches.Dialog.NewSearch.Search")} disableValidationUI />
              </Col>
              <Col xs={12} lg={3}>
                <ValidatedSelect
                  className={`new-search user-input w-100 ${validation.errors.marketplace && validation.touched.marketplace && "form-control is-invalid form-select-invalid"}`}
                  errorStyle="container"
                  options={props.userStoresSelectOptions}
                  validation={validation}
                  field="marketplace"
                  value={validation.values.marketplace}
                />
              </Col>
              <Col xs={12} lg={6}>
                <div className="d-flex justify-content-end">
                  {t("Searches.Dialog.NewSearch.RemainingUsageLimit")}
                  <span className="text-danger ms-1 fw-semibold">{<RenderNumber value={liveLimit} />}</span>
                </div>
              </Col>
            </Row>
            <Row className="align-items-center mt-3">
              <Col className="new-search check-col-first">
                <div className="form-check form-radio-outline form-radio-success mb-3">
                  <Input className="form-check-input" type="radio" name="asinListChecker" id="asinListChecker" value="asinList" checked={searchType === "asinList"} onChange={handleSearchType} />
                  <Label className="form-check-label" for="asinListChecker">
                    {t("Searches.Dialog.NewSearch.AsinList")}
                  </Label>
                </div>
              </Col>
              <Col className="new-search check-col-others">
                <div className="form-check form-radio-outline form-radio-success mb-3">
                  <Input
                    className="form-check-input"
                    type="radio"
                    name="uploadFileChecker"
                    id="uploadFileChecker"
                    value="uploadFile"
                    checked={searchType === "uploadFile"}
                    onChange={handleSearchType}
                  />
                  <Label className="form-check-label" for="uploadFileChecker">
                    {t("Searches.Dialog.NewSearch.ExcelUpload")}
                  </Label>
                </div>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col>
                {searchType === "asinList" && (
                  <>
                    {validation.touched.asinList && validation.errors.asinList ? (
                      <FormFeedback type="invalid" className="new-search validation-width w-100">
                        {validation.errors.asinList.toString()} <br />
                      </FormFeedback>
                    ) : null}
                    <ValidatedLineNumberedTextarea validation={validation} field="asinList" placeholder={t("Searches.Dialog.NewSearch.AddAsinInfo")} maxLines={maxLineLimit} />
                  </>
                )}
                {searchType === "categoryPageList" && (
                  <>
                    <textarea
                      className={"form-control form-control" + (validation.touched.categoryPageList && validation.errors.categoryPageList ? " is-invalid" : "")}
                      id="categoryPageList"
                      placeholder={t("Searches.Dialog.NewSearch.AddCategoryInfo")}
                      rows={10}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.categoryPageList}
                    ></textarea>
                    {validation.touched.categoryPageList && validation.errors.categoryPageList ? (
                      <FormFeedback type="invalid" className="new-search validation-width w-100">
                        {validation.errors.categoryPageList.toString()}
                      </FormFeedback>
                    ) : null}
                  </>
                )}
                {searchType === "uploadFile" && (
                  <div id="uploadFile">
                    <div className="hstack justify-content-end">
                      <Link to={`/SearchTemplate.xlsx`} target="_blank" className="ms-1" download>
                        <i className="ri-download-2-line align-bottom me-1"></i> {t("Searches.Dialog.NewSearch.DownloadSample")}
                      </Link>
                    </div>
                    <br></br>
                    <Dropzone
                      onDrop={(acceptedFiles: any) => {
                        handleAcceptedFiles(acceptedFiles);
                      }}
                    >
                      {({getRootProps, getInputProps}) => (
                        <div
                          className={
                            "dropzone dz-clickable new-search upload-height form-control form-control" +
                            (validation.touched.uploadedAsinList && validation.errors.uploadedAsinList ? " is-invalid" : "")
                          }
                        >
                          <div className="dz-message needsclick" {...getRootProps()}>
                            <div className="mb-3">
                              <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                            </div>
                            <h4>{t("Searches.Dialog.NewSearch.UploaderTitle")}</h4>
                            <h5>({t("Searches.Dialog.NewSearch.AddAsinLimit")})</h5>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    {validation.errors.uploadedAsinList && !isValidFile ? (
                      <FormFeedback type="invalid" className="new-search validation-width w-100">
                        {validation.errors.uploadedAsinList.toString()}
                      </FormFeedback>
                    ) : (
                      <div className="list-unstyled mb-0" id="file-previews">
                        {selectedFiles.map((f: any, i: any) => {
                          return (
                            <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete" key={i + "-file"}>
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col>
                                    <Link to="#" className="text-muted font-weight-bold">
                                      {f.name}
                                    </Link>
                                    <p className="mb-0">
                                      <strong>{f.formattedSize}</strong>
                                    </p>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          );
                        })}
                      </div>
                    )}
                  </div>
                )}
              </Col>
            </Row>
            <Dialog
              ref={exceedLimitConfirmationDialog}
              color="info"
              buttons={["yes", "no"]}
              busy={loading.update}
              iconClass="ri-alert-fill"
              message={t("Searches.Dialog.ExcelExceedLimitConfirmation.Description", {value: getDailySearchLimit()})}
              title={t("Searches.Dialog.ExcelExceedLimitConfirmation.Title")}
              onButtonClick={async (button, hide) => {
                if (button === "yes") {
                } else {
                  validation.resetForm();
                  setLimitExceeding(false);
                }
                exceedLimitConfirmationDialog.current?.hide();
              }}
            />
          </Form>
        </ModalBody>
        <ModalFooter>
          <div className="hstack gap-2 justify-content-end">
            <Button type="button" className="btn btn-light" disabled={loading.save} onClick={toggle}>
              {loading.save && <Spinner size="sm" className="me-2"></Spinner>}
              {t("Searches.Dialog.NewSearch.CloseButton")}
            </Button>

            <Button
              type="submit"
              className="btn btn-success"
              disabled={loading.save}
              onClick={() => {
                validation.setFieldTouched("searchName");
                validation.setFieldTouched("marketplace");
                validation.setFieldTouched("asinList");
                validation.setFieldTouched("categoryPageList");
                validation.setFieldTouched("uploadedAsinList");

                if ((validation.isValid && searchType !== "uploadFile") || (searchType === "uploadFile" && isValidFile)) {
                  validation.handleSubmit();
                }
              }}
            >
              {loading.save && <Spinner size="sm" className="me-2"></Spinner>}
              {t("Searches.Dialog.NewSearch.SubmitButton")}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default NewSearch;
