import React, {useMemo, useRef, useState} from "react";
import {UserOrderItem} from "models/user_order";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Card, CardBody, CardHeader, CardTitle, Col, Row} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";
import AmazonMarketplaceInfos from "Components/Common/AmazonMarketplaceInfos";
import CopyAsinWidget from "Components/Common/CopyAsinWidget";
import {ColumnDef} from "@tanstack/react-table";
import DataTable, {DataTableRef} from "Components/Common/DataTable";
import DisplayPrice from "Components/Common/DisplayPrice";

export type OrderItemListRef = {
  reload: VoidFunction;
};
const Content = () => {
  const {t} = useTranslation();
  const [amazonMarketplaceInfos] = useState(AmazonMarketplaceInfos());
  const tableRef = useRef<DataTableRef>(null);

  const ordersData = createSelector(
    (state: RootState) => state,
    (state) => ({
      order: state.OrderDetails.order,
      loading: state.OrderDetails.loading,
    }),
  );
  const {loading, order} = useSelector(ordersData);

  const columns = useMemo<ColumnDef<UserOrderItem>[]>(
    () => [
      {
        header: " ",
        size: 80,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserOrderItem;
          const imagePath = `https://m.media-amazon.com/images/I/${row.userInventory.imageBig}`;
          const [image, setImage] = useState<any>(imagePath); // eslint-disable-line
          return (
            <>
              <div className="product-img-slider product-img-container h-100">
                {image ? (
                  <img className="rounded w-100" src={image} alt="" onError={() => setImage(null)} />
                ) : (
                  <div className="img-thumbnail flex-shrink-0 h-100 avatar-title text-uppercase border bg-light text-primary">
                    <i className=" ri-image-line fs-18"></i>
                  </div>
                )}
              </div>
            </>
          );
        },
      },
      {
        header: t("OrderDetails.Card.Content.ProductName"),
        size: 400,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserOrderItem;
          const textContainer: any = {
            maxWidth: "420px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            marginBottom: "3px",
          };
          return (
            <>
              <Row>
                <Col xs="auto">
                  <div style={textContainer}>{row.userInventory.productName}</div>
                  <div>
                    <span className="fw-bold">{t("OrderDetails.Card.Content.OrderItemId")}</span> {row.orderItemId}
                  </div>
                  <div className="d-flex flex-row align-items-center">
                    <span className="fw-bold me-1">{t("OrderDetails.Card.Content.Asin")}</span>
                    <CopyAsinWidget Asin={row.asin} index={row.asin} asLink={true} marketplace={order.userStore.marketplace} />
                  </div>
                  <div className="d-flex flex-row align-items-center">
                    <span className="fw-bold me-2">{t("OrderDetails.Card.Content.SKU")}</span>
                    <CopyAsinWidget Asin={row.sellerSKU} index={row.sellerSKU} />
                  </div>
                </Col>
              </Row>
            </>
          );
        },
      },
      {
        header: t("OrderDetails.Card.Content.TableColumn.Quantity"),
        size: 80,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserOrderItem;
          return <>{row.quantityOrdered}</>;
        },
      },
      {
        header: t("OrderDetails.Card.Content.TableColumn.UnitPrice"),
        size: 80,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserOrderItem;
          const currency = amazonMarketplaceInfos.find((info) => info.marketplace === order.userStore.marketplace)?.currency;
          return (
            <>
              <DisplayPrice source={currency} value={row.salesProceedsPerUnit} decimals={2} notation="abbreviated"/>
            </>
          );
        },
      },
      {
        header: t("OrderDetails.Card.Content.TableColumn.Total"),
        size: 80,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserOrderItem;
          const currency = amazonMarketplaceInfos.find((info) => info.marketplace === order.userStore.marketplace)?.currency;
          return (
            <>
              <DisplayPrice source={currency} value={row.totalSalesProceeds} decimals={2} notation="abbreviated"/>
            </>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, order],
  );

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <CardTitle className="fw-medium mb-0">{t("OrderDetails.Card.Content.Title")}</CardTitle>
        </CardHeader>
        <CardBody>
          <DataTable ref={tableRef} busy={loading.list} columns={columns} data={order.userOrderItems || []} totalDataLength={order.userOrderItems?.length} hovered />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default Content;
