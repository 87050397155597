import DataTable, {DataTableRef} from "Components/Common/DataTable";
import {ColumnDef} from "@tanstack/react-table";
import {useMemo, useRef} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Card, CardBody, CardHeader, CardTitle, Col, Row} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {OrderShipments} from "models/user_order";
import NoData from "Components/Common/NoData";
import { Link, useParams } from "react-router-dom";

const ShipmentList = () => {
  const {t} = useTranslation();
  const tableRef = useRef<DataTableRef>(null);
  const dispatch: any = useDispatch();
  const {amazonOrderId}  = useParams();

  const orderDetailPageData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.OrderDetails.loading,
      order: state.OrderDetails.order,
      defaultShipments: state.OrderDetails.defaultShipments,
      availableShipments: state.OrderDetails.shipmentsWithoutInfo,
    }),
  );

  const {loading, defaultShipments, availableShipments} = useSelector(orderDetailPageData);

  const columns = useMemo<ColumnDef<OrderShipments, any>[]>(
    () => [
      {
        header: t("OrderDetails.Card.Shipments.TableColumn.Shipment"),
        size: 100,
        accessorKey: "shipment",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as OrderShipments;
          return <>{row.shipment}</>;
        },
      },
      {
        header: t("OrderDetails.Card.Shipments.TableColumn.Asin"),
        size: 100,
        accessorKey: "asin",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as OrderShipments;
          return <>{row.asin}</>;
        },
      },
      {
        header: t("OrderDetails.Card.Shipments.TableColumn.SKU"),
        size: 100,
        accessorKey: "sku",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as OrderShipments;
          const {order} = useSelector(orderDetailPageData); // eslint-disable-line
          const targetOrderItem = order.userOrderItems?.find((item) => item.asin === row.asin);

          return <>{targetOrderItem?.sellerSKU}</>;
        },
      },
      {
        header: t("OrderDetails.Card.Shipments.TableColumn.Quantity"),
        size: 100,
        accessorKey: "quantity",
        sortingFn: "datetime",
        cell: (cellProps) => {
          const row = cellProps.row.original as OrderShipments;
          return <>{row.quantity}</>;
        },
      },
    ],
    [t, dispatch], // eslint-disable-line
  );

  return (
    <>
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <CardTitle className="fw-medium mb-0">{t("OrderDetails.Card.Shipments.Title")}</CardTitle>
            </Col>
            {((defaultShipments && defaultShipments.length > 0) || (availableShipments && availableShipments.length > 0)) && (
              <Col xs="auto">
                <Link to={`/orders/${amazonOrderId}/re-assign`} className="btn btn-primary btn-sm">
              {t("OrderDetails.Dialog.ReassignShipment.Title")}
                </Link>
              </Col>
            )}
          </Row>
        </CardHeader>
        <CardBody>
          {defaultShipments && defaultShipments.length > 0 ? (
            <DataTable
              ref={tableRef}
              columns={columns}
              data={defaultShipments || []}
              totalDataLength={defaultShipments.length}
               
              busy={loading.list}
              hovered
            />
          ) : (
            <NoData icon="mdi mdi-truck-fast" />
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default ShipmentList;
