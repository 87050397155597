import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ApiError, LoadingStates} from "helpers/types";
import {
  AnalysisSummary,
  CompetitorAnalysis,
  GeneralCheckAndAlerts,
  ProductDetails,
  ProfitAnalysis,
  ProfitCalculation,
  RestrictionCheck,
  SalesAnalysis,
  SellerAndListingAnalysis,
  VariationAnalysis,
} from "models/product_details";
import {UserSearchProductCheckList} from "models/user_search_product_check_list";
import { UserStore } from "models/user_stores";

type ProductDetailsLoadingStates = LoadingStates<"list" | "save">;

export type ProductDetailsState = {
  loading: ProductDetailsLoadingStates;
  details: ProductDetails;
  store?: UserStore;
  pageUrl: string;
  error?: ApiError;
};

const initialState: ProductDetailsState = {
  loading: {
    list: false,
    save: false,
  },
  details: {
    showCheckList: false,
    userSearchId: "",
    productInfo: {
      productImage: "",
      productName: "",
      asin: "",
      sku: "",
      storeName: "",
      sourceMarketplace: "",
      targetMarketplace: "",
      currencyCodeSource: "",
      currencyCodeTarget: "",
      stockAvailable: 0,
      stockInbound: 0,
      stockReserved: 0,
      stockUnfulfillable: 0,
      stockResearching: 0,
      stockTotal: 0,
      dimensions: "",
      dimensionStatus: false,
      variationCount: 0,
      isFBAEligible: false,
      weight: 0,
      weightStatus: false,
      categoryName: "",
      reviewCount: 0,
      dimensionalWeight: 0,
      dimensionalWeightStatus: false,
      bsr: 0,
      launchDate: {} as Date,
      checkList: {} as UserSearchProductCheckList,
    },
    analysisSummary: {} as AnalysisSummary,
    competitorAnalysis: {} as CompetitorAnalysis,
    generalCheckAndAlerts: {} as GeneralCheckAndAlerts,
    profitAnalysis: {} as ProfitAnalysis,
    profitCalculation: {} as ProfitCalculation,
    restrictionCheck: {} as RestrictionCheck,
    salesAnalysis: {} as SalesAnalysis,
    sellerAndListingAnalysis: {} as SellerAndListingAnalysis,
    variationAnalysis: {} as VariationAnalysis,
  },
  store: {} as UserStore,
  pageUrl: "",
  error: undefined,
};

const ProductDetailsSlice = createSlice({
  name: "ProductDetails",
  initialState,
  reducers: {
    loading(state, action: PayloadAction<[keyof ProductDetailsLoadingStates, boolean]>) {
      const [operation, loadingState] = action.payload;
      state.loading[operation] = loadingState;
    },
    setDetails(state, action: PayloadAction<ProductDetails>) {
      state.details = action.payload;
    },
    setShowCheckList(state, action: PayloadAction<boolean>) {
      state.details.showCheckList = action.payload;
    },
    setPageUrl(state, action: PayloadAction<string>) {
      state.pageUrl = action.payload;
    },
    setStore(state, action: PayloadAction<UserStore>) {
      state.store = action.payload;
    },
    apiError(state, action: PayloadAction<ApiError>) {
      state.error = action.payload;
    },
    reset(state) {
      state.error = initialState.error;
      state.loading = initialState.loading;
      state.details = initialState.details;
      state.pageUrl = initialState.pageUrl;
      state.store = initialState.store;
    },
  },
});

export const {loading, setDetails, setShowCheckList, setPageUrl, setStore, apiError, reset} = ProductDetailsSlice.actions;

export default ProductDetailsSlice.reducer;
