import {User} from "./user";

export interface ScanAndSave {
  id: string;
  searchName: string;
  createDate: Date;
  status: ScanAndSaveStatus;
  total: number;
  sent: number;
  url: string;
  user: User;
  avatarUrl?: string;
  deleted: boolean;
}

export enum ScanAndSaveStatus {
  UNDEFINED = 0,
  WAITING = 1,
  PLANNED = 2,
  WORKING = 3,
  DONE = 4,
  CANCELED = 5,
}

export const getScanAndSaveStatusDescription = (status: ScanAndSaveStatus): string => {
  switch (status) {
    case ScanAndSaveStatus.UNDEFINED:
      return "Undefined";
    case ScanAndSaveStatus.WAITING:
      return "Waiting";
    case ScanAndSaveStatus.PLANNED:
      return "Planned";
    case ScanAndSaveStatus.WORKING:
      return "Working";
    case ScanAndSaveStatus.DONE:
      return "Done";
    case ScanAndSaveStatus.CANCELED:
      return "Canceled";
  }
};
