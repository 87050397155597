import DisplayDate from "Components/Common/DisplayDate";
import {useProfile} from "Components/Hooks/UserHooks";
import {UserSearchProduct} from "models/user_search_product";
import {useTranslation} from "react-i18next";
import {Card, CardBody, CardHeader, Table, UncontrolledPopover} from "reactstrap";

interface TargetServicesProps {
  row: UserSearchProduct;
}
const TargetServices = ({row}: TargetServicesProps) => {
  const {t} = useTranslation();
  const {userProfile} = useProfile();
  const statusCss = (status?: boolean): string => {
    let className = "";

    if (status === true) {
      className = "bx bxs-check-circle text-success";
    } else if (status === false) {
      className = "bx bxs-x-circle text-danger";
    } else {
      className = "text-muted ri-donut-chart-line";
    }

    return className;
  };
  return (
    <Card className="border card-animate">
      <CardHeader>
        <div className="d-flex justify-content-between align-items-center">
          <h6 className="card-title mb-0">{t("Admin.SearchLogs.Box.Results.TargetServices.Title")}</h6>
        </div>
      </CardHeader>
      <CardBody>
        <ul className="list-group">
          <li className="d-flex justify-content-between align-items-center">
            <span>Fee Estimate</span>
            <span>
              <h2 className="mb-0 mt-1">
                <i id={`TargetServices-${row.userSearchProductId}`} className={`${statusCss(row.spapiFeesEstimateSucceeded)}`} role="button"></i>
              </h2>
              <UncontrolledPopover trigger="legacy" placement="right" target={`TargetServices-${row.userSearchProductId}`}>
                <Table className="align-middle table table-hover mb-0">
                  <tbody>
                    <tr>
                      <td>{t("Admin.SearchLogs.Tooltip.Result")}</td>
                      <td>
                        {row.spapiFeesEstimateResultDate ? (
                          <DisplayDate id={`DisplayDateTargetServices-${row.userSearchProductId}`} value={row.spapiFeesEstimateResultDate} format="D MMM YYYY HH:mm" tz={userProfile?.timezone} />
                        ) : (
                          <span className="badge bg-warning-subtle text-warning">{t("Waiting")}</span>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </UncontrolledPopover>
            </span>
          </li>
          <li className="d-flex justify-content-between align-items-center">
            <span>Variations</span>
            <span>
              <h2 className="mb-0 mt-1">
                <i id={`Variations-${row.userSearchProductId}`} className={`${statusCss(row.spapiVariationsSucceeded)}`} role="button"></i>
              </h2>
              <UncontrolledPopover trigger="legacy" placement="right" target={`Variations-${row.userSearchProductId}`}>
                <Table className="align-middle table table-hover mb-0">
                  <tbody>
                    <tr>
                      <td>{t("Admin.SearchLogs.Tooltip.Result")}</td>
                      <td>
                        {row.spapiVariationsResultDate ? (
                          <DisplayDate id={`DisplayDateVariationResultDate-${row.userSearchProductId}`} value={row.spapiVariationsResultDate} format="D MMM YYYY HH:mm" tz={userProfile?.timezone} />
                        ) : (
                          <span className="badge bg-warning-subtle text-warning">{t("Waiting")}</span>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </UncontrolledPopover>
            </span>
          </li>
          <li className="d-flex justify-content-between align-items-center">
            <span>Category</span>
            <span>
              <h2 className="mb-0 mt-1">
                <i id={`Category-${row.userSearchProductId}`} className={`${statusCss(row.spapiCategorySucceeded)}`} role="button"></i>
              </h2>
              <UncontrolledPopover trigger="legacy" placement="right" target={`Category-${row.userSearchProductId}`}>
                <Table className="align-middle table table-hover mb-0">
                  <tbody>
                    <tr>
                      <td>{t("Admin.SearchLogs.Tooltip.Result")}</td>
                      <td>
                        {row.spapiCategoryResultDate ? (
                          <DisplayDate id={`DisplayDateCategory-${row.userSearchProductId}`} value={row.spapiCategoryResultDate} format="D MMM YYYY HH:mm" tz={userProfile?.timezone} />
                        ) : (
                          <span className="badge bg-warning-subtle text-warning">{t("Waiting")}</span>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </UncontrolledPopover>
            </span>
          </li>
          <li className="d-flex justify-content-between align-items-center">
            <span>FBA Eligibility</span>
            <span>
              <h2 className="mb-0 mt-1">
                <i id={`FbaEligibility-${row.userSearchProductId}`} className={`${statusCss(row.spapiFBAEligibilitySucceeded)}`} role="button"></i>
              </h2>
              <UncontrolledPopover trigger="legacy" placement="right" target={`FbaEligibility-${row.userSearchProductId}`}>
                <Table className="align-middle table table-hover mb-0">
                  <tbody>
                    <tr>
                      <td>{t("Admin.SearchLogs.Tooltip.RetryCount")}</td>
                      <td>{row.spapiFBAEligibilityRetryCount}</td>
                    </tr>
                    <tr>
                      <td>{t("Admin.SearchLogs.Tooltip.Request")}</td>
                      <td>
                        {row.spapiFBAEligibilityRequestDate ? (
                          <DisplayDate
                            id={`DisplayDateFbaEligibilityRequest-${row.userSearchProductId}`}
                            value={row.spapiFBAEligibilityRequestDate}
                            format="D MMM YYYY HH:mm"
                            tz={userProfile?.timezone}
                          />
                        ) : (
                          <span className="badge bg-warning-subtle text-warning">{t("Waiting")}</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Admin.SearchLogs.Tooltip.Result")}</td>
                      <td>
                        {row.spapiFBAEligibilityResultDate ? (
                          <DisplayDate
                            id={`DisplayDateFbaEligibilityResult-${row.userSearchProductId}`}
                            value={row.spapiFBAEligibilityResultDate}
                            format="D MMM YYYY HH:mm"
                            tz={userProfile?.timezone}
                          />
                        ) : (
                          <span className="badge bg-warning-subtle text-warning">{t("Waiting")}</span>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </UncontrolledPopover>
            </span>
          </li>
        </ul>
      </CardBody>
    </Card>
  );
};

export default TargetServices;
