import DataTable, {DataTableRef} from "Components/Common/DataTable";
import Dialog, {DialogRef} from "Components/Common/Dialog";
import {CSSProperties, useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import {UserSearchesQuery, SearchStatus, UserSearch, getSearchStatusDescription} from "models/user_search";
import {Button, Card, CardBody, Container, Progress} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import updateUrlParams, {formatDate, getToday, numberFormatter, preventScrollUp} from "helpers/utilities";
import {createSelector} from "reselect";
import {useTranslation} from "react-i18next";
import {useProfile} from "Components/Hooks/UserHooks";
import {ColumnDef} from "@tanstack/react-table";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {RootState} from "slices";
import {useImmer} from "use-immer";
import {useDebounce} from "Components/Hooks/HelperHooks";
import {deleteSearch, getUserSearchesList, resetSearchesState} from "slices/searches/thunk";
import {ConstantPage} from "helpers/permission_helper";
import Loader from "Components/Common/Loader";
import Restricted from "Components/Common/Restricted";
import Unauthorized from "pages/Errors/_Unauthorized";
import NewSearch from "./Modals/NewSearch";
import AmazonMarketplaceInfos from "Components/Common/AmazonMarketplaceInfos";
import BreadCrumb from "Components/Common/BreadCrumb";
import Filters from "./Filters";
import NoResult from "Components/Common/NoResult";
import RenderNumber from "Components/Common/RenderNumber";
import ReAnalyzeSearch from "./Modals/ReAnalyzeSearch";
import moment from "moment";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import DisplayDate from "Components/Common/DisplayDate";
import SingleSearch from "Components/Common/Modals/SingleSearch";

export type SearchesListRef = {
  reload: VoidFunction;
};
interface SearchesListProps {
  bulkSearch?: boolean;
  singleSearch?: boolean;
  reAnalyze?: boolean;
}
const PAGE_IDENTIFIER: ConstantPage = "search";
const SearchesPage = (props: SearchesListProps) => {
  const {t} = useTranslation();
  const {userProfile, hasPermission} = useProfile();
  const [amazonMarketplaceInfos] = useState(AmazonMarketplaceInfos());
  const [selectedSearch, setSelectedSearch] = useState<UserSearch>();
  const [query, updateQuery] = useImmer<UserSearchesQuery>({
    filtering: true,
    startDate: undefined,
    endDate: undefined,
    page: 1,
    pageSize: 10,
  });
  const tableRef = useRef<DataTableRef>(null);
  const listRef = useRef<SearchesListRef>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch: any = useDispatch();
  const deleteDialogRef = useRef<DialogRef>(null);

  const debouncedLoadList = useDebounce(() => {
    if (hasPermission(PAGE_IDENTIFIER)) {
      getUserSearchesList(query)(dispatch).then(() => {
        tableRef.current?.resetSelection();
      });
    }
  }, 200);

  useEffect(() => {
    if (query.endDate !== undefined && query.startDate !== undefined) {
      if (location.pathname !== "/searches/new/bulk") {
        updateUrlParams(navigate, location, {
          start: formatDate(query.startDate),
          end: formatDate(query.endDate, true),
        })
      }
      debouncedLoadList();
    }
  }, [debouncedLoadList, query]); // eslint-disable-line

  useImperativeHandle(
    listRef,
    () => {
      return {
        reload: () => {
          debouncedLoadList();
        },
      };
    },
    [debouncedLoadList],
  );

  const searchesData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Searches.loading,
      searchesList: state.Searches.searchesList,
    }),
  );

  const {loading, searchesList} = useSelector(searchesData);

  const columns = useMemo<ColumnDef<UserSearch, any>[]>(
    () => [
      {
        header: t("Actions"),
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearch;
          const disableStyle: CSSProperties = row.status === SearchStatus.INITIAL || row.status === SearchStatus.IN_PROGRESS ? {pointerEvents: "none", color: "#dedede"} : {};
          return (
            <>
              <Button
                id={`ReAnalyze-${row.userSearchId}`}
                className="btn btn-ghost-secondary px-1 py-0 fs-18 "
                type="button"
                onClick={() => {
                  setSelectedSearch(row);
                  navigate(`/searches/re-analyze/${row.userSearchId}`, {replace: true});
                  preventScrollUp();
                }}
                style={disableStyle}
              >
                <i className="mdi mdi-chart-timeline-variant-shimmer"></i>
              </Button>
              <DefaultUncontrolledTooltip target={`ReAnalyze-${row.userSearchId}`}>{t("Searches.Tooltip.ReAnalyzeSearch")}</DefaultUncontrolledTooltip>

              <Button
                id={`DeleteSearch-${row.userSearchId}`}
                className="btn btn-ghost-danger px-1 py-0 fs-18 "
                onClick={() => {
                  setSelectedSearch(row);
                  deleteDialogRef.current?.show();
                }}
                style={disableStyle}
              >
                <i className="ri-delete-bin-fill"></i>
              </Button>
              <DefaultUncontrolledTooltip target={`DeleteSearch-${row.userSearchId}`}>{t("Searches.Tooltip.DeleteSearch")}</DefaultUncontrolledTooltip>
            </>
          );
        },
      },
      {
        header: t("Searches.TableColumn.SearchName"),
        accessorKey: "name",
        size: 200,
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearch;
          return (
            <div className="ellipsis-two-lines">
              <Link className="link-secondary link-offset-2 text-decoration-underline" role="button" to={`/searches/search-results/${row.userSearchId}`}>
                {row.name}
              </Link>
            </div>
          );
        },
      },
      {
        header: t("Searches.TableColumn.Marketplace"),
        accessorKey: "marketplace",
        sortingFn: "alphanumeric",
        size: 150,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearch;
          return (
            <>
              <span className="hstack gap-2">
                <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                  <img
                    src={amazonMarketplaceInfos.find((amazonMarketplaceInfo: any) => amazonMarketplaceInfo.marketplace === row.marketplaceTarget)?.flag}
                    alt="Country Flag"
                    className=" rounded-circle"
                  />
                </div>
                {amazonMarketplaceInfos.find((amazonMarketplaceInfo: any) => amazonMarketplaceInfo.marketplace === row.marketplaceTarget)?.countryName} {`(${row.marketplaceTarget}) `}{" "}
                {row.userStore ? `- ${row.userStore.name}` : ""}
              </span>
            </>
          );
        },
      },
      {
        header: t("Searches.TableColumn.CreateDate"),
        accessorKey: "createDate",
        sortingFn: "datetime",
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearch;
          return (
            <div className="d-flex align-items-center nowrap">
              <DisplayDate id={`DisplayDateSearchesCreateDate-${row.userSearchId}`} value={row.createDate} format="D MMM YYYY HH:mm" tz={userProfile?.timezone} />
              {getToday().diff(moment.tz(row.createDate, userProfile?.timezone!), "hours") > 48 && (
                <>
                  <i
                    className={`ri-error-warning-fill ${getToday().diff(moment.tz(row.createDate, userProfile?.timezone!), "days") > 7 ? "text-danger" : "text-warning"} ms-1`}
                    id={`AnalysisWarningMessage-${row.userSearchId}`}
                  ></i>
                  <DefaultUncontrolledTooltip target={`AnalysisWarningMessage-${row.userSearchId}`}>{t("SearchResults.Summary.AnalysisWarningMessage", {hours: 48})}</DefaultUncontrolledTooltip>
                </>
              )}
            </div>
          );
        },
      },
      {
        header: t("Searches.TableColumn.Status"),
        accessorKey: "status",
        sortingFn: "alphanumericCaseSensitive",
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearch;
          const statuText: SearchStatus = row.status;
          const cssClass =
            statuText === SearchStatus.COMPLETED
              ? "bg-success-subtle text-success"
              : statuText === SearchStatus.IN_PROGRESS
              ? "bg-warning-subtle text-warning"
              : statuText === SearchStatus.INITIAL
              ? "bg-secondary-subtle text-secondary"
              : "bg-danger-subtle text-danger";
          return (
            <>
              <div className="d-flex align-items-center">
                <span className={`badge ${cssClass} fs-12`}>{t(getSearchStatusDescription(row.status))}</span>
                {statuText === SearchStatus.COMPLETED && row.restrictionCheckCompletedProductCount !== row.completedProductCount && (
                  <>
                    <i id={`RestrictionCheckTooltip-${row.userSearchId}`} className="bx bxs-time-five fs-16 text-warning ms-1"></i>
                    <DefaultUncontrolledTooltip target={`RestrictionCheckTooltip-${row.userSearchId}`}>
                      {t("Searches.RestrictionChecksInProgressMessage", {productCount: numberFormatter(row.totalProductCount - row.restrictionCheckCompletedProductCount)})}
                    </DefaultUncontrolledTooltip>
                  </>
                )}
              </div>
            </>
          );
        },
      },
      {
        header: t("Searches.TableColumn.Total"),
        accessorKey: "totalProductCount",
        sortingFn: "alphanumeric",
        size: 80,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearch;
          return (
            <Link className="link-secondary link-offset-2 text-decoration-underline me-1" role="button" to={`/searches/search-results/${row.userSearchId}`}>
              <RenderNumber value={row.totalProductCount} />
            </Link>
          );
        },
      },
      {
        header: t("Searches.TableColumn.Completed"),
        accessorKey: "completedProductCount",
        sortingFn: "alphanumeric",
        size: 80,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearch;
          const statusText: SearchStatus = row.status;
          return statusText === SearchStatus.IN_PROGRESS ? (
            <>
              <div className="align-items-center d-flex">
                <RenderNumber value={row.completedProductCount} />
                <i className="mdi mdi-spin mdi-loading fs-20 ms-2"></i>
              </div>
              <div style={{width: "90%"}}>
                <Progress color="secondary" value={(row.completedProductCount / row.totalProductCount) * 100} className="progress-sm" />
              </div>
            </>
          ) : (
            <RenderNumber value={row.completedProductCount} />
          );
        },
      },
      {
        header: t("Searches.TableColumn.Sellable"),
        accessorKey: "sellableProductCount",
        sortingFn: "alphanumeric",
        size: 80,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearch;
          return <RenderNumber value={row.sellableProductCount} />;
        },
      },
      {
        header: t("Searches.TableColumn.Recheck"),
        accessorKey: "recheckProductCount",
        sortingFn: "alphanumeric",
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearch;
          return <RenderNumber value={row.recheckProductCount} />;
        },
      },
    ],
    [t], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const commonData = createSelector(
    (state: RootState) => state,
    (state) => ({
      activeUserStoreOptions: state.Common.activeUserStoreOptions,
      activeMarketplaceOptions: state.Common.activeMarketplaceOptions
    }),
  );
  const {activeUserStoreOptions, activeMarketplaceOptions} = useSelector(commonData);

  useEffect(() => {
    return () => {
      dispatch(resetSearchesState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  document.title = t("PageTitles.Searches");

  //#endregion
  return (
    <Restricted require={PAGE_IDENTIFIER} read fallback={() => <Unauthorized />}>
      <div className="page-content">
        <Container fluid>
          <Restricted require="search" create>
            <NewSearch isOpen={props.bulkSearch || false} busy={loading.filter} userStoresSelectOptions={[...activeUserStoreOptions, ...activeMarketplaceOptions]} listRef={listRef} />
            <SingleSearch isOpen={props.singleSearch || false} />
            <ReAnalyzeSearch isOpen={props.reAnalyze || false} listRef={listRef} />
          </Restricted>
          <BreadCrumb title={t("Searches.Title")} menus={[{label: t("Searches.Title")}]} />
          <Filters
            handleFilter={(f) => updateQuery(f)}
            busy={loading.filter}
            page={query.page}
            pageSize={query.pageSize}
            listRef={listRef}
          />
          <Card>
            <CardBody>
              {loading.filter ? (
                <>
                  <Loader />
                </>
              ) : searchesList.items && searchesList.items.length > 0 ? (
                <>
                  <DataTable
                    ref={tableRef}
                    busy={loading.list}
                    columns={columns}
                    data={searchesList?.items || []}
                    totalDataLength={searchesList?.totalCount}
                    pagination={{
                      pageIndex: query.page - 1,
                      pageSize: query.pageSize,
                    }}
                    onPaginationChanged={(pagination) =>
                      updateQuery((q) => {
                        q.page = pagination.pageIndex + 1;
                        q.pageSize = pagination.pageSize;
                        q.filtering = false;
                      })
                    }
                    hovered
                  />
                </>
              ) : (
                <NoResult title={t("Searches.NoResult.Title")} description={t("Searches.NoResult.Description")} />
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
      <Dialog
        ref={deleteDialogRef}
        color="danger"
        buttons={["yes", "no"]}
        busy={loading.delete}
        iconClass="ri-delete-bin-line"
        message={t("Searches.Dialog.Delete.Description")}
        title={t("Searches.Dialog.Delete.Title", {value: selectedSearch?.name})}
        onButtonClick={async (button, hide) => {
          if (button === "yes") {
            if (selectedSearch) {
              const isSuccess = await deleteSearch(selectedSearch)(dispatch);
              if (isSuccess) {
                deleteDialogRef.current?.hide();
                listRef.current?.reload();
              }
            }
          } else {
            deleteDialogRef.current?.hide();
          }
        }}
      />
    </Restricted>
  );
};

export default SearchesPage;
