import {AmazonMarketplaceInfosType} from "Components/Common/AmazonMarketplaceInfos";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {Button, Card, CardBody, Col, Row, Table, UncontrolledPopover} from "reactstrap";
import updateUrlParams, {preventScrollUp} from "helpers/utilities";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {useSelector} from "react-redux";
import Rating from "react-rating";
import CopyAsinWidget from "Components/Common/CopyAsinWidget";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import DomainToFlag from "Components/Common/DomainToFlag";
import Moment from "react-moment";
import logoDarkTransparent from "assets/images/logo-dark-transparent.png";
import errorImage from "assets/images/svg/product.svg";
import i18n from "i18n";
import RenderNumber from "Components/Common/RenderNumber";
import RestrictionCheck from "./_RestrictionCheck";
import moment from "moment";
import {useProfile} from "Components/Hooks/UserHooks";

interface DecisionReasonsLinkProps {
  items: string[];
  isWarning?: boolean | false;
}
const DecisionReasonsLink = ({items, isWarning}: DecisionReasonsLinkProps) => {
  const {t} = useTranslation();
  return items.length === 1 ? t("SearchResults.Summary.Reason", {count: items.length}) : t("SearchResults.Summary.ReasonPlural", {count: items.length});
};

interface ProductInfoProps {
  sourceMarketplace?: AmazonMarketplaceInfosType;
  destinationMarketplace?: AmazonMarketplaceInfosType;
}
const ProductInfo = ({sourceMarketplace, destinationMarketplace}: ProductInfoProps) => {
  const {t} = useTranslation();
  const {isUserLoggedIn} = useProfile();
  const location = useLocation();
  const navigate = useNavigate();
  const [dimensionInMetric, setDimensionInMetric] = useState("");
  const [weightInMetric, setWeightInMetric] = useState("");
  const [dimensionalWeightInMetric, setDimensionalWeightInMetric] = useState("");

  const LIBRE_UNIT = 0.45359237; // 1lb equals to 0.45359237kg
  const INCH_UNIT = 2.54; // 1 inch equals to 2.54cm

  const productDetailsData = createSelector(
    (state: RootState) => state,
    (state) => ({
      details: state.ProductDetails.details,
    }),
  );
  const {details} = useSelector(productDetailsData);

  useEffect(() => {
    if (details.productInfo) {
      const splitDimension = details.productInfo.dimensions?.replaceAll(" ", "").split("*");
      if (splitDimension) {
        let length = parseFloat(splitDimension[0]);
        let width = parseFloat(splitDimension[1]);
        let height = parseFloat(splitDimension[2]);

        if (length && width && height) {
          length = length * INCH_UNIT;
          width = width * INCH_UNIT;
          height = height * INCH_UNIT;

          setDimensionInMetric(`${length.toFixed(2)} * ${width.toFixed(2)} * ${height.toFixed(2)}`);
        }
      }

      if (details.productInfo.weight) {
        setWeightInMetric((details.productInfo.weight * LIBRE_UNIT).toFixed(2).toString());
      }
      if (details.productInfo.dimensionalWeight) {
        setDimensionalWeightInMetric((details.productInfo.dimensionalWeight * LIBRE_UNIT).toFixed(2).toString());
      }
    }
  }, [details]);

  return (
    <Card className="card-animate">
      <CardBody className="common-css pb-0">
        <Row className="g-4">
          <Col className="mx-auto">
            <div className="product-img-slider product-img-container">
              <img src={details?.productInfo?.productImage ? `https://m.media-amazon.com/images/I/${details?.productInfo?.productImage}` : errorImage} alt="" className="rounded w-100" />
            </div>
            <div className="mt-2 mx-auto" style={{width: "80%"}}>
              {isUserLoggedIn() && (
                <>
                  <Button
                    className={"rounded-pill p-0 btn-secondary"}
                    id={`SellThisButton`}
                    onClick={() => {
                      if (details.showCheckList) {
                        updateUrlParams(navigate, location, {
                          "check-list": "true"
                        });
                      } else {
                        preventScrollUp();
                        updateUrlParams(navigate, location, {
                          "check-list": null,
                          "sell-this": "true",
                          "asin": details.productInfo?.asin || null,
                          "marketplace": details.productInfo?.targetMarketplace || null
                        });
                      }
                    }}
                  >
                    <img src={logoDarkTransparent} alt="" width="100%" className="px-2 py-1" />
                  </Button>
                  <DefaultUncontrolledTooltip target={`SellThisButton`}>{t("SearchResults.SellThisButton")}</DefaultUncontrolledTooltip>
                </>
              )}
            </div>
          </Col>

          <Col xl={11} lg={10} sm={9}>
            <div className="mt-xl-0">
              <div className="d-flex">
                <div className="flex-grow-1">
                  <Row>
                    <Col className="col-md">
                      <h4 className="ellipsis-single-line mb-0">{details?.productInfo?.productName}</h4>
                      <div className="text-muted mb-2">
                        {details?.productInfo?.categoryPath
                          ? details?.productInfo?.categoryPath.replaceAll("|", " > ")
                          : details?.productInfo?.categoryName
                          ? details?.productInfo.categoryName
                          : t("Category") + ": " + t("NA")}
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-1">
                    <Col xxl={3} xl={6} sm={12} className="d-flex vstack same-height">
                      <Card>
                        <CardBody className="gap-1 vstack justify-content-between">
                          <div className="d-flex align-items-center justify-content-between">
                            <span className="text-muted">{t("ProductDetails.ProductInfo.Label.Asin")}</span>
                            <div className="hstack gap-3 flex-wrap">
                              <CopyAsinWidget Asin={details.productInfo?.asin} index={`CopyAsin`} bold={true} />

                              <div className="d-flex align-items-center">
                                &nbsp;&nbsp;
                                <DomainToFlag marketplace={sourceMarketplace?.marketplace || ""} />
                                <Link
                                  to={`https://www.amazon.com/dp/${details.productInfo?.asin}?th=1&psc=1`}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="link-secondary link-offset-2 text-decoration-underline"
                                >
                                  .com
                                  <i className="ri-arrow-right-up-line"></i>
                                </Link>
                                &nbsp;&nbsp;
                                <DomainToFlag marketplace={destinationMarketplace?.marketplace || ""} />
                                <Link
                                  to={`https://www.amazon.${destinationMarketplace?.domain}/dp/${details.productInfo?.asin}?th=1&psc=1`}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="link-secondary link-offset-2 text-decoration-underline"
                                >
                                  .{destinationMarketplace?.marketplace?.toLowerCase()}
                                  <i className="ri-arrow-right-up-line"></i>
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-between">
                            <span className="text-muted">{t("ProductDetails.ProductInfo.Label.SKU")}</span>
                            <div className="hstack gap-3 flex-wrap">
                              {details.productInfo.sku ? <CopyAsinWidget Asin={details.productInfo.sku} index={details.productInfo.sku} bold={true} /> : t("NA")}
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-between">
                            <span className="text-muted">{t("ProductDetails.ProductInfo.Label.Stock")}</span>
                            <div className="d-flex align-items-center">
                              <Button id="Stock" color="link" className="p-0 link-body-emphasis link-opacity-75-hover fw-medium d-flex align-items-center">
                                <i className="ri-bar-chart-fill text-success fs-16 me-1"></i>
                                <span className="fw-medium">
                                  <RenderNumber value={details.productInfo.stockAvailable} renderOnEmpty={<span>0</span>} />
                                </span>
                                <i className="ri-arrow-down-s-line fs-16 ms-1 text-info"></i>
                              </Button>
                              <UncontrolledPopover trigger="legacy" placement="right" target="Stock">
                                <Table className="align-middle table table-hover mb-0">
                                  <thead className="table-light">
                                    <tr>
                                      <th>{t("Inventory.TableColumn.Stock.Tooltip.QuantityType")}</th>
                                      <th>{t("Inventory.TableColumn.Stock.Tooltip.Units")}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>{t("Inventory.TableColumn.Stock.Tooltip.Available")}</td>
                                      <td>
                                        <RenderNumber value={details.productInfo.stockAvailable} renderOnEmpty={<span className="text-muted">{t("NA")}</span>} />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>{t("Inventory.TableColumn.Stock.Tooltip.Inbound")}</td>
                                      <td>
                                        <RenderNumber value={details.productInfo.stockInbound} renderOnEmpty={<span className="text-muted">{t("NA")}</span>} />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>{t("Inventory.TableColumn.Stock.Tooltip.Reserved")}</td>
                                      <td>
                                        <RenderNumber value={details.productInfo.stockReserved} renderOnEmpty={<span className="text-muted">{t("NA")}</span>} />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>{t("Inventory.TableColumn.Stock.Tooltip.Unfulfillable")}</td>
                                      <td>
                                        <RenderNumber value={details.productInfo.stockUnfulfillable} renderOnEmpty={<span className="text-muted">{t("NA")}</span>} />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>{t("Inventory.TableColumn.Stock.Tooltip.Researching")}</td>
                                      <td>
                                        <RenderNumber value={details.productInfo.stockResearching} renderOnEmpty={<span className="text-muted">{t("NA")}</span>} />
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row">{t("Inventory.TableColumn.Stock.Tooltip.Total")}</th>
                                      <th scope="row">
                                        <RenderNumber value={details.productInfo.stockTotal} renderOnEmpty={<span className="text-muted">{t("NA")}</span>} />
                                      </th>
                                    </tr>
                                  </tbody>
                                </Table>
                              </UncontrolledPopover>
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-between">
                            <span className="text-muted">{t("ProductDetails.ProductInfo.Label.FbaEligibility")}</span>
                            <div className="d-flex align-items-center">
                              {details?.productInfo.isFBAEligible !== null ? (
                                <>
                                  {details?.productInfo?.isFBAEligible ? (
                                    <span className="d-flex align-items-center fw-medium">
                                      {t("ProductDetails.ProductInfo.Label.FbaEligible")}
                                      <i className="bx bx-check fs-18 text-success"></i>
                                    </span>
                                  ) : (
                                    <span className="d-flex align-items-center">
                                      <span className="text-danger">{t("ProductDetails.ProductInfo.Label.FbaNotEligible")}</span>
                                      <i className="bx bx-x fs-18 text-danger"></i>
                                      <Button
                                        color="link"
                                        id={`PopoverIneligibilityReasonList-${details?.productInfo?.asin}`}
                                        className="p-0 ms-1 fs-12 link-secondary link-offset-1 text-decoration-underline common-css"
                                        onClick={() => {
                                          updateUrlParams(navigate, location, {
                                            "ineligibility-reasons": "true"
                                          });
                                        }}
                                      >
                                        <DecisionReasonsLink items={details?.salesAnalysis?.ineligibilityReasonList?.split(",") ?? []} />
                                      </Button>
                                      <DefaultUncontrolledTooltip target={`PopoverIneligibilityReasonList-${details?.productInfo?.asin}`}>{t("Click for details")}</DefaultUncontrolledTooltip>
                                    </span>
                                  )}
                                </>
                              ) : (
                                <span className="text-muted">{t("NA")}</span>
                              )}
                            </div>
                          </div>

                          <div className="d-flex align-items-center justify-content-between">
                            <span className="text-muted">{t("ProductDetails.ProductInfo.Label.Review")}</span>
                            <span className="fw-medium">
                              {details.productInfo?.reviewCount ? (
                                <>
                                  <RenderNumber className="me-2 text-muted" value={details.productInfo?.reviewRating} decimals={1} />
                                  <Rating
                                    initialRating={details.productInfo?.reviewRating ?? 0}
                                    fractions={2}
                                    emptySymbol="mdi mdi-star-outline fs-16 text-muted"
                                    fullSymbol="mdi mdi-star fs-16 text-warning"
                                    readonly={true}
                                  />
                                  <span className="text-muted">
                                    {details.productInfo?.reviewCount > 1
                                      ? t("ProductDetails.ProductInfo.Label.ReviewCountPlural", {value: details.productInfo?.reviewCount})
                                      : t("ProductDetails.ProductInfo.Label.ReviewCount", {value: details.productInfo?.reviewCount})}
                                  </span>
                                </>
                              ) : (
                                t("NA")
                              )}
                            </span>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col xxl={3} xl={6} sm={12} className="d-flex vstack same-height">
                      <Card>
                        <CardBody className="gap-1 justify-content-between vstack">
                          <div className="d-flex align-items-center justify-content-between">
                            <span className="text-muted d-flex align-items-center">
                              {t("ProductDetails.ProductInfo.Label.Dimensions", {value: "(inch)"})}
                              <i id="PopoverDimensions" className="ms-1 bx bxs-info-circle text-secondary"></i>
                              <DefaultUncontrolledTooltip target="PopoverDimensions">{t("ProductDetails.ProductInfo.Label.Dimensions")}</DefaultUncontrolledTooltip>
                            </span>
                            <span className="d-flex align-items-center fw-medium">
                              {details.productInfo?.dimensions ? (
                                <>
                                  <span>{details.productInfo.dimensions} inch</span>
                                  <span>
                                    {details.productInfo.dimensionStatus ? <i className="bx bx-check fs-18 text-success"></i> : <i className="las la-exclamation-triangle fs-18 text-warning ms-1"></i>}{" "}
                                  </span>
                                </>
                              ) : (
                                <>{t("NA")}</>
                              )}
                            </span>
                          </div>
                          <div className="d-flex align-items-center justify-content-between">
                            <span className="text-muted d-flex align-items-center">
                              {t("ProductDetails.ProductInfo.Label.Dimensions", {value: "(cm)"})}
                              <i id="PopoverDimensions" className="ms-1 bx bxs-info-circle text-secondary"></i>
                              <DefaultUncontrolledTooltip target="PopoverDimensions">{t("ProductDetails.ProductInfo.Label.Dimensions")}</DefaultUncontrolledTooltip>
                            </span>
                            <span className="d-flex align-items-center fw-medium">
                              {details.productInfo?.dimensions ? (
                                <>
                                  <span>{dimensionInMetric} cm</span>
                                  <span>
                                    {details.productInfo.dimensionStatus ? <i className="bx bx-check fs-18 text-success"></i> : <i className="las la-exclamation-triangle fs-18 text-warning ms-1"></i>}{" "}
                                  </span>
                                </>
                              ) : (
                                <>{t("NA")}</>
                              )}
                            </span>
                          </div>
                          <div className="d-flex align-items-center justify-content-between">
                            <span className="text-muted">{t("ProductDetails.ProductInfo.Label.Weight", {value: "(lb)"})}</span>
                            <span className="d-flex align-items-center fw-medium">
                              {details.productInfo?.weight ? (
                                <span className="d-flex align-items-center">
                                  {details.productInfo.weight} {t("lb")} {`(${weightInMetric} kg)`}
                                  {details.productInfo.weightStatus ? <i className="bx bx-check fs-18 text-success"></i> : <i className="las la-exclamation-triangle fs-18 text-warning ms-1"></i>}
                                </span>
                              ) : (
                                <span>{t("NA")}</span>
                              )}
                            </span>
                          </div>
                          <div className="d-flex align-items-center justify-content-between">
                            <span className="text-muted">
                              {t("ProductDetails.ProductInfo.Label.DimensionalWeight", {value: "(lb)"})}
                              <i id="PopoverDimensionalWeight" className="ms-1 bx bxs-info-circle text-secondary"></i>
                              <DefaultUncontrolledTooltip target="PopoverDimensionalWeight" placement={"bottom"}>
                                {t("Definitions.DimensionalWeight")}
                              </DefaultUncontrolledTooltip>
                            </span>
                            {details.productInfo?.dimensionalWeight ? (
                              <span className="d-flex align-items-center fw-medium">
                                {details.productInfo.dimensionalWeight} {t("lb")} {`(${dimensionalWeightInMetric} kg)`}
                                {details.productInfo.dimensionalWeightStatus ? (
                                  <i className="bx bx-check fs-18 text-success"></i>
                                ) : (
                                  <i className="las la-exclamation-triangle fs-18 text-warning ms-1"></i>
                                )}
                              </span>
                            ) : (
                              <span>{t("NA")}</span>
                            )}
                          </div>
                          {(() => {
                            const diff = moment().diff(details?.productInfo.launchDate, "days");
                            const cssStyle = diff >= 90 ? "text-success" : "text-danger";
                            const icon = diff >= 90 ? "mdi mdi-check-circle" : "mdi mdi-close-circle";
                            return (
                              <>
                                <div className="d-flex align-items-center justify-content-between">
                                  <span className="text-muted">{t("ProductDetails.ProductInfo.Label.ListingLaunchDate")}</span>
                                  <span className={`${cssStyle} fw-medium`}>
                                    <Moment locale={i18n.language} fromNow>
                                      {details.productInfo?.launchDate}
                                    </Moment>
                                    <i className={`${icon} fs-14 align-middle ms-1`}></i>
                                  </span>
                                </div>
                              </>
                            );
                          })()}
                        </CardBody>
                      </Card>
                    </Col>
                    <Col xxl={3} xl={6} sm={12} className="d-flex vstack same-height">
                      <RestrictionCheck />
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default ProductInfo;
