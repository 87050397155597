import {FormFeedback} from "reactstrap";
import {Turkish} from "flatpickr/dist/l10n/tr.js";
import {english} from "flatpickr/dist/l10n/default.js";
import {FormikValidation} from "helpers/types";
import Flatpickr from "react-flatpickr";
import moment from "moment";

interface DateFilterProps<T> {
  value: any;
  field: keyof T;
  validation: FormikValidation<T>;
  className?: string;
}
const ValidatedDate = <T,>(props: DateFilterProps<T>) => {
  const fieldName = props.field.toString();
  const {error, touched, value} = props.validation.getFieldMeta(fieldName);

  return (
    <>
      <Flatpickr
        id={fieldName}
        name={fieldName}
        className={`form-control form-control-sm common-css filter-flatpickr filter-input flatpickr-input flatpickr-mobile border border-1 ${props.className}`}
        value={value}
        onChange={(date: any) => {
          if (date.length === 0) {
            props.validation.setFieldValue(fieldName, "");
          } else {
            props.validation.setFieldValue(fieldName, moment(new Date(date)).format("Y-MM-DD"));
          }
        }}
        options={
          localStorage.getItem("I18N_LANGUAGE") === "tr"
            ? {
                altInput: true,
                altFormat: "F j, Y",
                dateFormat: "Y-m-d",
                minDate: new Date("2023-01-01"),
                maxDate: new Date(),
                locale: {...Turkish},
                disableMobile: true,
              }
            : localStorage.getItem("I18N_LANGUAGE") === "en"
            ? {
                altInput: true,
                altFormat: "F j, Y",
                dateFormat: "Y-m-d",
                minDate: new Date("2023-01-01"),
                maxDate: new Date(),
                locale: {...english},
                disableMobile: true,
              }
            : {
                altInput: true,
                altFormat: "F j, Y",
                dateFormat: "Y-m-d",
                minDate: new Date("2023-01-01"),
                maxDate: new Date(),
                disableMobile: true,
              }
        }
      />
      {touched && error ? (
        <FormFeedback type="invalid" className="searches validation-width">
          {error}
        </FormFeedback>
      ) : null}
    </>
  );
};

export default ValidatedDate;
