import React, {useEffect, useRef, useState} from "react";
import Dialog, {DialogRef} from "Components/Common/Dialog";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import {useTranslation} from "react-i18next";
import {Link, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {getCostAndFeeOption, resetDefaultCostAndFees, updateCostAndFeeSettings} from "slices/settings/thunk";
import {IUpdateCostAndFeeItemDto} from "models/user_setting_cost_and_fee_options";
import {SelectOptionsType} from "Components/Hooks/SelectOptions";
import {ConstantPage} from "helpers/permission_helper";
import BreadCrumb, {BreadcrumbMenuItem} from "Components/Common/BreadCrumb";
import classnames from "classnames";
import SettingsLayout from "Components/Common/Layout/SettingsLayout";
import Loader from "Components/Common/Loader";
import AmazonMarketplaceInfos from "Components/Common/AmazonMarketplaceInfos";
import Select from "react-select";
import ErrorPopover from "Components/Common/ErrorPopover";
import * as Yup from "yup";
import {useProfile} from "Components/Hooks/UserHooks";

const PAGE_IDENTIFIER: ConstantPage = "settings.costAndFees";
const CostAndFeesEdit = () => {
  const [activeTab, setActiveTab] = useState("1");
  const {t} = useTranslation();
  const {hasPermission} = useProfile();
  const [prepAndShipHasError, setPrepAndShipHasError] = useState(false);
  const [taxesHasError, setTaxesHasError] = useState(false);
  const [feesHasError, setFeesHasError] = useState(false);
  const [otherCostsHasError, setOtherCostsHasError] = useState(false);
  const [settingsOptions, setSettingsOptions] = useState<SelectOptionsType[]>();
  const [selectedSettingOption, setSelectedSettingOption] = useState<SelectOptionsType>();
  const [amazonMarketplaceInfos] = useState(AmazonMarketplaceInfos());
  const [redirectUrl, setRedirectUrl] = useState<string>("");
  const {userSettingCostAndFeeOptionId} = useParams();
  const dispatch: any = useDispatch();
  const resetDialogRef = useRef<DialogRef>(null);

  const settingsPageData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Settings.loading,
      currentCostAndFeeOption: state.Settings.currentCostAndFeeOption,
      costAndFeesList: state.Settings.costAndFeesList,
    }),
  );
  const {loading, currentCostAndFeeOption, costAndFeesList} = useSelector(settingsPageData);

  useEffect(() => {
    if (userSettingCostAndFeeOptionId && hasPermission(PAGE_IDENTIFIER)) {
      dispatch(getCostAndFeeOption(userSettingCostAndFeeOptionId));
    }
  }, [dispatch, userSettingCostAndFeeOptionId]); // eslint-disable-line react-hooks/exhaustive-deps

  const tabChange = (tab: any) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: currentCostAndFeeOption?.name,
      isDefault: currentCostAndFeeOption?.isDefault,
      shippingCostPerLbs: currentCostAndFeeOption?.shippingCostPerLbs,
      labelingCost: currentCostAndFeeOption?.labelingCost,
      productAcceptanceCost: currentCostAndFeeOption?.productAcceptanceCost,
      minPrepAndShipPrice: currentCostAndFeeOption?.minPrepAndShipPrice,
      customsRate: currentCostAndFeeOption?.customsRate,
      stateSalesTaxRate: currentCostAndFeeOption?.stateSalesTaxRate,
      marketplaceTaxExceptionRate: currentCostAndFeeOption?.marketplaceTaxExceptionRate,
      bankTransferCommissionRate: currentCostAndFeeOption?.bankTransferCommissionRate,
      estimatedRefundRate: currentCostAndFeeOption?.estimatedRefundRate,
      currencyExchangeLossRate: currentCostAndFeeOption?.currencyExchangeLossRate,
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t("Settings.CostAndFees.Validation.Name")),
      isDefault: Yup.boolean(),
      shippingCostPerLbs: Yup.number().required(t("Settings.CostAndFees.Validation.ShippingCostPerLbs")),
      labelingCost: Yup.number().required(t("Settings.CostAndFees.Validation.LabelingCost")),
      productAcceptanceCost: Yup.number().required(t("Settings.CostAndFees.Validation.ProductAcceptanceCost")),
      minPrepAndShipPrice: Yup.number().required(t("Settings.CostAndFees.Validation.MinPrepAndShipPrice")),
      customsRate: Yup.number().required(t("Settings.CostAndFees.Validation.CustomsRate")),
      stateSalesTaxRate: Yup.number().required(t("Settings.CostAndFees.Validation.StateSalesTaxRate")),
      marketplaceTaxExceptionRate: Yup.number().required(t("Settings.CostAndFees.Validation.MarketplaceTaxExceptionRate")),
      bankTransferCommissionRate: Yup.number().required(t("Settings.CostAndFees.Validation.BankTransferCommissionRate")),
      estimatedRefundRate: Yup.number().required(t("Settings.CostAndFees.Validation.EstimatedRefundRate")),
      currencyExchangeLossRate: Yup.number().required(t("Settings.CostAndFees.Validation.CurrencyExchangeLossRate")),
    }),
    onSubmit: (values: any) => {
      const payload: IUpdateCostAndFeeItemDto = {
        userSettingCostAndFeeOptionId: userSettingCostAndFeeOptionId as string,
        isDefault: values.isDefault,
        name: values.name,
        shippingCostPerLbs: values.shippingCostPerLbs,
        labelingCost: values.labelingCost,
        productAcceptanceCost: values.productAcceptanceCost,
        minPrepAndShipPrice: values.minPrepAndShipPrice,
        customsRate: values.customsRate,
        stateSalesTaxRate: values.stateSalesTaxRate,
        marketplaceTaxExceptionRate: values.marketplaceTaxExceptionRate,
        bankTransferCommissionRate: values.bankTransferCommissionRate,
        estimatedRefundRate: values.estimatedRefundRate,
        currencyExchangeLossRate: values.currencyExchangeLoss,
      };
      const updatePromise = updateCostAndFeeSettings(payload)(dispatch);
      updatePromise.then((success) => {
        if (success) {
          validation.resetForm();
        }
      });
    },
  });

  useEffect(() => {
    if (validation.errors) {
      const errors = validation.errors;
      if (errors.shippingCostPerLbs || errors.minPrepAndShipPrice || errors.labelingCost || errors.customsRate || errors.productAcceptanceCost) setPrepAndShipHasError(true);
      else setPrepAndShipHasError(false);

      if (errors.stateSalesTaxRate || errors.marketplaceTaxExceptionRate) setTaxesHasError(true);
      else setTaxesHasError(false);

      if (errors.bankTransferCommissionRate || errors.referralFee) setFeesHasError(true);
      else setFeesHasError(false);

      if (errors.estimatedRefundRate || errors.currencyExchangeLoss) setOtherCostsHasError(true);
      else setOtherCostsHasError(false);
    }
  }, [validation.errors]);

  useEffect(() => {
    if (costAndFeesList.length > 0) {
      const optionArray: SelectOptionsType[] = [];
      costAndFeesList.forEach((item) => {
        optionArray.push({value: item.userSettingCostAndFeeOptionId, label: item.name});
      });
      setSettingsOptions(optionArray);

      const selectedOption = optionArray.find((option) => option.value === userSettingCostAndFeeOptionId);
      if (selectedOption) setSelectedSettingOption(selectedOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [costAndFeesList]);

  const breadcrumbMenus: BreadcrumbMenuItem[] = [
    {
      label: t("Account.Title"),
      url: "/account/settings/cost-and-fees",
    },
    {
      label: t("Settings.CostAndFees.Title"),
      url: "/account/settings/cost-and-fees",
    },
    {
      label: currentCostAndFeeOption.name,
      url: "",
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Settings.Title")} menus={breadcrumbMenus} />
          <SettingsLayout hasAnyChanges={validation.dirty} navigateTo={redirectUrl}>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Card style={{boxShadow: "none", marginBottom: "0px !important"}}>
                <CardHeader>
                  <Row>
                    <Col>
                      <CardTitle className="fw-medium">
                        <Row>
                          <Col>
                            {" "}
                            <i className="mdi mdi-currency-jpy fs-18 align-middle me-1"></i>
                            {t("Settings.CostAndFees.Title")} {">"} {currentCostAndFeeOption.name}
                          </Col>
                          <Col xs="auto">
                            {amazonMarketplaceInfos.map(
                              (marketplaceInfo) =>
                                marketplaceInfo.marketplace === currentCostAndFeeOption.marketplace && marketplaceInfo.active && (
                                  <>
                                    <span>
                                      <span className="hstack gap-2">
                                        <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                                          <img src={marketplaceInfo.flag} alt="Country Flag" className="rounded-circle" />
                                        </div>
                                        {`${t(marketplaceInfo.countryName)} (${marketplaceInfo.marketplace})`}
                                      </span>
                                    </span>
                                  </>
                                ),
                            )}
                          </Col>
                        </Row>
                      </CardTitle>
                      <Row className="mt-3">
                        <Col xs={12} md={4}>
                          <Label className="form-label" htmlFor="name">
                            {t("Settings.CostAndFees.Name")}
                          </Label>
                          <Input
                            id="name"
                            type="text"
                            className="form-control"
                            placeholder={t("Settings.CostAndFees.NamePlaceholder")}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name}
                            invalid={validation.touched.name && validation.errors.name ? true : false}
                          />
                          <FormFeedback>{validation.errors.name}</FormFeedback>
                        </Col>
                        <Col xs={12} md={4} className="mt-3 mt-md-0">
                          <Label className="form-label" htmlFor="selectSettings">
                            {t("Settings.CostAndFees.SelectedSettings")}
                          </Label>
                          <Select
                            id="selectSettings"
                            name="selectSettings"
                            options={settingsOptions}
                            onChange={(e: any) => {
                              const url: string = `/account/settings/cost-and-fees/edit/${e.value}`;
                              setRedirectUrl(url);
                            }}
                            value={selectedSettingOption}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardHeader>
              </Card>
              <Card>
                <CardHeader>
                  <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                    <NavItem>
                      <NavLink role="button" className={classnames({active: activeTab === "1"})} onClick={() => tabChange("1")}>
                        <Row>
                          {prepAndShipHasError && (
                            <Col xs="auto" className="text-danger pe-0">
                              <i className="ri ri-close-circle-fill"></i>
                            </Col>
                          )}
                          <Col>{t("Settings.CostAndFees.Tab.PrepAndShip")}</Col>
                        </Row>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink role="button" className={classnames({active: activeTab === "2"})} onClick={() => tabChange("2")} type="button">
                        <Row>
                          {taxesHasError && (
                            <Col xs="auto" className="text-danger pe-0">
                              <i className="ri ri-close-circle-fill"></i>
                            </Col>
                          )}
                          <Col>{t("Settings.CostAndFees.Tab.Taxes")}</Col>
                        </Row>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink role="button" className={classnames({active: activeTab === "3"})} onClick={() => tabChange("3")} type="button">
                        <Row>
                          {feesHasError && (
                            <Col xs="auto" className="text-danger pe-0">
                              <i className="ri ri-close-circle-fill"></i>
                            </Col>
                          )}
                          <Col>{t("Settings.CostAndFees.Tab.Fees")}</Col>
                        </Row>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink role="button" className={classnames({active: activeTab === "4"})} onClick={() => tabChange("4")} type="button">
                        <Row>
                          {otherCostsHasError && (
                            <Col xs="auto" className="text-danger pe-0">
                              <i className="ri ri-close-circle-fill"></i>
                            </Col>
                          )}
                          <Col>{t("Settings.CostAndFees.Tab.OtherCosts")}</Col>
                        </Row>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </CardHeader>
                <CardBody>
                  {loading.list ? (
                    <Loader />
                  ) : (
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        <Row>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label htmlFor="firstnameInput" className="form-label">
                                {t("Settings.CostAndFees.ShippingCostPerLbs")}
                              </Label>
                              <p className="text-muted">{t("Settings.CostAndFees.ShippingCostPerLbsDescription")}</p>
                              <InputGroup>
                                <Input
                                  id="shippingCostPerLbs"
                                  type="number"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.shippingCostPerLbs}
                                  invalid={validation.touched.shippingCostPerLbs && validation.errors.shippingCostPerLbs ? true : false}
                                />
                                <InputGroupText>lbs</InputGroupText>
                                <FormFeedback>{validation.errors.shippingCostPerLbs}</FormFeedback>
                              </InputGroup>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label htmlFor="lastnameInput" className="form-label">
                                {t("Settings.CostAndFees.MinPrepFee")}
                              </Label>
                              <p className="text-muted">{t("Settings.CostAndFees.MinPrepFeeDescription")}</p>
                              <Input
                                id="minPrepAndShipPrice"
                                placeholder="USD$"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.minPrepAndShipPrice}
                                invalid={validation.touched.minPrepAndShipPrice && validation.errors.minPrepAndShipPrice ? true : false}
                              />
                              <FormFeedback>{validation.errors.minPrepAndShipPrice}</FormFeedback>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label htmlFor="lastnameInput" className="form-label">
                                {t("Settings.CostAndFees.LabelingCost")}
                              </Label>
                              <p className="text-muted">{t("Settings.CostAndFees.LabelingCostDescription")}</p>
                              <InputGroup>
                                <Input
                                  id="labelingCost"
                                  type="number"
                                  placeholder="USD$"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.labelingCost}
                                  invalid={validation.touched.labelingCost && validation.errors.labelingCost ? true : false}
                                />
                                <InputGroupText>item</InputGroupText>
                                <FormFeedback>{validation.errors.labelingCost}</FormFeedback>
                              </InputGroup>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label htmlFor="emailInput" className="form-label">
                                {t("Settings.CostAndFees.CustomsRate")}
                              </Label>
                              <p className="text-muted">{t("Settings.CostAndFees.CustomsRateDescription")}</p>
                              <InputGroup>
                                <Input
                                  id="customsRate"
                                  type="number"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.customsRate}
                                  invalid={validation.touched.customsRate && validation.errors.customsRate ? true : false}
                                />
                                <InputGroupText>%</InputGroupText>
                                <FormFeedback>{validation.errors.customsRate}</FormFeedback>
                              </InputGroup>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label htmlFor="designationInput" className="form-label">
                                {t("Settings.CostAndFees.ProductAcceptanceCost")}
                              </Label>
                              <p className="text-muted">{t("Settings.CostAndFees.ProductAcceptanceCostDescription")}</p>
                              <InputGroup>
                                <Input
                                  id="productAcceptanceCost"
                                  type="number"
                                  placeholder="USD$"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.productAcceptanceCost}
                                  invalid={validation.touched.productAcceptanceCost && validation.errors.productAcceptanceCost ? true : false}
                                />
                                <InputGroupText>item</InputGroupText>
                                <FormFeedback>{validation.errors.productAcceptanceCost}</FormFeedback>
                              </InputGroup>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>

                      <TabPane tabId="2">
                        <Row>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label htmlFor="emailInput" className="form-label">
                                {t("Settings.CostAndFees.StateSalesTaxRate")}
                              </Label>
                              <p className="text-muted">{t("Settings.CostAndFees.StateSalesTaxRateDescription")}</p>
                              <InputGroup>
                                <Input
                                  id="stateSalesTaxRate"
                                  type="number"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.stateSalesTaxRate}
                                  invalid={validation.touched.stateSalesTaxRate && validation.errors.stateSalesTaxRate ? true : false}
                                />
                                <InputGroupText>%</InputGroupText>
                                <FormFeedback>{validation.errors.stateSalesTaxRate}</FormFeedback>
                              </InputGroup>
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="mb-3">
                              <Label htmlFor="emailInput" className="form-label">
                                {t("Settings.CostAndFees.MarketplaceTaxExceptionRate")}
                              </Label>
                              <p className="text-muted">{t("Settings.CostAndFees.MarketplaceTaxExceptionRateDescription")}</p>
                              <InputGroup>
                                <Input
                                  id="marketplaceTaxExceptionRate"
                                  type="number"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.marketplaceTaxExceptionRate}
                                  invalid={validation.touched.marketplaceTaxExceptionRate && validation.errors.marketplaceTaxExceptionRate ? true : false}
                                />
                                <InputGroupText>%</InputGroupText>
                                <FormFeedback>{validation.errors.marketplaceTaxExceptionRate}</FormFeedback>
                              </InputGroup>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>

                      <TabPane tabId="3">
                        <Row>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label htmlFor="emailInput" className="form-label">
                                {t("Settings.CostAndFees.BankTransferCommissionRate")}
                              </Label>
                              <p className="text-muted">{t("Settings.CostAndFees.BankTransferCommissionRateDescription")}</p>
                              <InputGroup>
                                <Input
                                  id="bankTransferCommissionRate"
                                  type="number"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.bankTransferCommissionRate}
                                  invalid={validation.touched.bankTransferCommissionRate && validation.errors.bankTransferCommissionRate ? true : false}
                                />
                                <InputGroupText>%</InputGroupText>
                                <FormFeedback>{validation.errors.bankTransferCommissionRate}</FormFeedback>
                              </InputGroup>
                            </div>
                          </Col>

                          <Col></Col>
                        </Row>
                      </TabPane>

                      <TabPane tabId="4">
                        <Row>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label htmlFor="emailInput" className="form-label">
                                {t("Settings.CostAndFees.EstimatedRefundRate")}
                              </Label>
                              <p className="text-muted">{t("Settings.CostAndFees.EstimatedRefundRateDescription")}</p>
                              <InputGroup>
                                <Input
                                  id="estimatedRefundRate"
                                  type="number"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.estimatedRefundRate}
                                  invalid={validation.touched.estimatedRefundRate && validation.errors.estimatedRefundRate ? true : false}
                                />
                                <InputGroupText>%</InputGroupText>
                                <FormFeedback>{validation.errors.estimatedRefundRate}</FormFeedback>
                              </InputGroup>
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="mb-3">
                              <Label htmlFor="emailInput" className="form-label">
                                {t("Settings.CostAndFees.CurrencyExchangeLoss")}
                              </Label>
                              <p className="text-muted">{t("Settings.CostAndFees.CurrencyExchangeLossDescription")}</p>
                              <InputGroup>
                                <Input
                                  id="currencyExchangeLossRate"
                                  type="number"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.currencyExchangeLossRate}
                                  invalid={validation.touched.currencyExchangeLossRate && validation.errors.currencyExchangeLossRate ? true : false}
                                />
                                <InputGroupText>%</InputGroupText>
                                <FormFeedback>{validation.errors.currencyExchangeLossRate}</FormFeedback>
                              </InputGroup>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  )}
                </CardBody>
              </Card>
              {!loading.list && (
                <Row>
                  <Col xs={12} md className="mb-3 mb-md-0">
                    <i className="ri-arrow-go-back-line align-middle me-1"></i>
                    <Link
                      color="link"
                      className="link-primary link-offset-2 text-decoration-underline link-underline-opacity-25 link-underline-opacity-100-hover"
                      onClick={() => resetDialogRef.current?.show()}
                      to={""}
                    >
                      {t("Settings.CostAndFees.Button.RestoreDefaultSettings")}
                    </Link>
                  </Col>
                  <Col xs={12} md="auto" className="d-flex align-items-center">
                    <Col className="me-2 d-flex justify-content-end">
                      <div className="form-check mb-0">
                        <Input
                          id="isDefault"
                          type="checkbox"
                          className="form-check-input"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.isDefault}
                          checked={validation.values.isDefault}
                        />
                        <Label className="form-check-label" for="isDefault">
                          {t("Settings.CostAndFees.Button.SetAsDefault")}
                        </Label>
                      </div>
                    </Col>
                    <Col xs="auto">{Object.keys(validation.errors).length > 0 && <ErrorPopover validationErrors={validation.errors} />}</Col>
                    <Col xs="auto" className="text-end">
                      <Button type="submit" color="success" disabled={loading.update || Object.keys(validation.errors).length > 0}>
                        {loading.update && <Spinner size="sm" className="me-2"></Spinner>}
                        {t("Settings.CostAndFees.Button.SaveChanges")}
                      </Button>
                    </Col>
                  </Col>
                </Row>
              )}
            </Form>
          </SettingsLayout>
        </Container>
      </div>
      <Dialog
        ref={resetDialogRef}
        color="info"
        buttons={["yes", "no"]}
        busy={loading.update}
        iconClass="ri-arrow-go-back-line"
        message={t("Settings.CostAndFees.Dialog.RestoreDefaultSettings.Description")}
        title={t("Settings.CostAndFees.Dialog.RestoreDefaultSettings.Title")}
        onButtonClick={async (button, hide) => {
          if (button === "yes") {
            await resetDefaultCostAndFees(currentCostAndFeeOption?.userSettingCostAndFeeOptionId)(dispatch);
          }
          resetDialogRef.current?.hide();
        }}
      />
    </React.Fragment>
  );
};

export default CostAndFeesEdit;
