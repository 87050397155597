import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ApiError, LoadingStates, PagedList} from "helpers/types";
import {LogEntry} from "models/log_entry";
import {Subscription} from "models/subscription";
import {User, UserStatus} from "models/user";
import {UserPayment} from "models/user_payment";

type AdminUsersLoadingStates = LoadingStates<"list" | "listSubscriptions" | "listPaymentHistory" | "listLogHistory" | "filter" | "block" | "unblock" | "resetPassword" | "downloadPayment" | "loginAs">;

export type AdminUsersState = {
  list: Partial<PagedList<User>>;
  paymentHistoryList: Partial<PagedList<UserPayment>>;
  logHistoryList: Partial<PagedList<LogEntry>>;
  loginToken: string;
  loading: AdminUsersLoadingStates;
  subscriptions: Subscription[];
  error?: ApiError;
};

const initialState: AdminUsersState = {
  list: {items: []},
  paymentHistoryList: {items: []},
  logHistoryList: {items: []},
  loginToken: "",
  loading: {
    list: false,
    listSubscriptions: false,
    filter: true,
    block: false,
    unblock: false,
    resetPassword: false,
    listPaymentHistory: false,
    listLogHistory: false,
    downloadPayment: false,
    loginAs: false,
  },
  subscriptions: [] as Subscription[],
  error: {} as ApiError,
};

const UsersSlice = createSlice({
  name: "Users",
  initialState,
  reducers: {
    setUsersList(state, action: PayloadAction<PagedList<User>>) {
      state.list = action.payload;
    },
    setSubscriptionList(state, action: PayloadAction<Subscription[]>) {
      state.subscriptions = action.payload;
    },
    setUserPaymentHistoryList(state, action: PayloadAction<PagedList<UserPayment>>) {
      state.paymentHistoryList = action.payload;
    },
    setUserLogHistoryList(state, action: PayloadAction<PagedList<LogEntry>>) {
      state.logHistoryList = action.payload;
    },
    setLoginToken(state, action: PayloadAction<string>) {
      state.loginToken = action.payload;
    },
    updateUserStatusAsBlock(state, action: PayloadAction<User>) {
      const user = action.payload;

      state.list.items = state.list.items?.map((u) => {
        if (u.userId === user.userId) {
          u.status = UserStatus.BANNED;
        }
        return u;
      });
    },
    updateUserStatusAsActive(state, action: PayloadAction<User>) {
      const user = action.payload;

      state.list.items = state.list.items?.map((u) => {
        if (u.userId === user.userId) {
          u.status = UserStatus.ACTIVE;
        }
        return u;
      });
    },
    loading(state, action: PayloadAction<[keyof AdminUsersLoadingStates, boolean]>) {
      const [operation, loadingState] = action.payload;
      state.loading[operation] = loadingState;
    },
    apiError(state, action: PayloadAction<ApiError>) {
      state.error = action.payload;
    },
    reset(state) {
      state.loading = initialState.loading;
      state.list = initialState.list;
      state.error = initialState.error;
    },
  },
});

export const {setUsersList, setSubscriptionList, setUserPaymentHistoryList, setUserLogHistoryList, setLoginToken, updateUserStatusAsBlock, updateUserStatusAsActive, loading, apiError, reset} = UsersSlice.actions;

export default UsersSlice.reducer;
