import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Card, CardBody, Col, Row} from "reactstrap";
import {UserShipmentItem} from "models/user_shipment_item";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {useSelector} from "react-redux";
import SimpleBar from "simplebar-react";
import errorImage from "assets/images/svg/product.svg";
import DomainToFlag from "Components/Common/DomainToFlag";
import CopyAsinWidget from "Components/Common/CopyAsinWidget";
import DisplayPrice from "Components/Common/DisplayPrice";
import NoData from "Components/Common/NoData";
import {UserStore} from "models/user_stores";

const RemainingProducts = () => {
  const {t} = useTranslation();
  const [remainingProducts, setRemainingProducts] = useState<UserShipmentItem[]>();
  const [userStore, setUserStore] = useState<UserStore>();
  const shipmentsData = createSelector(
    (state: RootState) => state,
    (state) => ({
      currentUserShipment: state.Shipment.currentUserShipment,
    }),
  );

  const {currentUserShipment} = useSelector(shipmentsData);

  useEffect(() => {
    if (currentUserShipment) {
      let remainingProductsFiltered = currentUserShipment.userShipmentItems.filter((item) => item.totalItems - item.soldItems > 0);
      remainingProductsFiltered = remainingProductsFiltered.sort((a, b) => {
        const soldLeftA = a.soldItems - a.totalItems;
        const soldLeftB = b.soldItems - b.totalItems;
        return soldLeftA - soldLeftB;
      });
      setRemainingProducts(remainingProductsFiltered);
      setUserStore(currentUserShipment.userStore);
    }
  }, [currentUserShipment]);

  return (
    <Col xs={12} lg={5} className="same-height">
      <Card className="card-animate">
        <div className="align-items-center d-flex card-header">
          <h4 className="card-title mb-0 flex-grow-1">{t("Shipments.ShippedOrCompleted.RemainingProducts.Title")}</h4>
        </div>
        <CardBody>
          {remainingProducts && remainingProducts.length > 0 ? (
            <div className="table-responsive table-card">
              <SimpleBar style={{height: "500px"}}>
                <table className="table-hover table table-centered align-middle mb-0">
                  <tbody>
                    {remainingProducts &&
                      remainingProducts.map((item: UserShipmentItem, index) => {
                        const imagePath = `https://m.media-amazon.com/images/I/${item.userInventory.imageSmall}`;
                        return (
                          <tr key={index}>
                            <td>
                              <div className="d-flex">
                                <div className="flex-shrink-0">
                                  <div className="avatar-md rounded flex-shrink-0 overflow-hidden">
                                    <img
                                      className="member-img img-fluid d-block rounded"
                                      src={imagePath}
                                      onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                                        e.currentTarget.onerror = null;
                                        e.currentTarget.src = errorImage;
                                      }}
                                      alt=""
                                    />
                                  </div>
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <div className="text-muted d-flex align-items-center text-nowrap mb-1">
                                    <DomainToFlag marketplace={userStore?.marketplace ?? ""} />
                                    <span>
                                      {userStore?.marketplace} - {userStore?.name}
                                    </span>
                                  </div>
                                  <p className="ellipsis-two-lines mb-0" title={item.userInventory.productName}>
                                    {item.userInventory.productName}
                                  </p>
                                  <div className="d-flex flex-wrap gap-2 align-items-center">
                                    <div className="d-flex">
                                      <CopyAsinWidget Asin={item.userInventory.asin} index={item.userInventory.asin} asLink={true} marketplace={userStore?.marketplace} />
                                    </div>
                                    <div className="d-flex">
                                      <CopyAsinWidget Asin={item.userInventory.sku} index={item.userInventory.sku} asLink={false} />
                                    </div>
                                  </div>
                                  <div className="d-flex gap-1 mt-2">
                                    <span className="badge bg-danger-subtle text-danger fs-12">{item.userInventory.fbaSellerCount} FBA</span>
                                    <span className="badge bg-success-subtle text-success fs-12">{item.userInventory.fbmSellerCount} FBM</span>
                                    <span className="badge bg-primary-subtle text-primary fs-12">
                                      {t("Stock")}: {item.userInventory.afnFulfillableQuantity}
                                    </span>
                                  </div>
                                  <Row className="mt-3">
                                    <Col xs={12} lg={6} className="mb-1">
                                      <span className="d-flex flex-nowrap">
                                        <i className="text-primary mdi mdi-trophy-variant-outline align-middle me-1"></i>
                                        <span title={item.userInventory.categoryName}>
                                          <span className="ellipsis-single-line">
                                            <strong>BSR:</strong> {item.currentBSR} - {item.userInventory.categoryName}
                                          </span>
                                        </span>
                                      </span>
                                    </Col>
                                    <Col xs={12} lg={6} className="mb-1">
                                      <span className="d-flex flex-nowrap">
                                        <i className="text-success mdi mdi-bank-plus align-middle me-1"></i>
                                        <strong className="me-1">{t("Shipments.ShippedOrCompleted.RemainingProducts.Label.EstimatedRevenue")}:</strong>
                                        <DisplayPrice source={item.userInventory.currencyCodeSource} value={item.userInventory.salesProceeds} decimals={2} notation="decimal"/>
                                      </span>
                                    </Col>

                                    <Col xs={12} lg={6} className="mb-1">
                                      <span className="d-flex flex-nowrap">
                                        <i className="text-secondary mdi mdi-bank-check align-middle me-1"></i>
                                        <strong className="me-1">{t("Shipments.ShippedOrCompleted.RemainingProducts.Label.EstimatedProfit")}:</strong>
                                        <DisplayPrice source={item.userInventory.currencyCodeSource} value={item.userInventory.profit} decimals={2} notation="decimal"/>
                                      </span>
                                    </Col>
                                    <Col xs={12} lg={6} className="mb-1">
                                      <span className="d-flex flex-nowrap">
                                        <i className="text-secondary mdi mdi-cash-register align-middle me-1"></i>
                                        <strong className="me-1">{t("Shipments.ShippedOrCompleted.RemainingProducts.Label.Cost")}: </strong>
                                        <DisplayPrice source={item.userInventory.currencyCodeSource} value={item.userInventory.profit} decimals={2} notation="decimal"/>
                                      </span>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </SimpleBar>
            </div>
          ) : (
            <NoData icon="mdi mdi-note-search-outline" />
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default RemainingProducts;
