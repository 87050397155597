import React, {useEffect, useState} from "react";
import {Card, CardBody, Col, Row} from "reactstrap";
import {useTranslation} from "react-i18next";
import {SuccessAnalysis} from "models/dashboard";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {useSelector} from "react-redux";
import CountUp from "react-countup";
import DisplayPrice from "Components/Common/DisplayPrice";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";

const SuccessAnalysisWidget = () => {
  const {t} = useTranslation();

  const [successAnalysis, setSuccessAnalysis] = useState<SuccessAnalysis>({} as SuccessAnalysis);

  const dashboardData = createSelector(
    (state: RootState) => state,
    (state) => ({
      statistics: state.Dashboard.statistics,
      filter: state.Dashboard.filter,
    }),
  );

  const {filter, statistics} = useSelector(dashboardData);

  useEffect(() => {
    if (statistics) {
      setSuccessAnalysis(statistics.successAnalysis);
    }
  }, [statistics]);

  return (
    <React.Fragment>
      <Col xl={6} className="same-height">
        <Card className="card-animate">
          <CardBody className="p-0">
            <div className="border-0 rounded-top rounded-0 m-0 d-flex align-items-center alert alert-info fade show" role="alert">
              <i className="bx bx-package me-1 fs-16"></i>
              {t("Dashboard.Widgets.Label.SuccessAnalysis.Title")}
            </div>
            <Row className="row-cols-md-3 row-cols-1">
              <Col className={"col-lg"}>
                <div className="mt-3 mt-md-0 py-3 px-3">
                  <h5 className="text-muted text-uppercase fs-13">{t("Dashboard.Widgets.Label.SuccessRate")}</h5>
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0 fs-2">
                      <i className={"text-secondary mdi mdi-trophy"}></i>
                    </div>
                    <div className="flex-grow-1 ms-2">
                      <h4 className="mb-0 d-flex align-items-center">
                        <span className="counter-value">
                          <CountUp start={0} prefix={"%"} end={successAnalysis?.successRate} decimals={2} duration={2} />
                        </span>
                        <i className="mdi mdi-information align-bottom ms-1 fs-14 text-secondary" id="success-rate-info"></i>
                        <DefaultUncontrolledTooltip target="success-rate-info">{t("Dashboard.Widgets.Info.SuccessRate")}</DefaultUncontrolledTooltip>
                      </h4>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className={"col-lg"}>
                <div className="mt-3 mt-md-0 py-3 px-3">
                  <h5 className="text-muted text-uppercase fs-13">{t("Dashboard.Widgets.Label.Speed")}</h5>
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0 fs-2">
                      <i className={"text-info mdi mdi-rocket-launch"}></i>
                    </div>
                    <div className="flex-grow-1 ms-2">
                      <h4 className="mb-0">
                        <div className="counter-value">
                          <CountUp start={0} end={successAnalysis?.speed} decimals={2} duration={2} />
                        </div>
                        <div className="fs-14 text-muted">{t("Dashboard.Widgets.Label.ItemsPerDay")}</div>
                      </h4>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="row-cols-md-3 row-cols-1">
              <Col className={"col-lg"}>
                <div className="mt-3 mt-md-0 py-3 px-3">
                  <h5 className="text-muted text-uppercase fs-13"> {t("Dashboard.Widgets.Label.EstimatedProfit")}</h5>
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0 fs-2">
                      <i className={"text-success mdi mdi-bank-check"}></i>
                    </div>
                    <div className="flex-grow-1 ms-2">
                      <h4 className="mb-0">
                        <div className="counter-value">
                          <DisplayPrice source={filter?.currency} value={successAnalysis?.estimatedProfit} decimals={2} notation="abbreviated" countUp />
                        </div>
                        <div id="realized-profit" className="fs-14 text-muted">
                          <DisplayPrice source={filter?.currency} value={successAnalysis?.actualProfit} decimals={2} notation="abbreviated" countUp />
                        </div>
                        <DefaultUncontrolledTooltip placement="top-start" target="realized-profit">
                          {t("Dashboard.Widgets.Tooltip.RealizedProfit")}
                        </DefaultUncontrolledTooltip>
                      </h4>
                    </div>
                  </div>
                </div>
              </Col>

              <Col className={"col-lg"}>
                <div className="mt-3 mt-md-0 py-3 px-3">
                  <h5 className="text-muted text-uppercase fs-13"> {t("Dashboard.Widgets.Label.Competition")}</h5>
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0 fs-1">
                      <i className={"text-info mdi mdi-trophy-award"}></i>
                    </div>
                    <div className="flex-grow-1 ms-2">
                      <h4 className="mb-0">{successAnalysis?.competitionLevel ? t(successAnalysis?.competitionLevel) : "..."}</h4>
                      <div className="fs-14 text-muted">
                        {t("Dashboard.Widgets.Label.AvgFbaSellers")}: {successAnalysis?.avgFbaSellerCount}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default SuccessAnalysisWidget;
