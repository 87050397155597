import {Button, Card, CardBody, Col, Form, Label, Row, Spinner} from "reactstrap";
import {useFormik} from "formik";
import {RefObject, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {ScanAndSaveListRef} from "..";
import {getRelativeDateByTimezone, getToday} from "helpers/utilities";
import {useNavigate} from "react-router-dom";
import {useProfile} from "Components/Hooks/UserHooks";
import {ScanAndSaveQuery} from "api/query";
import SelectOptions from "Components/Hooks/SelectOptions";
import PlaceholderGlow from "Components/Common/PlaceholderGlow";
import moment from "moment";
import ValidatedDate from "Components/Common/ValidatedDate";
import ValidatedSelect from "Components/Common/ValidatedSelect";
import * as Yup from "yup";

interface FiltersProps {
  listRef: RefObject<ScanAndSaveListRef>;
  busy: boolean;
  page: number;
  pageSize: number;
  handleFilter: (filter: ScanAndSaveQuery) => void;
}
const Filters = (props: FiltersProps) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const {scanAndSaveStatusSelectOptions} = SelectOptions();
  const {userProfile} = useProfile();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const start = queryParams.get("start");
    const end = queryParams.get("end");

    var payload: ScanAndSaveQuery = {
      page: 1,
      pageSize: props.pageSize,
      filtering: true,
    };
    if (start && end) {
      setStartDate(new Date(start));
      setEndDate(new Date(end));
      validation.setFieldValue("startDate", new Date(start));
      validation.setFieldValue("endDate", new Date(end));
      payload = {
        ...payload,
        startDate: moment
          .tz(start, userProfile?.timezone!)
          .startOf("day")
          .tz("Etc/GMT")
          .toDate(),
        endDate: moment
          .tz(end, userProfile?.timezone!)
          .endOf("day")
          .tz("Etc/GMT")
          .toDate(),
      };
    } else {
      setStartDate(getRelativeDateByTimezone(30, userProfile?.timezone));
      setEndDate(getToday().toDate());
      validation.setFieldValue("startDate", getRelativeDateByTimezone(30, userProfile?.timezone));
      validation.setFieldValue("endDate", getToday().toDate());
      payload = {
        ...payload,
        startDate: getRelativeDateByTimezone(30, userProfile?.timezone),
        endDate: getToday()
          .tz(userProfile?.timezone!)
          .endOf("day")
          .toDate(),
      };
    }

    props.handleFilter(payload);
  }, []); // eslint-disable-line

  // validation
  const validation = useFormik({
    initialValues: {
      startDate: startDate,
      endDate: endDate,
      status: "",
    } as ScanAndSaveQuery,
    validationSchema: Yup.object({
      startDate: Yup.date()
        .min(new Date("2023-01-01"), t("ScanAndSave.Filters.Validation.StartDateMin"))
        .max(Yup.ref("endDate"), t("ScanAndSave.Filters.Validation.StartDateMax"))
        .typeError(t("ScanAndSave.Filters.Validation.InvalidDateType")),
      endDate: Yup.date()
        .min(Yup.ref("startDate"), t("ScanAndSave.Filters.Validation.EndDateMin"))
        .max(getToday().toDate(), t("ScanAndSave.Filters.Validation.EndDateMax"))
        .typeError(t("ScanAndSave.Filters.Validation.InvalidDateType")),
      statusFilter: Yup.string()
        .test("invalid-status-filter", t("ScanAndSave.Filters.Validation.Status"), (valuesArray: any) => {
          if (!valuesArray) {
            return true;
          }

          valuesArray.split(",").forEach((value: any) => {
            if (!scanAndSaveStatusSelectOptions.some((option) => option.value === value)) {
              return false;
            }
          });

          return true;
        })
        .nullable(),
    }),
    onSubmit: (values: any) => {
      const payload: ScanAndSaveQuery = {
        page: 1,
        pageSize: props.pageSize,
        startDate: moment
          .tz(values.startDate, userProfile?.timezone!)
          .startOf("day")
          .tz("Etc/GMT")
          .toDate(),
        endDate: moment
          .tz(values.endDate, userProfile?.timezone!)
          .tz(userProfile?.timezone!)
          .endOf("day")
          .toDate(),
        status: values.statusFilter ? values.statusFilter.split(",") : undefined,
        filtering: true,
      };
      props.handleFilter(payload);
    },
  });

  return (
    <PlaceholderGlow busy={props.busy}>
      <Card style={{zIndex:4}}>
        <CardBody>
          <div className="live-preview">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Row className="align-items-center">
                <Col>
                  <div className="flex-shrink-0">
                    <Label className="form-label text-muted">{t("ScanAndSave.Filters.Title")}</Label>
                  </div>
                </Col>
                <Col xs="auto" align="right">
                  <Button type="button" color="info" disabled={props.busy} onClick={() => navigate("/scan-and-save/new")}>
                    <i className="ri-add-line align-bottom me-1"></i>
                    {t("ScanAndSave.Button.NewScanAndSave")}
                  </Button>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm={2} className="mb-3 scan-and-save filters-col">
                  <Label htmlFor="startDate">{t("ScanAndSave.Filters.StartDate")}</Label>
                  <ValidatedDate field="startDate" validation={validation} value={validation.values.startDate}></ValidatedDate>
                </Col>
                <Col sm={2} className="mb-3 scan-and-save filters-col">
                  <Label htmlFor="endDate">{t("ScanAndSave.Filters.EndDate")}</Label>
                  <ValidatedDate field="endDate" validation={validation} value={validation.values.endDate}></ValidatedDate>
                </Col>
                <Col sm={2} className="mb-3 scan-and-save filters-col">
                  <Label htmlFor="statusFilter">{t("ScanAndSave.Filters.Status")}</Label>
                  <ValidatedSelect
                    className="common-css filter-input"
                    options={scanAndSaveStatusSelectOptions}
                    validation={validation}
                    field="statusFilter"
                    value={validation.values.statusFilter}
                    errorStyle="solid"
                  />
                </Col>
                <Col sm={2}>
                  <div className="common-css top-5 mt-4">
                    <Button type="submit" className="btn bg-primary" disabled={props.busy}>
                      {props.busy ? <Spinner size="sm" className="me-2"></Spinner> : <i className="ri-equalizer-fill me-1 align-bottom"></i>}
                      {t("ScanAndSave.Filters.Button.Apply")}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </CardBody>
      </Card>
    </PlaceholderGlow>
  );
};

export default Filters;
