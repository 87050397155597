import BreadCrumb, {BreadcrumbMenuItem} from "Components/Common/BreadCrumb";
import Dialog, {DialogRef} from "Components/Common/Dialog";
import DataTable, {DataTableRef} from "Components/Common/DataTable";
import {useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import {useImmer} from "use-immer";
import {useDebounce} from "Components/Hooks/HelperHooks";
import {deleteUserShipment, getUserShipments, resetShipmentState} from "slices/shipment/thunk";
import {RootState} from "slices";
import {ColumnDef} from "@tanstack/react-table";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {setCurrentUserShipment} from "slices/shipment/reducer";
import {Card, CardBody, Col, Container, Progress} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {useTranslation} from "react-i18next";
import {FilterShipmentsQuery, IDeleteUserShipmentDto, UserShipment, UserShipmentStatus} from "models/user_shipment";
import {formatDate, getShipmentSpeed} from "helpers/utilities";
import {useProfile} from "Components/Hooks/UserHooks";
import {ConstantPage} from "helpers/permission_helper";
import AmazonMarketplaceInfos from "Components/Common/AmazonMarketplaceInfos";
import Loader from "Components/Common/Loader";
import Filters from "./Filters";
import EditShipment from "./Modal/EditShipment";
import Restricted from "Components/Common/Restricted";
import Unauthorized from "pages/Errors/_Unauthorized";
import DomainToFlag from "Components/Common/DomainToFlag";
import DisplayPrice from "Components/Common/DisplayPrice";
import RenderNumber from "Components/Common/RenderNumber";
import NoResult from "Components/Common/NoResult";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import DisplayDate from "Components/Common/DisplayDate";

export type ShipmentListRef = {
  reload: VoidFunction;
};

const PAGE_IDENTIFIER: ConstantPage = "shipments";
const Shipments = () => {
  const {t} = useTranslation();
  const {userProfile, hasPermission} = useProfile();
  const dispatch: any = useDispatch();
  const [amazonMarketplaceInfos] = useState(AmazonMarketplaceInfos());
  const [isEditing, setIsEditing] = useState(false);
  const deleteDialogRef = useRef<DialogRef>(null);
  const location = useLocation();
  const navigate = useNavigate();

  const tableRef = useRef<DataTableRef>(null);
  const listRef = useRef<ShipmentListRef>(null);
  const [query, updateQuery] = useImmer<FilterShipmentsQuery>({
    createDateRange: {
      start: undefined,
      end: undefined,
    },
    userStoreIds: [],
    statuses: [],
    page: 1,
    pageSize: 10,
    filtering: true,
  });

  const debouncedLoadList = useDebounce(() => {
    if (hasPermission(PAGE_IDENTIFIER)) {
      getUserShipments(query)(dispatch).then(() => {
        tableRef.current?.resetSelection();
      });
    }
  }, 200);
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (query.createDateRange?.start !== undefined) {
      searchParams.set("createDateStart", formatDate(query.createDateRange.start));
    } else {
      searchParams.delete("createDateStart");
    }
    if (query.createDateRange?.end !== undefined) {
      searchParams.set("createDateEnd", formatDate(query.createDateRange.end, true));
    } else {
      searchParams.delete("createDateEnd");
    }
    if (query.shipDateRange?.start !== undefined) {
      searchParams.set("shipDateStart", formatDate(query.shipDateRange.start));
    } else {
      searchParams.delete("shipDateStart");
    }
    if (query.shipDateRange?.end !== undefined) {
      searchParams.set("shipDateEnd", formatDate(query.shipDateRange.end, true));
    } else {
      searchParams.delete("shipDateEnd");
    }
    navigate(
      {
        pathname: location.pathname,
        search: searchParams.toString(),
      },
      {replace: true},
    );
    debouncedLoadList();
  }, [debouncedLoadList, query]); //eslint-disable-line

  useImperativeHandle(
    listRef,
    () => {
      return {
        reload: () => {
          debouncedLoadList();
        },
      };
    },
    [debouncedLoadList],
  );

  const shipmentsData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Shipment.loading,
      userShipmentList: state.Shipment.userShipmentList,
      currentUserShipment: state.Shipment.currentUserShipment,
      userStores: state.Common.userStores,
    }),
  );

  const {loading, userShipmentList, currentUserShipment, userStores} = useSelector(shipmentsData);

  const columns = useMemo<ColumnDef<UserShipment, any>[]>(
    () => [
      {
        header: t("Actions"),
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserShipment;
          return (
            <>
              <div className="d-flex flex-row align-items-center">
                <Col xs="auto">
                  <Link
                    to=""
                    id={`EditUserShipment-${row.userShipmentId}`}
                    className="btn btn-ghost-info px-1 py-0 fs-18"
                    onClick={() => {
                      dispatch(setCurrentUserShipment(row));
                      setIsEditing(true);
                    }}
                  >
                    <i className="ri-pencil-fill"></i>
                  </Link>
                  <DefaultUncontrolledTooltip target={`EditUserShipment-${row.userShipmentId}`}>{t("Edit")}</DefaultUncontrolledTooltip>
                </Col>
                <Col xs="auto">
                  <Link
                    to=""
                    id={`DeleteUserShipment-${row.userShipmentId}`}
                    className="btn btn-ghost-danger px-1 py-0 fs-18"
                    onClick={() => {
                      dispatch(setCurrentUserShipment(row));
                      deleteDialogRef.current?.show();
                    }}
                  >
                    <i className="ri-delete-bin-fill"></i>
                  </Link>
                  <DefaultUncontrolledTooltip target={`DeleteUserShipment-${row.userShipmentId}`}>{t("Delete")}</DefaultUncontrolledTooltip>
                </Col>
              </div>
            </>
          );
        },
      },
      {
        header: t("Shipments.TableColumn.NameDate"),
        accessorKey: "name",
        sortingFn: "alphanumeric",
        size: 200,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserShipment;
          return (
            <>
              <div className="mb-1 d-flex">
                <Link className="link-secondary link-offset-2 text-decoration-underline me-1" target="_self" to={`/shipments/shipment-details/${row.userShipmentId}`}>
                  {row.name}
                  <i className="ri-arrow-right-up-line"></i>
                </Link>
              </div>
              <span className="fs-14 my-1 text-nowrap">
                <DisplayDate id={`DisplayDateUserShipmentCreateDate-${row.userShipmentId}`} value={row.createDate} format="D MMM YYYY HH:mm" tz={userProfile?.timezone} />
              </span>
              <div className="d-flex align-items-center text-nowrap text-muted">
                <DomainToFlag marketplace={row.userStore.marketplace} />
                <span>{`${row.userStore.marketplace} - ${row.userStore.name}`}</span>
              </div>
            </>
          );
        },
      },
      {
        header: t("Shipments.TableColumn.Status"),
        size: 100,
        accessorKey: "status",
        sortingFn: "alphanumericCaseSensitive",
        cell: (cellProps) => {
          const row = cellProps.row.original as UserShipment;
          const status = row.status as UserShipmentStatus;
          const statusText: string = row.status === UserShipmentStatus.DRAFT ? "Draft" : row.status === UserShipmentStatus.SHIPPED ? "Shipped" : "Completed";
          const cssClass =
            status === UserShipmentStatus.DRAFT
              ? "bg-warning-subtle text-warning fs-12"
              : status === UserShipmentStatus.SHIPPED
              ? "bg-success-subtle text-success fs-12"
              : status === UserShipmentStatus.COMPLETED
              ? "bg-secondary-subtle text-secondary"
              : "bg-danger-subtle text-danger";
          return (
            <>
              {status === UserShipmentStatus.SHIPPED ? (
                <div className="d-flex flex-column">
                  <span className={`badge ${cssClass} fs-12 my-1`}>{t(statusText)}</span>
                  <DisplayDate id={`DisplayDateShipmentShipDate-${row.userShipmentId}`} value={row.shipDate} format="D MMM YYYY" tz={userProfile?.timezone} />
                </div>
              ) : (
                <span className={`badge ${cssClass} fs-12`}>{t(statusText)}</span>
              )}
            </>
          );
        },
      },
      {
        header: t("Shipments.TableColumn.SkuUnits"),
        size: 180,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserShipment;
          return (
            <>
              <h5 className="fs-14 my-1 align-items-center d-flex nowrap">{`${row.totalSKU} ${row.totalSKU <= 1 ? t("SKU") : t("SKUs")}`}</h5>
              <span className="text-muted nowrap">{`${row.totalItems} ${row.totalItems <= 1 ? t("Unit") : t("Units")}`}</span>
            </>
          );
        },
      },
      {
        header: t("Shipments.TableColumn.SoldLeftItems"),
        size: 200,
        accessorKey: "totalItems",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as UserShipment;
          return (
            <>
              <h5 className="fs-13 align-items-center d-flex">
                <span className="text-nowrap">
                  {`${row.soldItems} ${t("Shipments.Label.Sold")}`} <i className="mdi mdi-slash-forward"></i> {`${row.totalItems - row.soldItems} ${t("Shipments.Label.Left")}`}
                </span>
              </h5>
              <Progress color="secondary" value={(row.soldItems / row.totalItems) * 100} className="progress-sm" />
            </>
          );
        },
      },
      {
        header: t("Shipments.TableColumn.Speed"),
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserShipment;
          const {dayPassed, shipmentSpeed} = getShipmentSpeed(row);
          return (
            <>
              {row.status === UserShipmentStatus.DRAFT ? (
                <>-</>
              ) : (
                <>
                  <div className="align-items-center d-flex">
                    <i className="text-info mdi mdi-rocket-launch me-1 fs-14"></i>
                    <span className="text-nowrap">{t("Shipments.Label.SpeedPerItem", {value: shipmentSpeed.toFixed(2)})}</span>
                  </div>
                  <div className="align-items-center d-flex">
                    <i className="text-secondary mdi mdi-calendar-clock me-1 fs-14"></i>
                    <span className="text-nowrap">{t("Shipments.Label.DayPassed", {value: dayPassed})}</span>
                  </div>
                </>
              )}
            </>
          );
        },
      },
      {
        header: t("Shipments.TableColumn.RevenueAndProfit"),
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserShipment;
          const sourceCurrency = amazonMarketplaceInfos.find((info) => info.marketplace === row.userStore.marketplace)?.currency;
          return (
            <>
              <span className="text-muted text-nowrap">
                <DisplayPrice source={sourceCurrency!} value={row.userShipmentFinance.actualSoldRevenue} decimals={2} notation="abbreviated" />
              </span>
              <h5 className="fs-14 my-1 text-nowrap">
                <DisplayPrice source={sourceCurrency!} value={row.userShipmentFinance.actualSoldProfit} decimals={2} notation="abbreviated" />
              </h5>
            </>
          );
        },
      },
      {
        header: t("Shipments.TableColumn.RoiMargin"),
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserShipment;
          return (
            <>
              <h5 className="fs-14 my-1">
                <RenderNumber value={row.userShipmentFinance.actualSoldROI} decimals={0} prefix="%" suffix="" />
              </h5>
              <span className="text-muted">
                <RenderNumber value={row.userShipmentFinance.actualSoldMargin} decimals={0} prefix="%" suffix="" />
              </span>
            </>
          );
        },
      },
      {
        header: t("Shipments.TableColumn.TotalCosts"),
        size: 200,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserShipment;
          const sourceCurrency = amazonMarketplaceInfos.find((info) => info.marketplace === row.userStore.marketplace)?.currency;
          return (
            <>
              <h5 className="fs-14 my-1 text-nowrap">
                <DisplayPrice source={sourceCurrency!} value={row.userShipmentFinance.actualTotalCostTarget} decimals={2} notation="abbreviated" />
              </h5>
            </>
          );
        },
      },
      {
        header: t("Shipments.TableColumn.TotalPayouts"),
        size: 200,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserShipment;
          const sourceCurrency = amazonMarketplaceInfos.find((info) => info.marketplace === row.userStore.marketplace)?.currency;
          return (
            <>
              <h5 className="fs-14 my-1 text-nowrap">
                <DisplayPrice source={sourceCurrency!} value={row.userShipmentFinance.estimatedSoldPayout + row.userShipmentFinance.estimatedLeftPayout} decimals={2} notation="abbreviated" />
              </h5>
            </>
          );
        },
      },
      {
        header: t("Shipments.TableColumn.CostRecovery"),
        size: 230,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserShipment;
          return (
            <>
              <h5 className="fs-13">
                <RenderNumber value={row.userShipmentFinance.costRecoveryStatusRate} decimals={0} prefix="%" suffix={` ${t("Dashboard.LatestShipments.Label.Recovered")}`} />
              </h5>
              <Progress color="secondary" value={row.userShipmentFinance.costRecoveryStatusRate} className="progress-sm" />
            </>
          );
        },
      },
      {
        header: t("Shipments.TableColumn.SuccessRate"),
        size: 200,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserShipment;
          return (
            <>
              <h5 className="my-1 text-nowrap align-items-center d-flex">
                <i className="text-secondary mdi mdi-trophy me-1 fs-16"></i>
                <RenderNumber value={(row.userShipmentFinance.actualSoldProfit / row.userShipmentFinance.expectedSoldProfit) * 100} decimals={2} prefix="%" suffix=" " />
              </h5>
            </>
          );
        },
      },
    ],
    [t], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    return () => {
      resetShipmentState()(dispatch);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  document.title = t("PageTitles.Shipments");

  const breadcrumbMenus: BreadcrumbMenuItem[] = [
    {
      label: t("Shipments.Title"),
    },
  ];
  return (
    <Restricted require={PAGE_IDENTIFIER} read fallback={() => <Unauthorized />}>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Shipments.Title")} menus={breadcrumbMenus} />
          <Filters handleFilter={(f) => updateQuery(f)} busy={loading.filter} page={query.page} pageSize={query.pageSize} listRef={listRef} userStores={userStores} />
          <Card>
            <CardBody>
              {loading.filter ? (
                <>
                  <Loader />
                </>
              ) : userShipmentList.items && userShipmentList.items.length > 0 ? (
                <>
                  <DataTable
                    ref={tableRef}
                    busy={loading.list}
                    columns={columns}
                    data={userShipmentList?.items || []}
                    totalDataLength={userShipmentList?.totalCount}
                    pagination={{
                      pageIndex: query.page - 1,
                      pageSize: query.pageSize,
                    }}
                    onPaginationChanged={(pagination) =>
                      updateQuery((q) => {
                        q.page = pagination.pageIndex + 1;
                        q.pageSize = pagination.pageSize;
                        q.filtering = false;
                      })
                    }
                    hovered
                  />
                </>
              ) : (
                <NoResult title={t("Shipments.NoResult.Title")} description={t("Shipments.NoResult.Description")} />
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
      <EditShipment isOpen={isEditing} onReload={() => listRef.current?.reload()} toggle={() => setIsEditing(!isEditing)} from="Shipments" shipment={currentUserShipment} />
      <Dialog
        ref={deleteDialogRef}
        color="danger"
        buttons={["yes", "no"]}
        busy={loading.delete}
        iconClass="ri-delete-bin-line"
        message={t("Shipments.Dialog.Delete.Description", {shipmentName: currentUserShipment?.name})}
        title={t("Shipments.Dialog.Delete.Title")}
        onButtonClick={async (button) => {
          if (button === "yes") {
            let deleteUserShipmentRequest: IDeleteUserShipmentDto = {
              userShipmentId: currentUserShipment.userShipmentId,
              asin: "",
              from: "Shipments",
            };
            await deleteUserShipment(deleteUserShipmentRequest)(dispatch);
            listRef.current?.reload();
          }
          deleteDialogRef.current?.hide();
        }}
      />
    </Restricted>
  );
};

export default Shipments;
