import AmazonMarketplaceInfos, {AmazonMarketplaceInfosType} from "Components/Common/AmazonMarketplaceInfos";
import React, {useCallback, useEffect, useState} from "react";
import useSelectOptions, {SelectOptionsType} from "Components/Hooks/SelectOptions";
import {ButtonGroup, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown} from "reactstrap";
import {GetStatisticsQuery} from "api/query";
import {useProfile} from "Components/Hooks/UserHooks";
import {useTranslation} from "react-i18next";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {useDispatch, useSelector} from "react-redux";
import {UserStore} from "models/user_stores";
import {reInitFilter} from "slices/dashboard/reducer";
import {Link, useNavigate} from "react-router-dom";
import {updateDashboardFilter} from "helpers/local_storage";
import {getRelativeDateByTimezone, getToday} from "helpers/utilities";
import {MultiSelect} from "react-multi-select-component";
import {defaultItemRenderer} from "Components/Common/Select/MultiSelectStyle";
import Select from "react-select";

const TODAY = 1;
const YESTERDAY = 2;
const LAST_7_DAYS = 7;
const LAST_14_DAYS = 14;
const LAST_30_DAYS = 30;
const LAST_90_DAYS = 90;
const LAST_180_DAYS = 180;
const LAST_365_DAYS = 365;
interface SectionProps {
  handleFilter: (filter: GetStatisticsQuery) => void;
}
const Section = (props: SectionProps) => {
  const {userProfile} = useProfile();
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {multiSelectTranslations} = useSelectOptions();
  const dashboardData = createSelector(
    (state: RootState) => state,
    (state) => ({
      filter: state.Dashboard.filter,
      userStores: state.Common.userStores,
      commonLoading: state.Common.loading,
      layoutModeType: state.Layout.layoutModeType,
    }),
  );

  const {filter, userStores, commonLoading, layoutModeType} = useSelector(dashboardData);

  const [amazonMarketplaceInfos] = useState(AmazonMarketplaceInfos());
  const [storeOptions, setStoreOptions] = useState<SelectOptionsType[]>([]);

  const [selectedStores, setSelectedStores] = useState<SelectOptionsType[]>([]);
  const [selectedCurrency, setSelectedCurrency] = useState<SelectOptionsType>();
  const [selectedDayFilter, setSelectedDayFilter] = useState<number>(filter?.dateRange || 1);

  useEffect(() => {
    if (userStores) {
      const stores: SelectOptionsType[] = userStores.map((store: UserStore) => {
        const mp = amazonMarketplaceInfos.find((info: AmazonMarketplaceInfosType) => info.marketplace === store.marketplace)!;
        return {
          value: store.userStoreId,
          label: t(mp.countryName) + " - " + store.name,
        };
      });
      setStoreOptions(stores);
    }
  }, [userStores]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    //! LOAD DASHBOARD FILTER HERE FROM LOCAL STORAGE
    if (userStores.length > 0) {
      loadSelectedStoresFilter();
      loadSelectedCurrencyFilter();
      loadSelectedDayFilter();
    } else {
      loadSelectedCurrencyFilter();
      setSelectedCurrency({value: "USD", label: "USD"});
      handleCurrencySelection({value: "USD", label: "USD"});
    }
  }, [userStores]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadSelectedStoresFilter = () => {
    const savedStores = filter?.stores?.map((store: UserStore) => {
      return {value: store.userStoreId, label: store.name};
    });

    // If saved stores are not found, load all user stores
    const storeList = savedStores?.length
      ? savedStores
      : userStores?.map((store: UserStore) => ({
          value: store.userStoreId,
          label: store.name,
        })) ?? [];
    setSelectedStores(storeList);
  };

  const loadSelectedCurrencyFilter = () => {
    const savedCurrency = filter?.currency;
    if (savedCurrency) {
      setSelectedCurrency({value: savedCurrency, label: savedCurrency});
    } else {
      const differentMarketplaces = new Set(userStores.map((store) => store.marketplace));
      if (differentMarketplaces.size > 1) {
        handleCurrencySelection({value: "USD", label: "USD"});
      } else {
        const marketplaceName = Array.from(differentMarketplaces)[0];
        const mp = amazonMarketplaceInfos.find((info) => info.marketplace === marketplaceName)!;
        handleCurrencySelection({value: mp?.currency, label: mp?.currency});
      }
    }
  };

  const loadSelectedDayFilter = () => {
    const value = filter?.dateRange || 1;
    setSelectedDayFilter(value);
  };

  const handleStoreSelection = (selectedStoreList: SelectOptionsType[]) => {
    setSelectedStores(selectedStoreList);
    if (selectedStoreList.length !== 0) {
      const stores = selectedStoreList.map((store: SelectOptionsType) => {
        return userStores.find((userStore: UserStore) => userStore.userStoreId === store.value);
      }) as UserStore[];
      updateDashboardFilter({stores});
    } else {
      updateDashboardFilter({stores: userStores});
    }
    dispatch(reInitFilter());
  };

  const handleCurrencySelection = (selectedCurrency: SelectOptionsType) => {
    setSelectedCurrency(selectedCurrency);
    updateDashboardFilter({currency: selectedCurrency.value});
    dispatch(reInitFilter());
  };

  const handleDayChange = (selectedDay: number) => {
    setSelectedDayFilter(selectedDay);
    updateDashboardFilter({dateRange: selectedDay});
    dispatch(reInitFilter());
  };

  const handleFilterChange = useCallback(() => {
    updateDashboardFilter({userId: userProfile?.userId});

    let startDate: Date;
    let endDate: Date = getToday()
      .tz(userProfile?.timezone!)
      .endOf("day")
      .toDate();

    switch (selectedDayFilter) {
      case 1:
        startDate = getRelativeDateByTimezone(1 - 1, userProfile?.timezone);
        break;
      case 7:
        startDate = getRelativeDateByTimezone(7 - 1, userProfile?.timezone);
        break;
      case 14:
        startDate = getRelativeDateByTimezone(14 - 1, userProfile?.timezone);
        break;
      case 30:
        startDate = getRelativeDateByTimezone(30 - 1, userProfile?.timezone);
        break;
      case 90:
        startDate = getRelativeDateByTimezone(90 - 1, userProfile?.timezone);
        break;
      case 180:
        startDate = getRelativeDateByTimezone(180 - 1, userProfile?.timezone);
        break;
      case 365:
        startDate = getRelativeDateByTimezone(365 - 1, userProfile?.timezone);
        break;
      default:
        startDate = getRelativeDateByTimezone(1, userProfile?.timezone);
    }

    if (filter && filter.currency) {
      props.handleFilter({
        userStoreIds: selectedStores.map((store: SelectOptionsType) => store.value),
        dateRange: selectedDayFilter,
        startDate: startDate,
        endDate: endDate,
        currencyCode: filter?.currency,
      });
    }
  }, [selectedStores, selectedDayFilter, filter]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    handleFilterChange();
  }, [handleFilterChange]);

  const getGreetingsTitle = () => {
    const hour = getToday()
      .toDate()
      .getHours();
    if (hour >= 0 && hour <= 6) {
      return t("Dashboard.Greetings.Night", {value: userProfile?.firstName});
    } else if (hour > 6 && hour <= 12) {
      return t("Dashboard.Greetings.Morning", {value: userProfile?.firstName});
    } else if (hour > 12 && hour <= 18) {
      return t("Dashboard.Greetings.Afternoon", {value: userProfile?.firstName});
    } else if (hour > 18 && hour <= 24) {
      return t("Dashboard.Greetings.Evening", {value: userProfile?.firstName});
    }
  };

  const getGreetingsDescription = useCallback(() => {
    switch (filter?.dateRange) {
      case TODAY:
        return t("Dashboard.Greetings.Description.Today");
      case YESTERDAY:
        return t("Dashboard.Greetings.Description.Yesterday");
      case LAST_7_DAYS:
        return t("Dashboard.Greetings.Description.Last7Days");
      case LAST_14_DAYS:
        return t("Dashboard.Greetings.Description.Last14Days");
      case LAST_30_DAYS:
        return t("Dashboard.Greetings.Description.Last30Days");
      case LAST_90_DAYS:
        return t("Dashboard.Greetings.Description.Last90Days");
      case LAST_180_DAYS:
        return t("Dashboard.Greetings.Description.Last180Days");
      case LAST_365_DAYS:
        return t("Dashboard.Greetings.Description.Last365Days");
      default:
        return t("Dashboard.Greetings.Description.Today");
    }
  }, [filter?.dateRange, t]);

  const currencyOptions = (): SelectOptionsType[] => {
    const uniqueData: SelectOptionsType[] = [];
    const seenInfos = new Set();

    amazonMarketplaceInfos.forEach((info: AmazonMarketplaceInfosType) => {
      if (!seenInfos.has(info.currency) && (info.active || info.currency === "TRY" || info.isDefaultUs)) {
        seenInfos.add(info.currency);
        uniqueData.push({value: info.currency, label: info.currency});
      }
    });

    return uniqueData;
  };

  return (
    <React.Fragment>
      <Row className="mb-3 pb-1">
        <Col xs={12}>
          <div className="d-flex align-items-lg-center flex-lg-row flex-column">
            <div className="flex-grow-1">
              <h4 className="fs-16 mb-1">{getGreetingsTitle()}</h4>
              <p className="text-muted mb-0">{getGreetingsDescription()}</p>
            </div>
            <div className="mt-3 mt-lg-0">
              <form action="#">
                <Row className="g-3 mb-0 align-items-center">
                  {!commonLoading.userStore && storeOptions && storeOptions.length === 0 ? (
                    <div className="col-sm-auto">
                      <Link to="/stores/connect" role="button" className="btn btn-soft-danger waves-effect w-100">
                        <i className="mdi mdi-connection me-1"></i>
                        {t("ConnectYourStore")}
                      </Link>
                    </div>
                  ) : (
                    <div className="col-sm-auto" style={{width: "250px"}}>
                      <MultiSelect
                        className={`w-100 ${layoutModeType}`}
                        labelledBy="Select"
                        options={storeOptions}
                        value={selectedStores}
                        onChange={(selectedItems: any) => handleStoreSelection(selectedItems)}
                        overrideStrings={{
                          ...multiSelectTranslations,
                          selectSomeItems: t("General.Select.SelectStore"),
                          allItemsAreSelected: t("General.Select.AllStoresSelected"),
                        }}
                        ItemRenderer={defaultItemRenderer}
                      />
                    </div>
                  )}
                  <div className="col-sm-auto">
                    <Select
                      value={selectedCurrency}
                      isMulti={false}
                      onChange={(selectedSingle: any) => {
                        handleCurrencySelection(selectedSingle);
                      }}
                      options={currencyOptions()}
                      menuPortalTarget={document.body}
                      styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                    />
                  </div>
                  <div className="col-sm-auto">
                    <Row>
                      <Col className="pe-0">
                        <UncontrolledDropdown>
                          <DropdownToggle className="form-control user-input" color="white" style={{background: "var(--vz-secondary-bg)", borderColor: "var(--vz-border-color)"}} caret>
                            {selectedDayFilter === TODAY
                              ? t("Today")
                              : selectedDayFilter === YESTERDAY
                              ? t("Yesterday")
                              : selectedDayFilter === LAST_7_DAYS
                              ? t("Last7Days")
                              : selectedDayFilter === LAST_14_DAYS
                              ? t("Last14Days")
                              : selectedDayFilter === LAST_30_DAYS
                              ? t("Last30Days")
                              : selectedDayFilter === LAST_90_DAYS
                              ? t("Last90Days")
                              : selectedDayFilter === LAST_180_DAYS
                              ? t("Last180Days")
                              : selectedDayFilter === LAST_365_DAYS
                              ? t("Last365Days")
                              : ""}
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem onClick={() => handleDayChange(1)}>{t("Today")}</DropdownItem>
                            <DropdownItem onClick={() => handleDayChange(2)}>{t("Yesterday")}</DropdownItem>
                            <DropdownItem onClick={() => handleDayChange(7)}>{t("Last7Days")}</DropdownItem>
                            <DropdownItem onClick={() => handleDayChange(14)}>{t("Last14Days")}</DropdownItem>
                            <DropdownItem onClick={() => handleDayChange(30)}>{t("Last30Days")}</DropdownItem>
                            <DropdownItem onClick={() => handleDayChange(90)}>{t("Last90Days")}</DropdownItem>
                            <DropdownItem onClick={() => handleDayChange(180)}>{t("Last180Days")}</DropdownItem>
                            <DropdownItem onClick={() => handleDayChange(365)}>{t("Last365Days")}</DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </Col>
                      <Col xs="auto">
                        <ButtonGroup>
                          <UncontrolledDropdown>
                            <DropdownToggle className="btn btn-soft-info btn-icon waves-effect waves-light layout-rightside-btn">
                              <i className="ri-pulse-line"></i>
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem onClick={() => navigate("/searches/new/bulk")}>
                                <i className="mdi mdi-chart-timeline-variant-shimmer text-muted fs-16 align-middle me-1"></i>
                                <span className="align-middle text-dark">{t("Dashboard.Widgets.Button.NewAnalyze")}</span>
                              </DropdownItem>
                              <DropdownItem onClick={() => navigate("/scan-and-save/new")}>
                                <i className="ri-bug-2-line text-muted fs-16 align-middle me-1"></i>
                                <span className="align-middle text-dark">{t("Dashboard.Widgets.Button.NewScan")}</span>
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </ButtonGroup>
                      </Col>
                    </Row>
                  </div>
                </Row>
              </form>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Section;
