import React, {useCallback, useEffect, useMemo, useState} from "react";
import {getSubscriptionLocalData, LOCAL_STORAGE} from "helpers/local_storage";
import {Row, Col, CardBody, Card, Container, Label, Form, Button, Spinner, FormFeedback, Nav, NavItem, NavLink, TabPane, TabContent, Alert} from "reactstrap";
import {Link} from "react-router-dom";
import {Subscription} from "models/subscription";
import {initializeSubscription, getAllSubscriptionList, removeCoupon, validateCoupon} from "slices/account/newSubscription/thunk";
import {useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {useProfile} from "Components/Hooks/UserHooks";
import {SubscriptionPeriodType} from "models/local_storage";
import {numberFormatter} from "helpers/utilities";
import ValidatedInput from "Components/Common/ValidatedInput";
import Cleave from "cleave.js/react";
import countryList from "react-select-country-list";
import Loader from "Components/Common/Loader";
import ParticlesAuth from "pages/Authentication/ParticlesAuth";
import NonAuthLayout from "layouts/NonAuthLayout";
import logoTransparent from "assets/images/logo-dark-transparent.png";
import classnames from "classnames";
import ValidatedSelect from "Components/Common/ValidatedSelect";
import * as Yup from "yup";
import _ from "lodash";

const MONTHLY_PLAN: SubscriptionPeriodType = "month";
const SIX_MONTH_PLAN: SubscriptionPeriodType = "month6";
const YEARLY_PLAN: SubscriptionPeriodType = "annually";

const NewSubscriptionPage = () => {
  const {userProfile} = useProfile();
  const [activeArrowTab, setActiveArrowTab] = useState(1);
  const [passedArrowSteps, setPassedArrowSteps] = useState([1]);
  const [planActiveTab, setPlanActiveTab] = useState<SubscriptionPeriodType>(MONTHLY_PLAN);
  const [selectedSubscription, setSelectedSubscription] = useState<Subscription>();
  const [selectedSubscriptionPrice, setSelectedSubscriptionPrice] = useState<string>(""); // Stripe price
  const {t} = useTranslation();
  const countryOptions = useMemo(() => countryList().getData(), []);
  const dispatch: any = useDispatch();

  useEffect(() => {
    dispatch(getAllSubscriptionList());
  }, [dispatch]);

  const newSubscriptionData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.NewSubscription.loading,
      list: state.NewSubscription.list,
      couponStatus: state.NewSubscription.couponStatus,
      couponDetails: state.NewSubscription.couponDetails,
    }),
  );

  const {loading, list, couponStatus, couponDetails} = useSelector(newSubscriptionData);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      cardName: "",
      cardNumber: "",
      expireDate: "",
      cvc: "",
      countryCode: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zipCode: "",
      couponCode: "",
    },
    validationSchema: Yup.object({
      cardName: Yup.string().required(t("Account.Subscription.Validation.CardName")),
      cardNumber: Yup.string().required(t("Account.Subscription.Validation.CardNumber")),
      expireDate: Yup.string().required(t("Account.Subscription.Validation.ExpiryDate")),
      cvc: Yup.string().required(t("Account.Subscription.Validation.CVC")),
      countryCode: Yup.string().required(t("Please Select Country")),
      addressLine1: Yup.string().required(t("Account.Subscription.Validation.AddressLine1")),
      addressLine2: Yup.string(),
      city: Yup.string().required(t("Account.Subscription.Validation.City")),
      state: Yup.string().required(t("Account.Subscription.Validation.State")),
      zipCode: Yup.string().required(t("Account.Subscription.Validation.ZipCode")),
      couponCode: Yup.string(),
    }),
    onSubmit: async (values) => {
      const period = (() => {
        switch (planActiveTab) {
          case MONTHLY_PLAN:
            return 1;
          case SIX_MONTH_PLAN:
            return 6;
          default:
            return 12;
        }
      })();
      const email = userProfile?.email!;
      const name = `${userProfile?.firstName ?? ""} ${userProfile?.lastName ?? ""}`;
      const coupon = couponStatus === "applied" ? values.couponCode : "";
      const price: number = planActiveTab === "month" ? selectedSubscription?.priceMonth! : planActiveTab === "month6" ? selectedSubscription?.priceSixMonth! : selectedSubscription?.priceYear!;
      await initializeSubscription({
        billingPeriodMonth: period,
        subscriptionId: (selectedSubscription && selectedSubscription.subscriptionId) || "",
        subscriptionPriceId: selectedSubscriptionPrice,
        email: email,
        name: name,
        cardName: values.cardName,
        cardNumber: values.cardNumber,
        expireMonth: parseInt(values.expireDate.split("/")[0]),
        expireYear: parseInt(values.expireDate.split("/")[1]),
        cvc: values.cvc,
        countryCode: values.countryCode,
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2,
        city: values.city,
        state: values.state,
        zipCode: values.zipCode,
        couponCode: coupon,
      },price )(dispatch);
    },
  });

  const toggleArrowTab = useCallback(
    (tab: any) => {
      if (activeArrowTab !== tab) {
        var modifiedSteps = [...passedArrowSteps, tab];

        if (tab >= 1 && tab <= 2) {
          setActiveArrowTab(tab);
          setPassedArrowSteps(modifiedSteps);
        }
      }
    },
    [activeArrowTab, passedArrowSteps],
  );

  const togglePlanTab = (tab: SubscriptionPeriodType) => {
    if (planActiveTab !== tab) {
      setPlanActiveTab(tab);
    }
  };

  const handle_subscriptionSelection = (s: Subscription) => {
    setSelectedSubscription(s);

    switch (planActiveTab) {
      case MONTHLY_PLAN:
        setSelectedSubscriptionPrice(s.monthPriceId);
        break;
      case SIX_MONTH_PLAN:
        setSelectedSubscriptionPrice(s.sixMonthPriceId);
        break;
      case YEARLY_PLAN:
        setSelectedSubscriptionPrice(s.yearPriceId);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (list) {
      const localData = getSubscriptionLocalData();
      if (localData && localData.tier && localData.frequency) {
        const subscription = list.find((x) => _.toLower(x.name) === localData.tier);
        if (subscription) {
          handle_subscriptionSelection(subscription);
          setPlanActiveTab(localData.frequency);
        }
      }
    }
  }, [list]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      <NonAuthLayout>
        <ParticlesAuth>
          <div className="auth-page-content mt-lg-5">
            <Container>
              <Row className="mb-5">
                <Col lg={12}>
                  <div className="text-center mt-5 mb-4 text-white-50">
                    <div>
                      <div className="d-inline-block auth-logo">
                        <img src={logoTransparent} alt="" height="50" />
                      </div>
                    </div>
                    <p className="mt-3 fs-15 fw-medium">{t("General.Slogan")}</p>
                  </div>
                </Col>
              </Row>

              <Col lg={12} className="mx-auto">
                <Card>
                  <CardBody className="p-0">
                    <Card className="mb-0">
                      <CardBody
                        className="p-4"
                        style={{
                          minHeight: "200px",
                        }}
                      >
                        <div className="">
                          <div className="step-arrow-nav mb-4">
                            <Nav className="nav-pills custom-nav nav-justified" role="tablist">
                              <NavItem>
                                <NavLink
                                  className={classnames({
                                    active: activeArrowTab === 1,
                                    done: activeArrowTab <= 2 && activeArrowTab > 1,
                                  })}
                                >
                                  <div className="d-flex flex-row justify-content-center align-items-center">
                                    {activeArrowTab > 1 ? (
                                      <i className="text-success mdi mdi-checkbox-marked-circle fs-20 me-1 mt-1"></i>
                                    ) : (
                                      <i className="text-success mdi mdi-progress-check fs-20 me-1 mt-1"></i>
                                    )}
                                    <span className="mt-1">{t("Account.Subscription.NewSubscription.Tab.ChoosePlan")}</span>
                                  </div>
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  className={classnames({
                                    active: activeArrowTab === 2,
                                    done: activeArrowTab <= 1 && activeArrowTab > 2,
                                  })}
                                >
                                  <div className="d-flex flex-row justify-content-center align-items-center">
                                    {activeArrowTab > 2 ? (
                                      <i className="text-success mdi mdi-checkbox-marked-circle fs-20 me-1 mt-1"></i>
                                    ) : (
                                      <i className="text-success mdi mdi-progress-check fs-20 me-1 mt-1"></i>
                                    )}
                                    <span className="mt-1">{t("Account.Subscription.NewSubscription.Tab.StartYourFreeTrial")}</span>
                                  </div>
                                </NavLink>
                              </NavItem>
                            </Nav>
                          </div>
                          <TabContent activeTab={activeArrowTab}>
                            <TabPane tabId={1}>
                              <Row className="justify-content-center mt-4">
                                <Col lg={12}>
                                  <div className="text-center mb-4">
                                    <h4 className="fw-semibold fs-22 mt-4">{t("Account.Subscription.NewSubscription.Title")}</h4>
                                    <div className="justify-content-center mb-4 row">
                                      <p className="text-muted fs-15 col-lg-6">{t("Account.Subscription.NewSubscription.Description")}</p>
                                    </div>

                                    <div className="d-inline-flex">
                                      <Nav className="nav-pills arrow-navtabs plan-nav rounded mb-3 p-1" id="pills-tab" role="tablist">
                                        <NavItem>
                                          <NavLink
                                            href="#"
                                            className={classnames(
                                              {
                                                active: planActiveTab === MONTHLY_PLAN,
                                              },
                                              "fw-semibold",
                                            )}
                                            onClick={() => {
                                              togglePlanTab(MONTHLY_PLAN);
                                            }}
                                          >
                                            {t("Monthly")}
                                          </NavLink>
                                        </NavItem>
                                        <NavItem>
                                          <NavLink
                                            href="#"
                                            className={classnames(
                                              {
                                                active: planActiveTab === SIX_MONTH_PLAN,
                                              },
                                              "fw-semibold",
                                            )}
                                            onClick={() => {
                                              togglePlanTab(SIX_MONTH_PLAN);
                                            }}
                                          >
                                            {t("Account.Subscription.6-Month")} <span className="badge bg-success">{t("Account.Subscription.1-MonthFree")}</span>
                                          </NavLink>
                                        </NavItem>
                                        <NavItem>
                                          <NavLink
                                            href="#"
                                            className={classnames(
                                              {
                                                active: planActiveTab === YEARLY_PLAN,
                                              },
                                              "fw-semibold",
                                            )}
                                            onClick={() => {
                                              togglePlanTab(YEARLY_PLAN);
                                            }}
                                          >
                                            {t("Account.Subscription.12-Month")} <span className="badge bg-success">{t("Account.Subscription.2-MonthFree")}</span>
                                          </NavLink>
                                        </NavItem>
                                      </Nav>
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                {loading.list ? (
                                  <Loader />
                                ) : (
                                  list.map((subscription: Subscription, key: any) => (
                                    <Col xxl={4} lg={6} key={key} className="same-height">
                                      <Card className="pricing-box ribbon-box right">
                                        {subscription.name.startsWith("Professional") && (
                                          <div className="ribbon-two ribbon-two-danger">
                                            <span>{t("Account.Subscription.Popular")}</span>
                                          </div>
                                        )}
                                        <CardBody className="bg-light m-2 p-4 d-flex flex-column">
                                          <div className="d-flex align-items-center mb-3">
                                            <div className="">
                                              <TabContent activeTab={planActiveTab}>
                                                <TabPane tabId={MONTHLY_PLAN}>
                                                  <Row>
                                                    <Col xs={12} className="mb-3">
                                                      <h5 className="mb-0 fw-semibold">{subscription.name}</h5>
                                                    </Col>
                                                    <Col>
                                                      <h4 className="month mb-0 text-muted">
                                                        <del>${subscription.priceMonth}</del>
                                                        <del className="small fs-14">
                                                          <i className="mdi mdi-slash-forward"></i> {t("Account.Subscription.Month")}
                                                        </del>
                                                      </h4>
                                                      <h2 className="mt-3">
                                                        <span className="badge rounded-pill badge bg-success">{t("Free")}</span>{" "}
                                                        <small className="fs-14 text-muted fw-semibold">{t("for 7 days")}</small>
                                                      </h2>
                                                    </Col>
                                                  </Row>
                                                </TabPane>
                                                <TabPane tabId={SIX_MONTH_PLAN}>
                                                  <Row>
                                                    <Col xs={12} className="mb-3">
                                                      <h5 className="mb-0 fw-semibold">{subscription.name}</h5>
                                                    </Col>
                                                    <Col>
                                                      <h4 className="month mb-0 text-muted">
                                                        <del>${subscription.priceSixMonth}</del>
                                                        <del className="small fs-14">
                                                          <i className="mdi mdi-slash-forward"></i> {t("Account.Subscription.6-Month")}
                                                        </del>
                                                      </h4>
                                                      <h2 className="mt-3">
                                                        <span className="badge rounded-pill badge bg-success">{t("Free")}</span>{" "}
                                                        <small className="fs-14 text-muted fw-semibold">{t("for 7 days")}</small>
                                                      </h2>
                                                    </Col>
                                                  </Row>
                                                </TabPane>
                                                <TabPane tabId={YEARLY_PLAN}>
                                                  <Row>
                                                    <Col xs={12} className="mb-3">
                                                      <h5 className="mb-0 fw-semibold">{subscription.name}</h5>
                                                    </Col>
                                                    <Col>
                                                      <h4 className="month mb-0 text-muted">
                                                        <del>${subscription.priceYear}</del>
                                                        <del className="small fs-14">
                                                          <i className="mdi mdi-slash-forward"></i> {t("Account.Subscription.Year")}
                                                        </del>
                                                      </h4>
                                                      <h2 className="mt-3">
                                                        <span className="badge rounded-pill badge bg-success">{t("Free")}</span>{" "}
                                                        <small className="fs-14 text-muted fw-semibold">{t("for 7 days")}</small>
                                                      </h2>
                                                    </Col>
                                                  </Row>
                                                </TabPane>
                                              </TabContent>
                                            </div>
                                          </div>

                                          <p className="text-muted">{t(subscription.description)}</p>
                                          <ul className="list-unstyled vstack gap-3">
                                            <li>
                                              <div className="d-flex">
                                                <div className="flex-shrink-0 text-success me-1">
                                                  <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                </div>
                                                <div className="flex-grow-1">{t("Account.Subscription.Features.DailyProductAnalysis", {limitCount: numberFormatter(subscription.dailySearch)})}</div>
                                              </div>
                                            </li>
                                            <li>
                                              <div className="d-flex">
                                                <div className="flex-shrink-0 text-success me-1">
                                                  <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                </div>
                                                <div className="flex-grow-1">{t("Account.Subscription.Features.DailyScanAndSave", {limitCount: numberFormatter(subscription.dailyCrawlerScan)})}</div>
                                              </div>
                                            </li>
                                            <li>
                                              <div className="d-flex">
                                                <div className="flex-shrink-0 text-success me-1">
                                                  <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                </div>
                                                <div className="flex-grow-1">{t("Account.Subscription.Features.CrossBorderArbitrage")}</div>
                                              </div>
                                            </li>
                                            <li>
                                              <div className="d-flex">
                                                <div className="flex-shrink-0 text-success me-1">
                                                  <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                </div>
                                                <div className="flex-grow-1">{t("Account.Subscription.Features.ShipmentManagement")}</div>
                                              </div>
                                            </li>
                                            <li>
                                              <div className="d-flex">
                                                <div className="flex-shrink-0 text-success me-1">
                                                  <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                </div>
                                                <div className="flex-grow-1">{t("Account.Subscription.Features.ProfitCalculator")}</div>
                                              </div>
                                            </li>
                                            <li>
                                              <div className="d-flex">
                                                <div className="flex-shrink-0 text-success me-1">
                                                  <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                </div>
                                                <div className="flex-grow-1">{t("Account.Subscription.Features.DetailedProductAnalysis")}</div>
                                              </div>
                                            </li>
                                            <li>
                                              <div className="d-flex">
                                                <div className="flex-shrink-0 text-success me-1">
                                                  <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                </div>
                                                <div className="flex-grow-1">{t("Account.Subscription.Features.StoreAndCategoryScanning")}</div>
                                              </div>
                                            </li>
                                            <li>
                                              <div className="d-flex">
                                                <div className="flex-shrink-0 text-success me-1">
                                                  <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                </div>
                                                <div className="flex-grow-1">{t("Account.Subscription.Features.SalesForecasting")}</div>
                                              </div>
                                            </li>
                                            <li>
                                              <div className="d-flex">
                                                <div className="flex-shrink-0 text-success me-1">
                                                  <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                </div>
                                                <div className="flex-grow-1">{t("Account.Subscription.Features.AccountingAndSalesManagement")}</div>
                                              </div>
                                            </li>
                                            <li>
                                              <div className="d-flex">
                                                <div className="flex-shrink-0 text-success me-1">
                                                  <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                </div>
                                                <div className="flex-grow-1">{t("Account.Subscription.Features.CompetitorAnalysis")}</div>
                                              </div>
                                            </li>
                                            <li>
                                              <div className="d-flex">
                                                <div className="flex-shrink-0 text-success me-1">
                                                  <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                </div>
                                                <div className="flex-grow-1">{t("Account.Subscription.Features.ComprehensiveDashboard")}</div>
                                              </div>
                                            </li>
                                            <li>
                                              <div className="d-flex">
                                                <div className="flex-shrink-0 text-success me-1">
                                                  <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                </div>
                                                <div className="flex-grow-1">{t("Account.Subscription.Features.ProductRestrictionChecks")}</div>
                                              </div>
                                            </li>
                                            {subscription.name.toString().startsWith("Premium") && (
                                              <li>
                                                <div className="d-flex">
                                                  <div className="flex-shrink-0 text-success me-1">
                                                    <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                  </div>
                                                  <div className="flex-grow-1">{t("Account.Subscription.Features.AdditionalUsers")}</div>
                                                </div>
                                              </li>
                                            )}
                                          </ul>
                                          <div className="mt-3 pt-2 mt-auto">
                                            {selectedSubscription?.subscriptionId === subscription.subscriptionId ? (
                                              <Link to="#" className="btn btn-danger disabled w-100">
                                                {t("Account.Subscription.Button.SelectedPlan")}
                                              </Link>
                                            ) : (
                                              <Button type="button" className="btn btn-info w-100 " onClick={() => handle_subscriptionSelection(subscription)}>
                                                {t("Account.Subscription.Button.SelectPlan")}
                                              </Button>
                                            )}
                                          </div>
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  ))
                                )}
                              </Row>
                              <div className="d-flex align-items-start gap-3 ">
                                <button
                                  type="button"
                                  id="btnSubscriptionNext"
                                  className="btn btn-success btn-label right ms-auto nexttab"
                                  onClick={() => toggleArrowTab(2)}
                                  disabled={!selectedSubscription}
                                >
                                  <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                                  {t("Next")}
                                </button>
                              </div>
                            </TabPane>

                            <TabPane tabId={2}>
                              <Row className="justify-content-center">
                                <Col>
                                  <div className="text-center mt-4">
                                    <h4 className="fw-semibold fs-22">{t("Account.Subscription.NewSubscription.Tab.StartYourFreeTrial")}</h4>
                                    <div className="justify-content-center mb-4 row">
                                      <p className="text-muted fs-15 col-lg-8">{t("Account.Subscription.NewSubscription.DescriptionTrialStart")}</p>
                                    </div>

                                    <h4 className="text-success d-flex align-items-center justify-content-center">
                                      <i className="mdi mdi-checkbox-marked-circle fs-20 me-1"></i>
                                      {t("Account.Subscription.PreferredPlan")}
                                    </h4>
                                    <span className="fs-14 text-muted">
                                      {selectedSubscription?.name}
                                      {planActiveTab === MONTHLY_PLAN
                                        ? " - " + t("Account.Subscription.Monthly")
                                        : planActiveTab === SIX_MONTH_PLAN
                                        ? " - " + t("Account.Subscription.6-Month")
                                        : " - " + t("Account.Subscription.12-Month")}
                                      (
                                      <del>
                                        {planActiveTab === MONTHLY_PLAN
                                          ? `$${selectedSubscription?.priceMonth}`
                                          : planActiveTab === SIX_MONTH_PLAN
                                          ? `$${selectedSubscription?.priceSixMonth}`
                                          : `$${selectedSubscription?.priceYear}`}
                                      </del>
                                      )
                                    </span>
                                    <h2 className="mt-2">
                                      <span className="badge rounded-pill badge bg-success">{t("Free")}</span>
                                      <small className="fs-14 text-muted fw-semibold ms-2">{t("for 7 days")}</small>
                                    </h2>
                                  </div>
                                  <div className="p-4 mt-5">
                                    <Form
                                      onSubmit={(e) => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                        return false;
                                      }}
                                      className="needs-validation"
                                      action="#"
                                    >
                                      <Row className="justify-content-center">
                                        <Col className="mb-xl-0 mb-5" lg={5} sm={12}>
                                          <h4 className="mb-4">{t("Account.Subscription.BillingAddress")}</h4>
                                          <div className="clearfix "></div>
                                          <Row className="mb-3 p-0">
                                            <Col>
                                              <Label htmlFor="countryCode" className="text-start form-label ">
                                                {t("Account.Subscription.Country")}
                                                <span className="text-danger">*</span>
                                              </Label>
                                              <ValidatedSelect
                                                className="w-100"
                                                options={countryOptions}
                                                placeholder={t("Account.Subscription.CountryPlaceholder")}
                                                validation={validation}
                                                field="countryCode"
                                                value={validation.values.countryCode}
                                                errorStyle="container"
                                              />
                                            </Col>
                                          </Row>

                                          <Row className="mb-3">
                                            <Col>
                                              <Label htmlFor="addressLine1" className="float-start form-label">
                                                {t("Account.Subscription.AddressLine1")}
                                                <span className="text-danger">*</span>
                                              </Label>
                                              <ValidatedInput
                                                validation={validation}
                                                type="text"
                                                field="addressLine1"
                                                maxLength={60}
                                                placeholder={t("Account.Subscription.AddressLine1Placeholder")}
                                                disableValidationUI
                                              />
                                            </Col>
                                          </Row>

                                          <Row className="mb-3">
                                            <Col>
                                              <Label htmlFor="addressLine2" className="float-start form-label">
                                                {t("Account.Subscription.AddressLine2")}
                                              </Label>
                                              <ValidatedInput
                                                validation={validation}
                                                type="text"
                                                field="addressLine2"
                                                maxLength={60}
                                                placeholder={t("Account.Subscription.AddressLine2Placeholder")}
                                                disableValidationUI
                                              />
                                            </Col>
                                          </Row>

                                          <Row className="mb-3">
                                            <Col>
                                              <Label htmlFor="city" className="float-start form-label">
                                                {t("Account.Subscription.City")}
                                                <span className="text-danger">*</span>
                                              </Label>
                                              <ValidatedInput
                                                validation={validation}
                                                type="text"
                                                field="city"
                                                maxLength={60}
                                                placeholder={t("Account.Subscription.CityPlaceholder")}
                                                disableValidationUI
                                              />
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col>
                                              <Label htmlFor="state" className="float-start form-label">
                                                {t("Account.Subscription.State")}
                                                <span className="text-danger">*</span>
                                              </Label>
                                              <ValidatedInput
                                                validation={validation}
                                                type="text"
                                                field="state"
                                                maxLength={20}
                                                placeholder={t("Account.Subscription.StatePlaceholder")}
                                                disableValidationUI
                                              />
                                            </Col>
                                            <Col>
                                              <Label htmlFor="zipCode" className="float-start form-label">
                                                {t("Account.Subscription.ZipCode")}
                                                <span className="text-danger">*</span>
                                              </Label>
                                              <Cleave
                                                name="zipCode"
                                                className={"form-control " + (validation.touched.zipCode && validation.errors.zipCode && "is-invalid")}
                                                placeholder={t("Account.Subscription.ZipCodePlaceholder")}
                                                value={validation.values.zipCode}
                                                onChange={validation.handleChange}
                                                options={{
                                                  blocks: [10],
                                                  numericOnly: false,
                                                  uppercase: true,
                                                }}
                                              />
                                              {validation.touched.zipCode && validation.errors.zipCode ? (
                                                <FormFeedback type="invalid">
                                                  <div>{validation.errors.zipCode}</div>
                                                </FormFeedback>
                                              ) : null}
                                            </Col>
                                          </Row>
                                        </Col>
                                        <Col lg={5} sm={12}>
                                          <h4 className="mb-4">{t("Account.Subscription.CreditCardDetails")}</h4>
                                          <div className="clearfix "></div>
                                          <Row className="mb-3">
                                            <Col>
                                              <Label htmlFor="cardName" className="text-start form-label px-0">
                                                {t("Account.Subscription.CardHolder")}
                                                <span className="text-danger">*</span>
                                              </Label>
                                              <ValidatedInput validation={validation} type="text" field="cardName" placeholder={t("Account.Subscription.CardHolderPlaceholder")} disableValidationUI />
                                            </Col>
                                          </Row>

                                          <Row className="mb-3">
                                            <Col>
                                              <Label htmlFor="cardNumber" className="text-start form-label px-0 ">
                                                {t("Account.Subscription.CardNumber")}
                                                <span className="text-danger">*</span>
                                              </Label>
                                              <Cleave
                                                name="cardNumber"
                                                className={"form-control " + (validation.touched.cardNumber && validation.errors.cardNumber && "is-invalid")}
                                                placeholder="**** **** **** ****"
                                                value={validation.values.cardNumber}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                options={{
                                                  creditCard: true,
                                                  creditCardStrictMode: true,
                                                }}
                                              />
                                              {validation.touched.cardNumber && validation.errors.cardNumber ? (
                                                <FormFeedback type="invalid">
                                                  <div>{validation.errors.cardNumber}</div>
                                                </FormFeedback>
                                              ) : null}
                                            </Col>
                                          </Row>

                                          <Row className="mb-3">
                                            <Col xl={6}>
                                              <Label htmlFor="expireDate" className="float-start form-label">
                                                {t("Account.Subscription.ExpireDate")}
                                                <span className="text-danger">*</span>
                                              </Label>
                                              <Cleave
                                                name="expireDate"
                                                className={"form-control " + (validation.touched.expireDate && validation.errors.expireDate && "is-invalid")}
                                                placeholder={t("Account.Subscription.ExpireDatePlaceholder")}
                                                value={validation.values.expireDate}
                                                onChange={validation.handleChange}
                                                options={{
                                                  date: true,
                                                  datePattern: ["m", "y"],
                                                }}
                                              />
                                              {validation.touched.expireDate && validation.errors.expireDate ? (
                                                <FormFeedback type="invalid">
                                                  <div>{validation.errors.expireDate}</div>
                                                </FormFeedback>
                                              ) : null}
                                            </Col>
                                            <Col xl={6}>
                                              <Label htmlFor="cvc" className="float-start form-label">
                                                {t("Account.Subscription.CVC")}
                                                <span className="text-danger">*</span>
                                              </Label>
                                              <Cleave
                                                name="cvc"
                                                className={"form-control " + (validation.touched.cvc && validation.errors.cvc && "is-invalid")}
                                                placeholder={t("Account.Subscription.CVC")}
                                                value={validation.values.cvc}
                                                onChange={validation.handleChange}
                                                options={{
                                                  blocks: [4],
                                                  numericOnly: true,
                                                }}
                                              />
                                              {validation.errors.cvc ? (
                                                <FormFeedback type="invalid">
                                                  <div>{validation.errors.cvc}</div>
                                                </FormFeedback>
                                              ) : null}
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col>
                                              <Alert color="dark" className="border-dashed">
                                                <div className="d-flex align-items-center">
                                                  <div className="row">
                                                    <h5 className="fs-14 text-primary fw-semibold mt-1"> {t("Account.Subscription.NewSubscription.CouponTitle")}</h5>
                                                    <p>{t("Account.Subscription.NewSubscription.CouponDescription")}</p>
                                                    <Row className="align-items-center">
                                                      <Col xs="auto">
                                                        <i className="mdi mdi-label-percent-outline display-5 text-success"></i>
                                                      </Col>
                                                      <Col>
                                                        <Cleave
                                                          name="couponCode"
                                                          className={"form-control " + (validation.touched.couponCode && validation.errors.couponCode && "is-invalid")}
                                                          placeholder={t("Account.Subscription.NewSubscription.CouponPlaceholder")}
                                                          value={validation.values.couponCode}
                                                          onChange={validation.handleChange}
                                                          disabled={couponStatus === "applied"}
                                                          options={{
                                                            blocks: [50],
                                                            numericOnly: false,
                                                          }}
                                                        />
                                                      </Col>
                                                      <Col xs="auto">
                                                        {couponStatus === "applied" ? (
                                                          <Button
                                                            type="button"
                                                            className="btn btn-sm btn-danger add-btn"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#showModal"
                                                            disabled={loading.coupon || validation.values.couponCode === "" ? true : false}
                                                            onClick={() => {
                                                              removeCoupon()(dispatch);
                                                              validation.values.couponCode = "";
                                                            }}
                                                          >
                                                            {loading.coupon ? <Spinner size="sm" className="me-2"></Spinner> : <i className="ri-close-line label-icon align-middle fs-16 me-2"></i>}
                                                            Remove
                                                          </Button>
                                                        ) : (
                                                          <Button
                                                            type="button"
                                                            className="btn btn-info btn-sm add-btn"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#showModal"
                                                            disabled={loading.coupon || validation.values.couponCode === "" ? true : false}
                                                            onClick={() => {
                                                              validateCoupon(validation.values.couponCode)(dispatch);
                                                            }}
                                                          >
                                                            {loading.coupon ? <Spinner size="sm" className="me-2"></Spinner> : <i className="ri-check-line label-icon align-middle fs-16 me-2"></i>}
                                                            {t("Apply")}
                                                          </Button>
                                                        )}
                                                      </Col>
                                                    </Row>
                                                    {couponStatus === "applied" && (
                                                      <Col>
                                                        {
                                                          <Alert color="success" className="mt-2">
                                                            {couponDetails.durationInMonth
                                                              ? t("Account.Subscription.CouponInformationByMonth", {
                                                                  amount: couponDetails.amount,
                                                                  duration: couponDetails.durationInMonth,
                                                                })
                                                              : t(`Account.Subscription.CouponInformationFor${_.capitalize(couponDetails.duration)}`, {
                                                                  amount: couponDetails.amount,
                                                                  duration: couponDetails.duration,
                                                                })}
                                                          </Alert>
                                                        }
                                                      </Col>
                                                    )}

                                                    <div className="hstack gap-3 px-3 mx-n3"></div>
                                                  </div>
                                                </div>
                                              </Alert>
                                            </Col>
                                          </Row>
                                        </Col>
                                        <Row className="mt-4 text-center">
                                          <div className="signin-other-title">
                                            <h5 className="fs-13 title">
                                              <Button id="btnStartFreeTrial" color="primary" className="rounded-pill" type="submit" disabled={loading.create}>
                                                {loading.create ? <Spinner size="sm" className="me-2"></Spinner> : null}
                                                {t("Account.Subscription.NewSubscription.SubmitButton")}
                                              </Button>
                                            </h5>
                                          </div>
                                        </Row>
                                      </Row>
                                    </Form>
                                  </div>
                                </Col>
                              </Row>
                              <div className="d-flex align-items-center gap-3 mt-4">
                                <button
                                  type="button"
                                  className="btn btn-light btn-label previestab"
                                  onClick={() => {
                                    localStorage.removeItem(LOCAL_STORAGE.SUBSCRIPTION);
                                    toggleArrowTab(activeArrowTab - 1);
                                  }}
                                >
                                  <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i> {t("Account.Subscription.NewSubscription.ChooseAPlan")}
                                </button>
                                <span className="text-muted right ms-auto">{t("Account.Subscription.NewSubscription.AuthorizationHoldNotice")}</span>
                              </div>
                            </TabPane>
                          </TabContent>
                        </div>
                      </CardBody>
                    </Card>
                  </CardBody>
                </Card>
              </Col>
            </Container>
          </div>
        </ParticlesAuth>
      </NonAuthLayout>
    </React.Fragment>
  );
};

export default NewSubscriptionPage;
