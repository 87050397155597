import {ConstantPage, PagePermissionItem, Permissions} from "helpers/permission_helper";
import {ApplicationUser} from "models/application_user";
import { UserLimitType } from "models/user_limit_usage_history";
import {useSelector} from "react-redux";
import {createSelector} from "reselect";
import {RootState} from "slices";

const useProfile = () => {
  const selectProperties = createSelector(
    (state: RootState) => state,
    (state) => ({
      userProfile: state.Login.user
    }),
  );

  const {userProfile} = useSelector(selectProperties);

  const getDailySearchLimit = () => {
    return userProfile?.remainingLimits?.remaining.find((x) => x.type === UserLimitType.SEARCH)?.dailyValue || 0;
  };

  const getDailyScanLimit = () => {
    return userProfile?.remainingLimits?.remaining.find((x) => x.type === UserLimitType.CRAWLER_SCAN)?.dailyValue || 0;
  }
  /**
   * Checks the permission for a specific page.
   *
   * @param page - The page to check the permission for.
   * @return The permissions for the page if found, undefined otherwise.
   */
  const getPagePermissions = (page: ConstantPage) => {
    const pagePermissions = Permissions.find((p: PagePermissionItem) => p.page === page);
    return pagePermissions;
  };

  /**
   * Checks if the user has permission for a specific page.
   *
   * @param page - The page to check the permission for.
   * @return True if the user has permission, false otherwise.
   */
  const hasPermission = (page: ConstantPage) => {
    const profile: ApplicationUser | undefined = userProfile;
    const pageRoles = Permissions.find((p: PagePermissionItem) => p.page === page)?.roles;
    if (!pageRoles) {
      return false;
    } else {
      return pageRoles.some((role) => profile?.roles.includes(role.name));
    }
  };

  /**
   * Checks if the user has the permission to create based on the provided page.
   *
   * @param page - The page to check the permission for.
   * @return True if the user has the permission to create, false otherwise.
   */

  const canCreate = (page: ConstantPage) => {
    const permissions = getPagePermissions(page);
    if (!permissions) {
      return false; // Page not found in permissions
    }

    const hasCreatePermission = permissions.roles.some((role) => {
      return userProfile?.roles.includes(role.name) && role.permissions.includes("create");
    });

    return hasCreatePermission;
  };

  /**
   * Checks if the user has the permission to read based on the provided page.
   *
   * @param page - The page to check the permission for.
   * @return True if the user has the permission to read, false otherwise.
   */
  const canRead = (page: ConstantPage) => {
    const permissions = getPagePermissions(page);
    if (!permissions) {
      return false; // Page not found in permissions
    }

    const hasCreatePermission = permissions.roles.some((role) => {
      return userProfile?.roles.includes(role.name) && role.permissions.includes("read");
    });

    return hasCreatePermission;
  };

  /**
   * Checks if the user has the permission to update based on the provided page.
   *
   * @param page - The page to check the permission for.
   * @return True if the user has the permission to update, false otherwise.
   */
  const canUpdate = (page: ConstantPage) => {
    const permissions = getPagePermissions(page);
    if (!permissions) {
      return false; // Page not found in permissions
    }

    const hasCreatePermission = permissions.roles.some((role) => {
      return userProfile?.roles.includes(role.name) && role.permissions.includes("update");
    });

    return hasCreatePermission;
  };

  /**
   * Checks if the user has the permission to delete based on the provided page.
   *
   * @param page - The page to check the permission for.
   * @return True if the user has the permission to delete, false otherwise.
   */
  const canDelete = (page: ConstantPage) => {
    const permissions = getPagePermissions(page);
    if (!permissions) {
      return false; // Page not found in permissions
    }

    const hasCreatePermission = permissions.roles.some((role) => {
      return userProfile?.roles.includes(role.name) && role.permissions.includes("delete");
    });

    return hasCreatePermission;
  };
  
  /**
   * Checks if the user has the "Admin" role
   * @return True if the user has the role "Admin", false otherwise.
   */
  const isAdmin = () => {
    const roles = userProfile?.roles;
    if (!roles) {
      return false; // Page not found in permissions
    }

    const userHasAdminRole = roles.some((role) => role === "Admin");
    return userHasAdminRole;
  };

  /**
   * Checks if the user is logged in.
   *
   * @return True if the user is logged in, false otherwise.
   */
  const isUserLoggedIn = () => {
    return !!userProfile;
  }


  return {userProfile, canCreate, canRead, canUpdate, canDelete, hasPermission, getDailySearchLimit, getDailyScanLimit, isAdmin, isUserLoggedIn};
};



export {useProfile};
