import React, {useEffect, useState} from "react";
import BreadCrumb, {BreadcrumbMenuItem} from "Components/Common/BreadCrumb";
import {useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {Card, CardBody, CardHeader, CardTitle, Col, Container, Form, Input, Label, Row} from "reactstrap";
import {NotificationSettingsForm} from "./form";
import {useDispatch, useSelector} from "react-redux";
import {getNotificationOptions, updateNotificationOptions} from "slices/account/thunk";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {ConstantPage} from "helpers/permission_helper";
import {useProfile} from "Components/Hooks/UserHooks";
import Checkbox from "Components/Common/Checkbox";
import ValidationWrapper from "Components/Common/ValidationWrapper";
import Loader from "Components/Common/Loader";
import ProfileLayout from "Components/Common/Layout/ProfileLayout";
import Restricted from "Components/Common/Restricted";
import Unauthorized from "pages/Errors/_Unauthorized";
import * as Yup from "yup";

type NotificationsSettings = keyof NotificationSettingsForm;
const PAGE_IDENTIFIER: ConstantPage = "account.notification";
const NotificationSettings = () => {
  const {t} = useTranslation();
  const {hasPermission} = useProfile();
  const dispatch: any = useDispatch();
  const [changedFields, setChangedFields] = useState<(keyof NotificationSettingsForm)[]>([]);
  useEffect(() => {
    if (hasPermission(PAGE_IDENTIFIER)) {
      getNotificationOptions()(dispatch);
    }
  }, [dispatch]); //eslint-disable-line

  const notificationData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Account.loading,
      notificationOptions: state.Account.notificationOptions,
    }),
  );
  const {loading, notificationOptions} = useSelector(notificationData);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: notificationOptions,
    validationSchema: Yup.object<NotificationSettingsForm>({
      emailAnalysisCompleted: Yup.boolean(),
      emailASINFetchCompleted: Yup.boolean(),
      emailShipmentCompleted: Yup.boolean(),
      emailNewOrder: Yup.boolean(),
      emailPaymentReceived: Yup.boolean(),
      emailPaymentFailed: Yup.boolean(),
      emailDataDeletion: Yup.boolean(),
      emailChange: Yup.boolean(),
      emailCancellation: Yup.boolean(),
      emailReactivation: Yup.boolean(),
      webAnalysisCompleted: Yup.boolean(),
      webASINFetchCompleted: Yup.boolean(),
      webShipmentCompleted: Yup.boolean(),
      webNewOrder: Yup.boolean(),
      webPaymentReceived: Yup.boolean(),
      webPaymentFailed: Yup.boolean(),
      webDataDeletion: Yup.boolean(),
      webChange: Yup.boolean(),
      webCancellation: Yup.boolean(),
      webReactivation: Yup.boolean(),
    }),
    onSubmit: async (values: NotificationSettingsForm) => {
      // Get the list of fields that are being updated
      const updatedFields: (keyof NotificationSettingsForm)[] = Object.keys(values).filter((key) => {
        const k = key as keyof NotificationSettingsForm;
        return values[k] !== validation.initialValues[k];
      }) as (keyof NotificationSettingsForm)[];

      setChangedFields(updatedFields);
      await updateNotificationOptions(values)(dispatch);
      setChangedFields([]);
    },
  });

  const handleNotificationAll = (checked: boolean, notificationKeys: string[]) => {
    const updatedOptions: Partial<NotificationSettingsForm> = {};

    Object.keys(notificationOptions).forEach((key) => {
      if (notificationKeys.includes(key)) {
        const notificationKey = key as keyof NotificationSettingsForm;
        updatedOptions[notificationKey] = checked;
      }
    });

    validation.setValues({
      ...validation.values,
      ...updatedOptions,
    });

    validation.handleSubmit();
  };

  const handle_generalEmailAll = (checked: boolean) => {
    const generalEmail: NotificationsSettings[] = ["emailAnalysisCompleted", "emailASINFetchCompleted", "emailShipmentCompleted", "emailNewOrder"];
    handleNotificationAll(checked, generalEmail);
  };

  const handle_generalWebAll = (checked: boolean) => {
    const generalWeb: NotificationsSettings[] = ["webAnalysisCompleted", "webASINFetchCompleted", "webShipmentCompleted", "webNewOrder"];
    handleNotificationAll(checked, generalWeb);
  };

  const handle_subscriptionEmailAll = (checked: boolean) => {
    const subscriptionEmail: NotificationsSettings[] = [
      "emailPaymentReceived",
      "emailPaymentFailed",
      "emailDataDeletion",
      "emailSubscriptionChange",
      "emailSubscriptionCancellation",
      "emailSubscriptionReactivation",
    ];
    handleNotificationAll(checked, subscriptionEmail);
  };

  const handle_subscriptionWebAll = (checked: boolean) => {
    const subscriptionWeb: NotificationsSettings[] = [
      "webPaymentReceived",
      "webPaymentFailed",
      "webDataDeletion",
      "webSubscriptionChange",
      "webSubscriptionCancellation",
      "webSubscriptionReactivation",
    ];
    handleNotificationAll(checked, subscriptionWeb);
  };

  const breadcrumbMenus: BreadcrumbMenuItem[] = [
    {
      label: t("Account.Title"),
      url: "#",
    },
    {
      label: t("Account.Notifications.Title"),
      url: "",
    },
  ];

  document.title = t("PageTitles.Notifications");

  return (
    <Restricted require={PAGE_IDENTIFIER} read fallback={() => <Unauthorized />}>
      <Form
        onSubmit={(e) => {
          validation.handleSubmit(e);
        }}
      >
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title={t("Account.Title")} menus={breadcrumbMenus} />
            <ProfileLayout hasAnyChanges={false} navigateTo="">
              <>
                {loading.list ? (
                  <Loader />
                ) : (
                  <>
                    <Card>
                      <CardHeader>
                        <Row>
                          <Col xs={6} md={8}>
                            <CardTitle className="fw-medium mb-0">
                              <i className="bx bxs-bell fs-18 align-middle me-1"></i>
                              {t("Account.Notifications.General.Title")}
                            </CardTitle>
                          </Col>
                          <Col xs={3} md={2}>
                            <Row className="d-flex align-items-center">
                              <Col xs="auto" className="pe-0">
                                <div className="form-check form-switch form-switch-md d-none d-md-block">
                                  <Input
                                    className="form-check-input "
                                    type="checkbox"
                                    role="switch"
                                    id="generalEmailNotification"
                                    checked={
                                      validation.values.emailAnalysisCompleted &&
                                      validation.values.emailASINFetchCompleted &&
                                      validation.values.emailShipmentCompleted &&
                                      validation.values.emailNewOrder
                                    }
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handle_generalEmailAll(e.target.checked)}
                                  />
                                </div>
                              </Col>
                              <Col className="ps-0">
                                <Label className="form-check-label fs-14 user-select-none text-nowrap" htmlFor="generalEmailNotification">
                                  {t("Email")}
                                </Label>
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={3} md={2}>
                            <Row className="d-flex align-items-center">
                              <Col xs="auto" className="pe-0">
                                <div className="form-check form-switch form-switch-md user-select-none d-none d-md-block">
                                  <Input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    id="generalWebNotification"
                                    checked={
                                      validation.values.webAnalysisCompleted && validation.values.webASINFetchCompleted && validation.values.webShipmentCompleted && validation.values.webNewOrder
                                    }
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handle_generalWebAll(e.target.checked)}
                                  />
                                </div>
                              </Col>
                              <Col className="ps-0">
                                <Label className="form-check-label fs-14" htmlFor="generalWebNotification">
                                  {t("Web")}
                                </Label>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <Row className="mb-3">
                          <Col xs={6} md={8}>
                            <h6 className="fs-14 mb-1">{t("Account.Notifications.General.AnalysisCompleted")}</h6>
                            <p className="text-muted">{t("Account.Notifications.General.AnalysisCompletedDescription")}</p>
                          </Col>
                          <Col xs={3} md={2}>
                            <ValidationWrapper validation={validation} field="emailAnalysisCompleted" submitOnChange disabled={changedFields.includes("emailAnalysisCompleted")}>
                              <Checkbox switch />
                            </ValidationWrapper>
                          </Col>
                          <Col xs={3} md={2}>
                            <ValidationWrapper validation={validation} field="webAnalysisCompleted" submitOnChange disabled={changedFields.includes("webAnalysisCompleted")}>
                              <Checkbox switch />
                            </ValidationWrapper>
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col xs={6} md={8}>
                            <h6 className="fs-14 mb-1">{t("Account.Notifications.General.AsinFetchCompleted")}</h6>
                            <p className="text-muted">{t("Account.Notifications.General.AsinFetchCompletedDescription")}</p>
                          </Col>
                          <Col xs={3} md={2}>
                            <ValidationWrapper validation={validation} field="emailASINFetchCompleted" submitOnChange disabled={changedFields.includes("emailASINFetchCompleted")}>
                              <Checkbox switch />
                            </ValidationWrapper>
                          </Col>
                          <Col xs={3} md={2}>
                            <ValidationWrapper validation={validation} field="webASINFetchCompleted" submitOnChange disabled={changedFields.includes("webASINFetchCompleted")}>
                              <Checkbox switch />
                            </ValidationWrapper>
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col xs={6} md={8}>
                            <h6 className="fs-14 mb-1">{t("Account.Notifications.General.ShipmentCompleted")}</h6>
                            <p className="text-muted">{t("Account.Notifications.General.ShipmentCompletedDescription")}</p>
                          </Col>
                          <Col xs={3} md={2}>
                            <ValidationWrapper validation={validation} field="emailShipmentCompleted" submitOnChange disabled={changedFields.includes("emailShipmentCompleted")}>
                              <Checkbox switch />
                            </ValidationWrapper>
                          </Col>
                          <Col xs={3} md={2}>
                            <ValidationWrapper validation={validation} field="webShipmentCompleted" submitOnChange disabled={changedFields.includes("webShipmentCompleted")}>
                              <Checkbox switch />
                            </ValidationWrapper>
                          </Col>
                        </Row>

                        <Row>
                          <Col xs={6} md={8}>
                            <h6 className="fs-14 mb-1">{t("Account.Notifications.General.NewOrder")}</h6>
                            <p className="text-muted mb-0">{t("Account.Notifications.General.NewOrderDescription")}</p>
                          </Col>
                          <Col xs={3} md={2}>
                            <ValidationWrapper validation={validation} field="emailNewOrder" submitOnChange disabled={changedFields.includes("emailNewOrder")}>
                              <Checkbox switch />
                            </ValidationWrapper>
                          </Col>
                          <Col xs={3} md={2}>
                            <ValidationWrapper validation={validation} field="webNewOrder" submitOnChange disabled={changedFields.includes("webNewOrder")}>
                              <Checkbox switch />
                            </ValidationWrapper>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Card>
                      <CardHeader>
                        <Row>
                          <Col xs={6} md={8}>
                            <CardTitle className="fw-medium mb-0">
                              <i className="mdi mdi-credit-card-outline fs-18 align-middle me-1"></i>
                              {t("Account.Notifications.Subscription.Title")}
                            </CardTitle>
                          </Col>
                          <Col xs={3} md={2}>
                            <Row className="d-flex align-items-center">
                              <Col xs="auto" className="pe-0">
                                <div className="form-check form-switch form-switch-md user-select-none d-none d-md-block">
                                  <Input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    id="subscriptionEmailNotification"
                                    checked={
                                      validation.values.emailPaymentReceived &&
                                      validation.values.emailPaymentFailed &&
                                      validation.values.emailDataDeletion &&
                                      validation.values.emailSubscriptionChange &&
                                      validation.values.emailSubscriptionCancellation &&
                                      validation.values.emailSubscriptionReactivation
                                    }
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handle_subscriptionEmailAll(e.target.checked)}
                                  />
                                </div>
                              </Col>
                              <Col className="ps-0">
                                <Label className="form-check-label fs-14 text-nowrap" htmlFor="subscriptionEmailNotification">
                                  {t("Email")}
                                </Label>
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={3} md={2}>
                            <Row className="d-flex align-items-center">
                              <Col xs="auto" className="pe-0">
                                <div className="form-check form-switch form-switch-md user-select-none d-none d-md-block">
                                  <Input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    id="subscriptionWebNotification"
                                    checked={
                                      validation.values.webPaymentReceived &&
                                      validation.values.webPaymentFailed &&
                                      validation.values.webDataDeletion &&
                                      validation.values.webSubscriptionChange &&
                                      validation.values.webSubscriptionCancellation &&
                                      validation.values.webSubscriptionReactivation
                                    }
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handle_subscriptionWebAll(e.target.checked)}
                                  />
                                </div>
                              </Col>
                              <Col className="ps-0">
                                <Label className="form-check-label fs-14" htmlFor="subscriptionWebNotification">
                                  {t("Web")}
                                </Label>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <Row className="mb-3">
                          <Col xs={6} md={8}>
                            <h6 className="fs-14 mb-1">{t("Account.Notifications.Subscription.PaymentReceived")}</h6>
                            <p className="text-muted">{t("Account.Notifications.Subscription.PaymentReceivedDescription")}</p>
                          </Col>
                          <Col xs={3} md={2}>
                            <ValidationWrapper validation={validation} field="emailPaymentReceived" submitOnChange disabled={changedFields.includes("emailPaymentReceived")}>
                              <Checkbox switch />
                            </ValidationWrapper>
                          </Col>
                          <Col xs={3} md={2}>
                            <ValidationWrapper validation={validation} field="webPaymentReceived" submitOnChange disabled={changedFields.includes("webPaymentReceived")}>
                              <Checkbox switch />
                            </ValidationWrapper>
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col xs={6} md={8}>
                            <h6 className="fs-14 mb-1">{t("Account.Notifications.Subscription.PaymentFailed")}</h6>
                            <p className="text-muted">{t("Account.Notifications.Subscription.PaymentFailedDescription")}</p>
                          </Col>
                          <Col xs={3} md={2}>
                            <ValidationWrapper validation={validation} field="emailPaymentFailed" submitOnChange disabled={changedFields.includes("emailPaymentFailed")}>
                              <Checkbox switch />
                            </ValidationWrapper>
                          </Col>
                          <Col xs={3} md={2}>
                            <ValidationWrapper validation={validation} field="webPaymentFailed" submitOnChange disabled={changedFields.includes("webPaymentFailed")}>
                              <Checkbox switch />
                            </ValidationWrapper>
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col xs={6} md={8}>
                            <h6 className="fs-14 mb-1">{t("Account.Notifications.Subscription.DataDeletion")}</h6>
                            <p className="text-muted">{t("Account.Notifications.Subscription.DataDeletionDescription")}</p>
                          </Col>
                          <Col xs={3} md={2}>
                            <ValidationWrapper validation={validation} field="emailDataDeletion" submitOnChange disabled={changedFields.includes("emailDataDeletion")}>
                              <Checkbox switch />
                            </ValidationWrapper>
                          </Col>
                          <Col xs={3} md={2}>
                            <ValidationWrapper validation={validation} field="webDataDeletion" submitOnChange disabled={changedFields.includes("webDataDeletion")}>
                              <Checkbox switch />
                            </ValidationWrapper>
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col xs={6} md={8}>
                            <h6 className="fs-14 mb-1">{t("Account.Notifications.Subscription.Change")}</h6>
                            <p className="text-muted">{t("Account.Notifications.Subscription.ChangeDescription")}</p>
                          </Col>
                          <Col xs={3} md={2}>
                            <ValidationWrapper validation={validation} field="emailSubscriptionChange" submitOnChange disabled={changedFields.includes("emailSubscriptionChange")}>
                              <Checkbox switch />
                            </ValidationWrapper>
                          </Col>
                          <Col xs={3} md={2}>
                            <ValidationWrapper validation={validation} field="webSubscriptionChange" submitOnChange disabled={changedFields.includes("webSubscriptionChange")}>
                              <Checkbox switch />
                            </ValidationWrapper>
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col xs={6} md={8}>
                            <h6 className="fs-14 mb-1">{t("Account.Notifications.Subscription.Cancellation")}</h6>
                            <p className="text-muted">{t("Account.Notifications.Subscription.CancellationDescription")}</p>
                          </Col>
                          <Col xs={3} md={2}>
                            <ValidationWrapper validation={validation} field="emailSubscriptionCancellation" submitOnChange disabled={changedFields.includes("emailSubscriptionCancellation")}>
                              <Checkbox switch />
                            </ValidationWrapper>
                          </Col>
                          <Col xs={3} md={2}>
                            <ValidationWrapper validation={validation} field="webSubscriptionCancellation" submitOnChange disabled={changedFields.includes("webSubscriptionCancellation")}>
                              <Checkbox switch />
                            </ValidationWrapper>
                          </Col>
                        </Row>

                        <Row>
                          <Col xs={6} md={8}>
                            <h6 className="fs-14 mb-1">{t("Account.Notifications.Subscription.Reactivation")}</h6>
                            <p className="text-muted mb-0">{t("Account.Notifications.Subscription.ReactivationDescription")}</p>
                          </Col>
                          <Col xs={3} md={2}>
                            <ValidationWrapper validation={validation} field="emailSubscriptionReactivation" submitOnChange disabled={changedFields.includes("emailSubscriptionReactivation")}>
                              <Checkbox switch />
                            </ValidationWrapper>
                          </Col>
                          <Col xs={3} md={2}>
                            <ValidationWrapper validation={validation} field="webSubscriptionReactivation" submitOnChange disabled={changedFields.includes("webSubscriptionReactivation")}>
                              <Checkbox switch />
                            </ValidationWrapper>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </>
                )}
              </>
            </ProfileLayout>
          </Container>
        </div>
      </Form>
    </Restricted>
  );
};

export default NotificationSettings;
